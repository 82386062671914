import { Injectable, OnDestroy } from '@angular/core';
import { interval, Subject, Subscription, BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { HighLeitMessage, HighLeitMessageType } from '../interfaces/websocket-messages';
import { environment } from './../../../environments/environment';
import { ConnectionStatusService } from './connection-status.service';
import {SnackbarMessagesService} from './snackbar-messages-service.';
import Config from '../../config/Config';
import { ConsoleLoggingService } from  './console-logging.service';

export enum UpdateReceiverMessage {
    NewColors = 'neue Farben!',
    NewStates = 'neue Schalterzustände!',
    NewMarkers = 'neue Marker!',
    AuthError = 'Authentifizierungsfehler.',
    Authenticated = 'Erfolgreich angemeldet.'
}

@Injectable({
    providedIn: 'root',
})
export class UpdateReceiverService implements OnDestroy {

    public connectionStatusService: ConnectionStatusService;

    public subject: Subject<HighLeitMessage> = new Subject<HighLeitMessage>();

    // public set isConnected(conn: boolean) {
    //     // this.logging.logDebug('isConnected', conn);
    //     this._isConnected = conn;
    //     this.webSocketState.next(this._isConnected);
    // }

    // public get isConnected() {
    //     return this._isConnected;
    // }

    private webSocketState = new BehaviorSubject<boolean>(this.isConnected());

    public webSocketState$ = this.webSocketState.asObservable();

    private socket: WebSocket;

    private wsUrl = '';

    private usePing = false;

    private isAuthenticated = false;

    private isAuthError = false;

    private _isConnected = false;

    private ping$ = interval(50000);

    private subscriptions = new Subscription();

    private pingSub: Subscription;

    constructor(
        private snackbar: SnackbarMessagesService, private logging: ConsoleLoggingService
    ) { }

    public init(): Promise<boolean> {
        let statePromise = new Promise<boolean>( res => res(this.isConnected()));
        if (!this.isConnected()) {
            this.determineWsUrl();
            statePromise = this.connect();
            this.subject.subscribe({
                error: (error) => this.onConnectError(error),
                complete: () => {
                    this.logging.logDebug('WebSocket subject is complete');
                    this.socket.close();
                },
            });
        } else {
            this.webSocketState.next(this.isConnected());
        }
        return statePromise;
    }

    private onConnectError(error){
        this.logging.log('Error :', error);
        this.snackbar.displayErrorDebug(error, null);
    }

    ngOnDestroy() {
        this.subject.complete();
        this.subscriptions.unsubscribe();
    }

    public isConnected() {
        return this.socket ? this.socket.readyState === this.socket.OPEN : false;
    }

    public isConnecting() {
        return this.socket ? this.socket.readyState === this.socket.CONNECTING : false;
    }

    public checkConnection(): Promise<boolean> {
        if (!this.isConnected() && !!Config.frontend) {
            return this.init();
        }else{
            return new Promise<boolean>( res => res(this.isConnected()));
        }
    }

    private determineWsUrl() {
        const isWsUrlAbsolute = environment.webSocketUrl ? environment.webSocketUrl.indexOf('://') !== -1 : false;

        if (isWsUrlAbsolute) {
            this.wsUrl = environment.webSocketUrl;
        } else {
            const wsp = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
            const hostname = window.location.hostname;
            const port = window.location.port;
            // const endp = JSON.parse(environment.webSocketUrl) || '';
            const endp = environment.webSocketUrl || '';
            this.wsUrl = `${wsp}//${hostname}:${port}${endp}`;
        }

        // this.wsUrl = 'ws://192.168.18.22/ws';
    }

    private connect(): Promise<boolean> {

        return new Promise<boolean>( (res) => {
            try {
                if (!this.isConnecting()) {
                    this.socket = new WebSocket(this.wsUrl);
                    this.socket.onopen = event => {
                        this.logging.logDebug('WebSocket Connected');
                        // this.isConnected = true;
                        // setTimeout(() => {
                        this.webSocketState.next(this.isConnected());
                        this.subscriptToWebSocketEvents();
                        this.authenticate();
                        this.snackbar.displaySuccessDebug('Websocket: successfully connected', );
                        res(true);
                        // }, 250);
                    };
                }
                res(true);

            } catch (err) {
                // this.isConnected = false;
                this.socket = null;
                console.error('Error', 'Connecting WebSocket failed, URL:', this.wsUrl, err);
                this.snackbar.displayErrorDebug(`Websocket: Error, Connecting WebSocket failed, URL: ${this.wsUrl}`);
                res(false);
            }
        });
    }

    private disconnect() {
        this.snackbar.displayErrorDebug('Websocket: Verbindung geschlossen');
        this.socket.close();
    }

    private subscriptToWebSocketEvents() {

        this.socket.onerror = err => {
            console.error('WebSocket Error');
            this.logging.logDebug('Error: ',err);
        };

        this.socket.onmessage = event => {
            const msg = event.data;
            this.snackbar.displaySuccessDebug(`Websocket: recive Event, ${msg}`);
            const updateMessage = msg as UpdateReceiverMessage;

            switch (updateMessage) {
                case UpdateReceiverMessage.AuthError:
                    this.isAuthenticated = false;
                    this.isAuthError = true;
                    this.snackbar.displayErrorDebug(`Websocket: Error, ${updateMessage}`);
                    break;
                case UpdateReceiverMessage.Authenticated:
                    this.snackbar.displaySuccessDebug(`Websocket: ${updateMessage}`);
                    this.isAuthenticated = true;
                    this.isAuthError = false;
                    this.ping();
                    break;
            }

            if (this.isAuthenticated) {
                let _type = HighLeitMessageType.RefreshLayer;
                switch (updateMessage) {
                    case UpdateReceiverMessage.NewColors:
                        _type = HighLeitMessageType.NewColors;
                        break;
                    case UpdateReceiverMessage.NewMarkers:
                        _type = HighLeitMessageType.NewMarkers;
                        break;
                    case UpdateReceiverMessage.NewStates:
                        _type = HighLeitMessageType.NewStates;
                        break;
                }
                const hlMsg: HighLeitMessage = { type: _type, hlId: null };
                this.subject.next(hlMsg);
            }
        };

        this.socket.onclose = event => {
            this.logging.logDebug('WebSocket closed with code ', event.code);
            this.snackbar.displayErrorDebug(`WebSocket: closed with code ${event.code}`);
            this.socket.close();
            this.socket = null;
            // this.isConnected = false;
            this.webSocketState.next(this.isConnected());
            if (event.code === 1006) {
                this.logging.logDebug('--', 'will initiate reconnect with ping');
                this.snackbar.displayErrorDebug(`WebSocket: will initiate reconnect with ping`);
                this.usePing = true;
                this.connect();
            }
        };

    }

    private authenticate() {
        const sessionId = localStorage.getItem('sessionId');
        const appId = uuidv4();
        const authMessage = `auth: ${sessionId},{${appId}}`;
        this.socket.send(authMessage);
    }

    private ping() {
        if (this.usePing) {
            this.pingSub = this.ping$.subscribe(() => {
                if (this.isConnected()) {
                    this.socket.send('ping');
                    this.snackbar.displaySuccessDebug('Websocket: Client send Ping');
                } else {
                    this.pingSub.unsubscribe();
                }
            });

            this.subscriptions.add(this.pingSub);
        }
    }
}
