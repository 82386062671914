import { FrontendConfig } from './shared/interfaces/frontend.config';
import { HashLocationStrategy, LocationStrategy, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, DoBootstrap, InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorMessagePipe } from './error/error-message.pipe';
import { ErrorComponent } from './error/error.component';
import { LoginComponent } from './login/login.component';
import { MapsModule } from './maps/maps.module';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { MaterialModule } from './shared/modules/material.module';
import { StatefulAuthenticationService } from './shared/services/authentication/stateful-authentication.service';
import Config, { AppConfigInterface } from './config/Config';


export const APP_CONFIG = new InjectionToken<AppConfigInterface>('app.config');

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ErrorComponent,
        ErrorMessagePipe
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        MapsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        BrowserAnimationsModule,
    ],
    providers: [
        ...environment.providers,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: APP_CONFIG,
            useValue: Config
        }
    ],
})
export class AppModule implements DoBootstrap {
    constructor(
        private statefulAuth: StatefulAuthenticationService
    ) { }

    ngDoBootstrap(app: ApplicationRef): void {

        const oauthState = window.location.search;

        this.statefulAuth
            .bootstrapAuthService2(oauthState)
            .subscribe({
                next: () => {
                    app.bootstrap(AppComponent);
                },
                error: err => {
                    const ref = app.bootstrap(ErrorComponent, 'app-root');
                    ref.instance.addError(err);
                    console.error(err);
                }
            });
    }
}
