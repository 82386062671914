import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import Config from 'src/app/config/Config';
import { environment } from 'src/environments/environment';
import { ApiAuthMethodType, ApiLoginResult } from '../../interfaces/api-login-result';
import { AuthBase, AuthBaseConfig } from './auth.interface';
import { AuthenticationStateType } from './stateful-authentication.service';

export class BasicAuth extends AuthBase {

    public authMethod = ApiAuthMethodType.Basic;

    constructor({ httpClient, userService }: AuthBaseConfig) {
        super({ httpClient, userService });
    }


    async login(...params: any[]) {
        this.authAnswer.next({ state: AuthenticationStateType.AwaitingUserLogin });
    }

    logout(sessionId: string): Observable<any> | Promise<any> {
        return super.logout(sessionId);
    }

    submit(username: string, password: string) {
        const url = `${environment.apiRoot}${Config.Api.basicAuth}`;
        const encoded = window.btoa(`${username}:${password}`);
        let header = new HttpHeaders();
        header = header.set('Authorization', `Basic ${encoded}`);
        return this.httpClient.post<ApiLoginResult>(url, null, { headers: header });
    }

}
