import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, HostListener, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import packageJson from 'package.json';
import { filter, fromEvent, Observable, Subscription, take } from 'rxjs';
import Config from 'src/app/config/Config';
import version from 'src/app/config/version';
import { environment } from 'src/environments/environment';

import { StatefulAuthenticationService } from './../../shared/services/authentication/stateful-authentication.service';
import { I18nService } from './../../shared/services/i18n.service';
import { UserProfile, UserService } from './../../shared/services/user.service';



@Component({
    selector: 'app-user-account',
    templateUrl: './user-account.component.html',
    styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {

    @ViewChild('profileBox') profileBox: TemplateRef<any>;

    public currentLocaleId: string;

    public multiLanguage = Config.frontend?.multiLanguage || false;
    public logoutEnabled = true;
    public showUserAccountButton = this.multiLanguage || this.logoutEnabled;
    public isMobile = environment.mobile;

    public languages = [
        {
            id: 'de',
            name: $localize`:@@german:German`
        },
        {
            id: 'en',
            name: $localize`:@@english:English`
        }
    ];

    public currentLang;

    public frontendVersion = version;

    public userProfile$: Observable<UserProfile>;

    public isOpen = false;
    private userBoxOverlayRef: OverlayRef | null;
    private overlayClickSub: Subscription;


    constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private auth: StatefulAuthenticationService,
        private i18nService: I18nService,
        private userService: UserService) {

        this.currentLocaleId = this.i18nService.currentLocaleId;
        this.currentLang = this.languages.find(x => x.id === this.currentLocaleId);

        if (environment.develop) {
            this.frontendVersion = this.frontendVersion.replace('__VERSION__', `v${packageJson.version} - prod`);
        }
    }

    ngOnInit(): void {
        this.userProfile$ = this.userService.userProfile$;
    }

    @HostListener('window:resize')
    public resizeWindow(): void {
        if (this.isOpen && this.userBoxOverlayRef) {
            this.userBoxOverlayRef.updatePositionStrategy(
                this.setOverlayPosition()
            );
            this.userBoxOverlayRef.updatePosition();
        }
    }

    logout() {
        this.auth.logout();
    }

    switchLanguage(id: string) {
        this.i18nService.switchLanguage(id);
    }

    languageSelected(event) {
        this.switchLanguage(event.value.id);
    }


    closeUserBox() {
        if (this.overlayClickSub) {
            this.overlayClickSub.unsubscribe();
        }
        if (this.userBoxOverlayRef) {
            this.userBoxOverlayRef.dispose();
            this.userBoxOverlayRef = null;
        }
    }

    openUserBox({ x, y }: MouseEvent) {
        if (this.isOpen) {
            this.closeUserBox();
        } else {
            setTimeout(() => {
                this.showMenu();
            });
        }
        this.isOpen = !this.isOpen;
    }

    private setOverlayPosition() {
        const xmid = window.innerWidth - (275 * 0.5) - 10;
        const ytop = 10 + 56;
        return this.overlay
            .position()
            .flexibleConnectedTo({ x: xmid, y: ytop })
            .withPositions([
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top',
                },
            ]);
    }

    private showMenu() {

        const config = new OverlayConfig({
            panelClass: 'user-account-profile-box',
            positionStrategy: this.setOverlayPosition(),
            scrollStrategy: this.overlay.scrollStrategies.close(),
        });

        this.userBoxOverlayRef = this.overlay.create(config);

        this.userBoxOverlayRef.attach(
            new TemplatePortal(this.profileBox, this.viewContainerRef, {
                $implicit: {},
            })
        );

        this.overlayClickSub = fromEvent<MouseEvent>(document, 'click')
            .pipe(
                filter((event) => {
                    const clickTarget = event.target as HTMLElement;
                    const backdropLangClass = 'cdk-overlay-backdrop';
                    const isLanguageSwitcherBackgrop = clickTarget.classList.contains(backdropLangClass);
                    if (isLanguageSwitcherBackgrop) {
                        return false;
                    } else {
                        return (!!this.userBoxOverlayRef && !this.userBoxOverlayRef.overlayElement.contains(clickTarget));
                    }
                }),
                take(1)
            )
            .subscribe(() => {
                this.closeUserBox();
                this.isOpen = false;
            });

    }

}
