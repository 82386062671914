<button mat-fab
        id="layer-menu-button"
        i18n-aria-label="@@markerMenu"
        aria-label="Marker Menu"
        [matMenuTriggerFor]="layerMenu">
    <mat-icon >layers</mat-icon>
</button>

<mat-menu #layerMenu="matMenu" [hasBackdrop]="true">
    <div id="system-layers-menu">
        <mat-list class="system-layers-list">
            <mat-list-item (click)="$event.stopPropagation()">
                <div>
                    <input type="checkbox" #indeterminateInput (change)="toggleAllLayers($event);" checked />
                    <span i18n="@@layersMenu.hideShowAll">Hide/Show all Layers</span>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngIf="layers.length > 0" id="layers-menu">
      <mat-list class="layers-list">
        <ng-container *ngFor="let layer of layers | sortLayers; let i = index">
          <app-layers-menu-item [layer]="layer" [overViewLayer]="getOverlayLayerfromLayer(layer.get('apiLayer'))"
            [sortable]="true"
            [refreshable]="true"
            [index]="i"
            [length]="layers.length"
            (moveUp)="moveUp(i)"
            (moveDown)="moveDown(i)"
            (visbilityChanged)="checkIndeterminate()"
            (refreshLayer)="refreshLayer.emit($event)"
            class="layer-list-item"
            (click)="$event.stopPropagation()"></app-layers-menu-item>
        </ng-container>
      </mat-list>
    </div>
</mat-menu>
