<input type="file"
       #geojsonFileInput
       id="geoJsonUploadFile"
       (change)="uploadGeoJson($event)"
       [hidden]="true"
       accept=".geojson"/>
<input type="file"
       #esriFileInput
       id="esriUploadFile"
       (change)="uploadEsri($event)"
       [hidden]="true"
       accept=".zip"/>
<div class="geojson-upload-menu">
    <button mat-mini-fab color="white" [matMenuTriggerFor]="uploadMenu" >
        <mat-icon>file_upload</mat-icon>
    </button>

    <button mat-mini-fab color="white" *ngIf="uploaded" (click)="remove()">
        <mat-icon>highlight_off</mat-icon>
    </button>

    <button mat-mini-fab color="white" *ngIf="uploaded" (click)="toggleUpload.emit()">
        <mat-icon *ngIf="layerVisible">visibility</mat-icon>
        <mat-icon *ngIf="!layerVisible">visibility_off</mat-icon>
    </button>
</div>

<mat-menu #uploadMenu="matMenu" [hasBackdrop]="true" [overlapTrigger]="false">
    <button mat-menu-item (click)="geojsonFileInput.click()">GeoJSON</button>
    <button mat-menu-item (click)="esriFileInput.click()">ESRI Shape</button>
</mat-menu>
