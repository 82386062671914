<ng-container *ngIf="authState$ | async; let authState">
    <mat-card *ngIf="authState.state === 'awaitingUserLogin' && authState.method === 'basic'">
        <mat-card-title>Login</mat-card-title>
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="submit()">
                <p>
                    <mat-form-field>
                        <input type="text" matInput placeholder="Username" formControlName="username" id="username" name="username">
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field>
                        <input type="password" matInput placeholder="Password" formControlName="password" id="password" name="password">
                    </mat-form-field>
                </p>

                <p *ngIf="error" class="error">
                    {{ error }}
                </p>

                <div class="button">
                    <button type="submit" mat-button id="login" >Login</button>
                </div>

            </form>
        </mat-card-content>
    </mat-card>
    <div class="mobile-login-loading" *ngIf="authState.state !== 'awaitingUserLogin'">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
</ng-container>
