import {Injectable, Type} from '@angular/core';
import {transform} from 'ol/proj';
import {ApiIdent} from 'src/app/shared/interfaces/api-ident';
import {ProjectionsService} from 'src/app/shared/services/projections.service';
import {SchemaIdentDetailsComponent} from './default/schema-ident-details/schema-ident-details.component';
import {DynamicComponent} from './dynamic-component';
import Config from 'src/app/config/Config';
import { ConsoleLoggingService } from '../../shared/services/console-logging.service';

@Injectable()
export abstract class CustomConfigService {

    protected id = Math.random() * 11;

    constructor(protected projectionService: ProjectionsService, private logging: ConsoleLoggingService) {
    }

    searchResultTransformCoordinates(originalCoords: number[]): number[] {
        const coords = transform(originalCoords, this.projectionService.epsg25832Projection, this.projectionService.epsg3857Projection);
        this.logging.logDebug('CustomConfig, searchResultTransformCoordinates', {id:this.id, coords});
        return coords;
    }

    popupPanelTitle(prop: any): string {
        return prop.data.Name;
    }

    identDetailsComponent<T>(prop: T): { component: Type<DynamicComponent<ApiIdent>>; data: T } {
        return { component: SchemaIdentDetailsComponent, data: prop };
    }

    getSearchOpts(){
        return Config.frontend.searchOpts;
    }
}






