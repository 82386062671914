import { Injectable, OnDestroy } from '@angular/core';
import { FeatureCollection } from 'geojson';
import Feature from 'ol/Feature';
import { MultiPoint } from 'ol/geom';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import MultiLineString from 'ol/geom/MultiLineString';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { Subject, Subscription } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import Config from 'src/app/config/Config';
import { ApiSchemaService } from '../api-services/api-schema.service';
import { ApiSchema } from '../interfaces/api-schema';
import { Utilities } from './../../utils/Utilities';
import { ApiMarkersService } from './../api-services/api-markers.service';
import { ApiMeasurementService } from './../api-services/api-measurement.service';
import { ApiOperationsService } from './../api-services/api-operations.service';
import { ApiStateValueService } from './../api-services/api-statevalue.service';
import { ApiMeasurementElement } from './../interfaces/api-measurement';
import { ApiStateValueStatus } from './../interfaces/api-statevalue';
import { StyleService } from './style.service';
import { UpdateFeatureService } from './updatefeature.service';
import {Coordinate} from 'ol/coordinate';
import { ConsoleLoggingService } from  './console-logging.service';

export enum SchemaObjectType {
    Numeric = 'NUMERIC',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Text = 'TEXT',
    Symbol = 'SYMBOL',
    Line = 'LINE'
}

export enum SchemaObjectSubType {
    Location = 'LOCATION',
    RtuStateName = 'RTU_STATE_NAME',
    PvSupply = 'PV_SUPPLY',
    ExcitationUL = 'EXCITATION_UL',
    ExcitationULL = 'EXCITATION_ULL',
    Trip = 'TRIP',
    RtuError = 'RTU_ERROR',
    RtuEarning = 'RTU_WARNING',
    DoorContact = 'DOOR_CONTACT',
    UpsWarning = 'UPS_WARNING',
    VoltMissing = 'VOLT_MISSING',
    RtuStateRtGn = 'RTU_STATE_RT_GN',
    RtuStateGnRt = 'RTU_STATE_GN_RT',
    RtuStateGnGr = 'RTU_STATE_GN_GR',
    RtuStateGrGn = 'RTU_STATE_GR_GN',
    Busbar = 'BUSBAR',
    BayNumber = 'BAY_NUMBER',
    Line = 'LINE',
    Fuse = 'FUSE',
    Label = 'LABEL',
    BayName = 'BAY_NAME',
    EquipmentType = 'EQUIPMENT_TYPE',
    MwP = 'MW_P',
    MwQ = 'MW_Q',
    MwI = 'MW_I',
    MwU = 'MW_U',
    Transformer = 'TRANSFORMER',
}

export interface GetMarkerConfig {
    type: 'leitung' | 'knoten';
    vivavisId: string;
}

@Injectable({
    providedIn: 'root'
})
export class SchemaService implements OnDestroy {

    private numericValueSubject = new Subject<ApiMeasurementElement>();

    private subscription = new Subscription();

    constructor(private apiSchemaService: ApiSchemaService,
        private apiMeasurementService: ApiMeasurementService,
        private apiStateValueService: ApiStateValueService,
        private apiOperationsService: ApiOperationsService,
        private updateFeatureService: UpdateFeatureService,
        private apiMarkersService: ApiMarkersService,
        private styleService: StyleService,
        private logging: ConsoleLoggingService) {


            // const sub = this.updateFeatureService.updateNumericValue$.subscribe(message => {
            //     this.numericValueSubject.next({
            //         vivavisId: message.vivavisId,
            //         // values: [{
            //             // name: '',
            //             unit: '',
            //             value: message.value
            //             // }]
            //     });
            // });

            // this.subscription.add(sub);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public changeSwitchStateValue(vivavisId: string, state: ApiStateValueStatus) {
        return this.apiOperationsService.checkOperation(vivavisId, state);
    }

    public executeSwitchStateValueChange(vivavisId: string, state: ApiStateValueStatus, scadaLocks: any[], topoLocks: any[]) {
        return this.apiOperationsService.executeOperation(vivavisId, state, scadaLocks, topoLocks);
    }

    public getStateValues(ids: string[]) {
        return this.apiStateValueService.getStateValues(ids);
    }

    /**
     *
     * @deprecated
     */
    public getBatchStateValues(vivavisIds: string[]) {
        // const reqs = vivavisIds.map(x => this.apiStateValueService.getStateValue(x));
        // return forkJoin(reqs);
    }

    public getMeasurements(vivavisIds: string[], externCall?: any) {
        const url = (externCall) ? externCall.url : undefined;
        return this.apiMeasurementService.getMeasurements(vivavisIds, url).pipe(tap(data => {
            for(const measurement of data) {
                this.numericValueSubject.next(measurement);
            }
        }));
    }

    /**
     *
     * @deprecated
     */
    public getBatchNumericValues(vivavisIds: string[]) {

        // const reqs = vivavisIds.map(x => this.apiMeasurementService.getMeasurement(x));

        // return forkJoin(reqs)
        // .pipe(
        //     catchError(err => {
        //         this.logging.log('received Error from API Service');
        //         return throwError(err);
        //     }),
        //     tap(data => {
        //         for(const measurement of data) {
        //             this.numericValueSubject.next(measurement);
        //         }
        //     }));
    }

    public getNumericValueFor(vivavisId: string) {

        const nv$ = this.numericValueSubject.asObservable()
            .pipe(
                filter(x => x.vivavisId === vivavisId),
                shareReplay(1)
                );

        return nv$;
    }

    public getSchema(schemaId) {
        return this.apiSchemaService.getSchema(schemaId)
            .pipe(map((data: FeatureCollection) => {
                    const schema = this.processSchema(data);
                    return schema;
            }))
            ;
    }

    public getLineColors(vivavisId: string[]) {
        return this.apiSchemaService.getLineColors(vivavisId);
    }

    private processSchema(data: FeatureCollection) {
        const features: Feature<Geometry>[] = [];
        const labels: Feature<Geometry>[] = [];
        const angledLabels: Feature<Geometry>[] = [];
        const lines: Feature<Geometry>[] = [];
        const numerics: Feature<Geometry>[] = [];
        const symbols: Feature<Geometry>[] = [];
        const fuses: Feature<Geometry>[] = [];
        const rtuSymbols: Feature<Geometry>[] = [];
        const qualitySignals: Feature<Geometry>[] = [];
        const historyButtonCoords: Coordinate = new Array<number>(2).fill(181);
        let stationName = '';
        let i = 0;

        for (const f of data.features) {
            i++;
            // eslint-disable-next-line
            const featureProperties = f['properties'];
            const objId = featureProperties.objid === null ? i : featureProperties.objid;
            const vivavisid = featureProperties.vivavisid; // === 'null' ? null : featureProperties.vivavisid;
            const objectTypeStr = featureProperties.objecttype as string;
            const objectType = objectTypeStr as SchemaObjectType;
            const objectSubTypeStr = (featureProperties.objectsubtype as string) ? featureProperties.objectsubtype as string : featureProperties.subtype as string;
            const objectSubType = objectSubTypeStr as SchemaObjectSubType;
            // eslint-disable-next-line
            const featureGeometry = f['geometry'];
            // eslint-disable-next-line
            const type = featureGeometry['type'];
            // eslint-disable-next-line
            const coords = featureGeometry['coordinates'];


            if(objectSubType === SchemaObjectSubType.Location) {
                stationName = featureProperties.textcontent;
            } else {

                let geom: Geometry;
                switch (type) {
                    case 'LineString':
                        geom = new LineString(coords);
                        break;
                    case 'MultiLineString':
                        geom = new MultiLineString(coords);
                        break;
                    case 'MultiPoint':
                        geom = new MultiPoint(coords);
                        break;
                    case 'MultiPolygon':
                        geom = new MultiPolygon(coords);
                        break;
                    case 'Point':
                        geom = new Point(coords);
                        break;
                    case 'Polygon':
                        geom = new Polygon(coords);
                        break;
                    default:
                        this.logging.logDebug('type not found', type);
                        break;
                }

                const feature = new Feature({geometry: geom});
                feature.setId(objId);
                feature.set('vivavisId', vivavisid);
                feature.set('objId', objId);
                feature.set('objectType', objectType);
                feature.set('objectSubType', objectSubType);

                feature.setProperties(featureProperties);

                switch (objectType) {
                    case SchemaObjectType.Numeric:
                        const numericStyle = this.getTextStyle(featureProperties, objectType, objectSubType);
                        feature.setStyle(numericStyle);
                        feature.set('selected', false);
                        // feature.set('text', featureProperties.textcontent);
                        // feature.set('angle', featureProperties.angle);
                        numerics.push(feature);
                        break;
                    case SchemaObjectType.Text:
                        const textStyle = this.getTextStyle(featureProperties, objectType, objectSubType);
                        feature.setStyle(textStyle);
                        feature.set('text', featureProperties.textcontent);
                        // feature.set('angle', featureProperties.angle);

                        if(objectSubType === SchemaObjectSubType.RtuStateName){
                            feature.set('symboleType', 'rtu_state_text');
                        }

                        if(featureProperties.angle > 0) {
                            angledLabels.push(feature);
                        }else {
                            labels.push(feature);
                        }

                        break;
                    case SchemaObjectType.Symbol:
                        // feature.set('state', 'OFF');

                        if(objectSubType === SchemaObjectSubType.Fuse) {
                            feature.set('markerType', 'knoten');
                            fuses.push(feature);

                        } else if (objectSubType === SchemaObjectSubType.RtuEarning ||
                                objectSubType === SchemaObjectSubType.RtuError ||
                                objectSubType === SchemaObjectSubType.DoorContact ||
                                objectSubType === SchemaObjectSubType.UpsWarning ||
                                objectSubType === SchemaObjectSubType.VoltMissing )
                        {
                            feature.set('symboleType', 'rtu_warnings');
                            rtuSymbols.push(feature);

                        } else if( objectSubType === SchemaObjectSubType.RtuStateRtGn ||
                                objectSubType === SchemaObjectSubType.RtuStateGnRt ||
                                objectSubType === SchemaObjectSubType.RtuStateGnGr ||
                                objectSubType === SchemaObjectSubType.RtuStateGrGn )
                        {
                            feature.set('symboleType', 'rtu_state');
                            rtuSymbols.push(feature);

                        } else if( objectSubType === SchemaObjectSubType.PvSupply ||
                                objectSubType === SchemaObjectSubType.ExcitationUL ||
                                objectSubType === SchemaObjectSubType.ExcitationULL ||
                                objectSubType === SchemaObjectSubType.Trip)
                        {
                            feature.set('symboleType', 'quality');
                            qualitySignals.push(feature);

                        } else {
                            symbols.push(feature);
                        }

                        break;
                    case SchemaObjectType.Line:
                        coords.forEach( coord => {
                            if(coord[0] < historyButtonCoords[0]){
                                historyButtonCoords[0] = coord[0];
                                historyButtonCoords[1] = coord[1];
                            }
                        });
                        feature.set('markerType', 'leitung');
                        lines.push(feature);
                        break;
                    default:
                        features.push(feature);
                        break;
                }
            }

        }

        const hasError = data.features.length === 0;

        // this.showSlopesOfLines(lines, angledLabels);

        const schema: ApiSchema = {
            id: '',
            name: stationName,
            hasError,
            lines,
            labels,
            angledLabels,
            numerics,
            features,
            fuses,
            rtuSymbols,
            qualitySignals,
            symbols,
            historyButtonCoords,
        };

        return schema;
    }


    private getTextStyle(featureProperties, objectType: SchemaObjectType, objectSubType: SchemaObjectSubType) {
        // const labelText = this.multiLineString(featureProperties.textcontent);
        const labelText = featureProperties.textcontent as string;
        // if(labelText.length > 17) {
        //     labelText = labelText.substring(0, 14) + '...';
        // }
        const angle = featureProperties.angle;
        const textOffset = [0,0];
        let textAlign: CanvasTextAlign  = 'start';
        const isMultiLine = labelText.indexOf('\n') !== -1;
        const lineCount = labelText.split('\n').length;

        let font = '14px Roboto, Calibri, "Helvetica Neue", sans-serif';

        if (objectSubType === SchemaObjectSubType.Location) {
            font = '28px bold Roboto, Calibri, "Helvetica Neue", sans-serif';
        }

        if(objectSubType === SchemaObjectSubType.RtuStateName){
            font = '8px Roboto, Calibri, "Helvetica Neue", sans-serif';
            textAlign = 'center';
        }

        if(objectSubType === SchemaObjectSubType.Label){
            textOffset[0] = -50;
            // font = '12 Roboto, Calibri, "Helvetica Neue", sans-serif';
        }


        const textStyle = new Style({

            text: new Text({
                font,
                text: labelText,
                overflow: true,
                // offsetX: -25,
                textAlign,
                fill: new Fill({color: '#000'}),
                // stroke: new Stroke({color: '#000', width: 2, lineCap: 'round'}),
                placement: 'line',
                // maxAngle: 0.7853981633974483,
                rotation: Utilities.degreesToRadians(angle),
                // overflow: false,
                offsetX: textOffset[0],
                offsetY: textOffset[1],
            })
        });

        if (angle) {
            const prf = Utilities.pixelRatioFactor();
            const offsetX = -80 * prf;
            textStyle.getText().setOffsetX(offsetX);
        }

        if (objectType === SchemaObjectType.Numeric) {
            // textStyle.getText().setBackgroundFill(new Fill({color: '#ff0'}));
        }

        return textStyle;
    }
}
