<mat-list-item class="{{layerId}}" [attr.data-layer-id]="layerId">
    <div>
        <input type="checkbox" [attr.data-layer-id]="layerId" [(ngModel)]="isVisible" (change)="layerToggleVisibility($event)" />
        <span>{{layer.get('apiLayer').name}}</span>
    </div>
    <div class="layer-actions">

        <button *ngIf="refreshable"
        class="refresh-layer"
        mat-icon-button
        (click)="refresh($event)" ><mat-icon>autorenew</mat-icon></button>

        <ng-container *ngIf="sortable">
            <button mat-icon-button
            class="move-layer-up"
            [disabled]="index === 0"
            (click)="moveDown.emit()"><mat-icon>arrow_upward</mat-icon></button>

            <button mat-icon-button
            class="move-layer-down"
            [disabled]="index === (length-1)"
            (click)="moveUp.emit()"><mat-icon>arrow_downward</mat-icon></button>
        </ng-container>
    </div>
</mat-list-item>
