import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import Config from 'src/app/config/Config';
import { environment } from 'src/environments/environment';
import { ApiGeneralInterface } from '../interfaces/api-general';
import { ApiIdent } from '../interfaces/api-ident';

@Injectable({
    providedIn: 'root',
})
export class ApiIdentService {
    constructor(private httpClient: HttpClient) {
    }

    public getIdent(foildId: string, wsId: string, bbox: any[]): Observable<ApiIdent[]> {
        const sessionID = localStorage.getItem('sessionId');

        let httpParams = new HttpParams()
            .set('FoilID', foildId)
            // .set('srs', Config.frontend?.srs || '3857')
            .set('srs', Config.frontend?.srs)
            .set('bbox', bbox.join(','))
            .set('SessionID', sessionID) // quasi deprecated
            ;

        if (wsId && environment.mobile === false) {
            httpParams = httpParams.set('WSIDs', wsId);
        }

        const url = `${environment.apiRoot}${Config.Api.ident}`;
        return this.httpClient.get(url, { params: httpParams })
            .pipe(
                catchError(err => {
                    console.error('IDENT API Error');
                    return of({ response: [] });
                }),
                map((x: ApiGeneralInterface<ApiIdent[]>) => x.response),
            );
    }
}
