<div class="schemaRoot">

  <div class="displayInline" #schemaRoot>

    <div class="metaWrapLeft"
         [SchemaScrollDirective]="SchemaScrollPositions.metaLeft"
         [style.height.px]="getClientHeight()"
    [style.margin-top.px]="metaWrapTop.clientHeight">
      <ul>
        <li *ngFor="let meta of configuration.meta">
          <p *ngFor="let key of meta.split(' '); let i = index"
             [style.font-size.%]="getMetaFontSize(i)"
          >{{key}}</p>
<!--          <p>{{meta}}</p>-->
        </li>
      </ul>
    </div>

    <section class="layoutRight">

        <div class ="metaWrapTop borderLeft"
             #metaWrapTop
             [SchemaScrollDirective]="SchemaScrollPositions.metaTop">
            <section  *ngFor="let dataTable of measurements" class="borderLeft">
                <header class="">
                    <h6>{{dataTable.timestamp | date:'dd/MMM/yy'}}</h6>
                    <h6>{{dataTable.timestamp | date:'HH:mm:ss'}}</h6>
                    <h3>{{dataTable.meterPoint}}</h3>
                </header>
                <ul>
                    <li *ngFor="let configCol of configuration.data"
                        [style.display]="(getMeasurementbyObiId(configCol.obis, dataTable.values)) ? 'flex' : 'none'"
                    >
                        <p>{{(configCol.$title) ? configCol.$title: configCol.title}}</p>
                    </li>
                </ul>
            </section>
        </div>

        <div class="measurementsWrap borderLeft"
             [SchemaScrollDirective]="SchemaScrollPositions.content"
             [style.height.px]="getClientHeight()">

            <section *ngFor="let table of measurements" class="obisTable borderLeft">


                <section class="displayInline">
                    <app-schema-obis *ngFor="let col of configuration.data"
                                     [meterPoint]="table.meterPoint"
                                     [configuration]="col"
                                     [measurements]="table.values"
                                     (selectValueTrigger)="updateSelectedValues($event)"
                    ></app-schema-obis>
                </section>

            </section>

        </div>

    </section>


  </div>

</div>
