export enum ApiActionQueueAction {
    On = 'on',
    Off = 'off',
    CheckOperation = 'check operation',
    ExecuteOperation = 'execute operation',
    SetMarker = 'setmarker',
    DeleteMarker = 'deletemarker'
}

export interface ApiActionQueueElement {
    id: string;
    vivavisId: string;
    name: string;
    action: ApiActionQueueAction;
    marker?: string;
    subaction?: string;
    user?: string;
    deviceresponse?: any;
    enqueued: Date;
    dequeued: Date;
    isError?: boolean;
    errorMessage?: string;
    state: ActionQueueElementState;
}

export interface ApiActionQueueCount {
    errors: number;
    waiting: number;
}

export enum ActionQueueStatus {
    Offline = 'offline',
    Empty = 'empty',
    Syncing = 'synching',
    Error = 'error',
    Stopped = 'stopped'
}
export interface ApiActionQueueStatus {
    status: ActionQueueStatus;
}

export enum ActionQueueElementState {
    Pending = 'pending',
    Error = 'error',
    Synced = 'synced'
}
