import { environment } from './../../../environments/environment';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class I18nService {

    public currentLocaleId: string;

    constructor(@Inject(LOCALE_ID) protected localeId: string) {
        this.currentLocaleId = localeId;
    }

    public switchLanguage(lang: string) {
        const current = this.localeId;
        const change = current !== lang;

        if (change) {
            localStorage.setItem('i18n', lang);
            if (environment.production && window.location.href.includes(`/${current}/`)) {
                window.location.assign(window.location.href.replace(`/${current}/`, `/${lang}/`));
            } else {
                // window.location.reload();
            }
        }
    }
}
