import { ApiGeneralInterface } from './../interfaces/api-general';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Config from '../../config/Config';
import { ApiIdent } from '../interfaces/api-ident';

@Injectable({
    providedIn: 'root',
})
export class ApiSearchService {

    constructor(private httpClient: HttpClient) {}

    /**
     *
     * @param term Search String
     * @returns HTTP Observable of Array of {@link ApiIdent}
     */
    public search(term: string, maxHits = 10) {
        const url = `${environment.apiRoot}${Config.Api.search}`;

        const httpParams = new HttpParams()
            .set('searchstring', term)
            .set('srs', Config.frontend?.srs)
            .set('maxHits', JSON.stringify(maxHits));

        return this.httpClient
            .get<ApiGeneralInterface<ApiIdent[]>>(url, { params: httpParams })
            .pipe(map((x) => x.response));
    }

    // public searchStation(street: string, houseNumber: string, city: string, tech: string) {
    //     const url = `${environment.apiRoot}${Config.Api.searchStation}`;

    //     let httpParams = new HttpParams();

    //     if (street) {
    //         httpParams = httpParams.set('street', street);
    //     }

    //     if (houseNumber) {
    //         httpParams = httpParams.set('number', houseNumber);
    //     }

    //     if (city) {
    //         httpParams = httpParams.set('city', city);
    //     }

    //     if (tech) {
    //         httpParams = httpParams.set('techplatz', tech);
    //     }

    //     httpParams = httpParams.set('maxHits', '10').set('srs', Config.frontend?.map?.srs);

    //     return this.httpClient.get<ApiGeneralInterface<ApiIdent[]>>(url, {params: httpParams}).pipe(map(x => x.response));
    // }
}
