import { Component, OnInit } from '@angular/core';
import { ErrorMessagePipe } from './error-message.pipe';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    providers: [ErrorMessagePipe],
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {

    errors = [];
    constructor() {}

    ngOnInit() {
    }

    public addError(err: any) {
        this.errors.push(err);
    }

}
