import {AfterViewInit, Attribute, Directive, ElementRef, Host, HostListener, Input} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {SchemaScrollService} from './schema-scroll.service';
import {TEXT_ALIGN} from 'ol/render/canvas/TextBuilder';
import left = TEXT_ALIGN.left;

export enum SchemaScrollPositions{
    content,
    metaTop,
    metaLeft
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[SchemaScrollDirective]',
})
export class SchemaScrollDirective implements AfterViewInit {

  private mouseDown = false;
  private scrollPositions = { top: 0, left: 0, x: 0, y: 0 };

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    this.mouseDown = true;
    // this.el.nativeElement.style.cursor = 'grabbing';
    this.mouseDownHandler(event);
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    event.preventDefault();
    this.mouseDown = false;
    // this.el.nativeElement.style.cursor = 'grab';
    this.mouseUpHandler(event);
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    if(this.mouseDown) {
      this.mouseMoveHandler(event);
    }
  }

    @HostListener('touchstart', ['$event'])
    onTouchStart(event: TouchEvent) {
        event.preventDefault();
        this.mouseDown = true;
        this.mouseDownHandler(event);
    }
    @HostListener('touchmove', ['$event'])
    onTouchMove(event: TouchEvent) {
        if(this.mouseDown) {
            this.mouseMoveHandler(event);
        }
    }
    @HostListener('touchend', ['$event'])
    onTouchEnd(event: TouchEvent) {
        event.preventDefault();
        this.mouseDown = false;
        // this.el.nativeElement.style.cursor = 'grab';
        this.mouseUpHandler(event);
    }
    // @HostListener('touchcancel', ['$event'])


    @HostListener('scroll', ['$event'])
    onWindowScroll(event) {
        this.schemaScrollService.setOffset(
            this.el.nativeElement.scrollLeft,
            this.el.nativeElement.scrollTop
        );
    }

  @Input('SchemaScrollDirective') scrollType: SchemaScrollPositions;


    constructor(public el: ElementRef,
              private scroller: ViewportScroller,
              private schemaScrollService: SchemaScrollService) {}

  ngAfterViewInit() {
      this.el.nativeElement.style.overflow = 'auto';
      this.el.nativeElement.style.cursor = 'grab';

      this.schemaScrollService.changeScrollPosition.subscribe({
          next: (position: {left: number; top: number}) => {
              switch (this.scrollType) {
                  case SchemaScrollPositions.content:
                      this.el.nativeElement.scroll(
                          (position.left === 0 ? this.el.nativeElement.scrollLeft : position.left),
                          (position.top === 0 ? this.el.nativeElement.scrollTop : position.top));
                      break;
                  case SchemaScrollPositions.metaLeft:
                      this.el.nativeElement.scroll(0, position.top);
                      break;
                  case SchemaScrollPositions.metaTop:
                      this.el.nativeElement.scroll(position.left, 0);
                      break;
              }
          }
      });
  }

  private mouseDownHandler(event: MouseEvent | TouchEvent){
        const coords = this.getCoords(event);
        this.scrollPositions = {
            // The current scroll
            left: this.el.nativeElement.scrollLeft,
            top: this.el.nativeElement.scrollTop,
            // Get the current mouse position
            x: coords.x,
            y: coords.y,
        };
  }

  private mouseMoveHandler(event: MouseEvent | TouchEvent){
        const coords = this.getCoords(event);

        // How far the mouse has been moved
        const dx = coords.x - this.scrollPositions.x;
        const dy = coords.y - this.scrollPositions.y;

        // Scroll the element
        this.el.nativeElement.scrollLeft = this.scrollPositions.left - dx;
        this.el.nativeElement.scrollTop = this.scrollPositions.top - dy;

  }

  private mouseUpHandler(event: MouseEvent | TouchEvent){
      this.el.nativeElement.style.removeProperty('user-select');
  }

  private getCoords(event: MouseEvent | TouchEvent){
      const coords = {
          x: 0, y:0
      };
      if(event instanceof window.MouseEvent) {
          coords.x = event.clientX;
          coords.y = event.clientY;
      }
      if(window.TouchEvent && event instanceof TouchEvent) {
          coords.x = event.touches[0].clientX;
          coords.y = event.touches[0].clientY;
      }
      return coords;
  }
}
