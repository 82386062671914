<div class="obisCol"
  [style.display]="getColVisibility()">

  <ul class="data">
    <li *ngFor="let obisId of configuration.obis"
        [style.visibility]="(getMeasurementbyObiId(obisId).element) ? 'visible' : 'hidden'"
    >
      <app-schema-obis-value
        [obisId]="obisId"
        [value]="getMeasurementbyObiId(obisId).value"
        [unit]="getMeasurementbyObiId(obisId).unit"
        [isValueSelected]="getMeasurementbyObiId(obisId).selected"
        (selectValueTrigger)="processSelectedValues( getMeasurementbyObiId(obisId), $event)"
      >
      </app-schema-obis-value>
    </li>
  </ul>
</div>
