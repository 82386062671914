<ng-container *ngIf="syncState$ | async; let syncState">
    <button id="action-queue-button" mat-mini-fab color="white" (click)="openQueueMenu($event);" class="button-open-queue-menu action-queue-menu">
        <mat-progress-spinner *ngIf="syncState.progressing" mode="indeterminate" color="primary" diameter="25"></mat-progress-spinner>
        <span *ngIf="syncState.progressing === false">{{ syncState.waiting }}</span>
    </button>
</ng-container>

<ng-template #queueMenu let-data>
    <ng-container *ngIf="syncState$ | async; let syncState">
        <div style="display: flex;">
            <div class="mat-menu-panel action-queue-menu-panel">
                <div class="action-queue-button-bar">

                    <button id="sync"
                        mat-flat-button
                        #syncButton
                        [disabled]="!isOnline"
                        (click)="sync(syncButton)"
                        class="action-queue-menu"
                        color="accent"
                        i18n="@@actionQueue.sync.button">Sync</button>

                    <button id="discard"
                        mat-flat-button
                        (click)="discard()"
                        [disabled]="syncState.waiting === 0"
                        class="action-queue-menu"
                        color="accent"
                        i18n="@@actionQueue.discard.button">Discard changes</button>

                </div>
                <div class="action-queue-elements">

                    <app-action-queue-element *ngFor="let el of queue$ | async" [element]="el" [currentUnlockingElement]="currentUnlockingElement"></app-action-queue-element>

                    <div *ngIf="(queue$ | async)?.length === 0" class="empty-action-queue">
                        <span i18n="@@actionQueue.empty">Queue is empty</span>
                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="syncState.showSyncingIndicator" class="syncing-indicator" >
            <div>
                <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
            </div>
        </div>
    </ng-container>
</ng-template>
