import {Feature} from 'ol';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import {Text} from 'ol/style';

enum celLTypes{
    cellFeature,
    cellOverlay
}

export class PostLoadingCell {
    private readonly _type: celLTypes;
    private readonly data: Feature | Overlay;
    constructor(data: Feature | Overlay) {
        switch (data.constructor) {
            case Feature:
                this._type = celLTypes.cellFeature;
                break;
            case Overlay:
                this._type = celLTypes.cellOverlay;
                break;
        }
        this.data = data;
    }

    getCoordinates(){
        if(this._type === celLTypes.cellFeature){ return this.getFeatureCoordinates();}
        if(this._type === celLTypes.cellOverlay){ return this.getOverlayCoordinates();}
    }

    setStyle(style){
        if(this._type === celLTypes.cellFeature && style){this.setFeatureStyle(style.text);}
        if(this._type === celLTypes.cellOverlay && style){this.setOverlayStyle(style.html);}
    };

    translate(deltaX,deltaY){
        if(this._type === celLTypes.cellFeature){this.translateFeature(deltaX, deltaY);}
        if(this._type === celLTypes.cellOverlay){this.translateOverlay(deltaX, deltaY);}
    };


    private getFeatureCoordinates(){
        return ((this.data as Feature).getGeometry() as Point).getCoordinates();
    }
    private getOverlayCoordinates(){
        return (this.data as Overlay).getPosition();
    }

    private setFeatureStyle(style){
        const _style = ((this.data as Feature).getStyle() as Style);
        const _text =  _style.getText();
        const newStyle = new Style({
            ..._style,
            text: new Text({
                font: _text.getFont(),
                fill: _text.getFill(),
                maxAngle:  _text.getMaxAngle(),
                placement:  _text.getPlacement(),
                textAlign: _text.getTextAlign(),
                text: _text.getText(),
                ...style
            }),
        });
        (this.data as Feature).setStyle(newStyle);
    }
    private setOverlayStyle(style){
        const _overlay =  this.data as Overlay;
        const _htmlElem = (_overlay.getElement());

        setTimeout(() => {
            Object.keys(style).forEach( (styleProp) => {
                if((_htmlElem.children[0] as HTMLElement)){
                    (_htmlElem.children[0].children[0] as HTMLElement).style[styleProp] = style[styleProp];
                }
            });
        }, 1000);
    }

    private translateFeature(deltaX, deltaY){
        const _geometry =    (this.data as Feature).getGeometry() as Point;
        const _translateX = (deltaX) ? deltaX : 0;
        const _translateY = (deltaY) ? deltaY : 0;
        _geometry.translate(_translateX, _translateY);
    }
    private translateOverlay(deltaX, deltaY){
        const _overlay =  this.data as Overlay;
        const _position = _overlay.getPosition();
        _position[0] += deltaX;
        _position[1] += deltaY;
        _overlay.setPosition(_position);
    }
}
