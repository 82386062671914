
import { Pipe, PipeTransform } from '@angular/core';
import { CustomConfigService } from 'src/app/maps/custom/custom.config.service';

@Pipe({
    name: 'popupPanelTitle',
    pure: true
})
export class PopupPanelTitlePipe implements PipeTransform {

    constructor(private customConfigService: CustomConfigService) {
    }

    transform(value: any, ...args: any[]) {
        return this.customConfigService.popupPanelTitle(value);
    }
}
