export interface ApiServerStatusResult<T = ServerStatusMessage> {
    message: T;
    success: boolean;
    title: string;
}

export enum ServerStatusMessage {
    Offline = 'offline',
    Online = 'online'
}
