import {PostLoadingStyler} from './_PostLoadingStyler';
import {PostLoadingCell} from './_PostLoadingCell';

export class IMSysPostLoadingStyle extends PostLoadingStyler{
    constructor(features: PostLoadingCell[]) {
        super(features);
    }

    applyStyle(style){
        if(style.row){
            style.row.forEach( (_style: any, _index: number) => {
                if(_style !== null){
                    const _filed = this.getRow(_index);
                    if(_style.geometry){
                        this.edit_Geometry( _filed, _style.geometry);
                    }
                    if(_style.style){
                        this.edit_Style( _filed, _style.style);
                    }

                }
            });
        }
        if(style.col){
            style.col.forEach( (_style: any, _index: number) => {
                if(_style !== null){
                    const _filed = this.getCol(_index);
                    if(_style.geometry){
                        this.edit_Geometry( _filed, _style.geometry);
                    }
                    if(_style.style){
                        this.edit_Style( _filed, _style.style);
                    }

                }
            });
        }
    }

    private edit_Geometry(field: PostLoadingCell[], style){
        field.forEach( (_feature) => {
            const _translate = (style.translate) ? style.translate : [0,0];
            _feature.translate(_translate[0], _translate[1]);

        });
    }

    private edit_Style(field: PostLoadingCell[], style: any){
       field.forEach( (_feature) => {
            _feature.setStyle(style);
        });
    }

}
