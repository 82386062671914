import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import Config from '../../config/Config';
import { ApiGeneralInterface } from './../interfaces/api-general';
import {
    ApiMeasurement,
    ApiMeasurementElement
} from './../interfaces/api-measurement';
import { ConsoleLoggingService } from '../services/console-logging.service';

@Injectable({
    providedIn: 'root',
})
export class ApiMeasurementService {
    constructor(private httpClient: HttpClient, private logging: ConsoleLoggingService) { }

    public getMeasurements(ids: string[], endpointUrl?: string) {
        const url = (endpointUrl) ? `${environment.root}${endpointUrl}`: `${environment.apiRoot}${Config.Api.measurement}`;

        let httpParams = new HttpParams();
        httpParams = httpParams.set('vivavisIds', ids.join(','));

        const httpHeaders = new HttpHeaders({
            sessionId: localStorage.getItem('sessionId'),
            'Content-Type': 'application/json',
        });

        return this.httpClient
            .get<ApiGeneralInterface<ApiMeasurementElement[]>>(url, {
                params: httpParams,
                headers: httpHeaders,
            })
            .pipe(
                catchError((err) => {
                    this.logging.log('API getMeasurement Error');
                    return throwError(err);
                }),
                map((data) => {
                    let measurement = data.response;
                    if(endpointUrl){
                        measurement = measurement.map( m => {
                            m.vivavisId = m.vivavisId.toUpperCase();
                            return m;
                        });
                        console.log('loaded', measurement);
                    }
                    return measurement;
                })
            );
    }

    /**
     *
     * @param vivavisIds
     * @returns HTTP Observable
     */
    public getComplexMeasurement(vivavisIds: string[]) {
        const url = `${environment.apiRoot}${Config.Api.complexMeasurement}`;
        const vidParam = vivavisIds.join(',');
        let httpParams = new HttpParams();
        httpParams = httpParams.set('vivavisIds', vidParam);

        return this.httpClient
            .get<ApiGeneralInterface<ApiMeasurement[]>>(url, {
                params: httpParams
            })
            .pipe(
                catchError((err) => {
                    console.error('Error getting Complex Measurements');
                    this.logging.log('For IDS ', vidParam);

                    const emptyObject = {
                        response: []
                    };
                    return throwError(emptyObject);
                }),
                map((data) => data.response)
            );
    }
}
