import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import * as Coordinates from 'coordinate-parser';
import { Feature as GeoJsonFeature } from 'geojson';
import { Feature } from 'ol';
import { boundingExtent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry, Point } from 'ol/geom';
import { fromLonLat, transform, transformExtent } from 'ol/proj';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { CustomConfigService } from 'src/app/maps/custom/custom.config.service';
import { ApiIdent } from 'src/app/shared/interfaces/api-ident';
import { ConnectionStatusService } from 'src/app/shared/services/connection-status.service';
import { ApiGeocoderService } from '../../shared/api-services/api-geocoder.service';
import { ApiSearchService } from '../../shared/api-services/api-search.service';
import { ProjectionsService } from '../../shared/services/projections.service';
import { ConsoleLoggingService } from '../../shared/services/console-logging.service';


enum SearchInputState {
    Geocoder = 'geocoder',
    Backend = 'backend',
    History = 'history'
}

interface SearchInterface {
    state: SearchInputState;
    input: {
        formControl: FormControl;
        placeholder: string;
    };
    searchResults: Feature<Geometry>[];
    hasPagination: boolean;
    pagination?: {
        pageStart: number;
        pageEnd: number;
        pageSize: number;
    };
}
interface SearchStates {
    [key: string]: SearchInterface;
}

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

    @ViewChild(MatAutocompleteTrigger)
    public trigger: MatAutocompleteTrigger;

    @ViewChild('searchTextInput')
    public searchTextInput: ElementRef;

    @Output()
    public selectedResult = new EventEmitter<Feature<Geometry>>();
    public historySelectedResults: Feature<Geometry>[] = [];

    public showSearchBar = false;

    public isLoading = false;
    public isOffline = false;

    public enableGeoCoder = true;

    public searchInputStates = SearchInputState;

    public searchStates: SearchStates = {
        [SearchInputState.Backend]: {
            input: {
                formControl: new FormControl(),
                placeholder: $localize`:@@search.input.textSearch.placeholder:Text search`,
            },
            state: SearchInputState.Backend,
            hasPagination: false,
            searchResults: null
        },
        [SearchInputState.Geocoder]: {
            input: {
                formControl: new FormControl(),
                placeholder: $localize`:@@search.input.geocoder.placeholder:Enter address`,
            },
            state: SearchInputState.Geocoder,
            hasPagination: false,
            searchResults: null
        },
        [SearchInputState.History]: {
            input: {
                formControl: new FormControl({ value: '', disabled: true }),
                placeholder: '',
            },
            state: SearchInputState.History,
            hasPagination: true,
            pagination: {
                pageStart: 0,
                pageEnd: 8,
                pageSize: 8
            },
            searchResults: null
        }
    };

    public currentState: SearchInterface = this.searchStates[SearchInputState.Backend];

    private searchHistoryStorageKey = 'searchHistory';
    private subscriptions = new Subscription();
    private searchOpts: any;

    constructor(private projectionService: ProjectionsService,
        private apiSearch: ApiSearchService,
        private customConfigService: CustomConfigService,
        private connectionStatusService: ConnectionStatusService,
        private apiGeocoder: ApiGeocoderService,
        private logging: ConsoleLoggingService) {

        this.searchOpts = this.customConfigService.getSearchOpts();

        const sub = this.connectionStatusService.connectionStatus$.subscribe(status => {
            this.isOffline = status.isOffline;

            if (this.isOffline) {
                this.switchToStationSearch();
            }
        });

        this.subscriptions.add(sub);

    }

    ngOnInit(): void {
        this.restoreHistory();
        this.search();
    }


    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public toggle() {
        this.showSearchBar = !this.showSearchBar;
        this.focusInSearchBar();
    }

    public selectSearchResult(event: MouseEvent, f) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        this.selectedResult.emit(f);
        this.addHistoryEntry(f);
        this.saveHistory();
    }

    public focusout() {
        setTimeout(() => {
            this.showSearchBar = false;
            // this.clearSerachValues();
        }, 150);
    }

    public setPage(event) {
        this.currentState.pagination.pageStart = event.pageIndex * event.pageSize;
        this.currentState.pagination.pageEnd = this.currentState.pagination.pageStart + this.currentState.pagination.pageSize;
    }

    public clearSearch() {
        this.currentState.input.formControl.setValue('');
        this.currentState.searchResults = null;
        this.focusInSearchBar();
    }

    public switchToHistory() {
        this.currentState = this.searchStates[SearchInputState.History];
        this.currentState.input.formControl.disable();
    }

    public switchToStationSearch() {
        this.currentState = this.searchStates[SearchInputState.Backend];
        setTimeout(() => {
            this.currentState.input.formControl.enable();
            this.focusInSearchBar();
        }, 50);
    }

    public switchToGeoCoderSearch() {
        this.currentState = this.searchStates[SearchInputState.Geocoder];

        setTimeout(() => {
            this.focusInSearchBar();
            this.currentState.input.formControl.enable();
        }, 50);
    }

    public fireFullSearch(){
        const searchOpts = this.searchOpts[this.currentState.state];
        switch (this.currentState.state) {
            case this.searchInputStates.Geocoder:
                this._fireGeoCoderSearch(this.currentState.input.formControl.value, searchOpts.maxSearch);
                break;
            case this.searchInputStates.Backend:
                this._fireGridPilotSearch(this.currentState.input.formControl.value, searchOpts.maxSearch);
        }
    }

    private isInHistory(id) {
        return this.historySelectedResults.findIndex(x => x.get('id') === id) !== -1;
    }

    private addHistoryEntry(f: Feature<Geometry>) {

        if (this.historySelectedResults.length >= 50) {
            this.historySelectedResults.pop();
        }

        const propertyKey = 'entryDate';
        const index = this.historySelectedResults.findIndex(x => x.get('id') === f.get('id'));
        f.set(propertyKey, Date.now());

        this.logging.logDebug('addHistoryEntry  ', {index, f, fID: f.get('id'), currentState: this.currentState});
        if (index === -1) {
            this.historySelectedResults.push(f);
        } else {
            this.historySelectedResults[index].set(propertyKey, Date.now());
        }

        this.historySelectedResults.sort((a, b) => b.get(propertyKey) - a.get(propertyKey));
        this.searchStates[SearchInputState.History].searchResults = this.historySelectedResults;
    }

    private focusInSearchBar() {
        if (this.showSearchBar) {
            setTimeout(() => {
                if (this.searchTextInput) {
                    this.searchTextInput.nativeElement.focus();
                }
            }, 50);
        }
    }

    private search() {
        const sub1 = this.searchStates[SearchInputState.Geocoder].input.formControl.valueChanges
            .pipe(
                // tap(value => {
                //     if (value !== '') {
                //         // // this.showHistory = false;
                //         // this.switchToHistory();
                //     }
                // }),
                debounceTime(250),
                distinctUntilChanged()
            )
            .subscribe(async value => { this._fireGeoCoderSearch(value, this.searchOpts.geocoder.minSearch); });

        this.subscriptions.add(sub1);

        const sub2 = this.searchStates[SearchInputState.Backend].input.formControl.valueChanges
            .pipe(
                // tap(searchForm => {
                //     if (!!searchForm) {
                //         // this.showHistory = false;
                //         // this.switchToHistory();
                //     }
                // }),
                debounceTime(250),
                distinctUntilChanged()
            )
            .subscribe(searchForm => { this._fireGridPilotSearch(searchForm, this.searchOpts.backend.minSearch ); });

        this.subscriptions.add(sub2);
    }

    private _fireGeoCoderSearch(value, maxHits) {
        if (value) {
            this.isLoading = true;
            const coordinates = this.isValidPosition(value);
            if (coordinates) {
                this.reverseGeoCoder(coordinates.getLatitude(), coordinates.getLongitude(), maxHits)
                    .subscribe((res) => {
                        this.isLoading = false;
                        this.searchStates[SearchInputState.Geocoder].searchResults = res;
                    });
            } else {
                this.searchGeoCoder(value, maxHits)
                    .subscribe(res => {
                        this.isLoading = false;
                        this.searchStates[SearchInputState.Geocoder].searchResults = res;
                    });
            }
        }

    }

    private _fireGridPilotSearch(searchForm: string, maxHits = 10) {
        const textSearch = searchForm;
        if (!!textSearch) {
            this.isLoading = true;
            this.gridpilotSearch(textSearch, maxHits).subscribe(res => {
                this.isLoading = false;
                this.searchStates[SearchInputState.Backend].searchResults = res;
            });
        }
    }


    private restoreHistory() {
        try {
            const searchHistoryValue = sessionStorage.getItem(this.searchHistoryStorageKey);
            if (searchHistoryValue) {
                const geojsonFeatures = JSON.parse(searchHistoryValue);
                const geoJson = new GeoJSON();
                const searchHistory = geoJson.readFeatures(geojsonFeatures);
                this.historySelectedResults = searchHistory;
                this.searchStates[SearchInputState.History].searchResults = searchHistory;
            }
        } catch (e) {
            console.warn('Error reading Serach History');
        }
    }

    private saveHistory() {
        try {
            const geoJson = new GeoJSON();
            const geojsonFeatures = geoJson.writeFeaturesObject(this.historySelectedResults);
            const searchHistory = JSON.stringify(geojsonFeatures);
            sessionStorage.setItem(this.searchHistoryStorageKey, searchHistory);
        } catch (e) {
            console.warn('Error saving Serach History');
        }
    }

    private getIdentId(identData, keys: string[]) {
        let identId = '';
        let idKey = keys.find(x => x.toLowerCase().indexOf('vivavisid') !== -1);

        idKey = (!idKey) ? keys.find(x => x.toLowerCase().indexOf('objid') !== -1) : undefined;
        idKey = (!idKey) ? keys.find(x => x.toLowerCase().indexOf('objektid') !== -1) : undefined;

        identId = identData[idKey];

        return identId;
    }

    private gridpilotSearch(term: string, maxHits = 10): Observable<Array<Feature<Geometry>>> {
        const featureResults: Feature<Geometry>[] = [];
        return this.apiSearch.search(term, maxHits)
            .pipe(map((searchResults: ApiIdent[]) => {
                for (const result of searchResults) {
                    const data = result.data;
                    const meta = result.meta;

                    const originalCoords = [result.coordinates.x, result.coordinates.y];
                    const coordinates = this.customConfigService.searchResultTransformCoordinates(originalCoords);
                    // const coordinates = transform(originalCoords, this.projectionService.epsg25832Projection, this.projectionService.epsg3857Projection);
                    const feature = new Feature(new Point(coordinates));

                    feature.set('id', this.getIdentId(meta, Object.keys(meta)));
                    feature.set('properties', data);
                    feature.set('searchResult', result);
                    feature.set('geocoder', false);

                    featureResults.push(feature);
                }
                return featureResults;
            }));
    }

    // private gridpilotSearchStation(street: string, houseNumber: string, city: string, tech: string): Promise<Array<Feature<Geometry>>> {
    //     const featureResults: Array<Feature<Geometry>> = [];
    //     return this.apiSearch.searchStation(street, houseNumber, city, tech)
    //         .pipe(map((searchResults: ApiIdent[]) => {

    //             for (const result of searchResults) {
    //                 const data = result.data;
    //                 const meta = result.meta;

    //                 const originalCoords = [result.coordinates.x, result.coordinates.y];
    //                 const coordinates = transform(originalCoords, this.projectionService.epsg25832Projection, this.projectionService.epsg3857Projection);
    //                 const feature = new Feature(new Point(coordinates));

    //                 feature.set('id', this.getIdentId(meta, Object.keys(meta)));
    //                 feature.set('searchResult', result);
    //                 feature.set('properties', data);
    //                 feature.set('geocoder', false);

    //                 featureResults.push(feature);
    //             }

    //             return featureResults;
    //         }))
    //         .toPromise();
    // }

    private reverseGeoCoder(lat, lon, limit): Observable<Array<Feature<Geometry>>> {
        const results: Array<Feature<Geometry>> = [];
        return this.apiGeocoder.reverse(lat, lon, 'geojson', limit)
            .pipe(map((result: GeoJsonFeature<Geometry>[]) => {

                const geocoderConfig = this.apiGeocoder.geocoderConfig;

                for (const item of result) {

                    const { coordinates, bbox } = this.transformCoordinatesFromGeoCoder(item);
                    const properties = { displayName: item.properties[geocoderConfig.resultText] };
                    const idField = item.properties[geocoderConfig.idField];


                    const feature = new Feature(new Point(coordinates));
                    feature.set('properties', properties);
                    feature.set('bbox', bbox);
                    feature.set('id', idField);
                    feature.set('geocoder', true);

                    if (item.properties.address) {
                        feature.set('address', item.properties.address);
                    }

                    results.push(feature);
                }
                return results;
            }));
    }

    private searchGeoCoder(value, limit): Observable<Array<Feature<Geometry>>> {
        const results: Array<Feature<Geometry>> = [];
        return this.apiGeocoder.search(value, 'geojson', limit)
            .pipe(map((result: GeoJsonFeature<Geometry>[]) => {

                const geocoderConfig = this.apiGeocoder.geocoderConfig;

                for (const item of result) {

                    const { coordinates, bbox } = this.transformCoordinatesFromGeoCoder(item);
                    const properties = { displayName: item.properties[geocoderConfig.resultText] };
                    const idField = item.properties[geocoderConfig.idField];

                    const feature = new Feature(new Point(coordinates));
                    feature.set('properties', properties);
                    feature.set('bbox', bbox);
                    feature.set('id', idField);
                    feature.set('geocoder', true);

                    results.push(feature);
                }
                return results;
            }));
    }

    private transformCoordinatesFromGeoCoder(item: GeoJsonFeature) {
        const lon = item.geometry.coordinates[0];
        const lat = item.geometry.coordinates[1];
        const coordinates = fromLonLat([lon, lat], this.projectionService.currentProjection);
        let bbox;
        if (item.bbox) {
            const bboxCoordinates = [
                [item.bbox[0], item.bbox[1]],
                [item.bbox[2], item.bbox[3]]
            ];
            const bboxExtent = boundingExtent(bboxCoordinates);
            bbox = transformExtent(bboxExtent, this.projectionService.epsg4326Projection, this.projectionService.currentProjection);
        }
        return {
            coordinates,
            bbox
        };
    }

    private isValidPosition(position) {
        let isValid;
        try {
            isValid = true;
            return new Coordinates(position);
        } catch (error) {
            isValid = false;
            return isValid;
        }
    }
}

