import { ApiOutcome } from './api-outcome';

export interface ApiCheckOperation extends ApiOutcome {
    vivavisId?: string;
    scadaLocks: number[];
    scadaResults: ApiCheckOperationResult[];
    topoLocks: number[];
    topoResults: ApiCheckOperationResult[];
}

export interface ApiCheckOperationResult {
    bitNumber: number;
    description: string;
    label: string;
    requiredAction: ApiCheckOperationResultAction;
    severity: ApiCheckOperationResultSeverity;
}

export enum ApiCheckOperationResultAction {
    Unlock = 'Unlock',
    Discharge = 'Discharge',
    Forbidden = 'Forbidden',
    None = 'None'
}

export enum ApiCheckOperationResultSeverity {
    Error = 'Error',
    Hint = 'Hint',
    Warning = 'Warning'
}

