import { ApiOutcomeValue } from './../../shared/interfaces/api-outcome';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, firstValueFrom, fromEvent, merge, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, scan, shareReplay, startWith, take, takeUntil, tap } from 'rxjs/operators';
import {
    ApiCheckOperation,
    ApiCheckOperationResultAction,
    ApiCheckOperationResultSeverity,
} from 'src/app/shared/interfaces/api-checkopertaion';
import { ActionQueueService } from 'src/app/shared/services/action-queue.service';
import { ConnectionStatusService } from 'src/app/shared/services/connection-status.service';
import { UpdateFeatureService } from 'src/app/shared/services/updatefeature.service';

import selectDistincState from '../../utils/select-distinct-state';
import {
    ActionQueueElementState,
    ActionQueueStatus,
    ApiActionQueueAction,
    ApiActionQueueCount,
    ApiActionQueueElement,
    ApiActionQueueStatus,
} from './../../shared/interfaces/api-actionqueue';
import { CheckOperationDialogComponent } from './../schema-menu/check-operation/check-operation.component';
import { ConsoleLoggingService } from '../../shared/services/console-logging.service';


enum UiSyncStateKeys {
    isSyncing = 'isSyncing',
    showSyncingIndicator = 'showSyncingIndicator',
    progressing = 'progressing'
}

interface UiSyncState extends ApiActionQueueCount, ApiActionQueueStatus {
    isSyncing: boolean;
    showSyncingIndicator: boolean;
    progressing: boolean;
}

type UiSyncEvents = Partial<UiSyncState>;


@Component({
    selector: 'app-action-queue',
    templateUrl: './action-queue.component.html',
    styleUrls: ['./action-queue.component.scss'],
})
export class ActionQueueComponent implements OnInit, OnDestroy {
    @ViewChild('queueMenu') queueMenu: TemplateRef<any>;

    readonly actions = ApiActionQueueAction;
    readonly actionStatus = ActionQueueStatus;
    public isOpen = false;
    public isStopped = false;

    public queueSubject = new BehaviorSubject<ApiActionQueueElement[]>([]);
    public queue$: Observable<ApiActionQueueElement[]>;
    public syncState$: Observable<UiSyncState>;
    public uiSyncEvents = new Subject<UiSyncEvents>();
    public uiSyncEvents$: Observable<UiSyncEvents>;

    public isOnline = false;
    public syncingData = null;

    private currentUnlockingElement: ApiActionQueueElement;
    private queueOverlayRef: OverlayRef | null;
    private overlayClickSub: Subscription;
    private destroy$ = new Subject<void>();

    private initialUiSyncState: UiSyncState = {
        isSyncing: false,
        showSyncingIndicator: false,
        progressing: false,
        status: ActionQueueStatus.Empty,
        waiting: 0,
        errors: 0
    };

    constructor(
        private dialog: MatDialog,
        private actionQueueService: ActionQueueService,
        private connectionStatusService: ConnectionStatusService,
        private updateFeatureService: UpdateFeatureService,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private logging: ConsoleLoggingService
    ) {

        this.uiSyncEvents$ = merge(actionQueueService.actionQueueCount$, actionQueueService.actionQueueStatus$, this.uiSyncEvents);
        this.syncState$ = this.uiSyncEvents$.pipe(
            startWith(this.initialUiSyncState),
            scan((states: UiSyncState, newState): UiSyncState => ({ ...states, ...newState })),
            shareReplay(1)
        );
        // tap(console.log),

        this.queue$ = this.queueSubject
            .asObservable()
            .pipe(
                map((data: ApiActionQueueElement[]) => data.sort((a, b) => b.enqueued.getTime() - a.enqueued.getTime())),
                tap(elements => {
                    const el = elements.find(x => x.state === ActionQueueElementState.Pending && x.action === ApiActionQueueAction.ExecuteOperation);
                    if (el && this.isOnline) {
                        this.uiSyncEvents.next({ isSyncing: true, showSyncingIndicator: true });
                        this.processExecuteOpertaion(el);
                    } else {
                        this.uiSyncEvents.next({ isSyncing: false, showSyncingIndicator: false });
                    }
                })
            );

        // this.count$ = actionQueueService.actionQueueCount$;

        actionQueueService.actionQueueElements$
            .pipe(tap(this.queueSubject))
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    @HostListener('window:resize')
    public resizeWindow(): void {
        if (this.isOpen && this.queueOverlayRef) {
            this.queueOverlayRef.updatePositionStrategy(
                this.setOverlayPosition()
            );
            this.queueOverlayRef.updatePosition();
        }
    }

    ngOnInit() {
        this.connectionStatusService.connectionStatus$
            .pipe(takeUntil(this.destroy$))
            .subscribe(state => {
                this.isOnline = state.isOnline;
            });
    }

    ngOnDestroy(): void {
        this.closeActionQueue();
        this.destroy$.next();
        this.destroy$.complete();
    }

    public actionUnlocked() {
        this.actionQueueService
            .executeElements()
            .pipe(
                catchError(err => {
                    this.logging.log('ERROR - Unlocking Action', err);
                    return throwError(() => err);
                }),
                tap(res => {
                    this.logging.logDebug('SUCCESS', 'Unlocking Action');
                    this.refresh();
                })
            )
            .subscribe();
    }

    public refresh() {
        this.startProgressingIndicator();
        this.actionQueueService.updateActionQueue();
        setTimeout(() => {
            this.stopProgressingIndicator();
        }, 500);
    }

    public sync(syncButton: MatButton = null) {

        if (syncButton) {
            syncButton._elementRef.nativeElement.blur();
        }

        this.startProgressingIndicator();

        this.actionQueueService
            .executeElements()
            .pipe(catchError(err => {
                this.logging.log('Error: ', err);
                this.stopProgressingIndicator();
                return throwError(() => err);
            }))
            .pipe(tap(q => {
                this.refresh();
            }))
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    public discard() {
        const q = this.queueSubject.value;
        const pending = q.filter(x => x.state === ActionQueueElementState.Pending);
        const ids = pending.map((x) => x.id);
        if (ids.length > 0) {
            this.stop();
            this.actionQueueService
                .deleteElements(ids)
                .pipe(catchError(err => {
                    this.logging.log('Error: ', err);
                    return throwError(() => err);
                }))
                .pipe(tap((res) => {
                    this.refresh();
                    this.updateFeatureService.forceUpdate();
                }))
                .pipe(takeUntil(this.destroy$))
                .subscribe()
                ;
        }
    }

    public discardElementByUser(elementId: string) {
        return this.actionQueueService
            .deleteElements([elementId])
            .pipe(catchError(err => {
                this.logging.log('Error: ',err);
                return throwError(() => err);
            }))
            .pipe(tap((res) => {
                this.refresh();
            }))
            .pipe(takeUntil(this.destroy$));
    }

    public async stop() {
        await firstValueFrom(this.actionQueueService.stop());
    }

    public async start() {
        await firstValueFrom(this.actionQueueService.run());
    }

    public closeActionQueue() {
        if (this.overlayClickSub) {
            this.overlayClickSub.unsubscribe();
        }
        if (this.queueOverlayRef) {
            this.queueOverlayRef.dispose();
            this.queueOverlayRef = null;
        }
    }

    openQueueMenu({ x, y }: MouseEvent) {
        if (this.isOpen) {
            this.closeActionQueue();
        } else {
            setTimeout(() => {
                this.showMenu();
                this.refresh();
            });
        }
        this.isOpen = !this.isOpen;
    }

    private setOverlayPosition() {
        const xmid = window.innerWidth * 0.5;
        const ytop = 15 + 58;
        return this.overlay
            .position()
            .flexibleConnectedTo({ x: xmid, y: ytop })
            .withPositions([
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top',
                },
            ]);
    }

    private showMenu() {

        const config = new OverlayConfig({
            panelClass: 'action-queue-panel-zindex',
            positionStrategy: this.setOverlayPosition(),
            scrollStrategy: this.overlay.scrollStrategies.close(),
        });

        this.queueOverlayRef = this.overlay.create(config);

        this.queueOverlayRef.attach(
            new TemplatePortal(this.queueMenu, this.viewContainerRef, {
                $implicit: {},
            })
        );

        this.overlayClickSub = fromEvent<MouseEvent>(document, 'click')
            .pipe(
                filter((event) => {
                    const clickTarget = event.target as HTMLElement;
                    // return !!this.queueOverlayRef && !clickTarget.classList.contains('button-open-queue-menu');
                    return (
                        !!this.queueOverlayRef &&
                        // !clickTarget.classList.contains('action-queue-menu')
                        !this.queueOverlayRef.overlayElement.contains(
                            clickTarget
                        )
                    );
                }),
                take(1)
            )
            .subscribe(async () => {
                const isSyncing = await this.getStateFor(UiSyncStateKeys.isSyncing);
                if (!isSyncing) {
                    this.closeActionQueue();
                    this.isOpen = false;
                }
            });

    }

    private async getStateFor<T = boolean>(key: UiSyncStateKeys = UiSyncStateKeys.isSyncing) {
        return await firstValueFrom(this.syncState$.pipe(selectDistincState<UiSyncState, T>(key)));
    }

    private startProgressingIndicator() {
        this.uiSyncEvents.next({ progressing: true });
    }

    private stopProgressingIndicator() {
        this.uiSyncEvents.next({ progressing: false });
    }

    private processExecuteOpertaion(element: ApiActionQueueElement) {
        if (!this.currentUnlockingElement) {
            const newState = element.subaction;
            const currentState = newState === 'OFF' ? 'ON' : 'OFF';

            if (element.state === ActionQueueElementState.Pending) {

                const result = element.deviceresponse as ApiCheckOperation;

                if (result.outcome === ApiOutcomeValue.AccessDenied || result.outcome === ApiOutcomeValue.Success) {
                    this.currentUnlockingElement = element;
                    const topoLocks = result.topoLocks;
                    const scadaLocks = result.scadaLocks;

                    const unlocks = [];
                    const hints = [];

                    const topoActions = result.topoResults.filter(x => x.requiredAction !== ApiCheckOperationResultAction.None);
                    const topoHints = result.topoResults.filter(x => x.severity === ApiCheckOperationResultSeverity.Hint);

                    const scadaActions = result.scadaResults.filter(x => x.requiredAction !== ApiCheckOperationResultAction.None);
                    const scadaHints = result.scadaResults.filter(x => x.severity === ApiCheckOperationResultSeverity.Hint);

                    const dataConfig = {
                        topoLocks, scadaLocks, currentState, newState, topoActions, topoHints, scadaActions, scadaHints
                    };
                    this.syncingData = dataConfig;
                    this.askToUnlockOperation(dataConfig);
                }
            }
        }
    }

    private askToUnlockOperation(dataConfig) {
        const dialogRef = this.dialog.open(CheckOperationDialogComponent, {
            data: dataConfig,
            panelClass: 'action-queue-dialog-zindex',
            disableClose: true
        });

        dialogRef.afterOpened().subscribe(() => {
            this.uiSyncEvents.next({ showSyncingIndicator: false });
        });

        dialogRef.afterClosed().subscribe(async (answer) => {
            this.uiSyncEvents.next({ showSyncingIndicator: true });

            if (answer === true) {
                this.logging.logDebug('User has unlocked', this.currentUnlockingElement);
                this.actionUnlocked();
                this.currentUnlockingElement = null;
            } else {
                this.logging.logDebug('User has discarded unlocking for', this.currentUnlockingElement);
                const elementId = this.currentUnlockingElement.id;
                await this.stop();
                await firstValueFrom(this.discardElementByUser(elementId));
                this.currentUnlockingElement = null;
                this.queueOverlayRef.overlayElement.focus();
                await this.start();
                this.sync();
            }
        });
    }
}
