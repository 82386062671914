import { Pipe, PipeTransform } from '@angular/core';
import { ApiStateValueStatus } from 'src/app/shared/interfaces/api-statevalue';

@Pipe({
    name: 'translateStateValue',
})
export class TranslateStateValuePipe implements PipeTransform {
    transform(value: ApiStateValueStatus, args?: any): any {
        let res = '';
        switch (value) {
            case 'OFF':
                res = $localize`:@@schema.state.OFF:OFF`;
                break;
            case 'ON':
                res = $localize`:@@schema.state.ON:ON`;
                break;
        }

        return res;
    }
}
