import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { defaults as controlDefaults } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import { click, pointerMove } from 'ol/events/condition';
import { extend, getCenter } from 'ol/extent';
import Feature, { FeatureLike } from 'ol/Feature';
import { Geometry } from 'ol/geom';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import {defaults as interactionDefaults, DragPan, MouseWheelZoom, PinchZoom} from 'ol/interaction';
import Select from 'ol/interaction/Select';
import VectorLayer from 'ol/layer/Vector';
import OpenLayersMap from 'ol/Map';
import OlOverlay from 'ol/Overlay';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import View from 'ol/View';
import { fromEvent, interval, merge, NEVER, Observable, Subject, Subscription, throwError } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import Config from 'src/app/config/Config';
import {
    ApiCheckOperationResult,
    ApiCheckOperationResultAction,
    ApiCheckOperationResultSeverity,
} from 'src/app/shared/interfaces/api-checkopertaion';
import { ApiOutcomeValue } from 'src/app/shared/interfaces/api-outcome';
import { ActionQueueService } from 'src/app/shared/services/action-queue.service';
import { ConnectionStatusState } from 'src/app/shared/services/connection-status.service';
import { environment } from 'src/environments/environment';

import { ApiConfigMarker, ApiMarker } from '../../shared/interfaces/api-marker';
import { ProjectionsService } from '../../shared/services/projections.service';
import { SchemaObjectSubType, SchemaObjectType, SchemaService } from '../../shared/services/schema.service';
import { StyleService } from '../../shared/services/style.service';
import { ActionHistoryService } from '../action-history/action-history.service';
import { MapService } from '../services/map.service';
import { MarkerService } from '../services/marker.service';
import { ApiCheckOperation } from './../../shared/interfaces/api-checkopertaion';
import { ApiGridColorValue } from './../../shared/interfaces/api-gridcolor';
import { MarkerType } from './../../shared/interfaces/api-marker';
import { ApiMeasurementElement } from './../../shared/interfaces/api-measurement';
import { ApiSchema } from './../../shared/interfaces/api-schema';
import { ApiStateValue, ApiStateValueStatus } from './../../shared/interfaces/api-statevalue';
import { ConnectionStatusService } from './../../shared/services/connection-status.service';
import { UpdateFeatureService } from './../../shared/services/updatefeature.service';
import { MapPopupConfig } from './../map-popup/map-popup.component';
import { CheckOperationDialogComponent } from './check-operation/check-operation.component';
import { NumericValueOverlayLabel } from './numeric-value/numeric-value-overlay-label';
import Icon from 'ol/style/Icon';
import {Text} from 'ol/style';
import { ConsoleLoggingService } from '../../shared/services/console-logging.service';
import {postStyleIMSys} from './post-styler';


export interface MarkerOverlay {
    vivavisId: string;
    elementId: string;
    type: 'leitung' | 'knoten';
    coordinate: number[];
    markers: ApiMarker[];
    hidden?: boolean;
    class?: string;
    offset?: number[];
}

export interface FeatureMarkerOverlay {
    feature: Feature<Geometry>;
    markerOverlay: MarkerOverlay;
}

export enum schemaStyles {
    ONS = 'ONS',
    iMSys = 'iMSys'
}

export enum SchemaLoadingIndicatorState {
    Loading,
    Loaded,
    Error,
    Hint,
    ErrorHint
}

export enum SchemaStatusMessageId {
    Check = 'check',
    AddMarker = 'addMarker',
    RemoveMarker = 'removeMarker',
    LoadSchema = 'load-schema',
    ErrorSchema = 'error-schema',
    Measurement = 'measurement',
    Execute = 'execute',
    Color = 'color',
    Switch = 'switch'
}

export interface SchemaStatusMessage {
    id: SchemaStatusMessageId;
    message: string;
    state: SchemaLoadingIndicatorState;
}

export interface AngledTextLabel {
    id: string;
    width: string;
    coords: number[];
    selected: boolean;
    text: string;
}

@Component({
    selector: 'app-schema-menu',
    templateUrl: './schema-menu.component.html',
    styleUrls: ['./schema-menu.component.scss'],
    providers: [SchemaService]
})
export class SchemaMenuComponent implements OnInit, OnDestroy {

    @Output()
    public closeMenu = new EventEmitter();

    @Output()
    public cancelAddMarkerMode = new EventEmitter();

    @Output()
    public openGrafanaHistory = new EventEmitter<{ epvi: string[]; avi: string[]; vvi: string[]; kvi: string[] }>();

    @ViewChild('deleteMarkerMenuTemplate') deleteMarkerMenu: TemplateRef<any>;
    @ViewChild('titleEl') schemaTitleEl: ElementRef;
    @ViewChild('titleSubEl') schemaSubTitleEl: ElementRef;
    @ViewChild('errorTemplate') errorTemplate: TemplateRef<any>;

    // public markerVisible = true;
    public areMarkerHidden = false;
    public showTopButtons = true;
    public changed = false;
    public collapsed = false;
    public selectedFeature: Feature<Geometry>;
    public numericValues: NumericValueOverlayLabel[] = [];
    public selectedNumericValues: { nv: NumericValueOverlayLabel; measurement: ApiMeasurementElement }[] = [];
    public markerOverlays: MarkerOverlay[] = [];
    public selectedMarkerOverlayType: string;
    public notSelectableMarkerLayerType = MarkerType.NotNormal;
    public mapControlEnable = false;
    public viewExtent: number[] = [];
    public angledTextLabels: AngledTextLabel[] = [];

    public cursorStyle = this.sanitizer.bypassSecurityTrustStyle('unset');

    public stationName = '';
    public isLoading = false;
    public isMobile = environment.mobile;
    public isOffline = false;

    public statusMessages: SchemaStatusMessage[] = [];
    public loadingIndicatorStates = SchemaLoadingIndicatorState;
    public hasErrors = false;
    public hasErrorHints = false;

    // testBla = true;

    public enableGrafana = Config.frontend?.schema.grafanaHistoryUrl || false;
    public enableAddMarker = Config.frontend?.enableComponents?.marker ?? true;
    public enabledMarker = Config.frontend?.enableComponents?.marker ?? true;

    public numericValueSelectedUnit = '';

    public markerFilter = [];
    public hasMarker = false;

    public mapPopupConfig: MapPopupConfig;
    public lastSchemaTitleWidth = 150;
    public maxWidthSchemaTitle = this.sanitizer.bypassSecurityTrustStyle(`40%`);

    public errorDialogRef: MatDialogRef<any, any>;

    public hasNumerics = false;
    private hasLines = false;
    private hasLabels = false;

    private schemaMap: OpenLayersMap;
    private schemaFeatureLayer: VectorLayer<VectorSource<Geometry>>;
    private schemaMarkerLayer: VectorLayer<VectorSource<Geometry>>;
    private numericLayer: VectorLayer<VectorSource<Geometry>>;
    private linesLayer: VectorLayer<VectorSource<Geometry>>;
    private labelLayer: VectorLayer<VectorSource<Geometry>>;
    private angledLabelLayer: VectorLayer<VectorSource<Geometry>>;
    private symbolLayer: VectorLayer<VectorSource<Geometry>>;
    private fuseLayer: VectorLayer<VectorSource<Geometry>>;
    private _opened = false;
    private selectInteraction: Select;
    private stateChangeInteraction: Select;
    private hoverInteraction: Select;
    private addMarkerInteraction: Select;
    private addMarkerHoverInteraction: Select;
    private _feature;
    private _picno: any;
    private destroy$ = new Subject<void>();
    private updateSchema = new Subject<void>();
    private updateSchema$ = this.updateSchema.asObservable();
    private updateSwitchStateValue = new Subject<void>();
    private updateColorsAndMarkers = new Subject<void>();
    private updateNumericValues = new Subject<void>();
    private schema: ApiSchema;
    private featuresWithMarkers = {}; //  new Map<string, FeatureMarkerOverlay>();
    private schemaLayers: VectorLayer<VectorSource<Geometry>>[] = [];
    private _activeMarker;

    private deleteMarkerOverlayRef: OverlayRef | null;
    private deleteMarkerClickSub: Subscription;

    private objectSubTypePropertyKey = 'objectSubType';

    // private numericValuePolling = interval(45 * 1000);
    private numericValuePolling = interval((Config.frontend.schema.updateInterval || 45) * 1000);

    private uprightDisplay: boolean;


    private mobileOfflinePolling = interval(10 * 1000).pipe(
        tap(() => this.updateMobile()),
        takeUntil(this.destroy$)
    );

    private mobileOfflinePollingSub: Subscription;

    @Input()
    public set picno(no) {
        this._picno = no;
        if (this._opened && this.schemaMap) {
            this.loadSchema();
        }
    }

    public get picno() {
        return this._picno;
    }

    @Input()
    public schemaStyle: string | undefined = undefined;

    @Input()
    public set opened(o: boolean) {
        this._opened = o;
    }

    public get opened() {
        return this._opened;
    }

    @Input()
    public set feature(f: Feature<Geometry>) {
        if (f) {
            this._feature = f;
        }
    }

    public get feature() {
        return this._feature;
    }


    @Input()
    set activeMarker(v: ApiConfigMarker) {
        this._activeMarker = v;
        if (this._activeMarker && this.addMarkerMode) {
            this.setCursorStyle();
        }
    }

    @Input() isGrafanaOpen = false;

    get activeMarker() {
        return this._activeMarker;
    }

    public addMarkerMode = false;

    constructor(
        private projectionsService: ProjectionsService,
        private schemaService: SchemaService,
        private markerService: MarkerService,
        private mapService: MapService,
        private actionHistoryService: ActionHistoryService,
        private styleService: StyleService,
        private updateFeatureService: UpdateFeatureService,
        private actionQueueService: ActionQueueService,
        private connecetionStatusService: ConnectionStatusService,
        private dialog: MatDialog,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private sanitizer: DomSanitizer,
        private logging: ConsoleLoggingService
        ) {

        this.markerFilter = this.markerService.getAvailableMarkerTypes();

    }

    private scheduleMobileOfflinePolling() {

        if (this.isOffline && !this.mobileOfflinePollingSub) {
            this.logging.logDebug('scheduleMobileOfflinePolling', 'subscribe');
            this.mobileOfflinePollingSub = this.mobileOfflinePolling.subscribe();

        } else if (!this.isOffline && this.mobileOfflinePollingSub) {
            this.logging.logDebug('scheduleMobileOfflinePolling', 'unsubscribe');
            this.mobileOfflinePollingSub.unsubscribe();
            this.mobileOfflinePollingSub = null;
        }
    }

    async ngOnInit() {
        this.setupMap();
        this.setupUpdateSchemaData();
        this.subscribeToEvents();
        this.loadSchema();
    }

    ngOnDestroy(): void {
        // this.subscriptions.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    loadSchema() {
        this.numericValues = [];
        this.angledTextLabels = [];
        this.statusMessages = [];
        this.schemaMap.getLayers().forEach((x: VectorLayer<VectorSource<Geometry>>) => x.getSource().clear());
        this.schemaMap.getOverlays().clear();
        this.getSchemaPicture().then( (success: boolean | string) => {
            this.drawSchemaPicture();
        });
        // this.updateSchemaData();
    }

    closeDeleteMarkerMenu() {
        if (this.deleteMarkerClickSub) {
            this.deleteMarkerClickSub.unsubscribe();
        }
        if (this.deleteMarkerOverlayRef) {
            this.deleteMarkerOverlayRef.dispose();
            this.deleteMarkerOverlayRef = null;
        }
        this.selectedMarkerOverlayType = null;
    }

    openDeleteMarkerMenu({ x, y }: MouseEvent, marker: ApiMarker, markerOverlay: MarkerOverlay) {
        this.closeDeleteMarkerMenu();
        const markerType = marker.content;
        if (!marker.isOfflineRemoved && !marker.isOfflineSet) {
            if (this.markerService.getUserMarkerTypes().includes(markerType)) {
                this.selectedMarkerOverlayType = markerOverlay.vivavisId + '-' + markerType;
                const positionStrategy = this.overlay.position()
                    .flexibleConnectedTo({ x, y })
                    .withPositions([
                        {
                            originX: 'end',
                            originY: 'bottom',
                            overlayX: 'end',
                            overlayY: 'top',
                        }
                    ]);

                this.deleteMarkerOverlayRef = this.overlay.create({
                    positionStrategy,
                    scrollStrategy: this.overlay.scrollStrategies.close()
                });

                this.deleteMarkerOverlayRef.attach(
                    new TemplatePortal(
                        this.deleteMarkerMenu,
                        this.viewContainerRef,
                        {
                            $implicit: { markerType, markerOverlay }
                        }
                    )
                );

                this.deleteMarkerClickSub = fromEvent<MouseEvent>(document, 'click')
                    .pipe(
                        filter(event => {
                            const clickTarget = event.target as HTMLElement;
                            return !!this.deleteMarkerOverlayRef && !clickTarget.classList.contains('marker-symbol-icon');
                        }),
                        take(1)
                    ).subscribe(() => this.closeDeleteMarkerMenu());
            }
        }
    }

    public setCursorStyle() {
        this.cursorStyle = this.mapService.getAddMarkerModeCursorStyle(this.activeMarker);
    }

    public closePopup() {
        this.clearSelectedFeature();
    }

    public removeMarker(data: { markerType: MarkerType; markerOverlay: MarkerOverlay }) {

        this.setLoading(true, 0, false);
        this.statusMessages = [];
        this.updateStatusMessage(
            SchemaStatusMessageId.RemoveMarker,
            $localize`:@@schema.messages.removeMarker.loading:Verifying marker operation`);

        const markerType = data.markerType;
        const markerOverlay = data.markerOverlay;
        const markerConfig = this.markerService.getMarkerConfigForMarkerType(markerType);

        this.markerService.removeMarkerFromIdent(markerConfig, markerOverlay.vivavisId)
            .subscribe({
                next: res => {
                    if (res?.outcome === 'Success') {

                        this.updateStatusMessage(
                            SchemaStatusMessageId.RemoveMarker,
                            $localize`:@@schema.messages.removeMarker.loaded:Marker removed`,
                            SchemaLoadingIndicatorState.Loaded);

                        this.setLoading(false, 500);

                        this.updateMobile();

                        // const params = new URLSearchParams(window.location.search);
                        // this.actionHistoryService.addHistoryEntry({
                        //     type: ActionHistoryTypes.DeleteMarker,
                        //     subType: markerType,
                        //     targetVivavisId: markerOverlay.vivavisId,
                        //     location: {
                        //         lat: params.get('lat'),
                        //         lon: params.get('lon'),
                        //         zoom: params.get('zoom'),
                        //         schema: this._picno
                        //     }
                        // });

                    } else {

                    }
                },
                error: err => {

                    const errorMessage = err?.error?.error || $localize`:@@schema.messages.removeMarker.error:Error: Marker could not be removed`;

                    this.updateStatusMessage(
                        SchemaStatusMessageId.RemoveMarker,
                        errorMessage,
                        SchemaLoadingIndicatorState.Error);
                    this.logging.log('Error: ', errorMessage);
                }
            });
    }

    public updateOverlayPosition() {
        const ex = this.selectedFeature.getGeometry().getExtent();
        const center = getCenter(ex);
        // this.overlay.setPosition(center);
    }

    public fullTitle(el: HTMLElement) {
        const titleClass = 'big-title';
        if (el.classList.contains(titleClass)) {
            el.classList.remove(titleClass);
            this.maxWidthSchemaTitle = this.sanitizer.bypassSecurityTrustStyle(`${this.lastSchemaTitleWidth}px`);
        } else {
            el.classList.add(titleClass);
            this.maxWidthSchemaTitle = this.sanitizer.bypassSecurityTrustStyle('unset');
        }
    }

    public closeSchema() {
        this.closeMenu.emit();
    }

    public clickOpenGrafanaHistory() {
        const voltIds = this.selectedNumericValues.filter(x => x.measurement.unit.toLowerCase() === 'v').map(x => x.nv.id);
        const ampereIds = this.selectedNumericValues.filter(x => x.measurement.unit.toLowerCase() === 'a').map(x => x.nv.id);
        const kwIds = this.selectedNumericValues.filter(x => x.measurement.unit.toLowerCase() === 'kw').map(x => x.nv.id);
        const kvarIds = this.selectedNumericValues.filter(x => x.measurement.unit.toLowerCase() === 'kvar').map(x => x.nv.id);
        this.openGrafanaHistory.emit({ epvi: kwIds, avi: ampereIds, vvi: voltIds, kvi: kvarIds });
    }

    public selectNumericValue(apiMeasurement: ApiMeasurementElement, nvl: NumericValueOverlayLabel) {
        if (this.enableGrafana) {
            const index = this.selectedNumericValues.findIndex(x => x.nv.id === nvl.id);
            if (index === -1) {
                this.selectedNumericValues.push({ measurement: apiMeasurement, nv: nvl });
            } else {
                this.selectedNumericValues.splice(index, 1);
            }

            for (const value of this.numericValues) {
                value.selected = false;
                const exist = !!this.selectedNumericValues.find(x => x.nv.id === value.id);
                if (exist) {
                    value.selected = true;
                }
            }
        }
        if(this.isGrafanaOpen){
            this.clickOpenGrafanaHistory();
        }
    }

    public selectAngledText(angeledText: AngledTextLabel) {
        const originalValue = angeledText.selected;
        for (const label of this.angledTextLabels) {
            label.selected = false;
        }
        angeledText.selected = !originalValue;
    }

    public toggleMarkerVisibility() {

        const hidden = this.markerOverlays.map(x => x.hidden).reduce(x => x);
        this.setMarkerVisibility(!hidden);
    }

    private setMarkerVisibility(hidden: boolean) {
        if (this.markerOverlays.length) {
            for (const markerOverlay of this.markerOverlays) {
                markerOverlay.hidden = hidden;
            }
            this.areMarkerHidden = this.markerOverlays.map(x => x.hidden).reduce(x => x);
        }
        this.logging.logDebug('areMarkerHidden', this.areMarkerHidden);
    }

    private showMarkers() {
        this.setMarkerVisibility(false);
    }

    private hideMarkers() {
        this.setMarkerVisibility(true);
    }

    private setupUpdateSchemaData() {
        this.updateSchema$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.updateColorsAndMarkers.next();
                this.updateSwitchStateValue.next();
                this.updateNumericValues.next();
            });
    }

    private subscribeToEvents() {
        const giantOb = merge<any>(
            fromEvent(window, 'resize')
                .pipe(debounceTime(150))
                .pipe(tap(() => {
                    this.drawSchemaPicture();
                    // this.fitSchemaToView();
                    this.fitAngledTextLables();
                })),

            this.mapService.addMarkerMode$
                .pipe(tap(amm => this.setAddMarkerMode(amm))),

            this.mapService.markerLayerVisibility$
                .pipe(
                    // tap(console.log),
                    tap(visbility => {
                        this.setMarkerVisibility(!visbility);
                    })),


            this.updateColorsAndMarkers.asObservable()
                .pipe(debounceTime(100))
                .pipe(switchMap(() => this.getLineColors())),


            this.updateSwitchStateValue.asObservable()
                .pipe(switchMap(() => this.getSwitchStateValue())),


            this.updateNumericValues.asObservable()
                .pipe(switchMap(() => this.getNumericValues())),


            this.updateFeatureService.updateSwitches$
                .pipe(map(() => null))
                .pipe(tap(this.updateSwitchStateValue)),


            this.
            numericValuePolling
                .pipe(tap(() => {
                    this.updateSwitchStateValue.next();
                    this.updateNumericValues.next();
                })),

            merge(
                this.updateFeatureService.updateColors$,
                this.updateFeatureService.updateMarkers$
            )
                .pipe(tap(() => this.updateColorsAndMarkers.next())),


            this.connecetionStatusService.connectionStatus$
                .pipe(
                    distinctUntilChanged(),
                    tap(state => {
                        this.isOffline = state.currentStatus === ConnectionStatusState.Offline;
                    })
                ),
        );

        giantOb.pipe(takeUntil(this.destroy$)).subscribe();

    }

    private _controlMapInteractions = [
        new DragPan(),
        new MouseWheelZoom(),
        new PinchZoom()
    ];

    private setupMap() {
        if (!this.schemaMap) {
            this.schemaMap = new OpenLayersMap({
                target: document.getElementById('schema-map'),
                interactions: interactionDefaults({
                    altShiftDragRotate: false,
                    doubleClickZoom: false,
                    dragPan: false,
                    keyboard: false,
                    mouseWheelZoom: false,
                    onFocusOnly: false,
                    pinchRotate: false,
                    pinchZoom: false,
                    shiftDragZoom: false
                }).extend(this._controlMapInteractions),
                controls: controlDefaults({
                    attribution: false,
                    rotate: false,
                    zoom: false
                }),
                overlays: [],
                layers: [],
                view: new View({
                    projection: this.projectionsService.epsg4326Projection,
                })
            });

            this._controlMapInteractions.forEach((interaction) => {
                interaction.setActive(this.mapControlEnable);
            });

            this.setupLayers();
            this.addInteractions();
            if (environment.develop || environment.staging) {
                (window as any).schemaMap = this.schemaMap;
            }

        }

    }

    triggerMapControls(reset: boolean = false){
        this.mapControlEnable = (reset) ? false : !this.mapControlEnable;
        // const _actions = this.schemaMap.getInteractions();
        this._controlMapInteractions.forEach((interaction) => {
            interaction.setActive(this.mapControlEnable);
        });

        if(this.mapControlEnable){
            this.schemaMap.setView(
                new View({
                    projection: this.projectionsService.epsg4326Projection,
                    extent: this.schemaMap.getView().calculateExtent(this.schemaMap.getSize()),
                    // extent: this.viewExtent,
                    center: this.schemaMap.getView().getCenter(),
                    resolution: this.schemaMap.getView().getResolution(),
                    zoom: this.schemaMap.getView().getZoom()
                })
            );
            this.schemaMap.getView().setMaxZoom(this.schemaMap.getView().getZoom() + Config.frontend.schema.control.maxZoom);
        }else{
            this.schemaMap.setView(
                new View({
                    projection: this.projectionsService.epsg4326Projection,
                    center: this.schemaMap.getView().getCenter(),
                    resolution: this.schemaMap.getView().getResolution(),
                    zoom: this.schemaMap.getView().getZoom()
                })
            );
            if(!reset){
                this.fitSchemaToView(500);
            }
        }

    }

    private async setAddMarkerMode(amm: boolean) {
        this.addMarkerMode = amm;
        if (this.schemaMap && this.enableAddMarker) {
            if (this.addMarkerMode) {
                // this.schemaMap.removeInteraction(this.addMarkerHoverInteraction);
                this.schemaMap.addInteraction(this.addMarkerInteraction);
                this.schemaMap.addInteraction(this.addMarkerHoverInteraction);
                this.schemaMap.removeInteraction(this.stateChangeInteraction);
                this.enableGrafana = false;
                this.showTopButtons = false;
                if (this.areMarkerHidden) {
                    this.showMarkers();
                }
                this.numericLayer.setVisible(false);
                await this.fitSchemaToView(250);
            } else {
                this.schemaMap.removeInteraction(this.addMarkerHoverInteraction);
                this.schemaMap.removeInteraction(this.addMarkerInteraction);
                this.schemaMap.addInteraction(this.stateChangeInteraction);
                this.enableGrafana = true;
                this.showTopButtons = true;
                this.setMarkerVisibility(this.areMarkerHidden);
                this.numericLayer.setVisible(true);
                await this.fitSchemaToView(250);
            }

            if (this.activeMarker) {
                this.setCursorStyle();
            }
        }
    }

    private addInteractions() {

        this.hoverInteraction = new Select({
            style: null,
            condition: pointerMove,
            layers: [this.fuseLayer]
        });

        this.hoverInteraction.on('select', event => {
            event.stopPropagation();
            event.preventDefault();
            event.mapBrowserEvent.preventDefault();
            event.mapBrowserEvent.stopPropagation();

            if (event.selected.length === 1) {
                const feature = event.selected[0];
                const isOffline = feature.get('isOffline') as boolean;
                const isError = feature.get('isError') as boolean;
                if (!this.hasErrors && !this.addMarkerMode && !this.isOffline && !isError) {
                    this.schemaMap.getViewport().style.cursor = 'pointer';
                }
            } else {
                this.schemaMap.getViewport().style.cursor = '';
            }

            // this.hoverInteraction.getFeatures().clear();
        });

        this.addMarkerInteraction = new Select({
            condition: click,
            style: null,
            hitTolerance: 5,
            layers: [this.linesLayer]
        });

        this.addMarkerInteraction.on('select', event => {
            event.stopPropagation();
            event.preventDefault();
            event.mapBrowserEvent.preventDefault();
            event.mapBrowserEvent.stopPropagation();

            if (event.selected.length === 1) {
                if (this.addMarkerMode) {
                    const feature = event.selected[0];
                    const vivavisId = feature.get('vivavisId');
                    this.addMarkerToIdent(vivavisId);
                }
            }

            this.addMarkerInteraction.getFeatures().clear();
        });

        this.addMarkerHoverInteraction = new Select({
            condition: pointerMove,
            style: (f) => {
                const sot = f.get('objectType') as SchemaObjectType;
                const ssot = f.get(this.objectSubTypePropertyKey) as SchemaObjectSubType;


                let style: Style = null;
                if (ssot === SchemaObjectSubType.Line || ssot === SchemaObjectSubType.Busbar) {
                    style = new Style({
                        stroke: new Stroke({
                            color: '#ffa500',
                            width: 5
                        })
                    });
                }
                return style;
            },
            hitTolerance: 5,
            layers: [this.linesLayer]
        });

        this.addMarkerHoverInteraction.on('select', event => {
            event.stopPropagation();
            event.preventDefault();
            event.mapBrowserEvent.preventDefault();
            event.mapBrowserEvent.stopPropagation();

            if (event.selected.length === 1) {
            }
        });


        this.stateChangeInteraction = new Select({
            condition: click,
            style: null,
            layers: [this.fuseLayer]
        });

        this.stateChangeInteraction.on('select', event => {
            event.stopPropagation();
            event.preventDefault();
            event.mapBrowserEvent.preventDefault();
            event.mapBrowserEvent.stopPropagation();

            if (event.selected.length === 1) {
                this.stateChangeInteraction.getFeatures().clear();
                const feature = event.selected[0];
                const isOffline = feature.get('isOffline') as boolean;
                const isError = feature.get('isError') as boolean;
                if (!this.hasErrors && !isOffline && !isError) {
                    this.checkOperation(feature);
                }
            }
        });

        this.schemaMap.addInteraction(this.stateChangeInteraction);
        this.schemaMap.addInteraction(this.hoverInteraction);
        // this.schemaMap.addInteraction(markerClickInteraction);

        this.schemaMap.on('click', event => {
            for (const l of this.angledTextLabels) {
                l.selected = false;
            }
            this.schemaMap.changed();
        });
    }

    private setupLayers() {

        this.linesLayer = new VectorLayer({
            className: 'linesLayer',
            source: new VectorSource({
                features: []
            })
        });

        this.labelLayer = new VectorLayer({
            className: 'labelLayer',
            source: new VectorSource({
                features: []
            })
        });

        this.angledLabelLayer = new VectorLayer({
            className: 'angledLabelLayer',
            source: new VectorSource({
                features: []
            })
        });

        this.numericLayer = new VectorLayer({
            className: 'numericLayer',
            source: new VectorSource({
                features: []
            })
        });

        this.fuseLayer = new VectorLayer({
            className: 'fuseLayer',
            source: new VectorSource({
                features: []
            }),
            style: (eventFeature: FeatureLike) => {
                const symbolType = eventFeature.get(this.objectSubTypePropertyKey) as string;
                const state = eventFeature.get('state') as ApiStateValueStatus;
                const isOffline = eventFeature.get('isOffline') as boolean;
                const isError = eventFeature.get('isError') as boolean;
                return this.styleService.getSchemaSymbolStyle(symbolType, state, false, isOffline, isError);
            }
        });

        this.symbolLayer = new VectorLayer({
            className: 'symbolLayer',
            source: new VectorSource({
                features: []
            }),
            style: (eventFeature: FeatureLike) => {
                const symbolType = eventFeature.get(this.objectSubTypePropertyKey) as string;
                const state = eventFeature.get('state') as ApiStateValueStatus;
                return this.styleService.getSchemaSymbolStyle(symbolType, state);
            }
        });

        this.schemaFeatureLayer = new VectorLayer({
            className: 'schemaFeatureLayer',
            source: new VectorSource({
                features: []
            })
        });

        this.schemaMarkerLayer = new VectorLayer({
            className: 'schemaMarkerLayer',
            source: new VectorSource(),
            style: null
        });

        this.schemaLayers = [
            this.schemaFeatureLayer,
            this.labelLayer,
            this.angledLabelLayer,
            this.linesLayer,
            this.numericLayer,
            this.symbolLayer,
            this.fuseLayer,
            // this.schemaMarkerLayer
        ];

        this.schemaLayers.map((l, i) => {
            l.setZIndex(i);
            this.schemaMap.addLayer(l);
        });
    }

    private drawSchemaPicture(){
        this.uprightDisplay = window.innerWidth < window.innerHeight;
            if (this.schema) {

                this.selectedNumericValues = [];
                this.viewExtent = [];
                if(this.mapControlEnable){
                    this.triggerMapControls(true);
                }

                this.stationName = this.schema.name;

                if (this.schema.hasError) {

                    this.updateStatusMessage(SchemaStatusMessageId.LoadSchema, $localize`:@@noData:No data`, SchemaLoadingIndicatorState.Error);

                } else {

                    this.linesLayer.getSource().clear();
                    this.linesLayer.getSource().addFeatures(this.schema.lines);

                    this.labelLayer.getSource().clear();
                    this.labelLayer.getSource().addFeatures(this.schema.labels);

                    this.schemaMap.getOverlays().clear();
                    this.numericLayer.getSource().clear();

                    if (!this.isOffline) {
                        this.addNumericValuesAndOverlays();
                        this.numericLayer.getSource().addFeatures(this.schema.numerics);
                    }

                    this.symbolLayer.getSource().clear();
                    this.symbolLayer.getSource().addFeatures(this.schema.symbols);
                    this.symbolLayer.getSource().addFeatures(this.schema.rtuSymbols);
                    this.symbolLayer.getSource().addFeatures(this.schema.qualitySignals);

                    this.fuseLayer.getSource().clear();
                    this.fuseLayer.getSource().addFeatures(this.schema.fuses);

                    this.schemaFeatureLayer.getSource().clear();
                    this.schemaFeatureLayer.getSource().addFeatures(this.schema.features);

                    this.hasLines = this.linesLayer.getSource().getFeatures().length > 0;
                    this.hasLabels = this.labelLayer.getSource().getFeatures().length > 0;
                    this.hasNumerics = this.numericLayer.getSource().getFeatures().length > 0;

                    this.setupFeaturesForMarkers().then( (featuresWithMarkers) => {
                        this.featuresWithMarkers = featuresWithMarkers;

                        this.fitSchemaToView();

                        setTimeout( () => {
                            // if(this.schema.historyButtonCoords[0] === 181){ // 181 is the default for empty values
                            //     this.setupUI({
                            //         // historyButtonCoords: [100,100],
                            //         triggerViewControlCoords: [18,80],
                            //         historyButtonCoords: [18,100]
                            //     });
                            // }else{
                            //     this.setupUI({
                            //         // historyButtonCoords: this.schemaMap.getPixelFromCoordinate(this.schema.historyButtonCoords),
                            //         triggerViewControlCoords: [18,80],
                            //         historyButtonCoords: [0, 100]
                            //     });
                            // }

                            this.setupUI({
                                // historyButtonCoords: this.schemaMap.getPixelFromCoordinate(this.schema.historyButtonCoords),
                                triggerViewControlCoords: [18,0],
                                historyButtonCoords: [18, 50]
                            });

                            this.addAngledText();
                            this.addMarkerOverlays();
                        }, 50);

                        this.retrieveCurrentData();


                        // this.updateSchema.next();
                        // this.hasErrors = false;
                        // this.hasErrorHints = false;


                        // this.retrieveCurrentData();

                    });
                    // this.logging.logDebug('start 1. Timeout')
                    // setTimeout(() => {
                        // this.fitSchemaToView();
                        //
                        // this.logging.logDebug('start 2. Timeout')
                        // setTimeout(() => {
                        //     if(this.schema.historyButtonCoords[0] === 181){ // 181 is the default for empty values
                        //         this.setupUI({
                        //             historyButtonCoords: [100,100]
                        //         });
                        //     }else{
                        //         this.setupUI({
                        //             historyButtonCoords: this.schemaMap.getPixelFromCoordinate(this.schema.historyButtonCoords)
                        //         });
                        //     }
                        //
                        //     this.addAngledText();
                        //     this.addMarkerOverlays();
                        //      this.logging.logDebug('End 2. TImeout');
                        // }, 50);
                        //
                        // this.retrieveCurrentData();
                        // resolve(true);
                        //
                        //  this.logging.logDebug('End 1. TImeout')
                    // }, 50);
                }
            }

    }

    private getSchemaPicture() {
        return new Promise((resolve, reject) => {
            this.setLoading(true, 0, true);
            this.schemaService.getSchema(this.picno)
                .toPromise()
                .catch(err => {
                    this.updateStatusMessage(SchemaStatusMessageId.ErrorSchema, $localize`:@@schema.error:Error while loading the schema`, SchemaLoadingIndicatorState.Error);
                })
                .then((schema: ApiSchema) => {
                    if(schema){
                        this.schema = schema;
                        resolve(true);
                    }else{
                        reject('no Schema Loaded');
                    }
                });
        });
    }

    private addNumericValuesAndOverlays() {
        this.numericValues = [];
        const numerValueIdProperty = 'vivavisId';
        this.numericValues = this.schema.numerics.map(x => ({
            id: x.get(numerValueIdProperty),
            vivavisId: x.get('vivavisId'),
            objId: x.get('objId'),
            selected: false
        }));

        setTimeout(() => {
            const numericOverlays = this.schema.numerics.map(x => {
                const overlay = new OlOverlay({
                    id: x.get(numerValueIdProperty),
                    element: document.getElementById(x.get(numerValueIdProperty)),
                    position: (x.getGeometry() as Point).getCoordinates(),
                    className: 'show-numeric-labels',
                    offset: [-48, -10]
                });
                return overlay;
            });
            for (const nv of numericOverlays) {
                this.schemaMap.addOverlay(nv);
            }
        }, 50);
    }

    private addMarkerOverlays() {

        this.markerOverlays = [];

        this.markerOverlays = Object.values(this.featuresWithMarkers).map((x: Feature<Geometry>) => ({
            vivavisId: x.get('vivavisId'),
            elementId: 'marker-' + x.get('vivavisId'),
            coordinate: x.get('markerCoords'),
            type: x.get('markerType'),
            markers: x.get('markers'),
            class: x.get('cssClass'),
            offset: x.get('featureOffset'),
            hidden: false
        }));

        setTimeout(() => {
            for (const markerOverlay of this.markerOverlays) {
                const overlay = new OlOverlay({
                    id: markerOverlay.vivavisId,
                    element: document.getElementById(markerOverlay.elementId),
                    position: markerOverlay.coordinate,
                    className: 'show-marker-overlay' + markerOverlay.class,
                    offset: markerOverlay.offset
                });
                this.schemaMap.addOverlay(overlay);
                overlay.getElement().style.display = 'block';
            }
        }, 50);

    }

    private setupFeaturesForMarkers() {
        return new Promise ( (res) => {

            const idCounter = {};
            this.schema.lines.map(x => {
                const id = x.get('vivavisId');
                if (idCounter[id]) {
                    idCounter[id]++;
                } else {
                    idCounter[id] = 1;
                }
            });

            const linesOfInterest = this.schema.lines.reduce((hmap, x) => {
                const id = x.get('vivavisId');
                const subType = x.get(this.objectSubTypePropertyKey) as SchemaObjectSubType;

                if (idCounter[id] > 1 && subType === SchemaObjectSubType.Line) {
                    return hmap;
                } else {
                    const extent = (x.getGeometry() as LineString).getExtent();
                    const center = getCenter(extent);
                    const coords = (x.getGeometry() as LineString).getCoordinates();
                    const markerCoords = subType === SchemaObjectSubType.Busbar ? center : coords[coords.length - 1];
                    const cssClass = subType === SchemaObjectSubType.Busbar ? '-busbar' : '-line';
                    const overlayOffset = subType === SchemaObjectSubType.Busbar ? [0, -12] : [10, -20];
                    x.set('markerCoords', markerCoords);
                    x.set('cssClass', cssClass);
                    x.set('featureOffset', overlayOffset);
                    hmap[id] = x;
                    return hmap;
                }
            }, {});

            const fusesOfInterest = this.schema.fuses.reduce((hmap, x) => {
                const id = x.get('vivavisId');
                const coords = (x.getGeometry() as Point).getCoordinates();
                x.set('markerCoords', coords);
                x.set('cssClass', '-fuse');
                x.set('featureOffset', [15, -20]);
                hmap[id] = x;
                return hmap;
            }, {});
            res({ ...linesOfInterest, ...fusesOfInterest });
            // this.featuresWithMarkers = { ...linesOfInterest, ...fusesOfInterest };

        });

    }

    private getAngledTextOffsetX() {
        const prf = window.devicePixelRatio;
        const offsetX = 30;
        return offsetX;
    }

    private addAngledText() {
        if (this.schema.fuses.length > 0) {
            const fuse = this.schema.fuses[0];
            const fuseCoords = (fuse.getGeometry() as Point).getCoordinates();
            const offsetX = this.getAngledTextOffsetX();
            const pixelLen = this.calcTextPixelLength(fuseCoords) - offsetX;
            const width = pixelLen + 'px';
            const res: AngledTextLabel[] = [];

            for (const textLabel of this.schema.angledLabels) {
                const labelCoords = (textLabel.getGeometry() as Point).getCoordinates();
                const coords = fuseCoords ? [labelCoords[0], fuseCoords[1]] : labelCoords;
                res.push({
                    id: textLabel.getId() + '',
                    coords,
                    width,
                    selected: false,
                    text: textLabel.get('textcontent')
                });
            }

            this.angledTextLabels = res;

            setTimeout(() => {
                const angledTextOverlays = this.angledTextLabels.map(x => {
                    const overlay = new OlOverlay({
                        id: x.id,
                        element: document.getElementById(x.id),
                        position: x.coords,
                        className: 'show-90d-labels',
                        offset: [-11, offsetX]
                    });
                    return overlay;
                });
                for (const overlay of angledTextOverlays) {
                    this.schemaMap.addOverlay(overlay);
                    overlay.getElement().style.display = 'block';
                }
            }, 100);

        }

    }

    private setupUI(posObj){
        // console.log('featt', this.featuresWithMarkers, this.schemaStyle);
        // console.log('Labels',this.labelLayer.getSource().getFeatures(), (this.labelLayer.getSource().getFeatures()[0].getStyle() as Style).getText());
        // console.log('Num',this.numericLayer.getSource().getFeatures());
        // console.log('symbol',this.symbolLayer.getSource().getFeatures());
        // console.log('fusel',this.fuseLayer.getSource().getFeatures());
        // console.log('schemamap', this.schemaMap.getOverlays().getArray()[0].getPosition());

        const _setupSubTitle = () => {
            const subTitle = this.labelLayer.getSource().getFeatures()
                .find( (_f) => _f.getProperties().textcontent.substring(0, 3) === 'DE ');
            if(subTitle){
                this.schemaSubTitleEl.nativeElement.innerHTML = subTitle.getProperties().textcontent;
                this.labelLayer.getSource().removeFeature(subTitle);
            }
        };
        _setupSubTitle();


        switch (this.schemaStyle) {
            case(schemaStyles.iMSys):
                postStyleIMSys( Config.frontend.schemaStyle,
                    this.labelLayer.getSource().getFeatures() as Feature<Geometry>[] as any,
                    this.numericLayer.getSource().getFeatures() as Feature<Geometry>[] as any,
                    this.schemaMap.getOverlays().getArray()
                );
                break;
            case(schemaStyles.ONS):
            default:
                break;
        }

        const grafanaHistoryBTN = document.getElementById('grafanaHistoryButton');
        const triggerMapControlsBTN = document.getElementById('triggerMapControls');

        const _parent = document.getElementById('schema-map');

        Object.keys(posObj).forEach( (optKey: string) => {
            const newPosition = posObj[optKey];
            switch (optKey) {
                case 'historyButtonCoords':
                    this.logging.logDebug('setUpUI,', {newPosition, posObj});
                    // grafanaHistoryBTN.style.left = `calc( ${newPosition[0]}px - ${grafanaHistoryBTN.offsetWidth}px )`;
                    // grafanaHistoryBTN.style.top = `calc( ${newPosition[1]}px - ${grafanaHistoryBTN.offsetHeight/2}px )`;
                    grafanaHistoryBTN.style.right = `${newPosition[0]}px`;
                    grafanaHistoryBTN.style.top = `calc((${_parent.offsetHeight}px/2) - ${newPosition[1]}px )`;
                    break;
                case 'triggerViewControlCoords':
                    this.logging.logDebug('setUpUI,', {newPosition, posObj});
                    // triggerMapControlsBTN.style.left = `calc( ${newPosition[0]}px - ${triggerMapControlsBTN.offsetWidth}px )`;
                    triggerMapControlsBTN.style.right = `${newPosition[0]}px`;
                    triggerMapControlsBTN.style.top = `calc((${_parent.offsetHeight}px/2) - ${newPosition[1]}px )`;
                    break;
            }
        });
    }

    private coordsPixelLength(coords: Coordinate[]) {
        const point1 = coords[0];
        const point2 = coords[1];

        const pixel1 = this.schemaMap.getPixelFromCoordinate(point1);
        const pixel2 = this.schemaMap.getPixelFromCoordinate(point2);
        if (pixel1 && pixel1) {
            const a = pixel1[0] - pixel2[0];
            const b = pixel1[1] - pixel2[1];
            const lengthInPixel = Math.sqrt(a * a + b * b);
            return lengthInPixel;
        } else {
            return 0;
        }
    }

    private calcTextPixelLength(startCoords: Coordinate) {
        let endCoords: Coordinate;
        const lines = this.schema.lines.filter(x => x.get(this.objectSubTypePropertyKey) === SchemaObjectSubType.Line);
        let longestLine = 0;
        if (startCoords) {
            for (const line of lines) {
                const underlyingCoords = (line.getGeometry() as LineString).getCoordinates();
                const point2 = underlyingCoords[underlyingCoords.length - 1];

                const slope = this.slopeOfLine(startCoords, point2);

                if (slope === Infinity || slope === -Infinity) {
                    endCoords = point2;
                    const len = this.coordsPixelLength([startCoords, endCoords]);
                    if (len > longestLine) {
                        longestLine = len;
                    }
                }
            }
            return longestLine;
        } else {
            return 0;
        }
    }

    private slopeOfLine(point1: number[], point2: number[]) {
        const x1 = point1[0];
        const y1 = point1[1];
        const x2 = point2[0];
        const y2 = point2[1];
        return (y2 - y1) / (x2 - x1);
    }

    private fitAngledTextLables() {

        setTimeout(() => {
            if (this.schema.fuses.length > 0) {
                const fuse = this.schema.fuses[0];
                const fuseCoords = (fuse.getGeometry() as Point).getCoordinates();
                const offsetX = this.getAngledTextOffsetX();
                const pixelLen = this.calcTextPixelLength(fuseCoords) - offsetX;
                const width = pixelLen + 'px';

                for (const angledText of this.angledTextLabels) {
                    // angledText.width = (parseInt(angledText.width.replace('px',''), 10) - 10) + 'px';
                    angledText.width = width;
                    // this.schemaMap.getOverlayById(angledText.id).setOffset([0, offsetX]);
                }
            }
        }, 500);
    }

    private fitSchemaToView(duration: number = undefined) {
        // this.setTitleWidth();
            const ww = window.innerWidth;

            // const busbar = this.schema.lines.find(x => x.get('objectSubType') === SchemaObjectSubType.Busbar);
            // const coords = (busbar.getGeometry() as LineString).getCoordinates();
            // const busbarLength = this.coordsPixelLength(coords);
            // this.lastSchemaTitleWidth = (ww - busbarLength) * .5;
            // const roomForTitle = (ww - busbarLength) * .5;
            // const titleWidth = (this.schemaTitleEl.nativeElement as HTMLElement).offsetWidth;


            let bottom = 35;
            let padding = [];
            let boundingBox;
            let lr = 70;
            let rl = 70;
            let top = 65;
            const lineExtent =  this.linesLayer.getSource().getExtent();

            if (this.uprightDisplay) {
                lr = 150;
                rl = 100;
                top = 10;
            }

            const translateGeometry = (geometry: Geometry|Point, bbox: number[], delta: number[] ) => {
                const coords =  (geometry as Point).getCoordinates();
                if(this.uprightDisplay === false && coords[0] > bbox[0]) {
                    geometry.translate(delta[0]* -1, delta[1] * -1);
                }
                if(this.uprightDisplay === true && coords[0] < bbox[0]){
                    geometry.translate(delta[0], delta[1]);
                }
            };

            if (this.hasLines) {
                boundingBox = this.linesLayer.getSource().getExtent();

                if (this.hasLabels) {
                    this.labelLayer.getSource().getFeatures().forEach( (feature) => {
                        const type = feature.get('symboleType');
                        if(type === 'rtu_state_text'){
                            translateGeometry(feature.getGeometry(), lineExtent, [0.007,0.0012]);
                        }
                    });
                    const labelExtent = this.labelLayer.getSource().getExtent();
                    boundingBox = extend(boundingBox, labelExtent);
                }

                if (this.hasNumerics && this.numericLayer.getVisible() === true) {
                    const numericExtent = this.numericLayer.getSource().getExtent();
                    boundingBox = extend(boundingBox, numericExtent);
                } else {
                    bottom = 75;
                }


                if(this.symbolLayer.getVisible() === true){
                    this.symbolLayer.getSource().getFeatures().forEach( (feature) => {
                        const type = feature.get('symboleType');
                        if(type === 'rtu_state'){
                            translateGeometry(feature.getGeometry(), lineExtent, [0.007, 0.001]);
                        }else if(type === 'rtu_warnings'){
                            translateGeometry(feature.getGeometry(), lineExtent, [0.0135, 0.0022]);
                        }

                    });
                    const symbolExtent = this.symbolLayer.getSource().getExtent();
                    boundingBox = extend(boundingBox, symbolExtent);
                }

                padding = [top, rl, bottom, lr];
            } else {
                this.schemaLayers.forEach(x => {
                    if (x.getSource().getFeatures().length > 0) {
                        if (!boundingBox) {
                            boundingBox = x.getSource().getExtent();

                        } else {
                            const nextExtent = x.getSource().getExtent();
                            boundingBox = extend(boundingBox, nextExtent);
                        }
                    }
                });

                padding = [50, 150, 50, 70];

            }

            if (this.isOffline) {
                padding[2] = 150;
            }

            if (boundingBox) {

                if(this.viewExtent.length === 0){
                    this.viewExtent = boundingBox;
                }
                this.schemaMap.getView().fit(
                    this.viewExtent,
                    {
                        padding,
                        duration
                    }
                );

                // this.viewExtent = this.schemaMap.getView().calculateExtent(this.schemaMap.getSize());

            }

    }

    private checkHasMarker() {
        const hm1 = this.markerOverlays.map(x => x.markers?.length > 0).find(x => x === true) || false;
        this.hasMarker = hm1;
    }

    private retrieveCurrentData() {
        this.hasErrors = false;
        this.hasErrorHints = false;
        this.updateSchema.next();
    }

    private updateMobile() {
        this.logging.logDebug('updateMobile', {isMObile: this.isMobile, updateFeatureService_isWebSocketConnected: this.updateFeatureService.isWebSocketConnected});
        if (this.isMobile || this.updateFeatureService.isWebSocketConnected === false) {
            this.updateFeatureService.forceUpdate();
        }
    }

    private updateStatusMessage(id: SchemaStatusMessageId, message: string, state: SchemaLoadingIndicatorState = SchemaLoadingIndicatorState.Loading) {

        if (state === SchemaLoadingIndicatorState.Error) {
            this.hasErrors = true;
        }

        if (state === SchemaLoadingIndicatorState.ErrorHint) {
            this.hasErrorHints = true;
        }

        const sm = this.statusMessages.find(x => x.id === id);

        if (sm) {
            sm.message = message;
            sm.state = state;
        } else {
            this.statusMessages.push({
                id,
                message,
                state
            });
        }

        const mapped = this.statusMessages.map(x => x.state).filter(x => x !== SchemaLoadingIndicatorState.Hint);
        const states = [... new Set(mapped)];
        const hasErrorHints = this.statusMessages.map(x => x.state).filter(x => x === SchemaLoadingIndicatorState.ErrorHint).length > 0;

        const timeout = hasErrorHints ? 2000 : 500;

        if (states.length === 1 && states[0] === SchemaLoadingIndicatorState.Loaded) {
            this.setLoading(false, 1000);
        }
    }

    private clearSelectedFeature() {
        if (this.selectedFeature) {
            this.selectedFeature = null;
        }
    }

    private showPopup() {
        if (this.selectedFeature) {
            const ex = this.selectedFeature.getGeometry().getExtent();
            const center = getCenter(ex);
        }
    }

    private setLoading(loading: boolean, delay: number = 250, clearStatusMessages: boolean = false) {
        this.logging.logDebug('setLoading', { hasErrors: this.hasErrors, loading, delay });
        setTimeout(() => {
            if (this.hasErrors) {
                this.isLoading = true;
            } else {
                this.isLoading = loading;
            }
        }, delay);
    }

    private async addMarkerToIdent(vivavisId: string) {
        this.setLoading(true, 0, false);
        this.statusMessages = [];

        this.updateStatusMessage(
            SchemaStatusMessageId.AddMarker,
            $localize`:@@schema.messages.addMarker.loading:Verifying marker operation`);

        this.markerService.addMarkerToIdent(this.activeMarker, vivavisId)
            .subscribe(
                {
                    next: data => {
                        if (data?.response?.outcome === ApiOutcomeValue.Success) {

                            this.updateStatusMessage(
                                SchemaStatusMessageId.AddMarker,
                                $localize`:@@schema.messages.addMarker.loaded:Marker added`,
                                SchemaLoadingIndicatorState.Loaded);

                            this.setLoading(false, 500);
                            this.cancelAddMarkerMode.emit();
                            this.updateMobile();

                            // const params = new URLSearchParams(window.location.search);
                            // this.actionHistoryService.addHistoryEntry({
                            //     type: ActionHistoryTypes.SetMarker,
                            //     subType: this.activeMarker.markerType,
                            //     targetVivavisId: vivavisId,
                            //     location: {
                            //         lat: params.get('lat'),
                            //         lon: params.get('lon'),
                            //         zoom: params.get('zoom'),
                            //         schema: this._picno
                            //     }
                            // });

                        } else {
                            // let errorMessage = $localize`:@@schema.messages.addMarker.error:Error: Marker could not be added`;

                            // if (data.response.outcome === ApiOutcomeValue.UnknownValue) {
                            //     errorMessage = $localize`:schema.messages.checkOperation.error.unknownValue: Error: UnknownValue`;
                            // }

                            const errorMessage = 'Error: ' + data.response.outcome;

                            this.updateStatusMessage(
                                SchemaStatusMessageId.AddMarker,
                                errorMessage,
                                SchemaLoadingIndicatorState.ErrorHint);

                            this.cancelAddMarkerMode.emit();
                            // this.setLoading(false, 2000);
                        }
                    },
                    error: err => {

                        const errorMessage = err?.error?.error || $localize`:@@schema.messages.addMarker.error:Error: Marker could not be added`;

                        this.updateStatusMessage(
                            SchemaStatusMessageId.AddMarker,
                            errorMessage,
                            SchemaLoadingIndicatorState.Error);
                    }
                });
    }

    private askToUnlockOperation(feature, dataConfig) {
        const dialogRef = this.dialog.open(CheckOperationDialogComponent, {
            data: dataConfig
        });

        dialogRef.afterClosed().subscribe(answer => {
            this.setLoading(false, 0, false);
            if (answer === true) {
                this.operateSwitch(feature, dataConfig.topoLocks, dataConfig.scadaLocks, dataConfig.newState);
            }
        });
    }

    private errorCheckOperation() {
        this.errorDialogRef = this.dialog.open(this.errorTemplate, {
            height: '400px',
            width: '600px',
        });
    }

    private async checkOperation(feature: Feature<Geometry>) {
        this.setLoading(true, 0, false);
        this.statusMessages = [];

        this.updateStatusMessage(SchemaStatusMessageId.Check, $localize`:@@schema.messages.checkOperation.loading:Verifying operation`);

        const vivavisId = feature.get('vivavisId');
        const currentState = feature.get('state') as ApiStateValueStatus;
        let newState: ApiStateValueStatus = 'ON';

        if (currentState === newState) {
            newState = 'OFF';
        }

        this.schemaService.changeSwitchStateValue(vivavisId, newState)
            .subscribe({
                next: data => {
                    if (this.isMobile && this.isOffline) {

                        this.updateStatusMessage(
                            SchemaStatusMessageId.Check,
                            $localize`:@@schema.messages.checkOperation.scheduled:Operation scheduled`,
                            SchemaLoadingIndicatorState.Hint);

                        this.setLoading(false, 1500);
                        this.actionQueueService.updateActionQueue();
                        this.updateMobile();

                    } else {
                        if (data.response) {
                            const result = data.response as ApiCheckOperation;

                            if (result.outcome === ApiOutcomeValue.AccessDenied || result.outcome === ApiOutcomeValue.Success) {

                                const topoLocks = result.topoLocks;
                                const scadaLocks = result.scadaLocks;

                                this.updateStatusMessage(
                                    SchemaStatusMessageId.Check,
                                    $localize`:@@schema.messages.checkOperation.loaded:Operation verified`,
                                    SchemaLoadingIndicatorState.Loaded);

                                this.setLoading(false, 500);

                                const unlocks = [];
                                const hints = [];

                                const topoActions = result.topoResults.filter(x => x.requiredAction !== ApiCheckOperationResultAction.None);
                                const topoHints = result.topoResults.filter(x => x.severity === ApiCheckOperationResultSeverity.Hint);

                                const scadaActions = result.scadaResults.filter(x => x.requiredAction !== ApiCheckOperationResultAction.None);
                                const scadaHints = result.scadaResults.filter(x => x.severity === ApiCheckOperationResultSeverity.Hint);

                                const dataConfig = {
                                    topoLocks, scadaLocks, currentState, newState, topoActions, topoHints, scadaActions, scadaHints
                                };

                                this.askToUnlockOperation(feature, dataConfig);

                            } else if (result.outcome === ApiOutcomeValue.UnknownValue) {

                                this.updateStatusMessage(
                                    SchemaStatusMessageId.Check,
                                    $localize`:@@schema.messages.checkOperation.error.unknownValue:Error: User in HIGH-LEIT unknown`,
                                    SchemaLoadingIndicatorState.Error);
                            }

                        }
                    }

                },
                error: err => {
                    const errorMessage = err?.error?.error || $localize`:@@schema.messages.checkOperation.error:Error: Operation could not be verified`;

                    this.updateStatusMessage(SchemaStatusMessageId.Check, errorMessage, SchemaLoadingIndicatorState.Error);
                }
            });
    }

    closeLoadingHint(){
        // this.setLoading(false, 50);
        this.hasErrorHints = false;
        this.hasErrors = false;
        this.isLoading = false;
    }

    private async operateSwitch(feature: Feature<Geometry>, topoLocks: ApiCheckOperationResult[], scadaLocks: ApiCheckOperationResult[], newState: ApiStateValueStatus) {
        this.setLoading(true, 0, false);
        this.statusMessages = [];
        this.updateStatusMessage(SchemaStatusMessageId.Execute, $localize`:@@schema.messages.executeOperation.loading:Executing operation`);
        const vivavisId = feature.get('vivavisId');

        this.schemaService.executeSwitchStateValueChange(vivavisId, newState, scadaLocks, topoLocks)
            .subscribe({
                next: answer => {

                    if (answer.outcome === 'Success') {

                        feature.set('state', newState);

                        this.symbolLayer.changed();

                        this.updateMobile();

                        // const params = new URLSearchParams(window.location.search);
                        // this.actionHistoryService.addHistoryEntry({
                        //     type: newState === 'ON' ? ActionHistoryTypes.SwitchOn : ActionHistoryTypes.SwitchOff,
                        //     subType: newState,
                        //     targetVivavisId: vivavisId,
                        //     location: {
                        //         lat: params.get('lat'),
                        //         lon: params.get('lon'),
                        //         zoom: params.get('zoom'),
                        //         schema: this._picno
                        //     }
                        // });

                    }

                    this.updateStatusMessage(
                        SchemaStatusMessageId.Execute,
                        $localize`:@@schema.messages.executeOperation.loaded:Operation successfull executed`,
                        SchemaLoadingIndicatorState.Loaded);

                    this.setLoading(false, 500, true);
                },
                error: err => {
                    const errorMessage = err?.error?.error || $localize`:@@schema.messages.executeOperation.error:Error: Operation could not be executed`;
                    this.updateStatusMessage(SchemaStatusMessageId.Execute, errorMessage, SchemaLoadingIndicatorState.Error);

                    console.error(errorMessage);
                    console.error(err);

                    this.setLoading(false, 1000, true);
                }
            });
    }

    private getSwitchStateValue(): Observable<any> {

        this.updateStatusMessage(
            SchemaStatusMessageId.Switch,
            $localize`:@@schema.messages.switchStateValue.loading:Loading switch states`);

        const vivavisIds = [
            ...new Set<string>(this.schema.fuses.map(x => x.getProperties().vivavisId)),
            ...new Set<string>(this.schema.rtuSymbols.map( x => x.getProperties().vivavisId)),
            ...new Set<string>(this.schema.qualitySignals.map( x => x.getProperties().vivavisId))
        ];

        if (vivavisIds && vivavisIds.length > 0) {

            return this.schemaService.getStateValues(vivavisIds)
                .pipe(catchError(err => {

                    const errorMessage = err?.error?.error || $localize`:@@schema.messages.switchStateValue.error:Error: Switch states could not be loaded`;

                    this.updateStatusMessage(
                        SchemaStatusMessageId.Switch,
                        errorMessage, SchemaLoadingIndicatorState.Error);

                    return err;
                }))
                .pipe(tap((data: ApiStateValue[]) => {
                    for (const d of data) {
                        if (d.vivavisId) {
                            const markerOverlay = this.markerOverlays.find(x => x.vivavisId === d.vivavisId);
                            const feature = this.schema.fuses.find(x => x.get('vivavisId') === d.vivavisId);
                            if (feature) {
                                const status = d.isOn ? 'ON' : 'OFF';
                                feature.set('state', status);
                                feature.set('isOffline', d.offlineValue);
                                feature.set('isError', d.isError);
                            }

                            const featureRTU = this.schema.rtuSymbols.find(x => x.get('vivavisId') === d.vivavisId);
                            if (featureRTU) {
                                featureRTU.set('state',  d.isOn ? 'ON' : 'OFF');
                            }

                            const featureQuality = this.schema.qualitySignals.find(x => x.get('vivavisId') === d.vivavisId);
                            if (featureQuality){
                                featureQuality.set('state', d.isOn ? 'ON' : 'OFF');
                                // console.log('feature Quality', d, featureQuality.get('state'), featureQuality);
                            }

                            if (markerOverlay && d.markers) {
                                markerOverlay.markers = d.markers.filter(x => this.markerFilter.includes(x.content));
                            }

                        }
                    }

                    this.checkHasMarker();
                    this.fuseLayer.changed();
                    this.updateStatusMessage(
                        SchemaStatusMessageId.Switch,
                        $localize`:@@schema.messages.switchStateValue.loaded:Switch states loaded`,
                        SchemaLoadingIndicatorState.Loaded);
                }));
        } else {
            this.updateStatusMessage(
                SchemaStatusMessageId.Switch,
                $localize`:@@schema.messages.switchStateValue.hint:Hint: Switch states not found`,
                SchemaLoadingIndicatorState.Hint);
            return NEVER;
        }
    }

    private getNumericValues() {
        if (this.isOffline) {
            return NEVER;
        } else {

            this.updateStatusMessage(
                SchemaStatusMessageId.Measurement,
                $localize`:@@schema.messages.measurements.loading:Loading measurements`);

            const vivavisIds = [...new Set<string>(this.schema.numerics.map(x => x.getProperties().vivavisId))];

            if (vivavisIds && vivavisIds.length > 0) {
                const loadExternData = Config.frontend.schema.dataMeasurements.find(x => x.picno ===  Number(this._picno));
                return this.schemaService.getMeasurements(vivavisIds, loadExternData)
                    .pipe(catchError(err => {

                        const errorMessage = err?.error?.error || $localize`:@@schema.messages.measurements.error:Error: Measurements could not be loaded`;

                        this.updateStatusMessage(
                            SchemaStatusMessageId.Measurement,
                            errorMessage,
                            SchemaLoadingIndicatorState.ErrorHint);

                        return throwError(() => err);
                    }))
                    .pipe(tap(data => {
                        this.updateStatusMessage(
                            SchemaStatusMessageId.Measurement,
                            $localize`:@@schema.messages.measurements.loaded:Measurements loaded`,
                            SchemaLoadingIndicatorState.Loaded);
                    }));

            } else {
                this.updateStatusMessage(
                    SchemaStatusMessageId.Measurement,
                    $localize`:@@schema.messages.measurements.hint:Hint: Measurements not found`,
                    SchemaLoadingIndicatorState.Hint);
                return NEVER;
            }
        }
    }

    private getLineColors(): Observable<any> {
        this.updateStatusMessage(
            SchemaStatusMessageId.Color,
            $localize`:@@schema.messages.lines.loading:Loading lines`);
        const vivavisIds = [...new Set<string>(this.schema ? this.schema.lines.map(x => x.getProperties().vivavisId) : [])];

        if (vivavisIds && vivavisIds.length > 0) {
            return this.schemaService.getLineColors(vivavisIds)
                .pipe(catchError(err => {

                    const errorMessage = err?.error?.error || $localize`:@@schema.messages.lines.error:Error: Lines could not be loaded`;

                    this.updateStatusMessage(
                        SchemaStatusMessageId.Color,
                        errorMessage,
                        SchemaLoadingIndicatorState.Error);

                    return throwError(() => err);
                }))
                .pipe(tap((data: ApiGridColorValue[]) => {

                    for (const colorValue of data) {

                        const features = this.schema.lines.filter(x => x.get('vivavisId') === colorValue.vivavisId);
                        if (!features) {
                            this.logging.log('Feature not found for', colorValue);
                        }

                        for (const feature of features) {

                            const color1 = colorValue.color1;
                            const color2 = colorValue.color2;

                            if (color1 && color2) {
                                if (color1 === color2) {

                                    const style = new Style({
                                        stroke: new Stroke({
                                            color: color1,
                                            width: 3,
                                            lineDash: null,
                                            lineDashOffset: 0,
                                        }),
                                    });

                                    feature.setStyle(style);

                                } else {

                                    const style1 = new Style({
                                        stroke: new Stroke({
                                            color: color1,
                                            width: 3,
                                            lineDash: [4, 8],
                                            lineDashOffset: 6,
                                        }),
                                    });

                                    const style2 = new Style({
                                        stroke: new Stroke({
                                            color: color2,
                                            width: 3,
                                            lineDash: [4, 8],
                                        }),
                                    });

                                    feature.setStyle([style1, style2]);
                                }
                            }
                        }

                        const markerOverlay = this.markerOverlays.find(x => x.vivavisId === colorValue.vivavisId);
                        if (markerOverlay && colorValue.markers) {
                            markerOverlay.markers = colorValue.markers.filter(x => this.markerFilter.includes(x.content));
                        }
                    }

                    this.checkHasMarker();
                    this.updateStatusMessage(
                        SchemaStatusMessageId.Color,
                        $localize`:@@schema.messages.lines.loaded:Lines loaded`,
                        SchemaLoadingIndicatorState.Loaded);
                }));

        } else {

            this.updateStatusMessage(
                SchemaStatusMessageId.Color,
                $localize`:@@schema.messages.lines.hint:Hint: Lines not found`,
                SchemaLoadingIndicatorState.Hint);

            return NEVER;
        }
    }

}

