export enum ActionHistoryTypes {
    SetMarker = 'setMarker',
    DeleteMarker = 'deleteMarker',
    SwitchOn = 'switchOn',
    SwitchOff = 'switchOff'
}

export interface ActionHistoryItem {
    id: number;
    date: Date;
    type: ActionHistoryTypes;
    title?: string;
    subType?: any;
    targetVivavisId?: string;
    result?: any;
    notNormal?: boolean;
    location: {
        lat: number | any;
        lon: number | any;
        zoom: number | any;
        schema?: number;
    };
}
