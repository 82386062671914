import { FrontendConfig } from './../interfaces/frontend.config';
import Config from 'src/app/config/Config';
import { Injectable } from '@angular/core';
import { WMTSCapabilities } from 'ol/format';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ConsoleLoggingService } from  './console-logging.service';

@Injectable({
    providedIn: 'root'
})
export class TileRepositoryService {
    // private url = 'https://onmaps.de/phpWMS4?key=03b8e59b4f2d50cbf9d40964271bdae3&SERVICE=WMTS';
    public repositories$: Observable<any>;
    // private url = Config.frontend.onmaps.url;
    private repository = new Map<string, any>();
    private repoSubject = new Subject<void>();

    constructor(private httpClient: HttpClient, private logging: ConsoleLoggingService) {
        this.repositories$ = this.repoSubject.asObservable();
    }

    public addRepository(name: string, repo: any) {
        const parser = new WMTSCapabilities();
        const capabilities = parser.read(repo);
        this.repository.set(name, capabilities);
    }

    public hasRepository(name: string): boolean {
        return this.repository.has(name);
    }

    public getRepository(name: string): any {
        return this.repository.get(name) || null;
    }

    public getRepositoryForUrl(url: string) {
        if (this.hasRepository(url)) {
            this.repoSubject.next(this.repository.get(url));
        } else {
            this.resolveForUrl(url).then(res => this.repoSubject.next(this.repository.get(url)));
        }
        return this.repositories$;
    }

    private resolveForUrl(url: string) {
        return new Promise((resolve, reject) => {
            this.httpClient.get(url, { responseType: 'text' })
                .subscribe({
                    next: xml => {
                        this.addRepository(url, xml);
                        resolve(true);
                    },
                    error: err => {
                        console.error('Resolver ERROR, cant retrieve', url);
                        this.logging.log('Error: ', err);
                        this.addRepository(url, null);
                        resolve(false);
                    }
                });
        });
    }

}
