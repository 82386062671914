import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';


export enum DownloadAreaConfigState {
    Show = 'SHOW',
    Download = 'DOWNLOAD'
}

export interface DownloadAreaConfig {
    x: number;
    y: number;
    side: number;
    state: DownloadAreaConfigState;
}

@Component({
    selector: 'app-download-area',
    templateUrl: './download-area.component.html',
    styleUrls: ['./download-area.component.scss'],
})
export class DownloadAreaComponent implements OnInit, OnDestroy {
    @Input() coordinates: { x: number; y: number };

    @Output()
    public downloadArea = new EventEmitter<DownloadAreaConfig>();

    @Output()
    public cancelDownloadArea = new EventEmitter<DownloadAreaConfig>();

    public selectedArea = 3500;
    public calcArea = this.selectedArea*this.selectedArea;
    public unit = 'm';


    constructor() {}

    ngOnInit() {
        this.selectArea({value: this.selectedArea});
    }

    ngOnDestroy(): void {
    }

    selectArea(event) {
        const side = event.value;
        const a = side * side;

        if (a >= 1000000) {
            this.calcArea = a / 1000000;
            this.unit = 'km';
        } else {
            this.calcArea = a;
            this.unit = 'm';
        }

        const areaSettings: DownloadAreaConfig = {
            x: this.coordinates.x,
            y: this.coordinates.y,
            side: event.value,
            state: DownloadAreaConfigState.Show,
        };

        this.downloadArea.emit(areaSettings);
    }

    cancel() {
        this.cancelDownloadArea.emit();
    }

    download() {
        const areaSettings: DownloadAreaConfig = {
            x: this.coordinates.x,
            y: this.coordinates.y,
            side: this.selectedArea,
            state: DownloadAreaConfigState.Download,
        };

        this.downloadArea.emit(areaSettings);
    }
}
