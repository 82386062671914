import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER } from 'rxjs';
import { switchMap, mergeMap, take } from 'rxjs/operators';

import Config from '../../config/Config';
import { ApiAuthMethodType } from '../interfaces/api-login-result';
import { OAuthAuth } from '../services/authentication/oauth.auth';
import { StatefulAuthenticationService } from './../services/authentication/stateful-authentication.service';
import { I18nService } from './../services/i18n.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private statefulAuth: StatefulAuthenticationService,
        private i18n: I18nService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.statefulAuth.sessionId()
            .pipe(
                take(1),
                mergeMap(sessionid => {

                    const apiEndpoints = Object.values(Config.Api);

                    const isApi = !!apiEndpoints.find(x => req.url.includes(x));
                    const isActive = !!Config.activeApi.find(x => req.url.includes(x));

                    if (isApi) {
                        if (isActive) {
                            const headers = {
                                'Accept-Language': this.i18n.currentLocaleId,
                                'Content-Type': 'application/json',
                                sessionid
                            };

                            const isOAuth = this.statefulAuth.auth.authMethod === ApiAuthMethodType.OAuth;
                            if (isOAuth) {
                                // eslint-disable-next-line @typescript-eslint/dot-notation
                                headers['authorization'] = (this.statefulAuth.auth as OAuthAuth).getAuthorizationHeader();
                            }

                            req = req.clone({ setHeaders: headers });
                        }

                    }

                    return !isApi || (isApi && isActive) ? next.handle(req) : NEVER;
                    // return next.handle(req);
                })
            );
    }

}
