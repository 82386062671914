import {Inject, Injectable, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import Config, { AppConfigInterface } from 'src/app/config/Config';


interface CallStackEntrie {
    message: string;
    options: {[key: string]: any};
}

@Injectable({
    providedIn: 'root',
})
export class SnackbarMessagesService{

    private showLogs: boolean;
    @ViewChild('templateRef') templateRef?: TemplateRef<any>;

    private cssClasses: {[key: string]: string[] } = {
        success: ['snackbar', 'snackbar-successMessages'],
        error: ['snackbar', 'snackbar-errorMessages']
    };
    private options = {
        panelClass: [''],
        duration: 0
    };
    private stack: CallStackEntrie[] = [];
    private isOpen = false;
    constructor(
        private _snackBar: MatSnackBar,
    ) {
    }


    displaySuccessDebug(message: string, duration?: number){
        if(Config.frontend.debug){
            if(Config.frontend.debug.popup){
                this.stack.push({
                    message,
                    options:  this.getOptions('success', duration)
                });
                if(!this.isOpen){
                    this.callStack();
                }
            }
        }
    }

    displayErrorDebug(message: string, duration?: number){
        if(Config.frontend.debug){
            if(Config.frontend.debug.popup){
                this.stack.push({
                    message,
                    options:  this.getOptions('error', duration)
                });
                if(!this.isOpen){
                    this.callStack();
                }
            }
        }
    }

    private callStack(){

        if(this.stack.length > 0){
            this.isOpen = true;
            const opts = this.stack[0];
            const ref = this._snackBar.open(opts.message, 'X', opts.options);
            ref.afterDismissed().subscribe(() => {
                this.stack.shift();
                this.isOpen = false;
                this.callStack();
            });
        }

    }


    private getOptions(status: string, duration?: number) {

        this.options.panelClass = this.cssClasses[status];
        this.options.duration = (duration) ? duration*1000 : undefined;

        return this.options;
    }




}
