<div id="checkoperation-dialog">
    <div>
        <div>
            <h1>
                <span class="state {{currentState}}">{{currentState | translateStateValue}}</span>
                <mat-icon style="transform: scale(2);">arrow_right_alt</mat-icon>
                <span class="state {{newState}}">{{newState | translateStateValue}}</span>
            </h1>
        </div>
        <div class="release-toggles">
            <div>
                <mat-slide-toggle id="unlock-toggle" *ngIf="needsUnlock" (change)="checkExecution()" [(ngModel)]="isUnlocked" i18n="@@schema.unlock">Unlock</mat-slide-toggle>
            </div>
            <div>
                <mat-slide-toggle id="discharge-toggle" *ngIf="needsDischarge" (change)="checkExecution()"  [(ngModel)]="isDischarged" i18n="@@schema.discharge">Discharge</mat-slide-toggle>
            </div>
        </div>
        <div class="result-grid-table">
            <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{data: topoActions}"></ng-container>
            <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{data: topoHints}"></ng-container>
            <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{data: scadaActions}"></ng-container>
            <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{data: scadaHints}"></ng-container>
        </div>

    </div>
    <div class="buttons">
        <button id="cancel-operation-button" mat-raised-button color="accent" [mat-dialog-close]="false" (click)="cancel()" i18n="@@cancel">Cancel</button>
        <button id="execute-operation-button" mat-raised-button color="primary" (click)="execute()" [disabled]="!allowedToExecute" i18n="@@schema.execute">Execute</button>
    </div>
</div>

<ng-template #actions let-data="data">
    <div class="result-grid-table-row" *ngFor="let item of data">
        <div class="result-grid-table-column {{item.severity}}">{{item.severity}}</div>
        <div class="result-grid-table-column {{item.severity}}">{{item.label}}</div>
        <div class="result-grid-table-column {{item.severity}}">{{item.description}}</div>
    </div>
</ng-template>
