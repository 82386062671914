import { LoadComponentDirective } from './../../../custom/loadComponent.directive';
import { ApiIdent } from './../../../../shared/interfaces/api-ident';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { CustomConfigService } from 'src/app/maps/custom/custom.config.service';
import { DynamicComponent } from 'src/app/maps/custom/dynamic-component';
import Config, { AppConfigInterface } from 'src/app/config/Config';

@Component({
    selector: 'app-ident-details',
    templateUrl: './ident-details.component.html',
    styleUrls: ['./ident-details.component.scss']
})
export class IdentDetailsComponent implements OnInit {

    private _identDataItem: ApiIdent;

    @Output() markerDeleteEvent: EventEmitter<string> = new EventEmitter<any>();

    get identDataItem(): ApiIdent {
        return this._identDataItem;
    }
    @Input() set identDataItem(identDataItem: ApiIdent){
        this.filterEmptyValue(identDataItem.data);
        this.convertMeterPoint(identDataItem.data);
        this.addValueTypes(identDataItem.data);
        this._identDataItem = JSON.parse(JSON.stringify(identDataItem));
        if(identDataItem.markers){
            this._identDataItem.markers = identDataItem.markers.filter((marker) => marker.markerType !== 'NotNormal');
        }
    };

    @Input() measurements;

    @ViewChild(LoadComponentDirective, { static: true }) loadComponent!: LoadComponentDirective;

    constructor(private customConfigService: CustomConfigService) { }

    ngOnInit() {
        this.setup();
    }

    private setup() {
        const viewContainerRef = this.loadComponent.viewContainerRef;
        viewContainerRef.clear();


        const detailsComponent = this.customConfigService.identDetailsComponent(this.identDataItem);
        const componentRef = viewContainerRef.createComponent<DynamicComponent<ApiIdent>>(detailsComponent.component);
        componentRef.instance.data = this.identDataItem;
    }

    private filterEmptyValue(data: any){
        const deleteIds = Object.keys(data).filter( (_key) => data[_key] === '' );
        deleteIds.forEach( (_deleteId) => {
            delete data[_deleteId];
        });
    }

    private convertMeterPoint(data: any){
        if(data.Messlokation){
            data.Messlokation = data.Messlokation.split(',')
                .map((m) => m.replace(' ', ''));
        }
    }

    private addValueTypes(data: any){
        Object.keys(data).map((d: any) => {
            if(Config.frontend.ident.valueTypes[d]){
                data[d] =`${data[d]} ${Config.frontend.ident.valueTypes[d]}`;
            }
        });
    }

    deleteMarker(marker){
         this.markerDeleteEvent.emit(marker);
    }

    protected readonly Array = Array;
}
