<button mat-mini-fab color="white" [matMenuTriggerFor]="mapsMenu">
    <mat-icon>map</mat-icon>
</button>

<mat-menu #mapsMenu="matMenu" [hasBackdrop]="true" [overlapTrigger]="false">
    <ng-container *ngFor="let bgLayer of bgLayers">
        <div style="display: flex; align-items: center;">

            <button mat-menu-item

                [disabled]="bgLayer.state === BasicLayerState.Loading"
                [ngClass]="{'menu-item-selected': bgLayer.selected, 'bglayer-loading': bgLayer.state === BasicLayerState.Loading}"
                (click)="selectBgLayer(bgLayer)"> {{ bgLayer.apiLayer.name }} </button>

                <ng-container [ngSwitch]="bgLayer.state">
                    <mat-icon *ngSwitchCase="BasicLayerState.Error" color="warn">warning</mat-icon>
                    <mat-progress-spinner *ngSwitchCase="BasicLayerState.Loading"  mode="indeterminate" color="primary" diameter="15"></mat-progress-spinner>
                </ng-container>
        </div>
    </ng-container>
</mat-menu>
