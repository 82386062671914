import { I18nService } from './../../../shared/services/i18n.service';
import {
    Component,
    OnInit,
    Output,
    Input,
    ElementRef,
    ViewChildren,
    EventEmitter,
} from '@angular/core';
import { ApiIdent } from 'src/app/shared/interfaces/api-ident';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-ident-popup',
    templateUrl: './ident-popup.component.html',
    styleUrls: ['./ident-popup.component.scss'],
})
export class IdentPopupComponent implements OnInit {

    @ViewChildren('panel', { read: ElementRef }) panels;

    @Input() identData;

    @Input() isMultiple;

    @Input() measurements;

    @Output() processMarkerDeleteEvent = new EventEmitter<any>();


    langId = 'de';

    public isMobile = environment.mobile;

    constructor(private i18n: I18nService) {
        this.langId = this.i18n.currentLocaleId;
    }

    ngOnInit() { }

    public expandedElement(i: number) {
        if (this.panels) {
            this.panels
                .toArray()
            [i].nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    public processEventDeleteMarker(marker){
        this.processMarkerDeleteEvent.emit(marker);
    }
}


