import { ApiGeneralInterface } from './../interfaces/api-general';
import { Observable } from 'rxjs';
import { ActionQueueElementState, ApiActionQueueCount, ApiActionQueueElement, ApiActionQueueStatus } from './../interfaces/api-actionqueue';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Config from 'src/app/config/Config';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ApiActionQueueService {
    constructor(private httpClient: HttpClient) {}

    public getElements(): Observable<ApiActionQueueElement[]> {
        const url = `${environment.apiRoot}${Config.Api.actionQueueElements}`;
        return this.httpClient.get<ApiGeneralInterface<ApiActionQueueElement[]>>(url).pipe(map(x => {
            const data = x.response.map(y => {

                if (y.enqueued) {
                    y.enqueued = new Date(y.enqueued);
                }

                if (y.dequeued) {
                    y.dequeued = new Date(y.dequeued);
                    y.state = ActionQueueElementState.Synced;
                } else {
                    y.state = ActionQueueElementState.Pending;
                }

                if (y.isError) {
                    y.state = ActionQueueElementState.Error;
                }
                return y;
            });

            return data;
        }));
    }

    public executeElements() {
        const url = `${environment.apiRoot}${Config.Api.actionQueueElements}`;
        return this.httpClient.post(url, null);
    }

    public deleteElements(ids: string[]) {
        const url = `${environment.apiRoot}${Config.Api.actionQueueElements}`;
        const httParams = new HttpParams().set('ids', ids.join(','));
        return this.httpClient.delete(url, {params: httParams});
    }

    public run() {
        const url = `${environment.apiRoot}${Config.Api.actionQueueRun}`;
        return this.httpClient.delete(url);
    }

    public stop() {
        const url = `${environment.apiRoot}${Config.Api.actionQueueStop}`;
        return this.httpClient.post(url, {});
    }

    public status(): Observable<ApiActionQueueStatus> {
        const url = `${environment.apiRoot}${Config.Api.actionQueueStatus}`;
        return this.httpClient.get<ApiGeneralInterface<ApiActionQueueStatus>>(url).pipe(map(x => x.response));
    }

    public count() {
        const url = `${environment.apiRoot}${Config.Api.actionQueueCount}`;
        return this.httpClient.get<ApiGeneralInterface<ApiActionQueueCount>>(url).pipe(map(x => x.response));
    }
}
