import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisplayNoConnectionService {

    displayConnectionStatus$ = new Subject();

    constructor() {

    }
}
