import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ApiActionQueueAction,
    ApiActionQueueElement
} from 'src/app/shared/interfaces/api-actionqueue';
import { I18nService } from './../../../shared/services/i18n.service';



@Component({
    selector: 'app-action-queue-element',
    templateUrl: './action-queue-element.component.html',
    styleUrls: ['./action-queue-element.component.scss'],
})
export class ActionQueueElementComponent implements OnInit {
    public actions = ApiActionQueueAction;
    public isStateChange = false;
    public markerAction = false;
    public langId = 'de';
    public oldState;
    public newState;
    public showError = false;
    private _element: ApiActionQueueElement;

    @Output() actionUnlocked = new EventEmitter();

    @Input() currentUnlockingElement: ApiActionQueueElement;

    @Input()
    public set element(v: ApiActionQueueElement) {
        if (v) {
            this._element = v;

            switch (this._element.action) {
                case ApiActionQueueAction.ExecuteOperation:
                case ApiActionQueueAction.CheckOperation:
                    this.isStateChange = true;
                    this.markerAction = false;
                    break;
                case ApiActionQueueAction.SetMarker:
                case ApiActionQueueAction.DeleteMarker:
                    this.isStateChange = false;
                    this.markerAction = true;
                    break;
            }

            if (this.isStateChange) {
                if (this._element.subaction === 'OFF') {
                    this.oldState = 'ON';
                    this.newState = 'OFF';
                } else {
                    this.oldState = 'OFF';
                    this.newState = 'ON';
                }
            }

            if (this.markerAction) {

            }
        }
    }

    public get element() {
        return this._element;
    }

    constructor(private i18n: I18nService) {
        this.langId = i18n.currentLocaleId;
    }

    ngOnInit() {}

}
