import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {ObisConfig, ObisResponse, ObiValueSelect, ObisResponseValues} from './Models/Obis';
import {SchemaScrollPositions} from './schema-scroll.directive';
import {SchemaScrollService} from './schema-scroll.service';
import {unwrapConstructorDependencies} from '@angular/compiler-cli/src/ngtsc/annotations/src/util';

@Component({
  selector: 'app-schema-root',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.scss'],
  styles: [],
    providers:[SchemaScrollService]
})
export class SchemaComponent implements OnInit, AfterViewInit  {

  private _measurements: ObisResponse[] = [];

  private _emptyConfig: ObisConfig = {
    meta: [],
    data: []
  };

  private _configuration: ObisConfig | undefined;

  @Input() set configuration(config: ObisConfig | undefined){
      this._configuration = (config) ? config : this._emptyConfig;
  }

  get configuration(): ObisConfig{
    return this._configuration || this._emptyConfig;
  }

  get metaHeight(){
    if(this.schemaRoot){
      return this.schemaRoot.nativeElement.clientHeight;
    }
    return 0;
  }
  @Input() set measurements(_measurements: ObisResponse[]) {
    this._measurements = _measurements;
  }
  get measurements(): ObisResponse[] {
    return this._measurements;
  }

  @Output() userTriggerValueSelected: EventEmitter<ObiValueSelect> = new EventEmitter<ObiValueSelect>();

  @ViewChild('schemaRoot') schemaRoot!: ElementRef;
  @ViewChild('metaWrapTop') metaWrapTop!: ElementRef;

  constructor(
    private changeDetector: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  updateSelectedValues(valueState: ObiValueSelect){
    this.userTriggerValueSelected.emit(valueState);
  }

  getMetaFontSize(childIndex: number): number {
    const _diff = 20;
    return 100 - (childIndex * _diff);
  }

  getClientHeight(): number{
    let elem;
    let elemHeight = 0;
    if(this.schemaRoot && this.metaWrapTop){
        elem = this.schemaRoot.nativeElement.parentNode.parentNode.parentNode;
        elemHeight = elem.clientHeight - this.metaWrapTop.nativeElement.clientHeight;
    }
    return elemHeight;
  }

    getMeasurementbyObiId(configIds: string[], dataIds: ObisResponseValues[]): any {
        return configIds.some(
            (configId: string) => dataIds.some(
                (dataId: ObisResponseValues) => configId === dataId.element ));

    }
    readonly SchemaScrollPositions = SchemaScrollPositions;
}
