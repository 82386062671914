import { Injectable } from '@angular/core';
import {ObisApiService} from './obis-api.service';
import {Observable, Observer} from 'rxjs';
import {ObisResponse} from '../../../shared/_ObisSchema/Models/Obis';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObisHelpersService {

  constructor(
    private obisApiService: ObisApiService
  ) { }


    getMeasuringData(measuringSpotId: string[], url: string): Observable<ObisResponse[]  | Observable<never>> {
      return this.obisApiService.loadMeasuringData(measuringSpotId, url);
    }
}
