import { Component, Input, OnInit } from '@angular/core';
import { transform } from 'ol/proj';
import { MapPopupConfig } from 'src/app/maps/map-popup/map-popup.component';
import { PopupState } from 'src/app/maps/map-popup/popupstate.enum';
import { MapService } from 'src/app/maps/services/map.service';
import { ApiIdent } from 'src/app/shared/interfaces/api-ident';
import { ProjectionsService } from 'src/app/shared/services/projections.service';
import { environment } from 'src/environments/environment';
import { DynamicComponent } from '../../dynamic-component';


@Component({
    selector: 'app-schema-ident-details',
    templateUrl: './schema-ident-details.component.html',
    styleUrls: ['./schema-ident-details.component.scss']
})
export class SchemaIdentDetailsComponent implements OnInit, DynamicComponent<ApiIdent> {

    @Input() data: ApiIdent;

    config: MapPopupConfig;

    isMobile = environment.mobile;
    // isMobile = true;

    constructor(private mapService: MapService, private projectionsService: ProjectionsService) { }

    ngOnInit() {
    }

    public openSchema() {
        this.mapService.openBottomDrawer(this.data.meta.SchemabildNummer, this.data.data.Typ);
    }

    public openObisSchema(){
        if(this.data.data.Messlokation instanceof Array){
            this.mapService.openBottomObisDrawer(this.data.data.Messlokation, this.data.data.Name);
        }else{
            this.mapService.openBottomObisDrawer([this.data.data.Messlokation], this.data.data.Name);
        }
    }

    public openDownloadArea() {
        const x = this.data.coordinates.x;
        const y = this.data.coordinates.y;
        // FIXME
        // TODO: Projection Bug: https://vivavis-jira.vivavis.int/browse/GEOVIS-638
        const transformed = transform([x, y], this.projectionsService.epsg25832Projection, this.projectionsService.epsg3857Projection);
        this.config = {
            popupState: PopupState.DownloadArea,
            coordinates: {
                x: transformed[0],
                y: transformed[1],
            }
        };
        this.mapService.showDownloadAreaPopup(this.config);
    }

}
