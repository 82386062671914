<div class="side-wrapper">
        <div class="content-wrapper" *ngIf="open" [@slideInOut]>
            <ng-container *ngTemplateOutlet="menuButtons"></ng-container>
        </div>
    <div class="button-action" [@moveButton]="open ? 'open' : 'closed'" >
        <button id="menu-inout" mat-mini-fab color="white" (click)="open = !open">
            <mat-icon>compare_arrows</mat-icon>
        </button>
    </div>
</div>

<ng-template #menuButtons><ng-content></ng-content></ng-template>
