import {StatefulAuthenticationService} from './authentication/stateful-authentication.service';
import {take, tap} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import {OSM, XYZ} from 'ol/source';
import TileSource from 'ol/source/Tile';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS';
import {mergeMap, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {ApiLayersService} from '../api-services/api-layers.service';
import {ApiLayer, ApiMapType} from '../interfaces/api-layer';
import {TileRepositoryService} from './tile-repository.service';
import {ConsoleLoggingService} from './console-logging.service';
import MapboxVectorLayer from 'ol/layer/MapboxVector';
import Config from '../../config/Config';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTile from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';

export enum BasicLayerState {
    Loading = 'loading',
    Loaded = 'loaded',
    Error = 'error'
}

export interface AppMapLayer {
    state: BasicLayerState;
    layer: TileLayer<TileSource> | VectorTileLayer;
    apiLayer: ApiLayer;
    selected?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LayersService implements OnDestroy {

    private layersSubject = new ReplaySubject<Layer<any, any>[]>(1);
    public layers$: Observable<Layer<any, any>[]> = this.layersSubject.asObservable();

    private bgLayerSub = new Subject<AppMapLayer[]>();
    public bgMapLayers$ = this.bgLayerSub.asObservable();

    private appBgLayers: AppMapLayer[] = [];

    private layers = [];

    private lastTileTimestamp = new Date().getTime();

    private sessionId;

    private subscriptions = new Subscription();

    constructor(
        private apiLayers: ApiLayersService,
        private tileRepositoryService: TileRepositoryService,
        private stateAuth: StatefulAuthenticationService,
        private logging: ConsoleLoggingService
    ) { }

    ngOnDestroy() {
        this.logging.logDebug('Layers Service Destroy');
        this.subscriptions.unsubscribe();
    }

    public resetTimestamp() {
        this.lastTileTimestamp = new Date().getTime();
    }

    public loadLayers() {
        return this.stateAuth.sessionId()
            .pipe(take(1),
                mergeMap(sessionId => this.apiLayers
                    .getLayers()
                    .pipe(
                        tap(apiLayers => {
                            apiLayers = apiLayers.map((x: ApiLayer) => {
                                x.mapType =(Config.frontend.layerTypesCorrection[x.name] !== undefined) ?
                                    Config.frontend.layerTypesCorrection[x.name] : x.mapType;
                                return x;
                            });
                            this.sessionId = sessionId;
                            this.processApiLayers(apiLayers);
                        })
                    )
                )
            );
    }

    private processApiLayers(apiLayers: ApiLayer[]) {

        if (apiLayers && apiLayers.length > 0) {
            apiLayers = apiLayers.sort((a, b) => a.zIndex - b.zIndex);

            for (const apiLayer of apiLayers) {
                switch (apiLayer.mapType) {
                    case ApiMapType.OSM:
                    case ApiMapType.VectorOSM:
                    case ApiMapType.WTMS:
                    case ApiMapType.Offline:
                        this.getBackgroundLayers(apiLayer);
                        break;
                    case ApiMapType.VectorXYZ:
                        const src = this.getLayer(apiLayer);
                        this.layers.push(src);
                        break;
                }
            }

            this.bgLayerSub.next(this.appBgLayers);
            this.layersSubject.next(this.layers);
        }
    }

    public tileUrl(tileCoord, p1, p2, apiLayer: ApiLayer, callSrc) {
        const x = tileCoord[1] + '';
        const y = tileCoord[2] + '';
        const z = tileCoord[0];
        let url = apiLayer.url;
        url = url.replace('{x}', x).replace('{y}', y).replace('{z}', z + '');
        url = url.replace('{sessionId}', this.sessionId);
        // url = url + '&t=' + this.lastTileTimestamp;
        url = url + `&t=" + ${(new Date()).getTime()})`;
        return url;
    }

    getLayer(apiLayer: ApiLayer): Layer<any, any> {
        const vl = new TileLayer({ className: this.getClassName(apiLayer) });

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (apiLayer.zIndex !== null) ? vl.setZIndex(apiLayer.zIndex) : {};
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (apiLayer.minZoom !== null) ? vl.setMinZoom(apiLayer.minZoom) : {};
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (apiLayer.maxZoom !== null) ? vl.setMaxZoom(apiLayer.maxZoom) : {};

        const source = new XYZ({
            cacheSize: 2048,
            // interpolate: true,
            tileUrlFunction: (tileCoord, p1, p2) => this.tileUrl(tileCoord, p1, p2, apiLayer, 'mainMap')
        });

        vl.setSource(source);

        vl.set('apiLayer', apiLayer);
        vl.set('name', apiLayer.name);
        vl.set('layerId', apiLayer.id);
        vl.set('isBackground', false);
        vl.set('overviewmap', true);
        vl.set('isVisible', true);
        vl.set('isBuffer', false);
        return vl;
    }

    private getClassName(apiLayer: ApiLayer) {
        return 'ol-layer api-layer layer-' + apiLayer.id; //.replace('{', '').replace('}', '');
    }

    private updateAppMapLayer(apiLayer: ApiLayer, state: BasicLayerState, layerSource: TileSource = null, error: any = null) {
        const appMapLayer = this.appBgLayers.find(x => x.apiLayer.id === apiLayer.id);

        if (appMapLayer && appMapLayer.layer instanceof TileLayer) {
            appMapLayer.state = state;

            if (state === BasicLayerState.Loaded) {
                appMapLayer.layer.setSource(layerSource);
            } else if (state === BasicLayerState.Error) {
                appMapLayer.layer.set('hasError', true);
            }
        }
    }

    private createTileLayer(apiLayer: ApiLayer, state: BasicLayerState, layerSource: TileSource = null) {
        const layerName = apiLayer.name;
        const layerClassName = apiLayer.name;
        const tileLayer = new TileLayer({ className: layerClassName });

        tileLayer.set('apiLayer', apiLayer);
        tileLayer.set('name', layerName);
        tileLayer.set('selected', false);
        tileLayer.set('layerId', apiLayer.id);
        tileLayer.set('isBackground', true);
        tileLayer.set('hasError', false);

        if (layerSource) {
            tileLayer.setSource(layerSource);
            tileLayer.set('hasError', layerSource.get('hasError'));
        }
        const appMapLayer: AppMapLayer = {
            state,
            layer: tileLayer,
            apiLayer,
            selected: false
        };

        this.appBgLayers.push(appMapLayer);
    }

    private createVectorTileLayer(apiLayer: ApiLayer, state: BasicLayerState, layerSource: VectorTile = null) {
        const layerName = apiLayer.name;
        const layerClassName = apiLayer.name;
        const tileLayer = new VectorTileLayer({ className: layerClassName });

        tileLayer.set('apiLayer', apiLayer);
        tileLayer.set('name', layerName);
        tileLayer.set('selected', false);
        tileLayer.set('layerId', apiLayer.id);
        tileLayer.set('isBackground', true);
        tileLayer.set('hasError', false);

        if (layerSource) {
            tileLayer.setSource(layerSource);
            tileLayer.set('hasError', layerSource.get('hasError'));
        }
        const appMapLayer: AppMapLayer = {
            state,
            layer: tileLayer,
            apiLayer,
            selected: false
        };

        this.appBgLayers.push(appMapLayer);


    }


    private getBackgroundLayers(apiLayer: ApiLayer) {

        if (apiLayer.mapType === ApiMapType.WTMS) {

            this.loadWMTS(apiLayer);
            this.createTileLayer(apiLayer, BasicLayerState.Loading);

        } else if (apiLayer.mapType === ApiMapType.Offline) {

            let options = {};

            if (apiLayer.url) {
                options = { url: apiLayer.url, crossOrigin: null };
            }

            const layerSource = new XYZ({ ...options });
            layerSource.set('hasError', false);
            this.createTileLayer(apiLayer, BasicLayerState.Loaded, layerSource);

        } else if (apiLayer.mapType === ApiMapType.OSM) {

            let options = {};

            if (apiLayer.url) {
                options = { url: apiLayer.url, crossOrigin: null };
            }

            const layerSource = new OSM({ ...options });
            layerSource.set('hasError', false);
            this.createTileLayer(apiLayer, BasicLayerState.Loaded, layerSource);

        } else if( apiLayer.mapType === ApiMapType.VectorOSM) {

            const layerSource = new VectorTile({
                format: new MVT(),
                url: apiLayer.url,
            });

            this.createVectorTileLayer(apiLayer, BasicLayerState.Loaded, layerSource);
        }

    }

    private async loadWMTS(apiLayer: ApiLayer): Promise<TileSource> {
        return new Promise((res, rej) => {
            const lurl = apiLayer.url; //.replace('.de','.asd');
            const sub = this.tileRepositoryService
                .getRepositoryForUrl(lurl)
                // .pipe(debounceTime(20000))
                .subscribe((repo) => {
                    let error = false;
                    let tileSource: TileSource;
                    const layer = apiLayer.shaping;
                    const matrixSet = 'GoogleMapsCompatible';
                    let exception;
                    try {
                        if (repo) {
                            const sourceOptions = optionsFromCapabilities(
                                repo,
                                {
                                    layer,
                                    matrixSet,
                                    crossOrigin: null
                                }
                            );
                            tileSource = new WMTS(sourceOptions);
                            tileSource.set('hasError', false);
                            // this.createBackgroundLayer(apiLayer, tileSource);
                            this.updateAppMapLayer(apiLayer, BasicLayerState.Loaded, tileSource);
                        } else {
                            error = true;
                        }
                    } catch (e) {
                        exception = e;
                        error = true;
                    }

                    if (error) {
                        this.logging.log('Error WMTS Repo Setup');
                        this.logging.log('--layer:', layer);
                        this.logging.log('--matrixSet:', matrixSet);
                        this.logging.log(exception);
                        tileSource = new XYZ({ url: lurl, crossOrigin: null });
                        tileSource.set('hasError', true);
                        this.updateAppMapLayer(apiLayer, BasicLayerState.Error, tileSource, error);
                        // this.updateAppMapLayer(apiLayer, BasicLayerState.Loaded, tileSource);
                    }
                    res(tileSource);
                });
            this.subscriptions.add(sub);
        });
    }

}
