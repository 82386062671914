import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import Config from '../../config/Config';
import { ApiCheckOperation } from '../interfaces/api-checkopertaion';
import { ApiGeneralInterface } from './../interfaces/api-general';
import { ApiOutcome } from './../interfaces/api-outcome';
import { ApiStateValueStatus } from './../interfaces/api-statevalue';

@Injectable({
    providedIn: 'root',
})
export class ApiOperationsService {
    constructor(
        private httpClient: HttpClient
    ) { }

    public executeOperation(vivavisId: string, state: ApiStateValueStatus, scadaLocks: any[], topoLocks: any[]) {

        const url = `${environment.apiRoot}${Config.Api.executeOperation}`;


        let httpParams = new HttpParams();
        httpParams = httpParams.set('vivavisId', vivavisId);
        httpParams = httpParams.set('scadaLocks', scadaLocks.join(','));
        httpParams = httpParams.set('topoLocks', topoLocks.join(','));
        httpParams = httpParams.set('value', state);

        return this.httpClient.get<ApiGeneralInterface<ApiOutcome>>(url, {
            params: httpParams
        }).pipe(
            map(data => data.response)
        );
    }

    public checkOperation(vivavisId: string, state: ApiStateValueStatus) {

        const url = `${environment.apiRoot}${Config.Api.checkOperation}`;

        let httpParams = new HttpParams();
        httpParams = httpParams.set('vivavisId', vivavisId);
        httpParams = httpParams.set('value', state);


        return this.httpClient.get<ApiGeneralInterface<ApiCheckOperation>>(url, { params: httpParams })
            .pipe(
                map(data => {
                    if (data.response) {
                        const res = data.response;
                        (res as any).vivavisId = vivavisId;
                    } else {
                        // eslint-disable-next-line @typescript-eslint/dot-notation
                        data['vivavisId'] = vivavisId;
                    }
                    return data;
                })
                // map(x => x.response)
            );
    }
}
