import { NgModule } from '@angular/core';
import { SchemaComponent } from './schema.component';
import { SchemaScrollDirective } from './schema-scroll.directive';
import { CommonModule } from '@angular/common';
import { ObisModule } from './Views/obis.module';



@NgModule({
  declarations: [
      SchemaComponent,
      SchemaScrollDirective
  ],
  imports: [
    CommonModule,
    ObisModule
  ],
  exports: [
    SchemaComponent,
  ],
})
export class SchemaModule { }
