import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StatefulAuthenticationService } from '../services/authentication/stateful-authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    private returnUrlStorageKey = 'returnUrl';
    private returnUrlQuerytorageKey = 'returnUrlQuery';
    private storage = localStorage;

    constructor(private router: Router,
        private statefulAuth: StatefulAuthenticationService) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


        return this.statefulAuth.isLoggedIn().pipe(tap(loggedIn => {
            if (!loggedIn) {
                const openWithPosition = route.queryParams.lat && route.queryParams.lon && route.queryParams.zoom;
                if (localStorage.getItem(this.returnUrlQuerytorageKey) === 'undefined'
                    || localStorage.getItem(this.returnUrlQuerytorageKey) === null
                    || openWithPosition) {
                    this.storeDeepLink();
                }
                this.router.navigate(['/login']);
            } else {
                this.redirectToDeepLink();
            }
        }));
    }

    private storeDeepLink() {
        const fragments = window.location.href.split('?');
        const returnUrl = {
            url: '/',
            query: fragments[1]
        };

        this.storage.setItem(this.returnUrlStorageKey, returnUrl.url);
        this.storage.setItem(this.returnUrlQuerytorageKey, returnUrl.query);
    }

    private redirectToDeepLink() {
        const queryParams = {};
        const returnUrl = this.storage.getItem(this.returnUrlStorageKey);
        const returnUrlQuery= this.storage.getItem(this.returnUrlQuerytorageKey);

        if (returnUrlQuery) {
            returnUrlQuery.split('&').map((param) => {
                const chunks = param.split('=');
                queryParams[chunks[0]] = chunks[1];
            });
        }

        if (returnUrl) {
            this.router
                .navigate([returnUrl], { queryParams })
                .then(() => {
                    this.clearReturnUrl();
                });
        }
    }

    private clearReturnUrl() {
        this.storage.removeItem(this.returnUrlStorageKey);
        this.storage.removeItem(this.returnUrlQuerytorageKey);
    }

}
