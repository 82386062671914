import { LayerFeatureType } from '../enums/layer-feature-type.enum';

export enum ApiMapType {
    Offline = 1,
    WTMS = 2,
    VectorXYZ = 3,
    OSM = 4,
    VectorOSM = 5
}
export interface ApiLayer {
    id: string;
    name: string;
    zIndex: number;
    mapType: ApiMapType;
    url?: string;
    shaping: string;
    foildId: string;
    wsId: string;
    maxZoom: number;
    minZoom: number;
}

export interface ApiMapInfo {
    MapFileName: string;
    MapFileSize: number;
    MapId: string;
    MapLastModified: string;
    MapName: string;
    MapPreviewImage: string;
    MapType: ApiMapType;
    MapUrl: string;
    Worksessions: string[];
    shaping: string;
    url: string;
}


