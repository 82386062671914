import {PostLoadingCell} from './_PostLoadingCell';

export class PostLoadingStyler{

    private _field: PostLoadingCell[]= [];
    private readonly _rowsRaw: number[] = [];
    private readonly _colsRaw: number[] = [];
    constructor(features: PostLoadingCell[]) {

        this._field = features;
        this._colsRaw = [
            ...new Set(features
                .map((_f) => _f.getCoordinates()[0]).sort())
        ];

        this._rowsRaw = [
            ...new Set(features
                .map((_f) => _f.getCoordinates()[1]).sort().reverse())
        ];
    }

    getRow(index: number): PostLoadingCell[]{
        const posY: number = this._rowsRaw[index];
        return this._field.filter( (_f) => _f.getCoordinates()[1] === posY);
    }

    getCol(index: number): PostLoadingCell[]{
        const posX: number = this._colsRaw[index];
        return this._field.filter( (_f) => _f.getCoordinates()[0] === posX);
    }

}
