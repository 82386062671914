
<div class="map-popup middle-popup" id="map-popup" (wheel)="wheel($event)">
    <a (click)="close()" id="map-popup-closer" class="map-popup-closer"></a>

    <ng-container [ngSwitch]="currentViewState">

        <ng-container *ngSwitchCase="viewStates.Processing" [ngTemplateOutlet]="processing"></ng-container>
        <ng-container *ngSwitchCase="viewStates.Success" [ngTemplateOutlet]="success"></ng-container>
        <ng-container *ngSwitchCase="viewStates.AddMarker" [ngTemplateOutlet]="addMarker"></ng-container>
        <ng-container *ngSwitchCase="viewStates.DownloadArea" [ngTemplateOutlet]="downloadArea"></ng-container>
        <ng-container *ngSwitchCase="viewStates.Ident" [ngTemplateOutlet]="ident"></ng-container>
        <ng-container *ngSwitchCase="viewStates.Delete" [ngTemplateOutlet]="delMarker"></ng-container>
        <ng-container *ngSwitchCase="viewStates.NoData" [ngTemplateOutlet]="nodata"></ng-container>
        <ng-container *ngSwitchCase="viewStates.Error" [ngTemplateOutlet]="error"></ng-container>
        <ng-container *ngSwitchCase="viewStates.Null"></ng-container>

    </ng-container>

</div>

<ng-template #error>
    <div class="map-popup-loading marker-added-error">
        <span  class="material-icons-outlined has-error">error</span>
    </div>
    <div>
        <h4 class="text-center" i18n="@@error">Error</h4>
        <p class="text-center">{{errorMessage}}</p>
    </div>
</ng-template>

<ng-template #processing>
    <div class="map-popup-loading">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
</ng-template>


<ng-template #nodata>
    <div >
        <h4 class="text-center" i18n="@@noData">No Data found</h4>
    </div>
</ng-template>


<ng-template #success>
    <div class="map-popup-loading marker-added-success">
        <span  class="material-icons-outlined is-success">check_circle</span>
    </div>
</ng-template>


<!--<ng-template #delMarker>-->
<!--    <button mat-menu-item id="delete-marker" color="warn" (click)="deleteMarker()"><mat-icon>delete</mat-icon><span i18n="@@delete">Delete</span></button>-->
<!--</ng-template>-->


<ng-template #addMarker>
    <app-add-marker-popup
    [identData]="identData"
    (selectedIdent)="selectedIdent($event)"></app-add-marker-popup>
</ng-template>


<ng-template #ident>
    <app-ident-popup
            [identData]="identData"
            [measurements]="measurements"
            [isMultiple]="isMultiple"
            (processMarkerDeleteEvent)="deleteMarker($event)"></app-ident-popup>
</ng-template>

<ng-template #downloadArea>
    <app-download-area
    [coordinates]="config.coordinates"
    (cancelDownloadArea)="close()"
    (downloadArea)="emitDownloadArea($event)"></app-download-area>
</ng-template>
