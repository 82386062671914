import { IMSysPostLoadingStyle } from './IMSys';
import {Feature} from 'ol';
import Overlay from 'ol/Overlay';
import Geometry from 'ol/geom/Geometry';
import {PostLoadingCell} from './_PostLoadingCell';



export const postStyleIMSys = ( config,  ...features: Feature<Geometry>[] | Overlay[]) => {
    const _imsys = new IMSysPostLoadingStyle( [].concat(...features).map( (_f) => new PostLoadingCell(_f)));
    if(config.iMSys){
        _imsys.applyStyle( config.iMSys );
    }
};

