import { Injectable } from '@angular/core';
import Config from '../../config/Config';

@Injectable({
  providedIn: 'root'
})
export class ConsoleLoggingService {

  constructor() { }

    log(message: string, error?: any){
        if(error){ console.log(message, error);}else{console.log(message);}
    }

    logDebug(message: string, error?: any){
      const _isInit = Config.frontend && Config.frontend.debug && Config.frontend.debug.console;
      if(_isInit){
          if(error){ console.log(message, error);}else{console.log(message);}
      }

    }
}
