import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ConnectionStatusComponent } from './components/connection-status/connection-status.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { MaterialModule } from './modules/material.module';
import { StatefulAuthenticationService } from './services/authentication/stateful-authentication.service';
import { ConnectionStatusService } from './services/connection-status.service';
import { I18nService } from './services/i18n.service';
import { ServerStatusService } from './services/server-status.service';

@NgModule({
    declarations: [ConnectionStatusComponent, LoadingIndicatorComponent],
    imports: [CommonModule, OAuthModule.forRoot(), MaterialModule],
    exports: [ConnectionStatusComponent, LoadingIndicatorComponent],
    providers: [
        StatefulAuthenticationService,
        ConnectionStatusService,
        ServerStatusService,
        I18nService,
        { provide: OAuthStorage, useFactory: () => localStorage },
    ],
})
export class SharedModule {}
