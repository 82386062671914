import { catchError, Observable, of, tap } from 'rxjs';
import Config from 'src/app/config/Config';
import { environment } from 'src/environments/environment';
import { ApiAuthMethodType, ApiLoginResult } from '../../interfaces/api-login-result';
import { AuthBase, AuthBaseConfig } from './auth.interface';
import { AuthenticationStateType } from './stateful-authentication.service';
import { ConsoleLoggingService } from  '../console-logging.service';

export class MobileAuth extends AuthBase {

    public authMethod = ApiAuthMethodType.Mobile;

    constructor({ httpClient, userService }: AuthBaseConfig, private logging: ConsoleLoggingService) {
        super({ httpClient, userService });
    }

    submit() {
        throw new Error('Method not implemented.');
    }

    login() {
        const url = `${environment.apiRoot}${Config.Api.sessionAuth}`;

        return this.httpClient.get<ApiLoginResult>(url)
            .pipe(
                tap(results => {
                    if (results.success) {
                        this.userService.setUsername('mobile');
                        const username = this.userService.getUsername();
                        this.authAnswer.next({ state: AuthenticationStateType.LoginSuccessful, sessionId: results.sessionId, user: username });
                    } else {
                        this.authAnswer.next({ state: AuthenticationStateType.Error, error: { title: results.errorTitle, message: results.error } });
                    }
                }),
                catchError(err => {
                    this.authAnswer.next({ state: AuthenticationStateType.Error, error: err });
                    this.logging.log('Mobile Login Error', err);
                    return of(err);
                })
            );
    }

    logout(sessionId: string): Observable<any> | Promise<any> {
        return super.logout(sessionId);
    }
}
