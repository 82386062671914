export enum HighLeitMessageType {
    NumericValue = 'numerciValue',
    PowerValue = 'powerValue',
    EventListEntry = 'eventListEntry',
    RefreshLayer = 'refreshLayer',
    NewMarkers = 'newMarkers',
    NewColors = 'newColors',
    NewStates = 'newStates'
}

export interface HighLeitMessage {
    type: HighLeitMessageType;
    hlId: number;
}

export interface NumericValueMessage extends HighLeitMessage {
    value: number;
    vivavisId: string;
}

export interface PowerValueMessage extends HighLeitMessage {
    color1: string;
    color2: string;
}

export interface EventListEntryMessage extends HighLeitMessage {
    prio: string;
    nr: number;
    time: Date;
    description: string;
    domain: string;
    ipid: string;
    user: string;
}
