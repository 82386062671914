
<div>
  <section class="obiValueUnitsWrap">
    <input (click)="triggerValueSelection()"
           (touchend)="triggerValueSelection()"
           [ngClass]="(isValueSelected) ? 'valueSelected' : ''"
           [type]="(value) ? 'number' : 'hidden'"
           [readonly]="true"
           [value]="value"
           [title]="''">
    <p>{{unit}}</p>
  </section>

  <p class="obisIdSubline">{{obisId}}</p>

</div>
