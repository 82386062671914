import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { ApiConfigMarker } from '../../shared/interfaces/api-marker';
import { MapPopupConfig } from '../map-popup/map-popup.component';

export enum DrawerType {
    Top = 'top',
    Bottom = 'bottom'
}

export enum DrawerState {
    Open = 'open',
    Close = 'close'
}
export interface DrawerEvent {
    drawer: DrawerType;
    state: DrawerState;
    url?: SafeResourceUrl;
    picNo?: number;
    schemaType?: string;
    measuringSpot?: string[];
    measuringSpotTitle?: string;
}

@Injectable({
    providedIn: 'root',
})
export class MapService implements OnDestroy {


    public addMarkerMode$: Observable<boolean>;

    private blobs = new Map<string, string>();
    private markerModeSubject = new ReplaySubject<boolean>(1);
    private markerLayerVisibility = new BehaviorSubject<boolean>(true);

    public markerLayerVisibility$ = this.markerLayerVisibility.asObservable().pipe(shareReplay(1));

    private _addMarkerMode = false;
    private stateStorageKey = 'mapState';

    private drawerSubject = new Subject<DrawerEvent>();
    public drawer$ = this.drawerSubject.asObservable();

    private mapPopupSubject = new Subject<MapPopupConfig>();
    public mapPopupSubject$ = this.mapPopupSubject.asObservable();

    constructor(private sanitizer: DomSanitizer) {
        this.addMarkerMode$ = this.markerModeSubject.asObservable();
    }

    ngOnDestroy() { }

    public set addMarkerMode(value: boolean) {
        this._addMarkerMode = value;
        this.markerModeSubject.next(this._addMarkerMode);
    }

    public get addMarkerMode() {
        return this._addMarkerMode;
    }

    public showDownloadAreaPopup(popupConfig: MapPopupConfig) {
        this.mapPopupSubject.next(popupConfig);
    }

    public openTopDrawer(url?: string) {
        this.drawerSubject.next({
            drawer: DrawerType.Top,
            state: DrawerState.Open,
            url: this.sanitizer.bypassSecurityTrustResourceUrl(url)
        });
    }

    public openBottomDrawer(picNo?: number, schemaType?: string) {
        this.drawerSubject.next({
            drawer: DrawerType.Bottom,
            state: DrawerState.Open,
            picNo,
            schemaType
        });
    }

    public openBottomObisDrawer(measuringSpot?: string[], measuringSpotTitle?: string) {
        this.drawerSubject.next({
            drawer: DrawerType.Bottom,
            state: DrawerState.Open,
            schemaType: 'obis',
            measuringSpot,
            measuringSpotTitle
        });
    }

    public setMarkerLayerVisbility(visbility: boolean) {
        this.markerLayerVisibility.next(visbility);
    }

    public async toggleMarkerLayerVisibility() {
        const v = await firstValueFrom(this.markerLayerVisibility$);
        this.setMarkerLayerVisbility(!v);
    }

    public saveState(data: any) {
        const state = { ...data };
        const stateStr = JSON.stringify(state);
        localStorage.setItem(this.stateStorageKey, stateStr);
    }

    public getState() {
        const stateStr = localStorage.getItem(this.stateStorageKey);
        const state = JSON.parse(stateStr);
        return state;
    }

    public updateBlob(activeMarker: ApiConfigMarker) {
        this.createBlob(activeMarker);
    }

    public getBlob(activeMarker: ApiConfigMarker) {
        const blobId = activeMarker.id;
        if (!this.blobs.has(blobId)) {
            this.createBlob(activeMarker);
        }
        return this.sanitizer.bypassSecurityTrustUrl(this.blobs.get(blobId));
    }

    public getMarkerBackgroundStyle(
        activeMarker: ApiConfigMarker,
        withScale = false
    ) {
        const blobId = activeMarker.id;
        if (!this.blobs.has(blobId)) {
            this.createBlob(activeMarker);
        }
        // const url = this.blobs.get(blobId);
        const url = activeMarker.imgSrc;
        let css = `background-image: url("${url}")`;

        if (activeMarker.rotation > 0 || withScale) {
            css += '; transform: ';
        }

        if (activeMarker.rotation > 0) {
            // css += ` transform: rotate(${activeMarker.rotation}deg)`
            css += ` rotate(${activeMarker.rotation}deg)`;
        }

        if (activeMarker.scale && withScale) {
            css += ` scale(${activeMarker.scale})`;
        }

        const style = this.sanitizer.bypassSecurityTrustStyle(css);
        return style;
    }

    public getAddMarkerModeCursorStyle(activeMarker: ApiConfigMarker) {
        if (this.addMarkerMode) {
            const blobId = activeMarker.id;
            // let url = '';
            if (!this.blobs.has(blobId)) {
                this.createBlob(activeMarker);
            }
            // const url = this.blobs.get(blobId);
            const url = activeMarker.imgSrc;
            const tl = '18 18';

            // switch (activeMarker.markerType) {
            //     case MarkerType.Grounded:
            //         tl = '14 16.5';
            //         break;
            //     case MarkerType.TempDisconn:
            //         tl = '32.5 14.5';
            //         break;
            //     case MarkerType.TempLoad:
            //         tl = '15 26.5';
            //         break;
            //     case MarkerType.TempSupply:
            //         tl = '17 17';
            //         break;
            // }
            const css = ` url("${url}") ${tl}, auto`;
            // if (activeMarker.rotation > 0) {
            //     css += `; transform: rotate(${activeMarker.rotation}deg)`
            // }
            const style = this.sanitizer.bypassSecurityTrustStyle(css);
            return style;
        } else {
            return this.sanitizer.bypassSecurityTrustStyle('unset');
        }
    }

    // public buildArrayOfCoords(arr: any[], coordinates: any[]) {
    //     for (const element of arr) {
    //         if (Array.isArray(element)) {
    //             const len = element.length;
    //             if (len === 2) {
    //                 const isN1 = !isNaN(element[0]);
    //                 const isN2 = !isNaN(element[1]);
    //                 if (isN1 && isN2) {
    //                     coordinates.push(element);
    //                 } else {
    //                     this.buildArrayOfCoords(element, coordinates);
    //                 }
    //             } else {
    //                 let nan = false;
    //                 for (const c of element) {
    //                     if (isNaN(c)) {
    //                         nan = true;
    //                         break;
    //                     }
    //                 }
    //                 if (nan) {
    //                     this.buildArrayOfCoords(element, coordinates);
    //                 } else {
    //                     coordinates.push(element);
    //                 }
    //             }
    //         } else if (element.hasOwnProperty('coordinates')) {
    //             this.buildArrayOfCoords(element.coordinates, coordinates);
    //         } else if (element.hasOwnProperty('flatCoordinates')) {
    //             this.buildArrayOfCoords(element.flatCoordinates, coordinates);
    //         }
    //     }
    // }

    private createBlob(activeMarker: ApiConfigMarker) {
        let url = '';
        const blobId = activeMarker.id;
        if (activeMarker.imgSrc.includes('base64')) {
            url = activeMarker.imgSrc;
        } else {
            const blob = new Blob([activeMarker.imgSrc], {
                type: 'image/svg+xml',
            });
            url = URL.createObjectURL(blob);
        }
        this.blobs.set(blobId, url);
    }
}
