import { Injectable, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import RegularShape from 'ol/style/RegularShape';
import Stroke from 'ol/style/Stroke';
import Style, { StyleLike } from 'ol/style/Style';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { ApiMarkersService } from '../../shared/api-services/api-markers.service';
import { ApiConfigMarker, MarkerType } from '../../shared/interfaces/api-marker';
import { Utilities } from '../../utils/Utilities';

@Injectable({
    providedIn: 'root'
})
export class MarkerService implements OnDestroy {

    public userMarkers$: Observable<ApiConfigMarker[]>;
    private availableMarkerSubject = new ReplaySubject<ApiConfigMarker[]>(1);
    private userMarkerSubject = new ReplaySubject<ApiConfigMarker[]>(1);
    private availableMakers: ApiConfigMarker[] = [];
    private userMakers: ApiConfigMarker[] = [];
    private availableMakerTypes = [
        MarkerType.Grounded,
        MarkerType.NotNormal,
        MarkerType.TempDisconn,
        MarkerType.TempLoad,
        MarkerType.TempSupply
    ];
    private userMarkerTypes = [
        MarkerType.Grounded,
        MarkerType.TempDisconn,
        MarkerType.TempLoad,
        MarkerType.TempSupply
    ];

    private availableStyles = new Map<string, Style>();

    private subscription = new Subscription();

    constructor(private iconRegistry: MatIconRegistry,
                private apiMarkersService: ApiMarkersService,
                private sanitizer: DomSanitizer) {


        this.userMarkers$ = this.userMarkerSubject.asObservable();
        this.availableMakers = this.availableMakerTypes.map(mt => {
            const {src, scale} = this.markerIconUrl(mt);
            const am: ApiConfigMarker = {
                id: mt,
                imgSrc: src,
                name: mt,
                markerType: mt,
                rotation: 0,
                scale
            };
            return am;
        });

        for (const am of this.availableMakers) {
            this.markerStyle(am);
            this.markerStyle(am, true);
        }

        this.userMakers = this.userMarkerTypes.map(mt =>{
            const {src, scale} = this.markerIconUrl(mt);
            const am: ApiConfigMarker = {
                id: mt,
                imgSrc: src,
                name: mt,
                markerType: mt,
                rotation: 0,
                scale
            };
            return am;
        });
        this.userMarkerSubject.next(this.userMakers);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public markerIconUrl(markerType: MarkerType) {
        let src = 'assets/svg/schema-symbols/';
        const scale = 1;
        switch (markerType) {
            case MarkerType.Grounded:
                src += 'Handerde - GelbGruen.svg';
                break;
            case MarkerType.NotNormal:
                src += 'NichtNormal.svg';
                break;
            case MarkerType.TempDisconn:
                src += 'Trennung.svg';
                break;
            case MarkerType.TempLoad:
                src += 'Verbraucher.svg';
                break;
            case MarkerType.TempSupply:
                src += 'Erzeuger.svg';
                break;
        }

        return {src, scale};
    }

    /**
     *
     * @param bbox
     * @returns HTTP Observable
     */
    public getMarkerForArea(bbox: number[]) {
        return this.apiMarkersService.getMarkerForArea(bbox);
    }

    /**
     *
     * @param marker
     * @param vivavisId
     * @returns HTTP Observable
     */
    public removeMarkerFromIdent(marker: ApiConfigMarker, vivavisId: string) {
        return this.apiMarkersService.deleteMarker(marker.markerType, vivavisId);
    }

    /**
     *
     * @param marker
     * @param vivavisId
     * @returns HTTP Observable
     */
    public addMarkerToIdent(marker: ApiConfigMarker, vivavisId: string) {
        return this.apiMarkersService.addMarker(vivavisId, marker.markerType);
    }

    public markerStyle(apiConfigMarker: ApiConfigMarker, hover = false, isOffline: {set: boolean; removed: boolean} = null) {

        const styleId = hover ? apiConfigMarker.id + '_hover' : apiConfigMarker.id;
        const scale = hover ? 1.25 * apiConfigMarker.scale : apiConfigMarker.scale;
        const hourglassScale = hover ? 1.25 * 0.9 : 0.9;

        if (!this.availableStyles.has(styleId)) {

            const rotation = Utilities.degreesToRadians(apiConfigMarker.rotation);
            let imgSrc = '';
            if (apiConfigMarker.imgSrc.includes('base64')) {
                imgSrc = apiConfigMarker.imgSrc;
            } else if (apiConfigMarker.imgSrc.includes('<svg')) {
                imgSrc = 'data:image/svg+xml,' + escape(apiConfigMarker.imgSrc);
            } else {
                imgSrc = apiConfigMarker.imgSrc;
            }

            const newStyle = new Style({
                    image: new Icon({
                        crossOrigin: 'anonymous',
                        src: imgSrc,
                        rotation,
                        scale
                    })
                });
            this.availableStyles.set(styleId, newStyle);
        }

        const style: Style[] = [this.availableStyles.get(styleId)];

        if (isOffline) {
            if (isOffline.set || isOffline.removed) {
                style.push(new Style({
                    image: new Icon({
                        crossOrigin: 'anonymous',
                        src: 'assets/svg/hourglass.svg',
                        displacement: [25, 0],
                        scale: hourglassScale
                    })
                }));
            }

            if (isOffline.removed) {
                style.push(
                    new Style({
                        image: new RegularShape({
                            radius: 25 / Math.SQRT2,
                            radius2: 25,
                            points: 4,
                            angle: 0,
                            scale,
                            stroke: new Stroke({color: 'red', width: 3}),
                            fill: new Fill({color: 'rgba(255, 0, 0, .15)'})
                        })
                    })
                );
            }
        }

        return style;
    }

    public updateAvailableMarkers() {
        this.availableMarkerSubject.next(this.availableMakers);
    }

    public getAvailableMarkers() {
        return this.availableMakers;
    }

    public getUserMarkerTypes() {
        return this.userMarkerTypes;
    }

    public getMarkerConfigForMarkerType(markerType: MarkerType) {
        return this.availableMakers.find(x => x.markerType === markerType);
    }

    public getAvailableMarkerTypes() {
        return this.availableMakerTypes;
    }

    public getAvailableMarkers$(): Observable<ApiConfigMarker[]> {
        return this.availableMarkerSubject.asObservable();
    }

    private slopeOfLine(point1: number[], point2: number[]) {
        const x1 = point1[0];
        const y1 = point1[1];
        const x2 = point2[0];
        const y2 = point2[1];
        return (y2 - y1) / (x2 - x1);
    }

    private setupIcons() {
        for (const m of this.availableMakers) {
            this.iconRegistry.addSvgIconLiteral(
                m.markerType.toString(),
                this.sanitizer.bypassSecurityTrustHtml(m.imgSrc)
            );
        }
    }

}
