import { Pipe, PipeTransform } from '@angular/core';
import { ApiMeasurement } from './../../shared/interfaces/api-measurement';

@Pipe({
    name: 'complexMeasurement',
    pure: true
})
export class ComplexMeasurementPipe implements PipeTransform {
    transform(identDataMeta: any, measurements: ApiMeasurement[]): any {
        let result = null;

        if (identDataMeta && measurements && measurements.length > 0) {

            const k = Object.keys(identDataMeta).find(x => x.toLowerCase().indexOf('vivavisid') !== -1);

            if (k) {
                const m = measurements.find(x => x.vivavisId === identDataMeta[k]);

                if(m && m.values) {
                    result = m.values;
                }
            }
        }

        return result;
    }
}
