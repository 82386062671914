import {Pipe, PipeTransform} from '@angular/core';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';

@Pipe({
    name: 'searchResultIcon',
    pure: true
})
export class SearchResultIconPipe implements PipeTransform {

    transform(f: Feature<Geometry>, history: Feature<Geometry>[]): unknown {
        let icon = 'location_on';
        const isGeoCoder = f.get('geocoder');

        if (this.isInHistory(f.get('id'), history)) {
            icon = 'schedule';
        }

        if  (isGeoCoder) {
            icon = 'travel_explore';
        }

        return icon;
    }

    private isInHistory(id, history: Feature<Geometry>[]) {
        return history.findIndex(x => x.get('id') === id) !== -1;
    }

}
