import { MarkerType, ApiMarker } from './../../shared/interfaces/api-marker';
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appMarkerIcon]'
})
export class MarkerIconDirective {
    private _markerType: MarkerType;

    private _marker: ApiMarker;

    @Input('appMarkerIcon') set markerType(value: string | ApiMarker) {

        if (typeof(value) === 'string') {
            this._markerType = value as MarkerType;
            this.createImg();
        } else {
            this.marker = value as ApiMarker;
        }
    }

    get markerType() {
        return this._markerType;
    }

    set marker(value: ApiMarker) {
        this._marker = value;
        this.createImg2();
    }

    get marker() {
        return this._marker;
    }

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    private createImg2() {

        let markerIconSrc = 'assets/svg/schema-symbols/';

        switch (this.marker.content) {
            case MarkerType.Grounded:
                markerIconSrc += 'Handerde - GelbGruen.svg';
                break;
            case MarkerType.NotNormal:
                markerIconSrc += 'NichtNormal.svg';
                break;
            case MarkerType.TempDisconn:
                markerIconSrc += 'Trennung.svg';
                break;
            case MarkerType.TempLoad:
                markerIconSrc += 'Verbraucher.svg';
                break;
            case MarkerType.TempSupply:
                markerIconSrc += 'Erzeuger.svg';
                break;
        }

        let img;
        let background = `url("${markerIconSrc}")`;

        if (this.marker.isOfflineRemoved || this.marker.isOfflineSet) {

            img = this.renderer.createElement('img');
            this.renderer.setAttribute(img, 'src', 'assets/svg/hourglass.svg');
            this.renderer.setAttribute(img, 'class', 'marker-symbol-icon-hourglass');

            if (this.marker.isOfflineRemoved) {
                background = `linear-gradient(0deg, rgba(255, 0, 0, 0.15), rgba(255, 0, 0, 0.15)), url("${markerIconSrc}")`;
            }
        }

        const div = this.renderer.createElement('div');
        this.renderer.setAttribute(div, 'class', 'marker-symbol-icon');
        this.renderer.setStyle(
            div,
            'background-image',
            background
        );

        if (img) {
            this.renderer.appendChild(div, img);
        }

        this.renderer.appendChild(this.el.nativeElement, div);
    }

    private createImg() {
        let src = 'assets/svg/schema-symbols/';

        switch (this.markerType) {
            case MarkerType.Grounded:
                src += 'Handerde - GelbGruen.svg';
                break;
            case MarkerType.NotNormal:
                src += 'NichtNormal.svg';
                break;
            case MarkerType.TempDisconn:
                src += 'Trennung.svg';
                break;
            case MarkerType.TempLoad:
                src += 'Verbraucher.svg';
                break;
            case MarkerType.TempSupply:
                src += 'Erzeuger.svg';
                break;
        }

        const img = this.renderer.createElement('img');

        this.renderer.setAttribute(img, 'class', 'marker-symbol-icon');
        this.renderer.setAttribute(img, 'src', src);
        this.renderer.appendChild(this.el.nativeElement, img);
    }
}
