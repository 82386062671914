import { ConnectionStatusState } from './../../services/connection-status.service';
import {Observable, Subject, tap} from 'rxjs';
import {
    ConnectionStatus,
    ConnectionStatusService,
} from 'src/app/shared/services/connection-status.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {UpdateReceiverService} from '../../services/updatereceiver.service';
import {ApiLayersService} from '../../api-services/api-layers.service';
import {StatefulAuthenticationService} from '../../services/authentication/stateful-authentication.service';
import {environment} from '../../../../environments/environment';
import {MatMenuTrigger} from '@angular/material/menu';
import {DisplayNoConnectionService} from '../../services/display-no-connection.service';
import {SnackbarMessagesService} from '../../services/snackbar-messages-service.';
import { ConsoleLoggingService } from  '../../services/console-logging.service';

@Component({
    selector: 'app-connection-status',
    templateUrl: './connection-status.component.html',
    styleUrls: ['./connection-status.component.scss'],
})
export class ConnectionStatusComponent implements OnInit {
    public connectionStatus$: Observable<ConnectionStatus>;

    public connectionStatusEnum = ConnectionStatusState;

    public stateColor = 'button-white';

    public stateBackendConnected = true;
    public stateWebSocketConnected = true;

    private isMobile = environment.mobile;

    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

    constructor(private connectionStatusService: ConnectionStatusService,
                private updateReceiverService: UpdateReceiverService,
                private statefulAuthenticationService: StatefulAuthenticationService,
                private displayNoConnection: DisplayNoConnectionService,
                private snackbar: SnackbarMessagesService,
                private logging: ConsoleLoggingService) {


        this.connectionStatus$ = this.connectionStatusService.connectionStatus$
        .pipe(
            tap(state => {
                // this.stateColor = state.isOnline && state.webSocketConnected ? 'white' : 'warn';
                // this.stateColor = state.isOnline && !state.webSocketConnected ? 'orange' : 'warn';
                // this.stateColor = !state.isOnline && state.webSocketConnected ? 'orange' : 'warn';


                this.stateWebSocketConnected = this.updateReceiverService.isConnected();

                if ( (!state.isOnline && state.webSocketConnected) || (state.isOnline && !state.webSocketConnected ) ) {
                    this.stateColor = 'button-orange';
                    this.snackbar.displayErrorDebug(`Websocket: ${state.webSocketConnected} | Backend: ${state.isOnline}`);
                    this.displayNoConnection.displayConnectionStatus$.next(false);
                }
                else if (!state.isOnline && !state.webSocketConnected) {
                    this.stateColor = 'button-red';
                    this.snackbar.displayErrorDebug(`Websocket: ${state.webSocketConnected} | Backend: ${state.isOnline}`);
                    this.displayNoConnection.displayConnectionStatus$.next(false);
                }
                else if (state.isOnline && state.webSocketConnected) {
                    this.stateColor = 'button-white';
                    this.snackbar.displaySuccessDebug(`Websocket: ${state.webSocketConnected} | Backend: ${state.isOnline}`);
                    this.displayNoConnection.displayConnectionStatus$.next(true);

                }

            }),
            // tap(console.log),
        );

        this.connectionStatus$.subscribe( state => {
            this.stateBackendConnected = state.isOnline;
            this.stateWebSocketConnected = this.updateReceiverService.isConnected();
        });

        this.displayNoConnection.displayConnectionStatus$.subscribe( (_state: boolean): void => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (_state) ? this.menuTrigger.closeMenu() : this.menuTrigger.openMenu();
        });
    }

    ngOnInit(): void {}

    reconnect_BackendApp(){
        this.logging.logDebug('reconnect Backend');
        this.snackbar.displaySuccessDebug(`reconnecting Backend`);

        if(!this.isMobile){
            this.statefulAuthenticationService.isSessionIdValid().subscribe( {
                next: (data) =>
                {
                    const success = data.success;
                    this.stateColor = (success) ?  'button-white': 'button-red';
                    this.stateBackendConnected = data.success;
                    this.snackbar.displaySuccessDebug(`Backend: Connection success`);

                },
                error: (e) =>
                {
                    this.logging.log('no Connection to Backend', e);
                    this.snackbar.displayErrorDebug(`Backend: Connection Error, ${e}`);
                    this.stateColor = 'button-red';
                    this.stateBackendConnected = false;
                }
            });
        }else{
            this.connectionStatusService.getServerStatus();
        }


    }

    reconnect_WebSocket(){
        this.logging.logDebug('reconnect WebSocket');
        this.snackbar.displaySuccessDebug(`reconnecting Websocket`);
        this.updateReceiverService.checkConnection().then( state => {
            this.snackbar.displaySuccessDebug(`connection Successful`);
            this.stateWebSocketConnected = state;
        });
    }
}
