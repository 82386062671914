<button mat-fab
        i18n-aria-label="@@markerMenu"
        aria-label="Marker Menu"
        id="marker-menu-button"
        [matMenuTriggerFor]="markerMenu">
    <mat-icon *ngIf="_activeMarker">
        <div class="active-marker-icon" [style]="activeMarkerBackgroundStyle"></div>
    </mat-icon>
    <mat-icon *ngIf="!_activeMarker">library_add</mat-icon>
</button>
<mat-menu #markerMenu="matMenu" [hasBackdrop]="true">
    <div id="marker-menu-content">
        <app-marker-menu-item
            style="cursor: pointer"
            (click)="startAddMarkerMode(marker)"
            *ngFor="let marker of markerService.userMarkers$ | async"
            [marker]="marker"></app-marker-menu-item>
    </div>
</mat-menu>

