import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayerFeatureType } from 'src/app/shared/enums/layer-feature-type.enum';
import { environment } from '../../../environments/environment';
import Config from '../../config/Config';
import { ApiLayer, ApiMapInfo, ApiMapType } from '../interfaces/api-layer';

interface ApiMapInfoResult {
    mapInfos: ApiMapInfo[];
}

@Injectable({
    providedIn: 'root'
})
export class ApiLayersService {

    private layerConfig: any = {};
    constructor(private httpClient: HttpClient) {
        this.httpClient.get(`assets/layers.config.json`)
            .subscribe((e: any) => {
                this.layerConfig = e;
            });
    }

    /**
     *
     * @returns HTTP Observable
     */
    public getLayers(): Observable<ApiLayer[]> {

        const url = `${environment.apiRoot}${Config.Api.mapInfo}`;

        return this.httpClient.get(url)
            .pipe(map((result: ApiMapInfoResult) => {

                const mapLayers = result.mapInfos;
                const layers: ApiLayer[] = [];
                // const sessionId = localStorage.getItem('sessionId');

                let i = 0;

                for (const mapInfo of mapLayers) {
                    let wsId = null;

                    if (mapInfo.Worksessions && mapInfo.Worksessions.length > 0) {
                        wsId = mapInfo.Worksessions[0];
                    }

                    const tileUrl = mapInfo.url
                        .replace('{tileServer}', environment.tilesRoot)
                        .replace('{appServer}', environment.tilesRoot)
                        // .replace('{sessionId}', sessionId)
                        ;

                    let zIndex = 0;
                    let maxZoom = 1;
                    let minZoom = null;
                    if (mapInfo.MapType === ApiMapType.VectorXYZ) {
                        if (this.layerConfig[mapInfo.MapName]) {
                            zIndex = this.layerConfig[mapInfo.MapName].zIndex;
                            maxZoom = this.layerConfig[mapInfo.MapName].maxZoom;
                            minZoom = this.layerConfig[mapInfo.MapName].minZoom;
                            i = zIndex;
                        } else {
                            i++;
                            zIndex = i;
                        }
                    }

                    layers.push({
                        id: mapInfo.MapId,
                        foildId: mapInfo.MapId,
                        wsId,
                        name: mapInfo.MapName,
                        zIndex,
                        shaping: mapInfo.shaping,
                        url: tileUrl,
                        mapType: mapInfo.MapType,
                        maxZoom,
                        minZoom
                    });


                }
                return layers;
            }));
    }

}
