import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObisComponent } from './obis/obis.component';
import { ObisValueComponent } from './obis-value/obis-value.component';


@NgModule({
  declarations: [
    ObisComponent,
    ObisValueComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ObisComponent,
    ObisValueComponent,
  ],
})
export class ObisModule { }
