export interface ApiConfigMarker {
    id: string;
    name: string;
    imgSrc: string;
    markerType: MarkerType;
    rotation: number;
    scale: number;
    // isOffline?: boolean;
}

export interface ApiMarker {
    vivavisId: string;
    content: MarkerType;
    x: number;
    y: number;
    isOfflineSet?: boolean;
    isOfflineRemoved?: boolean;
}

export enum MarkerType {
    Grounded = 'Grounded',
    Questionable = 'Questionable',
    Plausible = 'Plausible',
    Implausible = 'Implausible',
    Unchecked = 'Unchecked',
    LimitViolation = 'LimitViolation',
    OutOfService = 'OutOfService',
    TimeInvalid = 'TimeInvalid',
    TransmissionBlocked = 'TransmissionBlocked',
    NotNormal = 'NotNormal',
    CmdDirLocked = 'CmdDirLocked',
    ConsumerSupplied = 'ConsumerSupplied',
    ConsumerUnsupplied = 'ConsumerUnsupplied',
    Disposed = 'Disposed',
    EarthFaultLocation = 'EarthFaultLocation',
    Empty = 'Empty',
    Loaded = 'Loaded',
    Local = 'Local',
    MonDirLocked = 'MonDirLocked',
    PeopleOnSite = 'PeopleOnSite',
    ShortCircuitLocation = 'ShortCircuitLocation',
    TempDisconn = 'TempDisconn',
    TempLoad = 'TempLoad',
    TempSupply = 'TempSupply',
    Testmode = 'Testmode',
}

export enum GetMarkerType {
    Grounded = 'Grounded',
    Questionable = 'Questionable',
    Plausible = 'Plausible',
    Implausible = 'Implausible',
    Unchecked = 'Unchecked',
    EarthFaultLocation = 'EarthFaultLocation',
    ShortCircuitLocation = 'ShortCircuitLocation',
    LimitViolation = 'LimitViolation',
    OutOfService = 'OutOfService',
    TimeInvalid = 'TimeInvalid',
    TransmissionBlocked = 'TransmissionBlocked',
    Disposed = 'Disposed',
    NotNormal = 'NotNormal',
    CmdDirLocked = 'CmdDirLocked',
    ConsumerSupplied = 'ConsumerSupplied',
    ConsumerUnsupplied = 'ConsumerUnsupplied',
    Empty = 'Empty',
    Loaded = 'Loaded',
    Local = 'Local',
    MonDirLocked = 'MonDirLocked',
    PeopleOnSite = 'PeopleOnSite',
    TempDisconn = 'TempDisconn',
    TempLoad = 'TempLoad',
    TempSupply = 'TempSupply',
    Testmode = 'Testmode',
}

export enum SetMarkerType {
    CmdDirLocked = 'CmdDirLocked',
    ConsumerSupplied = 'ConsumerSupplied',
    ConsumerUnsupplied = 'ConsumerUnsupplied',
    Disposed = 'Disposed',
    EarthFaultLocation = 'EarthFaultLocation',
    Empty = 'Empty',
    Grounded = 'Grounded',
    Loaded = 'Loaded',
    Local = 'Local',
    MonDirLocked = 'MonDirLocked',
    PeopleOnSite = 'PeopleOnSite',
    ShortCircuitLocation = 'ShortCircuitLocation',
    TempDisconn = 'TempDisconn',
    TempLoad = 'TempLoad',
    TempSupply = 'TempSupply',
    Testmode = 'Testmode',
}
