import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unwrapVivavisId'
})
export class UnwrapVivavisIdPipe implements PipeTransform {

  transform(value: any): any {
      return value.substring(1, value.length-1);
  }
}
