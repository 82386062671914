import {Pipe, PipeTransform} from '@angular/core';
import Layer from 'ol/layer/Layer';
import BaseLayer from 'ol/layer/Base';

@Pipe({
    name: 'sortLayers',
    pure: true
})
export class SortLayersPipe implements PipeTransform {

    transform(value: Array<BaseLayer>) {
        return value.sort((a, b) => b.getZIndex() - a.getZIndex());
    }

}
