import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-schema-obis-value',
  templateUrl: './obis-value.component.html',
  styleUrls: ['./obis-value.component.scss']
})
export class ObisValueComponent implements OnInit {

  @Input() isValueSelected: boolean;
  private _obisId!: string;
  @Input() set obisId(obisId: string){
    this._obisId = obisId;
  }
  get obisId(): any {
    return this._obisId;
  }

  private _unit: string | undefined;
  @Input() set unit(unit: string | undefined){
    this._unit = unit;
  }
  get unit(): string | undefined{
    return this._unit;
  }

  private _value: number | undefined;
  @Input() set value(value: number | undefined){
    this._value = value;
  }
  get value(): number | undefined{
    return Math.round(this._value * 100) / 100;
  }

  @Output() selectValueTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor() { }

  ngOnInit(): void {
  }

  triggerValueSelection(){
    this.isValueSelected = !this.isValueSelected;
    this.selectValueTrigger.emit(this.isValueSelected);
  }

}
