import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ObiMeasurements, ObisConfigData, ObisResponseValues, ObiValueSelect} from '../../Models/Obis';


@Component({
  selector: 'app-schema-obis',
  templateUrl: './obis.component.html',
  styleUrls: ['./obis.component.scss']
})
export class ObisComponent implements OnInit {

  private _config!: ObisConfigData;

  @Input() meterPoint!: string;

  @Input() set configuration(config: ObisConfigData) {
      this._config = config;
  }
  get configuration(): ObisConfigData {
    return this._config;
  }

  private _measurements: ObiMeasurements = {};
  @Input() set measurements(measurements: ObisResponseValues[]) {
    measurements.forEach( measurement => {
        if(!this._measurements[measurement.element]){
            this._measurements[measurement.element] = measurement;
        }
    });
  }
  get measurement(): ObiMeasurements {
    return this._measurements;
  }

  getMeasurementbyObiId(obiId: string): ObisResponseValues {
    return (this._measurements[obiId])
      ? this._measurements[obiId]
      : {value: undefined, unit: undefined, vivavisId: undefined} as unknown as ObisResponseValues;
  }

  @Output() selectValueTrigger: EventEmitter<ObiValueSelect> = new EventEmitter<ObiValueSelect>();
  constructor() { }

  ngOnInit(): void {
  }

  getColVisibility(): string {
    const _hasValues = this.configuration.obis.find(obisId => this.getMeasurementbyObiId(obisId).value !== undefined);
    return (_hasValues) ? 'block' : 'none';
  }

  processSelectedValues(element: ObisResponseValues, state: boolean){
    this.selectValueTrigger.emit({element, state, meterPoint: this.meterPoint} as ObiValueSelect);
  }

}
