import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import TileLayer from 'ol/layer/Tile';
import TileSource from 'ol/source/Tile';
import { AppMapLayer, BasicLayerState } from 'src/app/shared/services/layers.service';

@Component({
    selector: 'app-maps-menu2',
    templateUrl: './maps-menu2.component.html',
    styleUrls: ['./maps-menu2.component.scss'],
})
export class MapsMenu2Component implements OnInit {
    // /**
    //  * @deprecated
    //  */
    // @Input()
    // backgroundLayers: TileLayer<TileSource>[] = [];

    @Input()
    bgLayers: AppMapLayer[] = [];

    // /**
    //  * @deprecated
    //  */
    // @Output()
    // selectedBackgroundLayer = new EventEmitter<TileLayer<TileSource>>();

    @Output()
    selectedBgLayer = new EventEmitter<AppMapLayer>();

    readonly BasicLayerState = BasicLayerState;

    constructor() { }

    ngOnInit() { }

    // /**
    //  * @deprecated
    //  * @param bgLayer
    //  */
    // selectBackgroundLayer(bgLayer: TileLayer<TileSource>) {
    //     for (const bgl of this.backgroundLayers) {
    //         bgl.set('selected', false);
    //     }
    //     bgLayer.set('selected', true);
    //     this.selectedBackgroundLayer.emit(bgLayer);
    // }

    selectBgLayer(bgLayer: AppMapLayer) {
        for (const bgl of this.bgLayers) {
            bgl.selected = false;
        }

        bgLayer.selected = true;
        this.selectedBgLayer.emit(bgLayer);
    }
}
