import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import Config from '../../config/Config';
import { Feature as GeoJsonFeature } from 'geojson';
import { Observable } from 'rxjs';

export type FormatType = 'json' | 'geojson';

@Injectable({
    providedIn: 'root'
})
export class ApiGeocoderService {

    public geocoderConfig = {
        url: 'https://nominatim.openstreetmap.org',
        searchParam: 'q',
        lat: 'lat',
        lon: 'lon',
        idField: 'osm_id',
        resultText: 'display_name',
        searchEndpoint: '/search',
        reverseEndpoint: '/reverse',
    };

    constructor(private httpClient: HttpClient) {
        if(Config.frontend.searchOpts.geocoder.url){
            this.geocoderConfig.url = Config.frontend.searchOpts.geocoder.url;
        }
        if(Config.frontend.searchOpts.geocoder.minSearch){

        }
        if(Config.frontend.searchOpts.geocoder.maxSearch){

        }

        this.geocoderConfig = Config.frontend?.geocoder || this.geocoderConfig;
    }

    public search(query: string, format: FormatType = 'json', limit): Observable<GeoJsonFeature[]> {
        const url = `${this.geocoderConfig.url}${this.geocoderConfig.searchEndpoint}`;

        const httpParams = new HttpParams()
            .set('format', format)
            // .set('sources', 'osm')
            .set('limit', limit)
            .set(this.geocoderConfig.searchParam, query);
        return this.httpClient.get<GeoJsonFeature[]>(url, { params: httpParams }).pipe(map((data: any) => data.features));
    }

    public reverse(lat, lon, format: FormatType = 'json', limit) {
        const url = `${this.geocoderConfig.url}${this.geocoderConfig.reverseEndpoint}`;
        const httpParams = new HttpParams()
            .set(this.geocoderConfig.lat, lat + '')
            .set(this.geocoderConfig.lon, lon + '')
            .set('limit', limit)
            .set('format', format);

        return this.httpClient.get<GeoJsonFeature[]>(url, { params: httpParams }).pipe(map((data: any) => data.features));
    }
}
