import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {ObisResponse} from '../../../shared/_ObisSchema/Models/Obis';
import {Observable, throwError} from 'rxjs';
import {ajax} from 'rxjs/ajax';

@Injectable({
  providedIn: 'root'
})
export class ObisApiService {

  constructor(
      private httpClient: HttpClient
  ) { }


    loadMeasuringData(measuringSpotId: string[], urlEndpoint: string):  Observable<ObisResponse[]  | Observable<never>> {
        const urlWithHttpRoot = ( urlEndpoint.substring(0,4) === 'http');
        const url = (urlWithHttpRoot) ? '' : `${environment.apiRoot}${urlEndpoint}`;

        return ajax({
            url,
            method: 'GET',
            headers: {
                sessionId: localStorage.getItem('sessionId'),
                'Content-Type': 'application/json'
            },
            queryParams: {
                meterPoints: measuringSpotId.join(',')
            }
        }).pipe(map(res => {
            if( ((res.response as any).response as ObisResponse[]).length === 0 ) {
                return throwError(() => res.response);
            }
            return  (res.response as any).response as ObisResponse[];
        }));

    }
}
