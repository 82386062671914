import Config from 'src/app/config/Config';
import { ProjectionsService } from 'src/app/shared/services/projections.service';
import { CustomConfigService } from './custom.config.service';
import { DefaultCustomConfigService } from './default/default.config.service';
import { DewaCustomConfigService } from './dewa/dewa.config.service';
import { ConsoleLoggingService } from '../../shared/services/console-logging.service';


const customConfigServiceFactory = (projectionService: ProjectionsService, logging: ConsoleLoggingService) => {

    if (Config.frontend?.customConfig === 'dewa') {
        return new DewaCustomConfigService(projectionService, logging);
    } else {
        return new DefaultCustomConfigService(projectionService, logging);
    }
};

export const customConfigServiceProvider = {
    provide: CustomConfigService,
    useFactory: customConfigServiceFactory,
    deps: [ProjectionsService, ConsoleLoggingService]
};

