<div>
    <button mat-mini-fab color="{{showSearchBar ? 'primary' : 'white'}}" (click)="toggle()" id="search-toggle">
        <mat-icon>search</mat-icon>
    </button>
</div>
<div *ngIf="showSearchBar" class="search-bar">
    <div class="search-bar-input">

        <input
            id="search-text-input"
            [placeholder]="currentState.input.placeholder"
            [formControl]="currentState.input.formControl"
            autocomplete="off"
            autofocus
            type="search"
            #searchTextInput
            class="search-input"
             />

        <span class="clear-button" (click)="clearSearch()">
            <button mat-icon-button color="primary" id="clear-button" [disabled]="currentState.state === searchInputStates.History">
                <mat-icon>backspace</mat-icon>
            </button>
        </span>
    </div>
    <div class="search-controls">

        <span (click)="switchToStationSearch()">
            <button id="gridpilot-search-button" mat-icon-button color="primary" [disabled]="currentState.state === searchInputStates.Backend">
                <mat-icon>house</mat-icon>
            </button>
        </span>

        <span *ngIf="enableGeoCoder && !isOffline" (click)="switchToGeoCoderSearch()">
            <button id="geocoder-search-button" mat-icon-button color="primary" [disabled]="currentState.state === searchInputStates.Geocoder">
                <mat-icon>travel_explore</mat-icon>
            </button>
        </span>

        <span (click)="switchToHistory()">
            <button id="history-button" mat-icon-button color="primary" [disabled]="currentState.state === searchInputStates.History">
                <mat-icon>history</mat-icon>
            </button>
        </span>
    </div>

    <div class="loading-indicator" *ngIf="isLoading">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>

    <div class="auto-complete-wrapper" *ngIf="!isLoading">

        <ng-container *ngIf="currentState.searchResults">

            <ul class="search-results" *ngIf="currentState.searchResults.length > 0">

                <ng-container *ngIf="currentState.hasPagination">
                    <li *ngFor="let f of currentState.searchResults | slice:currentState.pagination.pageStart:currentState.pagination.pageEnd" (click)="selectSearchResult($event, f)">
                        <mat-icon>schedule</mat-icon>
                        <div [innerHTML]="f | searchResultHighlightText"></div>
                    </li>
                </ng-container>


                <ng-container *ngIf="!currentState.hasPagination">
                    <li *ngFor="let item of currentState.searchResults" (click)="selectSearchResult($event, item)">
                        <mat-icon fontSet="material-icons-outlined">{{item | searchResultIcon:historySelectedResults}}</mat-icon>
                        <div [innerHTML]="item | searchResultHighlightText:[currentState.input.formControl.value]"></div>
                    </li>
                </ng-container>

                <section *ngIf="currentState.state !== 'history'">
                    <ng-container *ngIf="!isLoading && (currentState.searchResults.length === searchOpts[currentState.state]['minSearch'] ) ">
                        <button mat-button class="loadMoreBtn" (click)="fireFullSearch()">
                            <span i18n="@@search.loadMore" >Load More</span>
                        </button>
                    </ng-container>
                </section>

            </ul>

            <div class="no-results" *ngIf="!isLoading && currentState.searchResults.length === 0">
                <span i18n="@@search.noResults">No Results</span>
            </div>

            <mat-paginator *ngIf="currentState.hasPagination && currentState.searchResults.length > (currentState.pagination.pageSize-1)"
                [pageSize]="currentState.pagination.pageSize"
                [length]="currentState.searchResults.length"
                (page)="setPage($event)"
            ></mat-paginator>

        </ng-container>

    </div>

</div>
