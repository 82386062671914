import { EnvInterface } from './env.interface';

export const environment: EnvInterface = {
    develop: false,
    staging: true,
    production: true,
    mobile: false,
    root: '',
    apiRoot: '/api',
    tilesRoot: '/tiles',
    webSocketUrl: '/ws',
    providers: []
};
