import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiIdent } from 'src/app/shared/interfaces/api-ident';

@Component({
    selector: 'app-add-marker-popup',
    templateUrl: './add-marker-popup.component.html',
    styleUrls: ['./add-marker-popup.component.scss'],
})
export class AddMarkerPopupComponent implements OnInit {

    @Output() selectedIdent = new EventEmitter<ApiIdent>();

    public langId = 'de';

    public isMultiple = false;

    private _identData: ApiIdent[];

    @Input()
    public set identData(data: ApiIdent[]) {
        this._identData = data;

        if (this._identData) {
            this.isMultiple = this._identData.length > 1;

            if (this._identData.length === 1) {
                setTimeout(() => {
                    this.selectedIdent.emit(this._identData[0]);
                }, 50);
            }
        } else {
            // this._identData = [];
        }
    }

    public get identData() {
        return this._identData;
    }

    constructor() {}

    ngOnInit() {}

    public selectOption(event) {
        this.selectedIdent.emit(event.option._value);
    }
}
