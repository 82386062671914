import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export interface UserProfile {
    username: string;
    picture: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserService implements OnDestroy {

    private userStorageKey = 'user';
    private storage = localStorage;

    private userProfile: UserProfile = {
        username: 'n/a',
        picture: 'assets/images/profile-cat.png'
    };

    private oauthUserProfile: any;

    private userProfileSubject = new BehaviorSubject<UserProfile>(this.userProfile);
    private username = '';
    public userProfile$: Observable<UserProfile>;


    constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) {
        this.userProfile$ = this.userProfileSubject.asObservable().pipe(shareReplay(1));
        this.restoreUsername();
    }

    ngOnDestroy() {
        this.clear();
    }

    private restoreUsername() {
        this.username = this.storage.getItem(this.userStorageKey) || '';
        this.extractUserProfile({ username: this.username });
    }

    private storeUsername() {
        this.storage.setItem(this.userStorageKey, this.username);
    }

    setUsername(username: string) {
        // this.storage.setItem(this.userStorageKey, value);
        this.extractUserProfile({ username });
    }

    getUsername() {
        return this.username;
    }

    public setOAuthUserProfile(userProfile: any) {
        this.oauthUserProfile = userProfile;
        this.extractUserProfile(userProfile);
    }

    public getUserProfile(): UserProfile {
        return this.userProfile;
    }

    public clear() {
        this.storage.removeItem(this.userStorageKey);
    }

    private extractUserProfile(up: any) {

        this.userProfile = {
            username: up.preferred_username || up.username || up.email,
            picture: up.picture || 'assets/images/profile-cat.png'
        };

        this.username = this.userProfile.username;
        this.storeUsername();

        this.userProfileSubject.next(this.userProfile);
    }

}
