import { ApiActionQueueElement, ApiActionQueueCount, ActionQueueElementState, ApiActionQueueAction } from './../interfaces/api-actionqueue';
import { Subject, Observable, BehaviorSubject, tap } from 'rxjs';
import { ApiActionQueueService } from './../api-services/api-actionqueue.service';
import { Injectable } from '@angular/core';
import { ApiActionQueueStatus } from '../interfaces/api-actionqueue';
import { MarkerType } from '../interfaces/api-marker';

@Injectable({
    providedIn: 'root',
})
export class ActionQueueService {

    actionQueueCount$: Observable<ApiActionQueueCount>;
    actionQueueStatus$: Observable<ApiActionQueueStatus>;
    actionQueueElements$: Observable<ApiActionQueueElement[]>;
    private actionQueueStatusSubject = new Subject<ApiActionQueueStatus>();
    private actionQueueCountSubject = new BehaviorSubject<ApiActionQueueCount>({ waiting: 0, errors: 0 });
    private actionQueueElementsSubject = new BehaviorSubject<ApiActionQueueElement[]>([]);

    constructor(private apiActionQueueService: ApiActionQueueService) {
        this.actionQueueCount$ = this.actionQueueCountSubject.asObservable();
        this.actionQueueStatus$ = this.actionQueueStatusSubject.asObservable();
        this.actionQueueElements$ = this.actionQueueElementsSubject.asObservable();

        // const q: ApiActionQueueElement[] = [
        //     {
        //         action: ApiActionQueueAction.CheckOperation,
        //         dequeued: null,
        //         enqueued: new Date('2021-12-09T17:49:31.137'),
        //         id: '{asdfasdfasdf-EDFB-4E63-A5D7-FE9772FD27BA}',
        //         name: 'ONS Lemförde-04 - Holmerdieck 11',
        //         subaction: 'ON',
        //         user: 'vivavis',
        //         vivavisId: '{E507F0DB-9F25-431B-AA09-5ADA15CC1C35}',
        //         state: ActionQueueElementState.Pending,
        //     },
        //     {
        //         action: ApiActionQueueAction.SetMarker,
        //         dequeued: new Date('2021-12-09T18:46:52.770'),
        //         enqueued: new Date('2021-12-09T17:49:31.137'),
        //         id: '{27C8B462-EDFB-4E63-A5D7-FE9772FD27BA}',
        //         name: 'ONS Lemförde-04 - Holmerdieck 11',
        //         subaction: MarkerType.TempDisconn,
        //         user: 'vivavis',
        //         vivavisId: '{E507F0DB-9F25-431B-AA09-5ADA15CC1C35}',
        //         state: ActionQueueElementState.Synced,
        //     },
        //     {
        //         action: ApiActionQueueAction.SetMarker,
        //         dequeued: new Date('2021-12-14T15:43:49.594'),
        //         enqueued: new Date('2021-12-14T15:43:04.381'),
        //         id: '{E33B0652-64DC-42F2-A568-9D3E83342712}',
        //         name: 'NETZ-LTG',
        //         subaction: MarkerType.Grounded,
        //         user: 'vivavis',
        //         vivavisId: '{B11AEB5C-7EAA-4171-AFD0-FEEEF357173B}',
        //         state: ActionQueueElementState.Synced,
        //     },
        //     {
        //         action: ApiActionQueueAction.DeleteMarker,
        //         dequeued: null,
        //         enqueued: new Date('2021-12-14T15:44:04.258'),
        //         id: '{54FF01C3-3195-46EA-84C0-F51A4DAED7AF}',
        //         name: 'NETZ-LTG',
        //         subaction: MarkerType.Grounded,
        //         user: 'vivavis',
        //         vivavisId: '{B11AEB5C-7EAA-4171-AFD0-FEEEF357173B}',
        //         state: ActionQueueElementState.Pending,
        //     },
        // ];

        // setTimeout(() => {
        //     this.actionQueueElementsSubject.next(q);
        // }, 1000);
    }

    public updateActionQueue() {
        // this.apiActionQueueService.status().subscribe(data => {
        //     this.actionQueueStatusSubject.next(data);
        // });

        // this.apiActionQueueService.getElements().subscribe(data => {
        //     this.actionQueueElementsSubject.next(data);
        // });

        // this.apiActionQueueService.count().subscribe(data => {
        //     this.actionQueueCountSubject.next(data);
        // });

        this.getStatus();
        this.getElements();
        this.getCount();
    }

    public getStatus() {
        this.apiActionQueueService.status().subscribe(data => {
            this.actionQueueStatusSubject.next(data);
        });

        return this.actionQueueStatus$;
    }

    public getElements() {
        this.apiActionQueueService.getElements().subscribe(data => {
            this.actionQueueElementsSubject.next(data);
        });
        return this.actionQueueElements$;
    }

    public executeElements() {
        return this.apiActionQueueService.executeElements();
    }

    public deleteElements(ids: string[]) {
        return this.apiActionQueueService.deleteElements(ids);
    }

    public run() {
        return this.apiActionQueueService.run().pipe(tap(() => this.getStatus()));
    }

    public stop() {
        return this.apiActionQueueService.stop().pipe(tap(() => this.getStatus()));
    }

    public getCount() {
        this.apiActionQueueService.count().subscribe(data => {
            this.actionQueueCountSubject.next(data);
        });
        return this.actionQueueCount$;
    }
}
