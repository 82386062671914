import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureCollection } from 'geojson';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import Config from '../../config/Config';
import { ApiGeneralInterface } from './../interfaces/api-general';
import { ApiGridColor, ApiGridColorValue } from './../interfaces/api-gridcolor';

@Injectable({
    providedIn: 'root',
})
export class ApiSchemaService {
    constructor(private httpClient: HttpClient) {}

    public getSchema(schemaId: string) {
        const url = `${environment.apiRoot}${Config.Api.schemas}`;

        let httpParams = new HttpParams();
        httpParams = httpParams.set('picno', schemaId);

        return this.httpClient
            .get<FeatureCollection>(url, { params: httpParams })
            .pipe(map((data: any) => data.response));
    }

    public getLineColors(vivavisIds: string[]) {
        const url = `${environment.apiRoot}${Config.Api.gridColor}`;

        let httpParams = new HttpParams();
        httpParams = httpParams.set('vivavisIds', vivavisIds.join(','));

        return this.httpClient
            .get<ApiGeneralInterface<ApiGridColorValue[]>>(url, {
                params: httpParams,
            })
            .pipe(
                // catchError((err) => throwError(err)),
                // tap((data) => {
                //     if (data.success === false) {
                //         throwError(data);
                //     }
                // }),
                map((data) => data.response)
            );
    }
}
