import { ApiServerStatusService } from './../api-services/api-serverstatus.service';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ApiServerStatusResult } from '../interfaces/api-serverstatus';
import { ConsoleLoggingService } from  './console-logging.service';

@Injectable({
    providedIn: 'root',
})
export class ServerStatusService {
    serverStatus$: Observable<ApiServerStatusResult>;
    private serverStatusSubject = new Subject<ApiServerStatusResult>();

    constructor(private apiServerStatusService: ApiServerStatusService, private logging: ConsoleLoggingService) {
        this.serverStatus$ = this.serverStatusSubject.asObservable();
    }

    public getStatus(): Observable<ApiServerStatusResult> {
        this.logging.logDebug('ServerStatusService', 'getStatus()');
        this.apiServerStatusService.getStatus().subscribe(
            (data) => {
                this.serverStatusSubject.next(data);
            },
            (err) => {}
        );
        return this.serverStatus$;
    }

    public goOffline() {
        this.logging.logDebug('ServerStatusService', 'goOffline()');
        this.apiServerStatusService
            .goOffline()
            .toPromise()
            .then((_) => {
                this.getStatus();
                setTimeout(() => {
                    this.logging.logDebug('timedout', 'getStatus');
                    this.getStatus();
                }, 5*1000);
            });
    }

    public goOnline() {
        this.logging.logDebug('ServerStatusService', 'goOnline()');
        this.apiServerStatusService
            .goOnline()
            .toPromise()
            .then((_) => {
                this.getStatus();
                setTimeout(() => {
                    this.logging.logDebug('timedout', 'getStatus');
                    this.getStatus();
                }, 5*1000);
            });
    }
}
