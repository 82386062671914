<div id="download-area-popup">
    <div class="download-area-settings">
        <label i18n="@@map.popup.downloadArea.label">Select area to download</label>
        <div style="padding-top: 15px;">
            <span i18n="@@map.popup.downloadArea.area">Area: <span id="area-size">{{calcArea| number}}</span>&nbsp;<span id="area-size-unit">{{unit}}</span><sup>2</sup></span>
        </div>
        <mat-slider
                    id="select-download-area-slider"
                    [min]="1000"
                    [max]="3500"
                    [step]="100"

                    [(ngModel)]="selectedArea"
                    (change)="selectArea($event)"></mat-slider>

    </div>
    <div class="button-line">
        <button id="cancel-download" mat-button (click)="cancel()" color="white">
            <mat-icon>cancel</mat-icon>
            <span i18n="@@cancel">Cancel</span>
        </button>

        <button id="start-download" mat-button (click)="download()" color="primary" [disabled]="!selectedArea">
            <mat-icon>download_for_offline</mat-icon>
            <span i18n="@@download">Download</span>
        </button>
    </div>
</div>
