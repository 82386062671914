import { FrontendConfig } from '../shared/interfaces/frontend.config';

export interface ApiEndpointInterface {
    gridpilotConfig: string;
    mapInfo: string;
    markers: string;
    authMethods: string;
    basicAuth: string;
    oAuth: string;
    sessionAuth: string;
    statusAuth: string;
    logout: string;
    serverstatus: string;
    schemas: string;
    stateValue: string;
    gridColor: string;
    measurement: string;
    complexMeasurement: string;
    checkOperation: string;
    executeOperation: string;
    ident: string;
    search: string;
    searchStation: string;
    actionQueueElements: string;
    actionQueueRun: string;
    actionQueueStop: string;
    actionQueueStatus: string;
    actionQueueCount: string;
    mapdownload: string;
    serverStatus: string;
    complexMeasurementService?: string;
}
export interface AppConfigInterface {
    Api: ApiEndpointInterface;
    activeApi: string[];
    availableApi: {
        default: string[];
        dewa: string[];
    };
    sessionId: string;
    frontend?: FrontendConfig;
}

const Api: ApiEndpointInterface = {
    authMethods: '/auth/methods',
    basicAuth: '/auth/basic',
    oAuth: '/auth/oauth',
    sessionAuth: '/auth/session',
    statusAuth: '/auth/status',
    logout: '/auth/logout',
    serverstatus: '/serverstatus',
    mapInfo: '/mapinfo',
    gridpilotConfig: '/gridpilot/gridpilotconfig',
    markers: '/gridpilot/marker',
    schemas: '/gridpilot/schema',
    stateValue: '/gridpilot/statevalue',
    gridColor: '/gridpilot/gridcolor',
    measurement: '/gridpilot/measurement',
    complexMeasurement: '/gridpilot/complexmeasurement',
    checkOperation: '/gridpilot/checkoperation',
    executeOperation: '/gridpilot/executeoperation',
    ident: '/gridpilot/ident',
    search: '/gridpilot/search',
    searchStation: '/gridpilot/searchstation',
    actionQueueElements: '/actionqueue/elements',
    actionQueueRun: '/actionqueue/stop',
    actionQueueStop: '/actionqueue/stop',
    actionQueueStatus: '/actionqueue/status',
    actionQueueCount: '/actionqueue/count',
    mapdownload: '/mapdownload',
    serverStatus: '/serverstatus',
};

const Config: AppConfigInterface = {
    Api,
    activeApi: [],
    availableApi: {
        default: Object.values(Api),
        dewa: [
            Api.authMethods,
            Api.basicAuth,
            Api.statusAuth,
            Api.logout,
            Api.mapInfo,
            Api.ident,
            Api.search
        ]
    },
    sessionId: '',
    frontend: null
};

Config.activeApi = Config.availableApi.default;

export default Config;
