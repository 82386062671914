/* eslint-disable @typescript-eslint/dot-notation */
import {Pipe, PipeTransform} from '@angular/core';
import Feature from 'ol/Feature';
import {DomSanitizer} from '@angular/platform-browser';
import { Geometry } from 'ol/geom';

@Pipe({
    name: 'searchResultHighlightText',
    pure: true
})
export class SearchResultHighlightTextPipe implements PipeTransform {
    constructor(private sanitize: DomSanitizer) {
    }

    transform(f: Feature<Geometry>, searches: string [] = []): unknown {
        let text: any = Object.values<string>(f['values_']['properties']).join(', ');

        if (searches) {
            for(const search of searches) {
                if(search) {
                    const start = text.toLowerCase().indexOf(search.toLowerCase());

                    if (start !== -1) {
                        const end = search.length;

                        const result = text.substr(start, end);
                        const firstPart = text.substr(0, start);
                        const lastPart = text.substr(start + end);

                        const highlightedText = firstPart + `<span class="highlight">${result}</span>` + lastPart;
                        text = highlightedText;
                    }
                }
            }
        }
        text = this.sanitize.bypassSecurityTrustHtml(text);
        return text;
    }
}
