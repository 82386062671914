
export interface ApiOutcome {
    outcome: ApiOutcomeValue;
}

export enum ApiOutcomeValue {
    UnknownValue = 'UnknownValue',
    AccessDenied = 'AccessDenied',
    Success = 'Success'
}
