import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { ApiConfigMarker } from '../../shared/interfaces/api-marker';
import { MapService } from '../services/map.service';
import { MarkerService } from '../services/marker.service';

@Component({
    selector: 'app-marker-menu',
    templateUrl: './marker-menu.component.html',
    styleUrls: ['./marker-menu.component.scss']
})
export class MarkerMenuComponent implements OnInit, OnDestroy {

    @Input()
    set activeMarker(value: ApiConfigMarker) {
        this._activeMarker = value;
        if (this._activeMarker) {
            this.setBackgroundStyle();
        }
    }

    get activeMarker() {
        return this._activeMarker;
    }

    @Output()
    public addMarker = new EventEmitter<ApiConfigMarker>();

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    public menuOpen = false;

    public _activeMarker: ApiConfigMarker;
    public activeMarkerBackgroundStyle;

    private subscriptions = new Subscription();

    constructor(public markerService: MarkerService, private mapService: MapService) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public toggleMenu() {
        this.trigger.toggleMenu();
        this.menuOpen = !this.menuOpen;
    }

    public setBackgroundStyle() {
        this.activeMarkerBackgroundStyle = this.mapService.getMarkerBackgroundStyle(this._activeMarker);
    }

    public startAddMarkerMode(marker) {
        this.addMarker.emit(marker);
    }

}
