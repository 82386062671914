import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from '../shared/modules/material.module';
import { SharedModule } from '../shared/shared.module';
import { ActionHistoryComponent } from './action-history/action-history.component';
import { ActionQueueElementComponent } from './action-queue/action-queue-element/action-queue-element.component';
import { ActionQueueComponent } from './action-queue/action-queue.component';
import { customConfigServiceProvider } from './custom/custom.config.provider';
import { SchemaIdentDetailsComponent } from './custom/default/schema-ident-details/schema-ident-details.component';
import { DewaIdentGrafanaComponent } from './custom/dewa/dewa-ident-grafana/dewa-ident-grafana.component';
import { LoadComponentDirective } from './custom/loadComponent.directive';
import { GeojsonUploadComponent } from './geojson-upload/geojson-upload.component';
import { LayerMenuItemComponent } from './layers-menu/layer-menu-item/layer-menu-item.component';
import { LayersMenuComponent } from './layers-menu/layers-menu.component';
import { SortLayersPipe } from './layers-menu/sort-layers.pipe';
import { MainMapComponent } from './main-map/main-map.component';
import { AddMarkerPopupComponent } from './map-popup/add-marker-popup/add-marker-popup.component';
import { DownloadAreaComponent } from './map-popup/download-area/download-area.component';
import { IdentDetailsComponent } from './map-popup/ident-popup/ident-details/ident-details.component';
import { IdentPopupComponent } from './map-popup/ident-popup/ident-popup.component';
import { MapPopupComponent } from './map-popup/map-popup.component';
import { MapsMenu2Component } from './maps-menu2/maps-menu2.component';
import { MarkerMenuItemComponent } from './marker-menu/marker-menu-item/marker-menu-item.component';
import { MarkerMenuComponent } from './marker-menu/marker-menu.component';
import { OverlaySideMenuComponent } from './overlay-side-menu/overlay-side-menu.component';
import { ComplexMeasurementPipe } from './pipes/complex-measurement.pipe';
import { FeaturePropertyFilterPipe } from './pipes/feature-property-filter.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FloatPipe } from './pipes/float.pipe';
import { HighlightTextPipe } from './pipes/highlight-text.pipe';
import { IfNumberPipe } from './pipes/if-number.pipe';
import { MarkerBackgroundStylePipe } from './pipes/marker-background-style.pipe';
import { PopupPanelTitlePipe } from './pipes/popup-panel-title.pipe';
import { SearchResultHighlightTextPipe } from './pipes/search-result-highlight-text.pipe';
import { SearchResultIconPipe } from './pipes/search-result-icon.pipe';
import { TranslateMarkerPipe } from './pipes/translate-marker.pipe';
import { TranslateStateValuePipe } from './pipes/translate-state-value.pipe';
import { TranslateUnitPipe } from './pipes/translate-unit.pipe';
import { UnwrapVivavisIdPipe } from './pipes/unwrap-vivavis-id.pipe';
import {
    CheckOperationComponent,
    CheckOperationDialogComponent,
} from './schema-menu/check-operation/check-operation.component';
import { MarkerIconDirective } from './schema-menu/marker-icon.directive';
import { NumericValueComponent } from './schema-menu/numeric-value/numeric-value.component';
import { SchemaMenuComponent } from './schema-menu/schema-menu.component';
import { SearchComponent } from './search/search.component';
import { MapService } from './services/map.service';
import { MarkerService } from './services/marker.service';
import { ProfilePictureFallbackDirective } from './user-account/profile-picture-fallback.directive';
import { UserAccountComponent } from './user-account/user-account.component';
import { SchemaModule } from 'src/app/shared/_ObisSchema/schema.module';
import { SchemaObisMenuComponent } from './schema-obis-menu/schema-obis-menu.component';



@NgModule({
    declarations: [
        MainMapComponent,
        ActionQueueComponent,
        ActionHistoryComponent,
        ActionQueueElementComponent,
        DewaIdentGrafanaComponent,
        MapPopupComponent,
        IdentPopupComponent,
        IdentDetailsComponent,
        SchemaIdentDetailsComponent,
        DownloadAreaComponent,
        AddMarkerPopupComponent,
        LayersMenuComponent,
        FeaturePropertyFilterPipe,
        SchemaMenuComponent,
        LayerMenuItemComponent,
        MarkerMenuComponent,
        MarkerMenuItemComponent,
        SortLayersPipe,
        TranslateMarkerPipe,
        SearchComponent,
        MapsMenu2Component,
        NumericValueComponent,
        HighlightTextPipe,
        GeojsonUploadComponent,
        UserAccountComponent,
        OverlaySideMenuComponent,
        CheckOperationComponent,
        CheckOperationDialogComponent,
        MarkerIconDirective,
        LoadComponentDirective,
        SearchResultIconPipe,
        SearchResultHighlightTextPipe,
        TranslateUnitPipe,
        MarkerBackgroundStylePipe,
        PopupPanelTitlePipe,
        TranslateStateValuePipe,
        ComplexMeasurementPipe,
        FloatPipe,
        FilterPipe,
        IfNumberPipe,
        UnwrapVivavisIdPipe,
        ProfilePictureFallbackDirective,
        SchemaObisMenuComponent
    ],
    exports: [
        MainMapComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        MaterialModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        FormsModule,
        SharedModule,
        SchemaModule
    ],
    bootstrap: [],
    providers: [
        MapService,
        MarkerService,
        DecimalPipe,
        customConfigServiceProvider
    ]
})
export class MapsModule {
}
