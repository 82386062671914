import { Pipe, PipeTransform } from '@angular/core';
import { MarkerType } from 'src/app/shared/interfaces/api-marker';

@Pipe({
    name: 'translateMarker',
    pure: true,
})
export class TranslateMarkerPipe implements PipeTransform {
    transform(value: MarkerType, args?: any): any {
        let tl;
        switch (value) {
            case MarkerType.Grounded:
                tl = $localize`:@@marker.name.Grounded:Ground`;
                break;
            case MarkerType.TempDisconn:
                tl = $localize`:@@marker.name.TempDisconn:Disconnected`;
                break;
            case MarkerType.TempLoad:
                tl = $localize`:@@marker.name.TempLoad:Consumer`;
                break;
            case MarkerType.TempSupply:
                tl = $localize`:@@marker.name.TempSupply:Generator`;
                break;
        }
        return tl;
    }
}
