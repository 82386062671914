import { ProjectionsService } from './../../shared/services/projections.service';
import { DownloadAreaConfig } from './download-area/download-area.component';
import {
    Component, EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiIdent } from 'src/app/shared/interfaces/api-ident';
import {ApiConfigMarker, MarkerType} from 'src/app/shared/interfaces/api-marker';
import { MarkerService } from '../services/marker.service';
import { ApiMeasurementService } from './../../shared/api-services/api-measurement.service';
import { ApiMeasurement } from './../../shared/interfaces/api-measurement';
import { I18nService } from './../../shared/services/i18n.service';
import { PopupState } from './popupstate.enum';
import { transform } from 'ol/proj';
import { ApiOutcomeValue } from 'src/app/shared/interfaces/api-outcome';
import { ConsoleLoggingService } from '../../shared/services/console-logging.service';

interface MarkerInterface {
    vivavisId: string;
    markerType: MarkerType;
    markerConfig: ApiConfigMarker;
    activeMarker?: ApiConfigMarker;
}
export interface MapPopupConfig {
    popupState: PopupState;
    identRequests?: Observable<ApiIdent[]>[];
    activeMarker?: ApiConfigMarker;
    vivavisId?: string;
    feature?: Feature<Geometry>;
    coordinates?: {
        x: number;
        y: number;
    };
    markersInIdent?: MarkerInterface[];
}

export enum PopupViewState {
    Processing = 'processing',
    Success = 'success',
    Delete = 'delete',
    Ident = 'ident',
    AddMarker = 'addMarker',
    DownloadArea = 'downloadArea',
    NoData = 'nodata',
    Null = 'null',
    Error = 'error'
}

@Component({
    selector: 'app-map-popup',
    templateUrl: './map-popup.component.html',
    styleUrls: ['./map-popup.component.scss'],
})
export class MapPopupComponent implements OnInit, OnDestroy {

    @Output()
    public markerRemoved = new EventEmitter();
    @Output()
    public markerAdded = new EventEmitter();

    @Output()
    public closePopup = new EventEmitter();

    @Output()
    public updatePosition = new EventEmitter();

    @Output()
    public downloadArea = new EventEmitter<DownloadAreaConfig>();

    @Output()
    public cancelDownloadArea = new EventEmitter();

    @Output()
    public identHasEntries = new EventEmitter<boolean | {x: number;y: number}>();

    public identData: ApiIdent[];
    public downloadAreaIdent: ApiIdent;
    public isMultiple = false;

    public popupStates = PopupState;
    public viewStates = PopupViewState;
    public currentViewState = PopupViewState.Ident;
    public currentPopupState = PopupState.Properties;

    public errorMessage = '';

    public measurements: ApiMeasurement[];
    public langId = 'de';
    private _config: MapPopupConfig;

    @Input()
    set config(cfg: MapPopupConfig) {
        if (cfg) {
            this._config = cfg;
            this.currentPopupState = this._config.popupState;

            switch (this.currentPopupState) {
                case PopupState.Delete:
                    this.currentViewState = PopupViewState.Delete;
                    break;
                case PopupState.AddMarker:
                case PopupState.Properties:
                    this.requestData(this._config.identRequests).then((success: boolean) => (success) ? this.identHasEntries.emit(cfg.coordinates) : this.identHasEntries.emit(success)) ;
                    break;
                case PopupState.DownloadArea:
                    this.currentViewState = PopupViewState.DownloadArea;
                    break;
                case PopupState.NoPopup:
                    this.currentViewState = PopupViewState.Null;
                    break;
            }
        } else {
            this.currentViewState = PopupViewState.Null;
        }
    }

    get config() {
        return this._config;
    }

    private identSubscription: Subscription;

    constructor(
        private apiMeasurementService: ApiMeasurementService,
        private i18n: I18nService,
        private projectionsService: ProjectionsService,
        private markerService: MarkerService,
        private logging: ConsoleLoggingService
    ) {
        this.langId = this.i18n.currentLocaleId;
    }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        this.close();
    }

    public emitShowDownloadArea(ident: ApiIdent) {
        this.downloadAreaIdent = ident;
        setTimeout(() => {
            // this.currentViewState = PopupViewState.DownloadArea;
            const x = this.downloadAreaIdent.coordinates.x;
            const y = this.downloadAreaIdent.coordinates.y;
            // FIXME
            // TODO: Projection Bug: https://vivavis-jira.vivavis.int/browse/GEOVIS-638
            const transformed = transform([x, y], this.projectionsService.epsg25832Projection, this.projectionsService.epsg3857Projection);
            this.config = {
                popupState: PopupState.DownloadArea,
                coordinates: {
                    x: transformed[0],
                    y: transformed[1],
                }
            };
        });
    }

    public emitDownloadArea(event) {
        this.downloadArea.emit(event);
    }

    public selectedIdent(ident: ApiIdent) {
        this.processing();
        const vivavisId = this.extractVivavisId(ident.meta);

        this.markerService
            .addMarkerToIdent(this._config.activeMarker, vivavisId)
            .pipe(
                tap((data) => {

                    if (data?.response?.outcome === ApiOutcomeValue.Success) {
                        this.success();

                        const expectedMarker = {
                            vivavisId,
                            markerType: this._config.activeMarker.markerType
                        };
                        this.markerAdded.emit(expectedMarker);

                    } else {

                        // let errorMessage = $localize`:@@schema.messages.addMarker.error:Error: Marker could not be added`;

                        // if (data.response.outcome === ApiOutcomeValue.UnknownValue) {
                        //     errorMessage = $localize`:schema.messages.checkOperation.error.unknownValue: Error: UnknownValue`;
                        // }
                        const errorMessage = 'Error: ' + data.response.outcome;
                        this.error(errorMessage);
                    }
                }),
                catchError(err => {
                    this.logging.log('Error: ', err);
                    this.error($localize`:@@map.popup.marker.selectIdent.error:Error adding marker`);
                    return of(err);
                })
            )
            .subscribe();
    }

    public deleteMarker(_markerConfig: MarkerInterface) {

        this.processing();
        const activeMarker = (_markerConfig) ? _markerConfig.markerConfig : this._config.activeMarker;
        const vivavisId = (_markerConfig) ? _markerConfig.vivavisId : this._config.vivavisId;

        // console.log('delete Herer',_markerConfig, 'bla');
        this.markerService
            .removeMarkerFromIdent(activeMarker, vivavisId)
            .pipe(
                tap(() => {
                    this.success();
                    setTimeout(() => {
                        this.markerRemoved.emit();
                    }, 500);
                }),
                catchError(err => {
                    this.logging.log('Error: ', err);
                    this.error($localize`:@@map.popup.marker.delete.error:Error removing Marker`);
                    return of(err);
                })
            )
            .subscribe();

    }

    public close() {
        if (this.identSubscription) {
            this.identSubscription.unsubscribe();
        }

        if (this.currentViewState === PopupViewState.DownloadArea) {
            this.cancelDownloadArea.emit();
        }

        this.closePopup.emit();
    }

    public wheel(event) {
        event.stopPropagation();
    }

    private error(message?: string) {
        this.errorMessage = message ? message : $localize`:@@map.popup.general.error:Error`;
        this.currentViewState = PopupViewState.Error;
    }

    private processing() {
        this.currentViewState = PopupViewState.Processing;
    }

    private success() {
        this.currentViewState = PopupViewState.Success;
    }

    private determinViewState() {
        setTimeout(() => {
            switch (this.currentPopupState) {
                case PopupState.Delete:
                    this.currentViewState = PopupViewState.Delete;
                    break;
                case PopupState.AddMarker:
                    this.currentViewState = PopupViewState.AddMarker;
                    break;
                case PopupState.Properties:
                    this.currentViewState = PopupViewState.Ident;
                    break;
            }
        }, 15);
    }

    private extractVivavisId(meta) {
        const k = Object.keys(meta).find(
            (x) => x.toLowerCase().indexOf('vivavisid') !== -1
        );
        const vivavisId = meta[k];
        return vivavisId;
    }

    private async requestData(requests: Observable<ApiIdent[]>[]) {
        return new Promise( (resolve) => {
            this.identData = null;
            this.processing();

            this.identSubscription = forkJoin(requests).subscribe({
                next: async (responses: ApiIdent[][]) => {
                    let idents: ApiIdent[] = [];

                    for (const res of responses) {
                        idents = [...idents, ...res];
                    }
                    idents = this.removeDoubleNames(idents);

                    this.isMultiple = idents.length > 1;

                    const vivavisIds = idents
                        .map(data => this.extractVivavisId(data.meta))
                        .filter(x => x);

                    if (this.currentPopupState === PopupState.Properties) {
                        this.complexMeasurements(vivavisIds);
                    }

                    if (idents.length === 0) {
                        this.close();
                        // this.currentViewState = PopupViewState.NoData;
                        // setTimeout( () => {
                        //     this.close();
                        // }, 800);
                        resolve(false);
                    } else {
                        this.identData = idents;
                        this.determinViewState();
                        resolve(true);
                    }
                    if(this.config.markersInIdent){
                        if(this.config.markersInIdent && Array.isArray(this.identData)) {
                            this.identData = this.identData.map((ident) => {
                                const markers = this.config.markersInIdent.filter( (marker) => marker.vivavisId === ident.meta.VivavisId );
                                return  {...ident, markers};
                            });
                        }
                    }
                },
                error: (error) => {
                    this.error($localize`:@@map.popup.request.error:Request error`);
                    console.error('Error on requesting identData');
                    console.error(error);
                    resolve(false);
                }
            });
        });
    }

    private removeDoubleNames(idents: ApiIdent[]){
        const newIdents = idents.filter((value, index, self) =>
            ( value.data['SAP number'] ) ? index === self.findIndex((t) => (
                (t.data['SAP number'] === value.data['SAP number'])
                )) : true
        );
        return newIdents;
    }

    private complexMeasurements(vivavisIds: string[]) {
        if (vivavisIds.length > 0) {
            this.apiMeasurementService
                .getComplexMeasurement(vivavisIds)
                .pipe(
                    tap((data) => {
                        this.measurements = data;
                    }),
                    catchError((err) => {
                        this.logging.log('Error fetching Complex Measurements');
                        return of([]);
                    })
                )
                .subscribe();
        }
    }
}
