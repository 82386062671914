<div class="action-card" [ngClass]="{'focus': element.id === currentUnlockingElement?.id}">
    <div class="action-status-indicator {{element.state}}"></div>
    <div class="action-content-wrapper">
        <div class="action-icon">
            <div *ngIf="isStateChange" class="action-state-change">
                <div class="new-state {{oldState}}"></div>
                <mat-icon style="transform: scale(2);">arrow_right_alt</mat-icon>
                <div class="new-state {{newState}}"></div>
            </div>
            <div *ngIf="markerAction" [appMarkerIcon]="element.subaction" class="marker-icon-wrapper"></div>
        </div>
        <div class="action-content">
            <div class="action-title">
                <span>{{element.name}}</span>
            </div>

            <div *ngIf="isStateChange" class="action-state-change">
                <span class="state {{oldState}}">{{oldState | translateStateValue}}</span>
                <mat-icon>arrow_right_alt</mat-icon>
                <span class="state {{newState}}">{{newState | translateStateValue}}</span>
            </div>

            <div *ngIf="markerAction">
                <span *ngIf="element.action === actions.DeleteMarker" i18n="@@actionQueue.element.marker.delete">Delete marker</span>
                <span *ngIf="element.action === actions.SetMarker" i18n="@@actionQueue.element.marker.set">Set marker</span>
            </div>

            <div class="action-date">
                <span *ngIf="element.isError" i18n="@@actionQueue.element.sync.error">Sync error</span>
                <button mat-raised-button color="accent" *ngIf="element.isError" (click)="showError = !showError" i18n="@@actionQueue.element.sync.error.button">Show Error</button>
            </div>
            <div class="action-date">
                <span *ngIf="element.dequeued" i18n="@@actionQueue.element.sync.executed">Executed: {{element.dequeued | date:'short':langId}}</span>
                <span *ngIf="!element.dequeued && !element.isError" i18n="@@actionQueue.element.sync.pending">Pending</span>
                <span i18n="@@actionQueue.element.created">Created: {{element.enqueued | date:'short':langId}}</span>
            </div>

            <div class="action-error" *ngIf="element.isError && showError">
                <pre>{{element.errorMessage}}</pre>
            </div>
        </div>
    </div>

</div>
<mat-divider></mat-divider>
