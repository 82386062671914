<div style="padding-top: 25px" *ngIf="data?.meta?.SchemabildNummer">
    <button id="open-schema-button" class="schema-button" mat-button (click)="openSchema()" color="primary" i18n="@@openSchemaMenu">Open Schema</button>

    <button id="download-area-button" class="schema-button" *ngIf="isMobile" mat-button (click)="openDownloadArea()" color="accent">
        <mat-icon>download_for_offline</mat-icon>
        <span i18n="@@downloadArea">Download Area</span>
    </button>
</div>


<div style="padding-top: 25px" *ngIf="data?.data?.Messlokation">
    <button id="open-obis-schema-button" class="schema-button" mat-button (click)="openObisSchema()" color="primary" i18n="@@openObisMeasurements">Open Measurements</button>
</div>
