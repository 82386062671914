import { I18nService } from './../../../shared/services/i18n.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Config from 'src/app/config/Config';
import { SchemaService } from '../../../shared/services/schema.service';
import { ApiMeasurement, ApiMeasurementElement } from './../../../shared/interfaces/api-measurement';
import { NumericValueOverlayLabel } from './numeric-value-overlay-label';

type NumericValueBackgroundClass = 'normal' | 'increased' | 'decreased';
@Component({
    selector: 'app-numeric-value',
    templateUrl: './numeric-value.component.html',
    styleUrls: ['./numeric-value.component.scss'],
})
export class NumericValueComponent implements OnInit, OnDestroy {

    @Input()
    historyEnabled = false;

    @Input()
    addMarkerMode = false;

    @Input()
    vivavisId: string;

    @Input()
    objId: string;

    @Input()
    set config(cfg: NumericValueOverlayLabel) {
        this._nvConfig = cfg;
        this.vivavisId = cfg.vivavisId;
        this.objId = cfg.objId;
        this._selected = cfg.selected;
    }

    get config() {
        return this._nvConfig;
    }

    // @Input()
    // set selected2(value: boolean) {
    //     this._selected = value;
    //     // const selectedCss = ' selected';
    //     // if(value) {
    //     //     this.backgroundColorClass += selectedCss;
    //     // }else {
    //     //     this.backgroundColorClass = this.backgroundColorClass.substr(this.backgroundColorClass.indexOf(selectedCss), selectedCss)
    //     // }
    // }

    // get selected2() {
    //     return this._selected;
    // }

    @Output()
    clicked = new EventEmitter<ApiMeasurementElement>();

    defaultValue = 123.56;

    numericValue$: Observable<ApiMeasurementElement>;

    apiMeasurement: ApiMeasurementElement;

    unselectable = false;

    pastValues = [];

    langId = 'de';

    backgroundColorClass: NumericValueBackgroundClass = 'normal';

    cssClass = {
        normal: true,
        selected: false,
        increased: false,
        decreased: false
    };

    private _selected = false;
    private _nvConfig: NumericValueOverlayLabel;

    constructor(private schemasService: SchemaService, private i18n: I18nService) {
        this.langId = this.i18n.currentLocaleId;
    }

    ngOnInit() {
        this.numericValue$ = this.schemasService
            .getNumericValueFor(this.vivavisId)
            .pipe(tap((value) => this.setApiMeasurement(value)));
    }

    ngOnDestroy() {}

    chipClick(event) {
        if(this.historyEnabled) {
            this.clicked.emit(this.apiMeasurement);
        }
    }

    private setApiMeasurement(value: ApiMeasurementElement) {
        this.apiMeasurement = value;
    }

    // private isSelectable(value: ApiMeasurementElement) {

    // }

    // private addPastValue(value) {
    //     if (this.pastValues.length > 2) {
    //         this.pastValues.shift();
    //     }
    //     this.pastValues.push(value);
    //     // this.trackChange();
    // }

    // private trackChange() {
    //     if (this.pastValues.length > 1) {
    //         const lastValueIndex = this.pastValues.length - 1;
    //         const prevValueIndex = this.pastValues.length - 2;
    //         const lastValue = this.pastValues[lastValueIndex];
    //         const prevValue = this.pastValues[prevValueIndex];

    //         if (lastValue > prevValue) {
    //             this.backgroundColorClass = 'increased';
    //             console.log('- increased');
    //         } else if (lastValue < prevValue) {
    //             this.backgroundColorClass = 'decreased';
    //             console.log('- decreased');
    //         }
    //     }
    // }
}
