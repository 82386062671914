<app-map-popup id="map-popup"
    [config]="mapPopupConfig"
    (closePopup)="closePopup()"
    (updatePosition)="updateOverlayPosition()"
    (markerRemoved)="markerRemoved()"
    (markerAdded)="markerAdded($event)"
    (downloadArea)="downloadArea($event)"
    (cancelDownloadArea)="cancelDownloadArea()"
    (identHasEntries)="drawPopupFromIdent($event)"></app-map-popup>

<div *ngIf="addMarkerMode" class="add-marker-mode">
    <div id="top">
        <div>
            <span i18n="@@addMarkerTitle">Add Marker Mode</span>
            <button
                mat-raised-button
                id="cancel-marker-mode-button"
                color="primary"
                i18n-aria-label="@@addmarker.cancel.aria"
                aria-label="Cancel Add Marker Mode"
                (click)="cancelAddMarkerMode()"
                i18n="@@addmarker.cancel.button">Cancel</button>
        </div>
    </div>
    <div id="left"></div>
    <div id="right"></div>
    <div id="bottom"></div>
</div>

<div class="grafana-drawer" *ngIf="showTopDrawer" [@transformDrawer]="showTopDrawer ? 'open' : 'void'">
    <iframe style="height: 100%" [src]="topDrawerUrl" #grafanaIFrame (load)="iFrameOnload($event)" ></iframe>
<!--    <object style="height: 100%" [data]="topDrawerUrl" #grafanaIFrame (load)="iFrameOnload($event)" ></object>-->
    <div class="buttons">
        <button mat-mini-fab (click)="showTopDrawer = false" id="close-grafana-drawer">
            <mat-icon class="mat-18">close</mat-icon>
        </button>
    </div>
</div>

<div class="schema-drawer" *ngIf="showBottomDrawer" [@transformDrawer]="showBottomDrawer ? 'open' : 'void'"
    [ngClass]="(measuringSpot) ? 'schema-drawer-obis': ''">

    <app-schema-menu *ngIf="schemaPicNo"
        [picno]="schemaPicNo"
        [schemaStyle]="schemaStyle"
        [feature]="selectedFeature"
        [opened]="showBottomDrawer"
        [activeMarker]="activeMarker"
        (cancelAddMarkerMode)="cancelAddMarkerMode()"
        [isGrafanaOpen]="showTopDrawer"
        (closeMenu)="closeBottomDrawer()"
        (openGrafanaHistory)="openGrafanaHistory($event)">
    </app-schema-menu>

    <app-schema-obis-menu *ngIf="measuringSpot"
          [title]="measuringSpotTitle"
          [apiUrl]="getObisSchemaApiUrl()"
          [config]="getObisSchemaConfig()"
          [measuringSpotId]="measuringSpot"
          [isGrafanaOpen]="showTopDrawer"
          (closeMenu)="closeBottomDrawer()"
          (openGrafanaHistory)="openGrafanaHistorybyBoardId($event)"
    ></app-schema-obis-menu>

</div>

<!-- <div id="centerPixelMap" style="position: absolute; background-color: red; width: 1px; height: 10px; left: 50%; top:1px; z-index: 10;"></div> -->

<div class="map-container">
    <div #mapContainer class="map" [style.cursor]="cursorStyle" id="map"></div>

    <app-overlay-side-menu>

        <div class="overlay-buttons-left-column">

            <div id="locate-me" class="grid-area-a" [ngClass]="{'add-marker-mode': addMarkerMode}">
                <button mat-mini-fab color="white" (click)="getGeoLocation()">
                    <mat-icon>near_me</mat-icon>
                </button>
            </div>

            <div id="search" class="grid-area-a" [ngClass]="{'add-marker-mode': addMarkerMode}">
                <app-search (selectedResult)="searchResult($event)"></app-search>
            </div>

            <div id="upload-geojson" *ngIf="enableUploadGeoJson" class="grid-area-a"
                [ngClass]="{'add-marker-mode': addMarkerMode}">
                <app-geojson-upload
                    (uploadedUrl)="uploadGeoJson($event)"
                    (removeUpload)="removeUploadGeoJson($event)"
                    (toggleUpload)="temporaryDataLayer.setVisible(!temporaryDataLayer.getVisible())"
                    [layerVisible]="temporaryDataLayer.getVisible()"></app-geojson-upload>
            </div>

            <div id="grafana-dashboard-toggle-wrapper" *ngIf="enableGrafana" class="grid-area-a"
                [ngClass]="{'add-marker-mode': addMarkerMode}">
                <button id="grafana-toggle-button" mat-mini-fab color="white" aria-label="Grafana Dashboard"
                    (click)="openGrafanaDrawer(true)">
                    <mat-icon>dashboard</mat-icon>
                </button>
            </div>

            <!-- Disables Info Button -->
            <div id="info-toggle-wrapper" *ngIf="enableInfoButton" class="grid-area-a"
                [ngClass]="{'add-marker-mode': addMarkerMode}">
                <button id="info-toggle-button" mat-mini-fab color="white" aria-label="Grafana Dashboard"
                    (click)="openInfoUrl(true)">
                    <mat-icon>info</mat-icon>
                </button>
            </div>

            <div id="maps-menu-side" [ngClass]="{'add-marker-mode': addMarkerMode}">
                <app-maps-menu2
                [bgLayers]="backgroundLayers3"
                (selectedBgLayer)="changeBackgroundLayer3($event)"
                ></app-maps-menu2>
            </div>

            <!-- Disables HotSpot/Marker Trigger
            <div *ngIf="enableMarkerMenu">
                <button mat-mini-fab [color]="markerLayerGroup?.getVisible() ? 'white' : 'primary'"
                    (click)="toggleMarkerLayers()">
                    <mat-icon>location_off</mat-icon> -->
                    <!-- <mat-icon *ngIf="!markerLayerGroup?.getVisible()">location_on</mat-icon> -->
            <!--   </button>
            </div>
            -->
            <div *ngIf="isMobile" [ngClass]="{'add-marker-mode': addMarkerMode}">
                <button id="download-area-button" mat-mini-fab color="white" (click)="initDownloadAreaPopup()">
                    <mat-icon>download_for_offline</mat-icon>
                </button>
            </div>

            <div *ngIf="showAirplaneMode" id="toggleOnOffLine" [ngClass]="{'add-marker-mode': addMarkerMode}">
                <button mat-mini-fab color="white" (click)="toggleOnOfflineStatus()">
                    <mat-icon *ngIf="isOffline">airplanemode_inactive</mat-icon>
                    <mat-icon *ngIf="!isOffline">flight</mat-icon>
                </button>
            </div>

        </div>

        <div class="overlay-buttons-right-column">
            <div id="connection-status" class="grid-area-b" [ngClass]="{'add-marker-mode': addMarkerMode}">
                <app-connection-status></app-connection-status>
            </div>
        </div>
        <!-- <div class="overlay-buttons-right-column">
            <div>
                <app-action-history></app-action-history>
            </div>
        </div> -->
    </app-overlay-side-menu>

    <div id="overviewmap-button">
        <button mat-mini-fab color="white" (click)="toggleOverViewMap()">
            <mat-icon *ngIf="overviewMapControlCollapsed">keyboard_double_arrow_left</mat-icon>
            <mat-icon *ngIf="!overviewMapControlCollapsed">keyboard_double_arrow_right</mat-icon>
        </button>
        <div id="traget-overview-map"></div>
    </div>

    <div id="user-account" [ngClass]="{'add-marker-mode': addMarkerMode}">
        <app-user-account></app-user-account>
    </div>

    <div id="marker-menu" *ngIf="enableMarkerMenu"
        [ngClass]="{'add-marker-mode': addMarkerMode, 'drawer-opened': showBottomDrawer}">
        <app-marker-menu [activeMarker]="activeMarker" (addMarker)="addMarker($event)">
        </app-marker-menu>
    </div>

    <div id="action-queue" *ngIf="showActionQueue">
        <app-action-queue></app-action-queue>
    </div>

    <app-layers-menu *ngIf="map" id="layer-menu" [ngClass]="{'add-marker-mode': addMarkerMode}"
        (refreshLayer)="refreshLayer($event)" [layers]="this.mapLayers" [ovLayers]="this.overviewMapLayers"></app-layers-menu>



</div>
