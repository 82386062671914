import { Component, Input, OnInit } from '@angular/core';
import { isNumber } from 'lodash';
import Config from 'src/app/config/Config';
import { MapService } from 'src/app/maps/services/map.service';
import { ApiIdent } from 'src/app/shared/interfaces/api-ident';

import { DynamicComponent } from '../../dynamic-component';
import { environment } from './../../../../../environments/environment';


@Component({
    selector: 'app-dewa-ident-grafana',
    templateUrl: './dewa-ident-grafana.component.html',
    styleUrls: ['./dewa-ident-grafana.component.scss']
})
export class DewaIdentGrafanaComponent implements OnInit, DynamicComponent<ApiIdent> {

    @Input() public data: ApiIdent;

    public hasGrafanaValueButton = false;
    public hasGrafanaHistoryButton = false;

    constructor(
        private mapService: MapService) {
    }

    ngOnInit() {
        this.setGrafanaValueButton();
        this.setGrafanaHistoryButton();
    }

    public openGrafanaDrawer() {
        const data = this.data.data['Element short name'];
        const dashboard = Config.frontend?.grafana?.url.replace('{data}', data);

        const url = dashboard.startsWith('http') || dashboard.startsWith('//') ? dashboard : environment.root + dashboard;

        this.mapService.openTopDrawer(url);
    }

    public openGrafanaHistoryDrawer() {
        const data = this.data.data['Element short name'];
        const prefix = data.substring(0, 2);
        let url = '';
        let dashboard = '';
        switch (prefix) {
            case 'FT':
                dashboard = Config.frontend?.grafana?.trendUrls.FT.replace('{data}', data);
                break;
            case 'PT':
                dashboard = Config.frontend?.grafana?.trendUrls.PT.replace('{data}', data);
                break;
            case 'MV':
                dashboard = Config.frontend?.grafana?.trendUrls.MV.replace('{data}', data);
                break;
            case 'CV':
                break;
            case 'HV':
                break;
            default:
                const [first, second] = prefix.split('');
                const secondAsNumber = Number(second);
                if (first === 'M' && isNumber(secondAsNumber)) {
                    dashboard = Config.frontend?.grafana?.trendUrls.M.replace('{data}', data);
                }
                break;

        }

        url = dashboard.startsWith('http') || dashboard.startsWith('//') ? dashboard : environment.root + dashboard;
        this.mapService.openTopDrawer(url);
    }

    private setGrafanaValueButton() {
        let has = false;

        if (this.data.data['Element short name']) {
            const data = this.data.data['Element short name'];
            const prefix = data.substring(0, 2);
            switch (prefix) {
                case 'CV':
                case 'HV':
                case 'FT':
                case 'PT':
                case 'MV':
                    has = true;
                    break;
                default:
                    const [first, second] = prefix.split('');
                    const secondAsNumber = Number(second);
                    if (first === 'M' && isNumber(secondAsNumber)) {
                        has = true;
                    }
                    break;

            }
        }

        this.hasGrafanaValueButton = has;
    }

    public setGrafanaHistoryButton() {
        let has = false;
        if (this.data.data['Element short name']) {
            const data = this.data.data['Element short name'];
            const prefix = data.substring(0, 2);
            switch (prefix) {
                case 'CV':
                case 'HV':
                    has = false;
                    break;
                case 'FT':
                case 'PT':
                case 'MV':
                    has = true;
                    break;
                default:
                    const [first, second] = prefix.split('');
                    const secondAsNumber = Number(second);
                    if (first === 'M' && isNumber(secondAsNumber)) {
                        has = true;
                    }
                    break;
            }
        }

        this.hasGrafanaHistoryButton = has;
    }

}
