<div  [hidden]="!identData || identData.length === 0" >
    <div class="map-popup-content add-marker-multi-selection" *ngIf="isMultiple">
        <h4>Add Marker - Select Target</h4>
        <mat-selection-list #props [multiple]="false" (selectionChange)="selectOption($event)">
            <ng-container *ngFor="let prop of identData">
                <mat-list-option [value]="prop">
                    {{prop.data.Name}}
                </mat-list-option>
                <mat-divider></mat-divider>
            </ng-container>
          </mat-selection-list>
    </div>
</div>
