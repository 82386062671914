<ng-container *ngIf="numericValue$ | async; let numericValue">
    <div class="numeric-value-label"(click)="chipClick($event)" *ngIf="!addMarkerMode">
        <div class="value {{backgroundColorClass}}"
        [ngClass]="{
            'selected': config.selected,
            'history': historyEnabled
        }"
        >{{numericValue.value | number:'1.0-1':langId}}</div>
        <!-- <div class="unit">{{numericValue.unit}}</div> -->
    </div>
</ng-container>
