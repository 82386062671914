export class Utilities {
    public static degreesToRadians(degrees) {
        const pi = Math.PI;
        return degrees * (pi / 180);
    }

    public static radiansToDegree(radians) {
        const pi = Math.PI;
        return (radians * 180) / pi;
    }

    public static pixelRatioFactor() {
        let pr = 1;

        if (window.devicePixelRatio > 1) {
            pr =  1 - (window.devicePixelRatio - 1);
        }else if(window.devicePixelRatio < 1) {
            pr = 1 + (1 - window.devicePixelRatio);
        }

        return pr;
    }
}
