import { Injectable, OnDestroy } from '@angular/core';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
    HighLeitMessage,
    HighLeitMessageType,
    NumericValueMessage,
    PowerValueMessage
} from '../interfaces/websocket-messages';
import { UpdateReceiverService } from './updatereceiver.service';
import { ConsoleLoggingService } from  './console-logging.service';

@Injectable({
    providedIn: 'root',
})
export class UpdateFeatureService implements OnDestroy {

    public isWebSocketConnected = false;

    public updateColors$: Observable<{type: HighLeitMessageType}>;
    public updateSwitches$: Observable<{type: HighLeitMessageType}>;
    public updateMarkers$: Observable<{type: HighLeitMessageType}>;
    public updateNumericValue$: Observable<NumericValueMessage>;

    private updateColors = new Subject<{type: HighLeitMessageType}>();
    private updateSwitches = new Subject<{type: HighLeitMessageType}>();
    private updateMarkers = new Subject<{type: HighLeitMessageType}>();
    private updateNumericValue = new Subject<NumericValueMessage>();

    private forceUpdate$ = new Subject<void>();

    private subscriptions = new Subscription();

    constructor(private updateReceiverService: UpdateReceiverService, private logging: ConsoleLoggingService) {

        this.isWebSocketConnected = this.updateReceiverService.isConnected();

        this.updateColors$ = this.updateColors.asObservable().pipe(debounceTime(100));
        this.updateMarkers$ = this.updateMarkers.asObservable().pipe(debounceTime(100));
        this.updateSwitches$ = this.updateSwitches.asObservable().pipe(debounceTime(100));

        this.updateNumericValue$ = this.updateNumericValue.asObservable();

        const sub = this.updateReceiverService.subject.subscribe((msg) =>
            this.handleMessage(msg)
        );

        const sub2 = this.forceUpdate$.asObservable().pipe(debounceTime(3000)).subscribe(() => {
            this.logging.logDebug('UpdateFeatureService', 'forceUpdate$');
            this.updateColors.next({type: HighLeitMessageType.NewColors});
            this.updateMarkers.next({type: HighLeitMessageType.NewMarkers});
            this.updateSwitches.next({type: HighLeitMessageType.NewStates});
        });

        this.subscriptions.add(sub);
        this.subscriptions.add(sub2);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


    public forceUpdate() {
        this.forceUpdate$.next();
    }

    private handleMessage(msg: HighLeitMessage) {

        switch (msg.type) {
            case HighLeitMessageType.NewMarkers:
                this.updateMarkers.next(msg);
                break;
            case HighLeitMessageType.NewColors:
                this.updateColors.next(msg);
                break;
            case HighLeitMessageType.NewStates:
                this.updateSwitches.next(msg);
                break;
            default:
                break;
        }
    }
}
