import { map, scan, startWith, tap, shareReplay } from 'rxjs/operators';
import { UpdateReceiverService } from './updatereceiver.service';
import { UpdateFeatureService } from './updatefeature.service';
import { Injectable, OnDestroy } from '@angular/core';
import {
    firstValueFrom,
    fromEvent,
    interval,
    merge,
    MonoTypeOperatorFunction,
    Observable,
    ReplaySubject,
    Subject,
    Subscription,
    switchMapTo,
    takeUntil,
    timer,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServerStatusMessage } from '../interfaces/api-serverstatus';
import { ServerStatusService } from './server-status.service';
import { ConsoleLoggingService } from  './console-logging.service';

export enum ConnectionStatusState {
    Online = 'online',
    Offline = 'offline',
}

export interface ConnectionStatus {
    currentStatus: ConnectionStatusState;
    isOnline: boolean;
    isOffline: boolean;
    webSocketConnected?: boolean;
    hasBeenOffline?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ConnectionStatusService implements OnDestroy {
    public subject = new Subject<ConnectionStatus>();
    public blockSubject = new ReplaySubject<boolean>(1);
    public blockStatus$ = this.blockSubject.asObservable();
    public connectionStatus$: Observable<ConnectionStatus>;

    private offlineEvent: Observable<Event>;
    private onlineEvent: Observable<Event>;
    private subscriptions = new Subscription();

    private state: ConnectionStatus = {
        currentStatus: ConnectionStatusState.Online,
        isOnline: true,
        isOffline: false,
        webSocketConnected: false,
        hasBeenOffline: false
    };

    constructor(
        private serverStatusService: ServerStatusService,
        private updateReceiverService: UpdateReceiverService,
        private updateFeatureService: UpdateFeatureService,
        private logging: ConsoleLoggingService) {

            this.state.webSocketConnected = this.updateFeatureService.isWebSocketConnected;

            const tempSub = merge(
                this.updateReceiverService.webSocketState$
                    .pipe(
                        map(x => ({webSocketConnected: x}))
                    ),
                this.subject.asObservable()
            );

            this.connectionStatus$ = tempSub.pipe(
                startWith(this.state),
                scan((acc: ConnectionStatus, newState) => ({...acc, ...newState})),
                shareReplay(1),
            );

            if (environment.mobile) {
                this.webAppEvents();
                this.mobileEvents();
            } else {
                this.webAppEvents();
            }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private mobileEvents() {
        this.logging.logDebug('mobileEvents');
        const sub1 = this.serverStatusService.serverStatus$
        .subscribe((status) => {
            const isOffline = status.message === ServerStatusMessage.Offline;
            this.processEvents(isOffline);
        });

        const sub2 = interval(1000 * 60).subscribe(() => {
            this.logging.logDebug('interval check ServerStatus');
            this.serverStatusService.getStatus();
        });

        this.serverStatusService.getStatus();

        this.subscriptions.add(sub1);
        this.subscriptions.add(sub2);
    }

    private webAppEvents() {
        this.logging.logDebug('webAppEvents');
        this.offlineEvent = fromEvent(window, 'offline');
        this.onlineEvent = fromEvent(window, 'online');

        const sub1 = this.offlineEvent.subscribe((event) => {
            this.processEvents(true);

            if (environment.mobile) {
                this.serverStatusService.getStatus();
            }
        });

        const sub2 = this.onlineEvent.subscribe((event) => {
            this.processEvents(false);

            if (environment.mobile) {
                this.serverStatusService.getStatus();
            }
        });

        this.subscriptions.add(sub1);
        this.subscriptions.add(sub2);
    }

    private async processEvents(isOffline: boolean = false) {
        const isOnline = !isOffline;
        let currentStatus;
        let hasBeenOffline = false;

        const lastState = await firstValueFrom(this.connectionStatus$);

        if (isOffline) {
            currentStatus = ConnectionStatusState.Offline;
        } else {
            currentStatus = ConnectionStatusState.Online;
        }

        if (isOffline && lastState.hasBeenOffline === false) {
            hasBeenOffline = true;
        }

        if (!isOffline && lastState.hasBeenOffline) {
            this.updateFeatureService.forceUpdate();
            this.updateReceiverService.init();
            hasBeenOffline = false;
        }

        this.subject.next({isOffline, isOnline, currentStatus, hasBeenOffline});
    }

    public getServerStatus(){
        return this.serverStatusService.getStatus();
    }

    private poll<T>(_interval: number): MonoTypeOperatorFunction<T> {
        return source$ => timer(0, _interval).pipe(switchMapTo(source$));
    }
}
