import {Inject, Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'any',
})
export class SchemaScrollService {

    private offsetLeft = 0;
    private offsetTop = 0;

    changeScrollPosition = new Subject();
    constructor() {

    }



    setOffset(offsetLeft: number, offsetTop: number) {
        this.offsetLeft = (offsetLeft > 0) ? offsetLeft : this.offsetLeft;
        this.offsetTop = (offsetTop > 0) ? offsetTop : this.offsetTop;
        this.triggerSubscriptions();
    }

    getOffset() {
        return {offsetLeft: this.offsetLeft, offsetTop: this.offsetTop};
    }

    private triggerSubscriptions() {
        this.changeScrollPosition.next({
            left:this.offsetLeft,
            top: this.offsetTop
        });
    }

}
