import { CustomConfigService } from '../custom.config.service';

export class DefaultCustomConfigService extends CustomConfigService {


    override popupPanelTitle(prop: any): string {
        const sapNumber = 'SAP number';
        const shortName = 'Name';
        const shotNameObj = prop.data[shortName];
        if (shotNameObj) {
            return prop.data[shortName];
        } else {
            return prop.data[sapNumber];
        }
    }
}
