import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import Config from '../../config/Config';
import { ApiStateValue } from '../interfaces/api-statevalue';
import { ApiGeneralInterface } from './../interfaces/api-general';

@Injectable({
    providedIn: 'root',
})
export class ApiStateValueService {
    constructor(
        private httpClient: HttpClient
    ) {}

    public getStateValues(ids: string[]) {
        const url = `${environment.apiRoot}${Config.Api.stateValue}`;

        let httpParams = new HttpParams();
        httpParams = httpParams.set('vivavisIds', ids.join(','));

        return this.httpClient.get<ApiGeneralInterface<ApiStateValue[]>>(url, {
            params: httpParams
        }).
        pipe(
            // catchError(err => throwError(err)),
            // tap(data => {
            //     if (data.success === false) {
            //         throwError(data);
            //     }
            // }),
            map((data) => {
                const response = data.response;
                // response.vivavisId = vivavisId;
                return response;
            })
        );
    }
}
