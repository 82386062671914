import {
    Component, EventEmitter, Input, OnDestroy, OnInit,
    Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isObject } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationStateType, StatefulAuthenticationService } from './../shared/services/authentication/stateful-authentication.service';
import { ConsoleLoggingService } from '../shared/services/console-logging.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    @Input() error: string | null;

    @Output() submitEM = new EventEmitter();

    public isMobile = environment.mobile;
    public authState$: Observable<any>;
    public isLoggingIn = false;

    form: FormGroup = new FormGroup({
        username: new FormControl(''),
        password: new FormControl(''),
    });

    private destroy$ = new Subject<void>();

    constructor(
        public statefulAuth: StatefulAuthenticationService,
        private router: Router,
        private logging: ConsoleLoggingService) {


        this.authState$ = this.statefulAuth.authState$
            .pipe(
                tap(currentState => {
                    if (currentState.error && currentState.state === AuthenticationStateType.AwaitingUserLogin) {
                        const err = currentState.error;
                        this.logging.log('Error: ', err);
                        if (err.status === 404) {
                            this.error = '404: Backend nicht erreichbar.';
                        } else if (isObject(err.error)) {
                            this.error = err.error.errorTitle + ': ' + err.error.error;
                        } else {
                            this.error = 'Allgemeiner Fehler. Administrator kontaktieren.';
                        }
                    }
                })
            );
    }

    ngOnInit() {
        this.checkLoginStatus();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    submit() {
        if (this.form.valid) {
            this.isLoggingIn = true;
            this.statefulAuth.userLogin(this.form.value.username, this.form.value.password);
        }
    }

    private checkLoginStatus() {
        this.statefulAuth
            .isLoggedIn()
            .pipe(
                tap(isLoggedIn => {
                    this.logging.logDebug('login - checkLoginStatus', isLoggedIn);
                    if (isLoggedIn) {
                        this.destroy$.next();
                        this.router.navigate(['/']);
                    }
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

}
