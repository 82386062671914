import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorMessage',
  pure: true
})
export class ErrorMessagePipe implements PipeTransform {

  transform(value: any): string {
      let message = '';
      if (value.hasOwnProperty('message')) {
          message = value.message;
      }else {
          message = value.toString();
      }
    return message;
  }

}
