import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DisplayNoConnectionService} from '../../shared/services/display-no-connection.service';

@Component({
    selector: 'app-overlay-side-menu',
    templateUrl: './overlay-side-menu.component.html',
    styleUrls: ['./overlay-side-menu.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({transform: 'translateX(-100%)'}),
                animate('200ms ease-in', style({transform: 'translateX(0%)'}))
            ]),
            transition(':leave', [
                animate('200ms ease-out', style({transform: 'translateX(-100%)'}))
            ])
        ]),
        trigger('moveButton', [
            state('open', style({
                // 'padding-left': '25px',
                // transform: 'translateX(60%)'
            })),
            state('closed', style({
                // 'padding-left': '15px',
                // transform: 'translateX(0%)'
            })),
            transition('open => closed', animate('200ms ease-in')),
            transition('closed => open', animate('200ms ease-out'))
        ])
    ]
})
export class OverlaySideMenuComponent implements OnInit {

    open = false;

    constructor(private displayNoConnection: DisplayNoConnectionService) {
        // this.open = true;
        this.displayNoConnection.displayConnectionStatus$.subscribe( {
            next: (displayConnectionStatusState: boolean): void => {
                this.open = !displayConnectionStatusState ;
            }
        });
    }

    ngOnInit(): void {
    }

}
