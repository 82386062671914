import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import * as shp from 'shpjs';
import { ConsoleLoggingService } from '../../shared/services/console-logging.service';

@Component({
    selector: 'app-geojson-upload',
    templateUrl: './geojson-upload.component.html',
    styleUrls: ['./geojson-upload.component.scss']
})
export class GeojsonUploadComponent implements OnInit {

    @Output()
    public uploadedUrl = new EventEmitter();

    @Output()
    public removeUpload = new EventEmitter();

    @Output()
    public toggleUpload = new EventEmitter();

    @Input()
    public layerVisible = true;
    public uploaded = false;

    private lastBlobUrl;

    constructor(
        private logging: ConsoleLoggingService
    ) {
    }

    ngOnInit(): void {
    }

    public remove() {
        this.removeUpload.emit();
        this.uploaded = false;
        if (this.lastBlobUrl) {
            URL.revokeObjectURL(this.lastBlobUrl);
        }
    }

    public uploadGeoJson(event: any) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            const file = event.target.files[0];

            reader.onload = this.readFile.bind(this);

            reader.readAsText(file);
        }

    }

    public uploadEsri(event: any) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            const file = event.target.files[0];
            const fileName = file.name as string;
            const isZip = fileName.indexOf('.zip') !== -1;

            if (isZip) {
                reader.onload = this.readEsri.bind(this);
                reader.readAsArrayBuffer(file);
            }
        }
    }

    private readEsri(e) {
        const buffer = e.target.result;
        shp(buffer)
            .then(data => {

                let geojson = data;

                if (Array.isArray(data)) {
                    geojson = data[0];
                }

                const jsonString = JSON.stringify(geojson);
                const blob = new Blob([jsonString], {type: 'application/json'});
                const objectURL = URL.createObjectURL(blob);

                if (objectURL) {
                    this.lastBlobUrl = objectURL;
                    this.uploadedUrl.emit(objectURL);
                    this.uploaded = true;
                }
            })
            .catch(err => {
                this.logging.log('Error: ', err);
            });
    }

    private readFile(e) {
        const geojson = e.target.result;
        const blob = new Blob([geojson], {type: 'application/json'});
        const objectURL = URL.createObjectURL(blob);

        if (objectURL) {
            this.lastBlobUrl = objectURL;
            this.uploadedUrl.emit(objectURL);
            this.uploaded = true;
        }
    }

}
