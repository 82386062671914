/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { ApiStateValueStatus } from './../interfaces/api-statevalue';
import {Circle, RegularShape, Text} from 'ol/style';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { Draw } from 'ol/interaction';

@Injectable({
    providedIn: 'root'
})
export class StyleService {

    private schemaSymbolStyles = {};

    constructor() {
        this.setupSchemaSymbolStyles();
    }

    public getSchemaSymbolStyle(symbolType: string, state: ApiStateValueStatus = 'OFF', hover = false, isOffline = false, isError = false) {


        let iconStyle: Style;
        const styles: Style[] = [];

        const hoverStr = hover ? '_highlight' : '';
        const stateIsOn = (state === 'ON');
        switch (symbolType) {
            case 'FUSE':
            case 'sicherung':
                const key = 'sicherung_' + state + hoverStr;
                iconStyle = this.schemaSymbolStyles[key];
                if (iconStyle) {
                    styles.push(iconStyle);
                }
                break;
            case 'ueberstrom':
                iconStyle = this.schemaSymbolStyles['leistungsschalter_' + state];
                if (iconStyle) {
                    styles.push(iconStyle);
                }
                break;
            case 'TRANSFORMER':
            case 'trafo':
                iconStyle = this.schemaSymbolStyles['transformer'];
                if (iconStyle) {
                    styles.push(iconStyle);
                }
                break;
            case 'strassenbeleuchtung':
                iconStyle = this.schemaSymbolStyles['trennschalter_' + state];
                if (iconStyle) {
                    styles.push(iconStyle);
                }
                break;
            case 'PV_SUPPLY':
            case 'EXCITATION_UL':
            case 'EXCITATION_ULL':
            case 'TRIP':
                iconStyle = this.schemaSymbolStyles[symbolType](stateIsOn);
                if (iconStyle) {
                    styles.push(iconStyle);
                }
                break;
            case 'RTU_WARNING':
            case 'RTU_ERROR':
            case 'DOOR_CONTACT':
            case 'UPS_WARNING':
            case 'VOLT_MISSING':
                iconStyle = this.schemaSymbolStyles[symbolType](stateIsOn);
                if (iconStyle) {
                    styles.push(iconStyle);
                }
                break;
            case 'RTU_STATE_RT_GN':
            case 'RTU_STATE_GN_RT':
            case 'RTU_STATE_GN_GR':
            case 'RTU_STATE_GR_GN':
                iconStyle = this.schemaSymbolStyles['RTU_State_Remote'](stateIsOn, symbolType);
                if (iconStyle) {
                    styles.push(iconStyle);
                }
                break;
        }

        if (isOffline) {
            styles.push(this.schemaSymbolStyles['offline_hourglass']);
        }

        if (isError) {
            styles.splice(0, styles.length);
            styles.push(this.schemaSymbolStyles['isError']);
        }

        return styles ? styles : null;
    }

    public downloadAreaStyle() {
        return new Style({
            fill: new Fill({color: 'rgba(255,255,255,0.6)'}),
            stroke: new Stroke({color: '#ff0000', width: 1.25})
        });
    }

    private setupSchemaSymbolStyles() {

        this.schemaSymbolStyles['isError'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Sicherung - fehler.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['offline_hourglass'] = new Style({
            image: new Icon({
                crossOrigin: 'anonymous',
                src: 'assets/svg/hourglass.svg',
                displacement: [20, 0]
            })
        });

        this.schemaSymbolStyles['sicherung_OFF'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Sicherung - offen.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['sicherung_ON'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Sicherung - geschlossen.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['sicherung_OFF_highlight'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Sicherung - offen_highlight.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['sicherung_ON_highlight'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Sicherung - geschlossen_highlight.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['lasttrenner_OFF'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Lasttrenner - offen.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['lasttrenner_ON'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Lasttrenner - geschlossen.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['leistungsschalter_OFF'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Leistungsschalter - offen.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['leistungsschalter_ON'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Leistungsschalter - geschlossen.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['trennschalter_OFF'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Trennschalter - offen.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['trennschalter_ON'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Trennschalter - geschlossen.svg',
                scale: 0.8
            })
        });

        this.schemaSymbolStyles['transformer'] = new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Trafo.svg',
                scale: 0.8
            })
        });
        /****
         *
         ******************************************************/

        // this.schemaSymbolStyles['PV_SUPPLY'] = (isStateOn) => {
        //     const color = 'blue';
        //     return new Style({
        //         image: new Circle({
        //             fill: new Fill({
        //                 color
        //             }),
        //             radius: 50
        //         })
        //     });
        // };

        this.schemaSymbolStyles['EXCITATION_UL'] = (isStateOn) => this.getRtuProtectMessage(isStateOn, 'U<');

        this.schemaSymbolStyles['EXCITATION_ULL'] = (isStateOn) => this.getRtuProtectMessage(isStateOn, 'U<<');

        this.schemaSymbolStyles['TRIP'] = (isStateOn) => this.getRtuProtectMessage(isStateOn, 'X');

        this.schemaSymbolStyles['PV_SUPPLY'] = (isStateOn) => this.getPvSupply(isStateOn);

        /***************************
         */

        this.schemaSymbolStyles['RTU_ERROR'] = (isStateOn) => {
            const color = (isStateOn) ? 'rgba(230, 0, 0, 1)' : 'rgba(230, 0, 0, 0.1)';
            return this.getRtuTransmission(isStateOn, color, 'RTU\nERROR');
        };

        this.schemaSymbolStyles['RTU_WARNING'] = (isStateOn) => {
            const color = (isStateOn) ? 'rgba(255, 140, 0, 1)' : 'rgba(255, 140, 0, 0.1)';
            return this.getRtuTransmission(isStateOn, color, 'RTU\nWARNING');
        };

        this.schemaSymbolStyles['DOOR_CONTACT'] = (isStateOn) => {
            const color = (isStateOn) ? 'rgba(255, 140, 0, 1)' : 'rgba(255, 140, 0, 0.1)';
            return this.getRtuTransmission(isStateOn, color, 'DOOR\nCONTACT');
        };

        this.schemaSymbolStyles['UPS_WARNING'] = (isStateOn) => {
            const color = (isStateOn) ? 'rgba(255, 140, 0, 1)' : 'rgba(255, 140, 0, 0.1)';
            return this.getRtuTransmission(isStateOn, color, 'UPS\nWARNING');
        };

        this.schemaSymbolStyles['VOLT_MISSING'] = (isStateOn) => {
            const color = (isStateOn) ? 'rgba(255, 140, 0, 1)' : 'rgba(255, 140, 0, 0.1)';
            return this.getRtuTransmission(isStateOn, color, 'VOLT\nMISSING');
        };


        this.schemaSymbolStyles['RTU_State_Remote'] = (isStateOn, symbolType) => this.getRtuStateRemote(isStateOn, symbolType.split('_').slice(-2) );

    }

    private getRtuProtectMessage(isStateOn: boolean, text: string){
        const canvas = document.createElement('canvas');
        canvas.width = 20;
        canvas.height = 12;
        const ctx = canvas.getContext('2d');
        ctx.strokeStyle = `rgba(230,0,0,${this.getStrokeAplhafromState(isStateOn)})`;
        ctx.lineWidth = 1;
        ctx.strokeRect(0, 0, canvas.width, canvas.height);

        if(isStateOn){
            ctx.fillStyle = `rgba(255,0,0,${this.getStrokeAplhafromState(isStateOn)})`;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }

        return new Style({
            image: new Icon({
                src: canvas.toDataURL(),
            }),
            text: (isStateOn) ?  this.getRtuText(text, 8, true, [0,2]) : this.getRtuText(text, 8, false, [0,2])
        });

    }

    private getPvSupply(isState: boolean){
        return  new Style({
            image: new Icon({
                src: 'assets/svg/schema-symbols/Photovoltaik.svg',
                scale: 0.8
            })
        });
    }

    private getRtuTransmission(isStateOn: boolean, color: string, text: string){
        return new Style({
            image: new RegularShape({
                fill: new Fill({ color }),
                stroke:  new Stroke({ color: `rgba(0,0,0,${this.getStrokeAplhafromState(isStateOn)})`, width: 1}),
                points: 3,
                radius: 24,
                scale: 1,
                displacement: [0, -50]
            }),
            text: (isStateOn) ?  this.getRtuText(text, 10, true, [0,50]) : this.getRtuText(text, 7, false, [0,50])
        });
    };

    private getRtuStateRemote(isStateOn: boolean, types: string[]){
        const colorModel = {
            GN: 'rgb(70, 255, 0)',
            RT: 'rgb(230, 0, 0)',
            GR: 'rgba(100, 100, 100, 0.5)',
        };
        const color = (!isStateOn) ? colorModel[types[0]] : colorModel[types[1]];
        return new Style({
            image: new Circle({
                fill: new Fill({
                    color
                }),
                radius: 6,
                displacement: [0,0]
            }),
        });
    };

    private getRtuText(text: string, fontSize: number, bold: boolean, offset: number[] = [0,0]){
        const _bold: string = (bold) ? 'bold' : '';
        return new Text({
            text,
            font: `${_bold} ${fontSize}px/1.3 Roboto, Calibri, "Helvetica Neue", sans-serif`,
            fill: new Fill({
                color: 'rgba(255,255,255,1)'
            }),
            stroke: new Stroke({
                color: 'rgba(0,0,0,1)',
                width: 2
            }),
            placement: 'line',
            scale: 1,
            offsetX: offset[0],
            offsetY: offset[1]
        });
    }

    private getStrokeAplhafromState(isStateOn){
        return (isStateOn) ? '1' : '0.2';
    }

}
