import { DOCUMENT } from '@angular/common';
import {Component, HostListener, Inject, OnInit} from '@angular/core';
import Config from './config/Config';
import {StatefulAuthenticationService} from './shared/services/authentication/stateful-authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'Grid Pilot';
    // addMarkerMode: any;

    constructor(@Inject(DOCUMENT) private _document,
                private auth: StatefulAuthenticationService) {
    }

    public ngOnInit() {
        // if(Config.frontend?.defaultFont) {
        //     this._document.body.style.fontFamily = Config.frontend?.defaultFont;
        // } else {
        //     this._document.body.style.fontFamily = 'Roboto';
        // }

        document.addEventListener('wheel', event => {
            if (event.ctrlKey) {
                event.preventDefault();
            }
        }, { passive: false });

        document.addEventListener('touchmove',  (event) => {
            // @ts-ignore
            if (event.scale !== 1) { event.preventDefault(); }
        }, { passive: false });
    }

    @HostListener('click') onClick() {
        this.auth.resetLogoutTimer();
    }
    @HostListener('tap', ['$event']) onTap() {
        this.auth.resetLogoutTimer();
    }
    @HostListener('mousemove') onMouseMove(){
        this.auth.resetLogoutTimer();
    }
}
