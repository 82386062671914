
<table [style.width]="'100%'" *ngIf="identDataItem" class="ident-properties-table">

    <ng-container *ngIf="identDataItem.data">

        <tr *ngFor="let kv of identDataItem.data | keyvalue">

            <td class="prop-key">{{kv.key}}</td>

            <ng-container *ngIf="Array.isArray(kv.value); then isArrayValues; else noArrayValues"></ng-container>

            <ng-template #isVivavisId>
                <td class="pull-right bold">{{kv.value | unwrapVivavisId }}</td>
            </ng-template>

            <ng-template #noVivavisId>
                <td class="pull-right bold">{{kv.value}}</td>
            </ng-template>

            <ng-template #isArrayValues>
                <td class="pull-right bold">
                    <tr *ngFor="let val of kv.value">{{val}}</tr>
                </td>
            </ng-template>

            <ng-template #noArrayValues>
                <ng-container *ngIf="kv.key === 'VivavisId'; then isVivavisId; else noVivavisId"></ng-container>
            </ng-template>
        </tr>
    </ng-container>

    <ng-container [ngTemplateOutlet]="complexMeasurementTableTemplate" [ngTemplateOutletContext]="{data: identDataItem.meta}"></ng-container>
</table>

<ng-container *ngIf="identDataItem">

            <mat-list *ngIf="identDataItem.markers" class="identMarkerList">
                <mat-list-item *ngFor="let marker of identDataItem.markers">
                    <button color="warn" (click)="deleteMarker(marker)">
                        <img [src]="marker.markerConfig.imgSrc"/>
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>

</ng-container>


<ng-template appLoadComponent></ng-template>

<ng-template #complexMeasurementTableTemplate let-data="data">
    <ng-container *ngIf="data | complexMeasurement:measurements; let ms" >
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr *ngFor="let m of ms | filter:filterMeasurements" class="complex-measurements-table">
                    <ng-container *ngIf="m; let mv">
                        <td>{{mv.name}}</td>
                        <td class="numeric-value-label">
<!--                            <span class="numeric-value">{{mv.value | number:'1.0-1':langId}}</span>-->
                            <span class="numeric-value">{{ mv.value | number}} </span>
                            <span class="numeric-unit">{{mv.unit}}</span>
                        </td>
                    </ng-container>
                </tr>
    </ng-container>
</ng-template>
