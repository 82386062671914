import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Config from 'src/app/config/Config';
import { environment } from 'src/environments/environment';
import { ApiServerStatusResult } from '../interfaces/api-serverstatus';

@Injectable({
    providedIn: 'root',
})
export class ApiServerStatusService {
    constructor(private httpClient: HttpClient) {}

    getStatus() {
        const url = `${environment.apiRoot}${Config.Api.serverStatus}`;
        return this.httpClient.get<ApiServerStatusResult>(url);
    }

    goOffline() {
        const url = `${environment.apiRoot}${Config.Api.serverStatus}`;
        return this.httpClient.post(url, null);
    }

    goOnline() {
        const url = `${environment.apiRoot}${Config.Api.serverStatus}`;
        return this.httpClient.delete(url);
    }
}
