import { ApiAuthMethodType } from './../../interfaces/api-login-result';
import { Observable, of, Subject, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationStateEvents, AuthenticationStateType } from './stateful-authentication.service';
import { environment } from 'src/environments/environment';
import Config from 'src/app/config/Config';
import { UserService } from '../user.service';

export interface AuthBaseConfig {
    httpClient: HttpClient;
    userService: UserService;
    [key: string]: any;
}

export abstract class AuthBase {

    protected httpClient: HttpClient;
    protected userService: UserService;
    protected storage = localStorage;
    protected sessionIdStorageKey = 'sessionId';
    protected authAnswer = new Subject<AuthenticationStateEvents>();
    protected destroy$ = new Subject<void>();
    public authAnswer$ = this.authAnswer.asObservable();
    public authMethod = ApiAuthMethodType.NoMethod;

    constructor(config: AuthBaseConfig) {
        this.httpClient = config.httpClient;
        this.userService = config.userService;
    }

    destroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }


    submit(...params) { }

    login(...params): Observable<any> | Promise<any> {
        return of(null);
    }

    logout(sessionId: string): Observable<any> | Promise<any> {
        const url = `${environment.apiRoot}${Config.Api.logout}`;
        const httpHeaders = new HttpHeaders({ sessionId });
        return this.httpClient.get<any>(url, { headers: httpHeaders }).pipe(tap(() => this.authAnswer.next({ state: AuthenticationStateType.LoggedOut })));
    }
}
