import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import * as _ from 'lodash';
import {
    BehaviorSubject,
    firstValueFrom,
    from,
    interval,
    map,
    merge,
    NEVER,
    Observable,
    of,
    Subject,
    Subscription,
    tap,
    throwError,
} from 'rxjs';
import { catchError, debounceTime, mergeMap, scan, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import Config from 'src/app/config/Config';
import selectDistincState from 'src/app/utils/select-distinct-state';
import { environment } from 'src/environments/environment';

import { ApiAuthMethods, ApiAuthMethodType } from '../../interfaces/api-login-result';
import { FrontendConfig } from '../../interfaces/frontend.config';
import { UpdateReceiverService } from '../updatereceiver.service';
import { UserService } from '../user.service';
import { AuthBase, AuthBaseConfig } from './auth.interface';
import { BasicAuth } from './basic.auth';
import { MobileAuth } from './mobile.auth';
import { OAuthAuth } from './oauth.auth';
import { ConsoleLoggingService } from '../console-logging.service';

import { merge as deepMerge} from 'lodash';
export enum AuthenticationStateType {
    Init = 'init',
    ConfigureOAuth = 'configureOauth',
    Error = 'error',
    CheckSession = 'checkSession',
    VerifySession = 'verifySession',
    LoggedIn = 'loggedIn',
    NeedLogin = 'needLogin',
    AwaitingUserLogin = 'awaitingUserLogin',
    LoginSuccessful = 'loginSuccessful',
    // LoginError = 'loginError',
    Logout = 'logOut',
    LoggedOut = 'loggedOut',
    LoadConfig = 'loadConfig',
    Idle = 'idle'
}

enum AuthenticationStateKeys {
    state = 'state',
    sessionId = 'sessionId',
    isLoggedIn = 'isLoggedIn',
    method = 'method',
    apiMethod = 'apiMethod',
    error = 'error',
    rand = 'rand',
    date = 'date',
    user = 'user'
}

export interface AuthenticationState {
    state: AuthenticationStateType;
    prevState?: AuthenticationStateType;
    sessionId: string | null;
    sessionVerified: boolean;
    isLoggedIn: boolean;
    method: ApiAuthMethodType;
    apiMethod?: ApiAuthMethods;
    error?: any;
    rand: number | string;
    date?: any;
    user?: string;
}

export type AuthenticationStateEvents = Partial<AuthenticationState>;

interface AuthenticationStateReducer {
    [key: string]: (currentState: AuthenticationState) => Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class StatefulAuthenticationService implements OnDestroy {
    public auth: AuthBase;
    public oauthState = '';
    private storage = localStorage;

    private httpClient: HttpClient;

    private authStateEvents: Observable<AuthenticationStateEvents>;
    public authState$: Observable<AuthenticationState>;
    private stateMachine: Observable<any>;

    private sessionIdStorageKey = 'sessionId';

    private checkSessionInterval = interval(1 * 60 * 1000);
    private checkSessSub: Subscription;
    private destroy$ = new Subject<void>();

    private noActionTimer: number;
    private noActionInterval?: number;

    private statesReducer: AuthenticationStateReducer = {
        [AuthenticationStateType.Init]: this.stateInit.bind(this),
        [AuthenticationStateType.ConfigureOAuth]: this.stateConfigureOauth.bind(this),
        [AuthenticationStateType.CheckSession]: this.stateCheckSession.bind(this),
        [AuthenticationStateType.VerifySession]: this.stateVerifySession.bind(this),
        [AuthenticationStateType.NeedLogin]: this.stateNeedLogin.bind(this),
        [AuthenticationStateType.AwaitingUserLogin]: this.stateAwaitingUserLogin.bind(this),
        [AuthenticationStateType.LoginSuccessful]: this.stateLoginSuccessful.bind(this),
        [AuthenticationStateType.LoadConfig]: this.stateLoadConfig.bind(this),
        [AuthenticationStateType.LoggedIn]: this.stateLoggedIn.bind(this),
        [AuthenticationStateType.Logout]: this.stateLogout.bind(this),
        [AuthenticationStateType.LoggedOut]: this.stateLoggedOut.bind(this),
        [AuthenticationStateType.Error]: this.stateError.bind(this),
    };

    private initState: AuthenticationState = {
        rand: (Math.random() * 10).toFixed(3),
        date: Date.now(),
        state: AuthenticationStateType.Idle,
        sessionId: this.storage.getItem(this.sessionIdStorageKey),
        sessionVerified: false,
        isLoggedIn: false,
        method: ApiAuthMethodType.NoMethod,
    };

    private authStateSubject = new BehaviorSubject<AuthenticationStateEvents>(this.initState);
    private bootstrap = new BehaviorSubject<AuthenticationStateEvents>({});
    private bootstrap$ = this.bootstrap.asObservable()
        .pipe(switchMap(x => x.state === AuthenticationStateType.Error ? throwError(() => x.error) : of(x)));

    constructor(
        private httpBackend: HttpBackend,
        private router: Router,
        private userService: UserService,
        private updateReceiverService: UpdateReceiverService,
        private oauthService: OAuthService,
        private logging: ConsoleLoggingService
    ) {

        this.httpClient = new HttpClient(this.httpBackend);

        this.authStateEvents = merge(
            this.authStateSubject.asObservable()
                .pipe(
                    // debounceTime(100),
                    map(x => {
                        x.date = Date.now();
                        return x;
                    })
                ),

            this.getAuthMethods()
        );

        this.authState$ = this.authStateEvents.pipe(
            // startWith(this.initState),
            scan((states: AuthenticationState, newState): AuthenticationState => ({ ...states, ...newState, prevState: states.state })),
            // tap(console.log),
            shareReplay(1),
            debounceTime(1),
        );

        this.stateMachine = this.authState$.pipe(mergeMap((authState) => {

            if (authState.prevState === authState.state) {
                return NEVER;
            }

            const nextStateFunction = _.has(this.statesReducer, authState.state.toString()) ? this.statesReducer[authState.state] : (p: any) => NEVER;
            const nextState = nextStateFunction(authState);

            return nextState ? nextState : NEVER;
        }));


    }

    public resetLogoutTimer(){
        if(Config.frontend){
            this.noActionTimer =  Config.frontend.logoutTimeInSec * (1000);
            this.setIntervalFunc();
        }
    }

    private setIntervalFunc(){
        this.logging.logDebug(`reset login in ${this.noActionTimer/1000} sec.`);
        clearInterval(this.noActionInterval);
        this.noActionInterval = setInterval(()=> {
            const _sId = localStorage.getItem('sessionId');
            if(_sId !==  null ) {
                this.logout();
            }
        }, this.noActionTimer);
    }

    public async bootstrapAuthService(state: string = '') {
        this.oauthState = state;
        this.stateMachine.subscribe();
    }

    public bootstrapAuthService2(state: string = '') {
        this.oauthState = state;
        this.stateMachine.subscribe();

        return this.bootstrap$;
    }

    public ngOnDestroy(): void {
        this.logging.logDebug('StatefulAuthservice', 'ngOnDestroy');
        this.auth.destroy();
        this.destroy$.next();
        this.destroy$.complete();
    }

    public logout() {
        this.authStateSubject.next({ state: AuthenticationStateType.Logout });
    }

    public async userLogin(username: string, password: string) {
        const currentState = await firstValueFrom(this.currentState());
        if (currentState === AuthenticationStateType.AwaitingUserLogin) {
            (this.auth as BasicAuth)
                .submit(username, password)
                .pipe(
                    tap(result => {
                        if (result.success && result.sessionId) {
                            this.userService.setUsername(username);
                            this.authStateSubject.next({ state: AuthenticationStateType.LoginSuccessful, sessionId: result.sessionId, user: username });
                        }
                    }),
                    catchError(err => {
                        // Special treatment for User Login Form, dont change state, just display error
                        this.authStateSubject.next({ error: err });
                        return throwError(() => err);
                    })
                )
                .subscribe();
        }
    }

    // #region SIDE EFFECTS
    public isLoggedIn() {
        return this.authState$.pipe(selectDistincState<AuthenticationState, boolean>(AuthenticationStateKeys.isLoggedIn));
    }

    public currentMethod() {
        return this.authState$.pipe(selectDistincState<AuthenticationState, ApiAuthMethodType>(AuthenticationStateKeys.method));
    }

    public currentState() {
        return this.authState$.pipe(selectDistincState<AuthenticationState, AuthenticationStateType>(AuthenticationStateKeys.state));
    }

    public sessionId() {
        return this.authState$.pipe(selectDistincState<AuthenticationState, string>(AuthenticationStateKeys.sessionId));
    }


    // #endregion

    // #region SETUP


    // #endregion


    // #region STATES
    private stateInit(currentState: AuthenticationState) {
        this.logging.log('stateInit');
        this.authStateSubject.next({ state: AuthenticationStateType.CheckSession });
    }

    private stateConfigureOauth(currentState: AuthenticationState) {
        this.logging.logDebug('stateConfigureOauth');
        (this.auth as OAuthAuth).configure().then(() => {
            this.authStateSubject.next({ state: AuthenticationStateType.CheckSession });
        }).catch( (error) => {
            this.authStateSubject.next({ state: AuthenticationStateType.Error, error });
        });
    }

    private stateCheckSession(currentState: AuthenticationState) {
        this.logging.log('stateCheckSession');
        const sessionId = this.getSessionId() || currentState.sessionId;
        if (sessionId) {
            this.authStateSubject.next({ state: AuthenticationStateType.VerifySession });
        } else {
            this.authStateSubject.next(this.needLoginResetState());
        }
    }

    private stateVerifySession(currentState: AuthenticationState) {
        this.logging.log('stateVerifySession');
        return this.checkSessionIdStatus(currentState.sessionId)
            .pipe(

                tap(data => {
                    if (data.success) {
                        this.updateReceiverService.checkConnection();

                        if (this.auth.authMethod === ApiAuthMethodType.OAuth) {
                            this.authStateSubject.next({ state: AuthenticationStateType.NeedLogin, sessionVerified: true });
                        } else {
                            this.authStateSubject.next({ state: AuthenticationStateType.LoginSuccessful, sessionVerified: true });
                        }
                    } else {
                        this.storage.removeItem(this.sessionIdStorageKey);
                        this.userService.clear();
                        this.authStateSubject.next({ state: AuthenticationStateType.LoggedOut });
                    }
                }),
                catchError(err => {
                    this.logging.log('Error: ',err);
                    this.storage.removeItem(this.sessionIdStorageKey);
                    this.userService.clear();
                    this.authStateSubject.next({ state: AuthenticationStateType.LoggedOut });
                    return NEVER;
                })
            );
    }

    private stateNeedLogin(currentState: AuthenticationState) {
        this.logging.log('stateNeedLogin');
        this.logging.logDebug('stateNeedLogin', currentState);
        if (this.checkSessSub) {
            this.checkSessSub.unsubscribe();
        }

        return from(this.auth.login(currentState));
    }

    private stateAwaitingUserLogin(currentState: AuthenticationState) {
        this.logging.log('stateAwaitingUserLogin');
        this.router.navigate(['/login']);
        return NEVER;
    }

    private stateLoggedIn(currentState: AuthenticationState) {
        this.logging.logDebug('stateLoggedIn');
        this.authStateSubject.next({ isLoggedIn: true, state: AuthenticationStateType.Idle });
        // this.storage.setItem(this.sessionIdStorageKey, currentState.sessionId);

    }

    private stateLoginSuccessful(currentState: AuthenticationState) {
        this.logging.log('stateLoginSuccessful');
        if (!Config.frontend) {
            this.checkSessSub = this.checkSessionInterval.subscribe(() => {
                this.logging.logDebug('checkSessionInterval');
                this.authStateSubject.next({ state: AuthenticationStateType.CheckSession });
            });
            this.authStateSubject.next({ state: AuthenticationStateType.LoadConfig });
        } else {
            this.authStateSubject.next({ state: AuthenticationStateType.LoggedIn });
        }
    }

    private stateLoadConfig(currentState: AuthenticationState) {
        this.logging.log('stateLoadConfig');
        return this.postLoginProcess(currentState).pipe(tap(() => {
            this.resetLogoutTimer();
            this.authStateSubject.next({ state: AuthenticationStateType.LoggedIn });
        }));
    }

    private stateLogout(currentState: AuthenticationState) {
        this.logging.logDebug('stateLogout');
        return from(this.auth.logout(currentState.sessionId));
    }

    private stateLoggedOut(currentState: AuthenticationState) {
        this.logging.logDebug('stateLoggedOut');
        // return from(this.auth.logout(currentState.sessionId));
        const mapState = this.storage.getItem('mapState');

        this.storage.clear();

        if (mapState) {
            this.storage.setItem('mapState', mapState);
        }

        this.logging.logDebug('logged Out');
        window.location.reload();

        return NEVER;
    }

    private stateError(currentState: AuthenticationState) {
        this.logging.logDebug('<stateError>');
        this.bootstrap.next(currentState);
        return NEVER;
    }

    // #endregion

    private needLoginResetState(): AuthenticationStateEvents {
        this.logging.log('needLoginResetState');
        return {
            state: AuthenticationStateType.NeedLogin,
            isLoggedIn: false,
            user: undefined,
            sessionVerified: false,
            sessionId: null
        };
    }

    private getSessionId() {
        return this.storage.getItem(this.sessionIdStorageKey);
    }

    // #region HTTP CALLS

    private getAuthMethods() {
        const url = `${environment.apiRoot}${Config.Api.authMethods}`;
        return this.httpClient.get<ApiAuthMethods>(url)

            .pipe(
                // map(data => {
                //     data.supported_method.method = ApiAuthMethodType.OAuth;
                //     data.supported_method.identity_provider = {
                //         issuer: 'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/v2.0',
                //         redirectUri: 'http://localhost:4200',
                //         client: '5c96b053-bb7c-40b4-8eea-12725ecd4eed',
                //         scope: 'openid',
                //     };
                //     return data;
                // }),
                // map(data => {
                //     data.supported_method.method = ApiAuthMethodType.OAuth;
                //     data.supported_method.identity_provider = {
                //         issuer: 'https://geovisualisierung.vivavis.int:8443/auth/realms/gridpilot-dev',
                //         redirectUri: 'http://localhost:4200',
                //         client: 'gridpilot-dev',
                //         // scope: 'openid',
                //     };
                //     return data;
                // }),
                map((data) => {
                    const method: ApiAuthMethodType = data.supported_method.method;
                    let nextState = AuthenticationStateType.Init;
                    const authBaseConfig: AuthBaseConfig = { httpClient: this.httpClient, userService: this.userService };
                    switch (method) {
                        case ApiAuthMethodType.Basic:
                            this.auth = new BasicAuth(authBaseConfig);
                            break;
                        case ApiAuthMethodType.Mobile:
                            this.auth = new MobileAuth(authBaseConfig, this.logging);
                            break;
                        case ApiAuthMethodType.OAuth:
                            this.auth = new OAuthAuth({
                                ...authBaseConfig,
                                oauthConfig: data.supported_method.identity_provider,
                                oauthService: this.oauthService,
                                oauthState: this.oauthState
                            }, this.logging);
                            nextState = AuthenticationStateType.ConfigureOAuth;
                            break;
                    }

                    this.auth.authAnswer$.pipe(tap(this.authStateSubject), takeUntil(this.destroy$)).subscribe();
                    return { method, state: nextState };
                }),
                catchError(err => {
                    this.logging.log('Error: ', err);
                    return of({ state: AuthenticationStateType.Error, error: err });
                })
            );

    }


    /**
     * checks sessionId Status
     *
     * @returns HTTP Observable
     */
    private checkSessionIdStatus(sessionId: string) {
        const url = `${environment.apiRoot}${Config.Api.statusAuth}`;
        const httpHeaders = new HttpHeaders({ sessionId: this.getSessionId() || sessionId });
        return this.httpClient.get<any>(url, { headers: httpHeaders });
    }

    isSessionIdValid(){
        const sessionId = this.getSessionId();
        return this.checkSessionIdStatus(sessionId);
    }

    private postLoginProcess(currentState: AuthenticationState) {

        const sessionId = currentState.sessionId;

        return this.frontendConfig(sessionId).pipe(tap(() => {

            this.storage.setItem(this.sessionIdStorageKey, sessionId);
            this.updateReceiverService.init();

            if (!environment.mobile) {
                // this.userService.username = currentState.user;
            }
        }));
    }

    private frontendConfig(sessionId?: string) {
        const isMobile = environment.mobile;
        // console.log('-- frontendConfig');
        const mergreObject = (config): FrontendConfig => {
            const JSONparse = (value) => {
                try {
                    return JSON.parse(value);
                } catch (e) {
                    return undefined;
                }
            };
            let _return: FrontendConfig = {} as FrontendConfig;
            Config.frontend = {
                debug: {
                    console: (config['debug.console']) ? JSON.parse(config['debug.console']) : false,
                    popup: (config['debug.console']) ? JSON.parse(config['debug.popup']) : false
                }
            } as FrontendConfig;

            Object.keys(config)
                // .filter((key: string) => key.indexOf('.') > -1)
                .forEach((key: string) => {
                    const _keys = key.split('.');
                    if(_keys.length === 1){
                        this.logging.logDebug('parse Frontend Config Key / Value', key+' '+config[key]);

                        const parsedJSON = JSONparse(config[key]);
                        if(parsedJSON !== undefined){
                            _return[_keys[0]] = parsedJSON;
                        }else{
                            console.warn('Fail parse JSON from Backend Key: ', _keys[0], config[key], ' use default');
                        }
                    }else{
                        if(!_return[_keys[0]]){_return[_keys[0]] = {};}

                        let _model = {};
                        (_keys.reverse()).reduce( (previousValue: string | any, currentValue: string, currentIndex: number, array: string[]): string | any => {
                            if(currentIndex === 1){
                                _model[currentValue] = {};
                                this.logging.logDebug('parse Frontend Config Key / Value',key+' '+config[key]);

                                const parsedJSON = JSONparse(config[key]);
                                if(parsedJSON !== undefined){
                                    _model[currentValue][previousValue] = parsedJSON;
                                }else{
                                    console.warn('Fail parse JSON from Backend Key: ', currentValue, previousValue, ' use default');
                                }
                            }
                            if(typeof previousValue === 'object'){
                                _model = {[currentValue]:previousValue};
                            }
                            return _model;
                        });
                        _return = deepMerge(_return, _model);

                    }

            });
            return _return;
        };

        const setupEnvironment = (gpConfig) => {
            environment.tilesRoot = gpConfig.tileServer || environment.tilesRoot;
            environment.webSocketUrl = gpConfig.webSocketUrl || environment.webSocketUrl;
        };

        return this.retrieveFrondendConfig(sessionId)
            .pipe(
                tap(gridpilotConfig => {
                    Config.frontend = mergreObject(gridpilotConfig);
                    Config.activeApi = Config.frontend?.customConfig && Config.availableApi[Config.frontend.customConfig] || Config.availableApi.default;

                    if (environment.production) {
                        setupEnvironment(gridpilotConfig);
                    }
                }),
                mergeMap(
                    () => this.httpClient.get<Partial<FrontendConfig>>(`assets/frontend.default.config.json?t=${Date.now()}`)
                        .pipe(
                            tap(fronendDefaultConfig => {
                                Config.frontend = deepMerge(fronendDefaultConfig, Config.frontend);
                            })
                        )
                ),
                mergeMap(
                    gridpilotConfig => (isMobile) ?
                        this.httpClient.get<Partial<FrontendConfig>>(`assets/frontend.config.mobile.json?t=${Date.now()}`) :
                        this.httpClient.get<Partial<FrontendConfig>>(`assets/frontend.config.web.json?t=${Date.now()}`)
                        .pipe(
                            tap(fronendConfig => {
                                Config.frontend = deepMerge(Config.frontend, fronendConfig);
                                if (!Config.frontend?.enableComponents) {
                                    Config.frontend.enableComponents = {};
                                }
                                Config.frontend.enableComponents.marker = Config.activeApi.includes(Config.Api.markers);
                            })
                        )
                ),
                // mergeMap(
                //     gridpilotConfig => this.httpClient.get<Partial<FrontendConfig>>(`assets/schema.config.json?t=${Date.now()}`)
                //         .pipe(
                //             tap(schemaConfig => {
                //                 Config.frontend.schemaStyle = deepMerge(Config.frontend.schemaStyle, schemaConfig);
                //             })
                //         )
                // )
            );
    }

    /**
     * retrieves gridpilotConfig
     *
     * @returns HTTP Observable
     */
    private retrieveFrondendConfig(sessionId?: string) {
        const httpHeaders = new HttpHeaders().set('SessionId', sessionId);
        const configUri = (environment.mobile) ?
            '/gridpilotconfig' : '/gridpilot/gridpilotconfig';

        const url = environment.apiRoot + configUri;
        const request: Observable<FrontendConfig> = this.httpClient.get<FrontendConfig>(url, { headers: httpHeaders });

        // if (environment.mobile === false) {
        //
        //     const url = environment.apiRoot + '/gridpilot/gridpilotconfig';
        //     request = this.httpClient.get<FrontendConfig>(url, { headers: httpHeaders });
        //
        // } else {
        //
        //     const url = 'http://localhost:6799/gridpilotconfig';
        //     request = this.httpClient.get<FrontendConfig>(url, { headers: httpHeaders });
        //
        // }

        return request.pipe(map(this.mapGridpilotConfig));
    }

    private mapGridpilotConfig(config: FrontendConfig) {

        config.customConfig = config.customConfig || '"default"';
        // config.customConfig = config.customConfig || 'dewa';

        // config.srs = config.srs || '3857';
        // config.lon = config.lon || 10.447683;
        // config.lat = config.lat || 51.163361;
        // config.zoom = config.zoom || 6;

        // config.multiLanguage = config.multiLanguage || false;

        // try {
        //     if (config?.view.bbox) {
        //         config.view.bbox = JSON.parse(config.view.bbox);
        //     }
        // } catch (e) {
        //     console.error('mapping Gridpilot Config Error');
        //     this.logging.logDebug('Error,', e);
        //     config.view.bbox = undefined;
        // }
        return config;
    }

    // #endregion
}
