<!--<div >-->
<!--    <ng-container *ngIf="connectionStatus$|async; let state">-->
<!--        <button mat-mini-fab [matMenuTriggerFor]="statusMenu" class="{{stateColor}}">-->
<!--            <mat-icon *ngIf="state.isOnline">wifi</mat-icon>-->
<!--            <mat-icon *ngIf="state.isOffline">wifi_off</mat-icon>-->
<!--        </button>-->
<!--    </ng-container>-->
<!--</div>-->

<!--<mat-menu #statusMenu="matMenu" [hasBackdrop]="true">-->
<!--    <ng-container *ngIf="connectionStatus$|async; let state" >-->
<!--        <div mat-menu-item (click)="this.reconnect_BackendApp()">-->
<!--            <mat-icon *ngIf="state.isOnline" class="connected-green">wifi</mat-icon>-->
<!--            <mat-icon *ngIf="state.isOffline" color="warn">wifi_off</mat-icon>-->
<!--            <span>Backend</span>-->
<!--        </div>-->
<!--        <div mat-menu-item (click)="this.reconnect_WebSocket()">-->
<!--            <mat-icon *ngIf="state.webSocketConnected" class="connected-green">wifi</mat-icon>-->
<!--            <mat-icon *ngIf="!state.webSocketConnected" color="warn">wifi_off</mat-icon>-->
<!--            <span>WebSocket</span>-->
<!--        </div>-->
<!--    </ng-container>-->
<!--</mat-menu>-->


<div>
    <ng-container>
        <button mat-mini-fab [matMenuTriggerFor]="statusMenu" class="{{stateColor}}">
            <mat-icon *ngIf="stateBackendConnected && stateWebSocketConnected">wifi</mat-icon>
            <mat-icon *ngIf="!stateBackendConnected || !stateWebSocketConnected">wifi_off</mat-icon>
        </button>
    </ng-container>
</div>

<mat-menu #statusMenu="matMenu" [hasBackdrop]="true">
    <ng-container>
        <div mat-menu-item (click)="this.reconnect_BackendApp()">
            <mat-icon *ngIf="stateBackendConnected" class="connected-green">wifi</mat-icon>
            <mat-icon *ngIf="!stateBackendConnected" color="warn">wifi_off</mat-icon>
            <span>Backend</span>
        </div>
        <div mat-menu-item (click)="this.reconnect_WebSocket()">
            <mat-icon *ngIf="stateWebSocketConnected" class="connected-green">wifi</mat-icon>
            <mat-icon *ngIf="!stateWebSocketConnected" color="warn">wifi_off</mat-icon>
            <span>WebSocket</span>
        </div>
    </ng-container>
</mat-menu>

