export interface ApiLoginResult {
    success: boolean;
    sessionId?: string;
    errorTitle?: string;
    error?: string;
}

// export type ApiAuthMethodType = 'oauth' | 'basic' | 'mobile';
export enum ApiAuthMethodType {
    OAuth = 'oauth',
    Basic = 'basic',
    Mobile = 'mobile',
    NoMethod = 'noMethod'
}

export interface ApiAuthMethodIdentityProvider {
    issuer: string;
    redirectUri?: string;
    clientSecret?: string;
    client: string;
    scope?: string;
    authorizationEndpoint?: string;
}

export interface ApiAuthMethods {
    success: boolean;
    supported_method: {
        call_as: 'POST' | 'GET';
        details: string;
        endpoint: string;
        method: ApiAuthMethodType;
        required_arguments?: any[];
        identity_provider?: ApiAuthMethodIdentityProvider;
    };
}
