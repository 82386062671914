import { ApiGeneralInterface } from './../interfaces/api-general';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import Config from '../../config/Config';
import { ApiMarker, MarkerType } from '../interfaces/api-marker';
import { map } from 'rxjs/operators';
import { ApiOutcome } from '../interfaces/api-outcome';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class ApiMarkersService {
    constructor(
        private httpClient: HttpClient
    ) { }

    public deleteMarker(markerType: string, vivavisId: string) {
        const url = `${environment.apiRoot}${Config.Api.markers}`;
        const httpParams = new HttpParams()
            .set('vivavisId', vivavisId)
            .set('marker', markerType);

        return this.httpClient.delete<ApiGeneralInterface<ApiOutcome>>(url, { params: httpParams }).pipe(map(x => x.response));
    }

    public getMarkerForArea(bbox: number[]) {
        const url = `${environment.apiRoot}${Config.Api.markers}`;

        const httpParams = new HttpParams()
            .set('bbox', bbox.join(','))
            // .set('srs', Config.frontend?.srs || '3857');
            .set('srs', Config.frontend?.srs);
        return this.httpClient.get<ApiGeneralInterface<ApiMarker[]>>(url, { params: httpParams }).pipe(map(x => x.response));
    }

    public addMarker(vivavisId: string, markerType: MarkerType) {
        const url = `${environment.apiRoot}${Config.Api.markers}`;
        const httpParams = new HttpParams()
            .set('vivavisId', vivavisId)
            .set('marker', markerType);

        const body = {
            vivavisId,
            marker: markerType
        };
        return this.httpClient.post<ApiGeneralInterface<ApiOutcome>>(url, body);
    }
}
