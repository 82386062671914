import { UserService } from './../user.service';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthConfig, OAuthErrorEvent, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { BehaviorSubject, catchError, firstValueFrom, from, NEVER, tap } from 'rxjs';
import Config from 'src/app/config/Config';
import { environment } from 'src/environments/environment';
import { ApiAuthMethodIdentityProvider, ApiAuthMethodType, ApiLoginResult } from '../../interfaces/api-login-result';
import { AuthBase, AuthBaseConfig } from './auth.interface';
import { AuthenticationState, AuthenticationStateType } from './stateful-authentication.service';
import { ConsoleLoggingService } from  '../console-logging.service';

export interface OAuthAuthBaseConfig extends AuthBaseConfig {
    oauthService: OAuthService;
    oauthState: string;
    oauthConfig: ApiAuthMethodIdentityProvider;
    userService: UserService;
}

export class OAuthAuth extends AuthBase {

    public authMethod = ApiAuthMethodType.OAuth;

    private oauthConfig: AuthConfig = {
        // dummyClientSecret: 'secret',
        // silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
        // useSilentRefresh: true,
        oidc: false,
        strictDiscoveryDocumentValidation: false,
        responseType: 'code',
        scope: 'openid',
        sessionChecksEnabled: true,
        clearHashAfterLogin: false,
        showDebugInformation: false,
    };

    private oauthState = '';
    private oauthService: OAuthService;
    private logoutSessionId = '';
    private username = '';
    private discoveryDocumentLoaded = false;
    private userProfileLoaded = false;
    private isConfigured = false;
    private oAuthSent = false;
    private userProfile;

    authConfig: ApiAuthMethodIdentityProvider;

    constructor({ httpClient, oauthService, oauthState = '', oauthConfig, userService }: OAuthAuthBaseConfig,
                private logging: ConsoleLoggingService
                ) {
        super({ httpClient, userService });
        this.oauthService = oauthService;
        this.oauthState = oauthState;
        this.authConfig = oauthConfig;

        this.subscribeToOAuthEvents();
    }

    public getAuthorizationHeader() {
        return this.oauthService.authorizationHeader();
    }

    submit() {
        throw new Error('Method not implemented.');
    }

    checkSession() {
        this.oauthService.checkSession();
    }

    loadUserProfile() {
        if (!this.userProfileLoaded) {
            this.oauthService.loadUserProfile().then((userProfile: any) => {
                this.userProfile = userProfile;
                this.userService.setOAuthUserProfile(userProfile.info);
                this.username = this.userService.getUsername();
                // this.userProfileLoaded = true;
                this.authAnswer.next({ user: this.username });
            });
        }
    }

    configure() {
        const currentLocation = window.location.origin + window.location.pathname;
        const slashIfNeeded = currentLocation.endsWith('/') ? '' : '/';

        this.oauthConfig.issuer = this.authConfig.issuer;
        this.oauthConfig.clientId = this.authConfig.client;
        this.oauthConfig.scope = this.authConfig.scope || this.oauthConfig.scope;
        this.oauthConfig.redirectUri = this.authConfig.redirectUri || currentLocation;
        this.oauthConfig.logoutUrl  = this.authConfig.redirectUri || currentLocation;
        this.oauthConfig.postLogoutRedirectUri  = this.authConfig.redirectUri || currentLocation;
        // this.oauthConfig.postLogoutRedirectUri = this.authConfig.authorizationEndpoint.replace('/authorize', '/logout');

        if (this.authConfig.clientSecret) {
            this.oauthConfig.dummyClientSecret = this.authConfig.clientSecret;
        }

        this.oauthService.configure(this.oauthConfig);
        this.isConfigured = true;
        return this.oauthService.loadDiscoveryDocument()
            .then(() => this.oauthService.tryLoginCodeFlow({ customHashFragment: this.oauthState }));

    }

    async login(currentState: AuthenticationState): Promise<any> {

        try {

            if (this.isConfigured && this.discoveryDocumentLoaded) {

                if (this.oauthService.hasValidAccessToken()) {

                    this.oauthService.setupAutomaticSilentRefresh();

                    if (!currentState.sessionVerified) {
                        this.sendOAuthToBackend().subscribe();
                    } else {
                        this.loadUserProfile();
                        this.authAnswer.next({ state: AuthenticationStateType.LoginSuccessful });
                    }

                    Promise.resolve(true);

                } else {

                    this.oauthService.initLoginFlow(this.oauthState);
                    Promise.resolve(true);
                }
            } else {
                this.logging.log('OAuth', 'login defered');
                Promise.resolve(false);
            }

        } catch (e) {
            this.logging.log('OAuth Process', 'Error');
            this.logging.logDebug('OAuth Process Error', e);
            this.processError(e);
            Promise.resolve(e);
        }

    }

    async logout(sessionId?: string) {
        this.storage.setItem('returnUrlQuery', null);
        this.storage.setItem('sessionId', null);
        this.logoutSessionId = sessionId;
        this.oauthService.logOut();
        // this.oauthService.revokeTokenAndLogout();
    }

    private sendOAuthToBackend() {
        if (!this.oAuthSent) {
            this.oAuthSent = true;
            const url = `${environment.apiRoot}${Config.Api.oAuth}`;
            const token = this.oauthService.getAccessToken();

            this.storage.setItem('bearerToken', token);
            const header = new HttpHeaders().set('Authorization', this.oauthService.authorizationHeader());

            return this.httpClient.post<ApiLoginResult>(url, null, { headers: header })
                .pipe(
                    tap(r => this.processResults(r)),
                    catchError(err => {
                        this.logging.log('sendOAuthToBackend Error', err);
                        this.processError(err);
                        return err;
                    }),
                );
        } else {
            return NEVER;
        }
    }

    /**
     * Only for debug purposes
     *
     * @param username username
     * @param password username
     * @returns HTTP Observable
     */
    private basicLogin(username: string, password: string) {
        const url = `${environment.apiRoot}${Config.Api.basicAuth}`;

        const encoded = window.btoa(`${username}:${password}`);

        let header = new HttpHeaders();
        header = header.set('Authorization', `Basic ${encoded}`);

        return this.httpClient.post<ApiLoginResult>(url, null, { headers: header })
            .pipe(tap(r => this.processResults(r)));
    }

    private processResults(results: ApiLoginResult) {
        if (results && results.success && results.sessionId) {
            this.authAnswer.next({ state: AuthenticationStateType.LoginSuccessful, sessionId: results.sessionId, sessionVerified: true });
        } else {
            this.authAnswer.next({ state: AuthenticationStateType.Error, error: { title: results.errorTitle, message: results.error } });
        }
    }

    private processError(err) {
        this.authAnswer.next({ state: AuthenticationStateType.Error, error: err });
    }

    private logoutBackend() {
        const sessionId = this.storage.getItem('sessionId');
        from(super.logout(sessionId)).subscribe();
    }

    private subscribeToOAuthEvents() {

        this.oauthService.events
            .pipe(takeUntil(this.destroy$))
            .subscribe(async event => {
                switch (event.type) {
                    case 'token_validation_error':
                        break;
                    case 'token_received':
                        this.loadUserProfile();
                        break;
                    case 'token_expires':
                        break;
                    case 'user_profile_loaded':
                        this.userProfileLoaded = true;
                        break;
                    case 'discovery_document_loaded':
                        if (event instanceof OAuthSuccessEvent && event.info) {
                            this.discoveryDocumentLoaded = true;
                        }
                        break;
                    case 'discovery_document_load_error':
                    case 'discovery_document_validation_error':
                        this.discoveryDocumentLoaded = false;
                        break;
                    case 'session_error':
                    case 'session_terminated':
                        break;
                    case 'token_refresh_error':
                        this.logout();
                        break;
                    case 'logout':
                        this.logoutBackend();
                        break;
                    default:

                        break;
                }

                if (event instanceof OAuthErrorEvent) {
                    // this.oauthService.stopAutomaticRefresh();
                    const errorEvent = event as OAuthErrorEvent;
                    const errorReason = errorEvent.reason as HttpErrorResponse;
                    // this.authAnswer.next({ state: AuthenticationStateType.Error, error: errorEvent });

                }

            });
    }
}
