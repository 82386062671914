import {Injectable} from '@angular/core';
import {register} from 'ol/proj/proj4';
import {get} from 'ol/proj';
import proj4 from 'proj4';
import Projection from 'ol/proj/Projection';

@Injectable({
    providedIn: 'root'
})
export class ProjectionsService {

    public epsg21781Projection;
    public epsg31466Projection;
    public epsg31467Projection;
    public epsg31468Projection;
    public epsg25832Projection;
    public epsg25833Projection;
    public epsg4647Projection;
    public epsg4326Projection;
    public epsg3857Projection;

    get currentProjection(): Projection {
        return this._currentProjection;
    }

    set currentProjection(value: Projection) {
        this._currentProjection = value;
    }

    private _currentProjection: Projection;


    constructor() {
        proj4.defs(
            'EPSG:21781',
            '+proj=somerc +lat_0=46.95240555555556 ' +
            '+lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel ' +
            '+towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs'
        );

        proj4.defs('EPSG:31466', '+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs');

        proj4.defs('EPSG:31467', '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs');

        proj4.defs('EPSG:31468', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs');

        proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

        proj4.defs('EPSG:25833', '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

        proj4.defs('EPSG:4647', '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=32500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

        proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

        proj4.defs('EPSG:3857', '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs');

        register(proj4);

        this.epsg21781Projection = get('EPSG:21781');

        this.epsg31466Projection = get('EPSG:31466');

        this.epsg31467Projection = get('EPSG:31467');

        this.epsg31468Projection = get('EPSG:31468');

        this.epsg25832Projection = get('EPSG:25832');

        this.epsg25833Projection = get('EPSG:25833');

        this.epsg4647Projection = get('EPSG:4647');

        this.epsg4326Projection = get('EPSG:4326');

        this.epsg3857Projection = get('EPSG:3857');
    }

    public getEpsg21781() {
        return this.epsg21781Projection;
    }
}
