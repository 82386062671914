<div class="obisSchemaRoot" #rootObiSchemaMenu>

    <button class="closeButton" mat-mini-fab (click)="closeSchema()">
        <mat-icon class="mat-18">close</mat-icon>
    </button>

    <mat-form-field class="selectFormField">
<!--        <mat-label>{{refreshSelected}}</mat-label>-->
        <mat-select
            [(value)]="refreshWaitTimesObject[refreshSelected]"
            (selectionChange)="refreshTimeChanged($event.value)">
            <mat-option *ngFor="let time of refreshWaitTimes" [value]="refreshWaitTimesObject[time]">
                {{time}}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <div *ngIf="isLoading" class="loading-indicator" [style.cursor]="'unset'">
        <div>
            <span *ngIf="hasErrors && !hasErrorHints" class="material-icons-outlined has-errors">error</span>
            <span *ngIf="!hasErrors && hasErrorHints" class="material-icons-outlined has-error-hints">warning</span>
            <mat-progress-spinner *ngIf="!hasErrors && !hasErrorHints" mode="indeterminate" color="primary"></mat-progress-spinner>
        </div>
                <div class="status-box">
                    <ul>
                        <li *ngFor="let sm of statusMessages" [ngClass]="{
                            'loaded': sm.state === loadingIndicatorStates.Loaded,
                            'loading':  sm.state === loadingIndicatorStates.Loading,
                            'hint':  sm.state === loadingIndicatorStates.Hint,
                            'error-hint':  sm.state === loadingIndicatorStates.ErrorHint,
                            'error':  sm.state === loadingIndicatorStates.Error
                            }">{{sm.message}}</li>
                    </ul>
                    <button *ngIf="hasErrors || hasErrorHints"
                            id="continue-with-errors"
                            (click)="closeLoadingHint()"
                            mat-raised-button color="primary" i18n="@@continue">Continue</button>
                </div>
    </div>

    <div *ngIf="!isLoading" >

        <section
            id="grafanaHistoryButton"
            [matTooltip]="(selectedNumericValues.length === 0) ? triggerGrafanaHistoryButtonWithHint.innerText : triggerGrafanaHistoryButton.innerText"
            [matTooltipClass]="'toolTipMultiLine'"
        >
            <button
                mat-mini-fab
                color="accent"
                *ngIf="enableGrafana"
                [disabled]="selectedNumericValues.length === 0"
                (click)="clickOpenGrafanaHistory()">
                <mat-icon class="mat-18">history</mat-icon>
            </button>
            <template
                #triggerGrafanaHistoryButton>
                <section
                    i18n='@@schema.grafana.history.button'>
                    History
                </section>
            </template>
            <template
                #triggerGrafanaHistoryButtonWithHint>
                <section
                    i18n='@@schema.grafana.history.button'>
                    History
                </section>
                <section
                    i18n='@@schema.grafana.history.hint.button'>
                    History Hint
                </section>
            </template>
        </section>

        <section class="infoWrap">
            <h3>{{title}}</h3>

        </section>

        <section class="schemaWrap">
            <app-schema-root
                [configuration]="this.config"
                [measurements]="this.measurementsData"
                (userTriggerValueSelected)="updateSelectedValues($event)"
            ></app-schema-root>
        </section>

    </div>



</div>
