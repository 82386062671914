import {
    Component, DoCheck, EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import Layer from 'ol/layer/Layer';
import XYZ from 'ol/source/XYZ';
import { ApiLayer } from 'src/app/shared/interfaces/api-layer';
import BaseLayer from 'ol/layer/Base';

@Component({
    selector: 'app-layers-menu-item',
    templateUrl: './layer-menu-item.component.html',
    styleUrls: ['./layer-menu-item.component.scss'],
})
export class LayerMenuItemComponent implements OnInit, OnDestroy, DoCheck {

    @Output()
    public visbilityChanged = new EventEmitter<BaseLayer>();

    @Output()
    public refreshLayer = new EventEmitter<BaseLayer>();

    @Output()
    public moveUp = new EventEmitter();

    @Output()
    public moveDown = new EventEmitter();

    isVisible = false;
    layerId = '';

    @Input()
    sortable = false;

    @Input()
    refreshable = false;

    @Input()
    index = 0;

    @Input()
    length = 0;

    private _layer: BaseLayer;
    private _overViewLayer: BaseLayer;

    @Input()
    public set layer(l: BaseLayer) {
        this._layer = l;
        if (this._layer) {
            this.isVisible = this.layer.getVisible();
            this.layerId = this.getClassName(this.layer.get('apiLayer') as ApiLayer);
        }
    }

    public get layer() {
        return this._layer;
    }

    @Input()
    public set overViewLayer(l: BaseLayer) {
        this._overViewLayer = l;
    }

    public get overViewLayer(){
        return this._overViewLayer;
    }
    constructor() {

    }

    ngDoCheck(): void {
        if (this._layer) {
            this.isVisible = this.layer.getVisible();
        }
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }

    public layerToggleVisibility(event) {
        event.preventDefault();
        event.stopPropagation();

        const newVisibleState= !this.layer.getVisible();
        this.layer.setVisible(newVisibleState);
        this.overViewLayer.setVisible(newVisibleState);

        this.layer.set('isVisible', this.layer.getVisible());
        this.visbilityChanged.emit(this.layer);
    }

    public refresh(event) {
        event.preventDefault();
        event.stopPropagation();
        this.refreshLayer.emit(this.layer);
    }

    private getClassName(apiLayer: ApiLayer) {
        return 'layer-' + apiLayer.id;
    }
}
