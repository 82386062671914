<button mat-fab i18n-aria-label="@@account" aria-label="Account" (click)="openUserBox($event);">
    <mat-icon>account_circle</mat-icon>
</button>

<!--
<mat-menu #userMenu="matMenu" [hasBackdrop]="true" [overlapTrigger]="false">
    <button *ngIf="logoutEnabled" mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        Logout
    </button>
    <ng-container *ngIf="multiLanguage">
        <button mat-menu-item (click)="switchLanguage('de')" i18n="@@german">German</button>
        <button mat-menu-item (click)="switchLanguage('en')" i18n="@@english">English</button>
    </ng-container>
    <div mat-menu-item>{{frontendVersion}}</div>
</mat-menu> -->

<ng-template #profileBox>
    <div class="profile-box mat-menu-panel">
        <ng-container  *ngIf="userProfile$ | async; let userProfile">
            <div class="profile-picture-wrapper">
                <!-- <div class="profile-picture" ><img appProfilePictureFallback [src]="userProfile.picture"></div> -->
                <div class="profile-picture" [style.backgroundImage]="'url(' + userProfile.picture + ')'"></div>
            </div>
            <div class="profile-details-wrapper">
                <div><h3>{{userProfile.username}}</h3></div>
            </div>
        </ng-container>
        <div class="profile-buttons">
            <button mat-stroked-button *ngIf="logoutEnabled" (click)="logout()">
                <mat-icon>logout</mat-icon>
                Logout
            </button>
            <ng-container *ngIf="multiLanguage">
                <mat-select [(ngModel)]="currentLang" class="language-switcher" (selectionChange)="languageSelected($event)">
                    <ng-container *ngFor="let lang of languages">
                        <mat-option [value]="lang" (click)="$event.preventDefault(); $event.stopPropagation()" >
                            {{lang.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </ng-container>
        </div>
        <div class="version">{{frontendVersion}}</div>
    </div>
</ng-template>
