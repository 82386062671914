import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Layer from 'ol/layer/Layer';
import {Collection, Map} from 'ol';
import BaseLayer from 'ol/layer/Base';

@Component({
    selector: 'app-layers-menu',
    templateUrl: './layers-menu.component.html',
    styleUrls: ['./layers-menu.component.scss']
})
export class LayersMenuComponent implements OnInit {

    @ViewChild('indeterminateInput') inputCheckBox: ElementRef;

    @Output()
    public refreshLayer = new EventEmitter<BaseLayer>();

    private _layers: BaseLayer[]= [];
    private _overViewLayers: BaseLayer[] = [];
    private _systemLayers: BaseLayer[] = [];

    constructor() {
    }

    @Input()
    set layers(layers: BaseLayer[]) {
        this._layers = layers.filter(x => x.get('isBackground') === false);
        this._systemLayers = layers.filter(x => x.get('isSystem') === true);
    }
    get layers() {
        return this._layers;
    }

    @Input()
    set ovLayers(layers: BaseLayer[]){
      this._overViewLayers = layers.filter(x => x.get('isBackground') === false);
    }

    get ovLayers(){
        return this._overViewLayers; // .sort((a, b) => b.getZIndex() - a.getZIndex());
    }

    get systemLayers() {
        return this._systemLayers;
    }

    ngOnInit(): void {
        // interval(1000).subscribe(() => this.checkIntermediate());
    }

    public moveUp(currentIndex) {
        const nextIndex = currentIndex + 1;
        moveItemInArray(this.layers, currentIndex, nextIndex);
        this.layers.forEach((l, i) => {
            l.setZIndex(this.getZIndex(i));
        });
        this._overViewLayers.forEach((l, i) => {
            l.setZIndex(this.getZIndex(i));
        });
    }

    public moveDown(currentIndex) {
        const nextIndex = currentIndex - 1;
        moveItemInArray(this.layers, currentIndex, nextIndex);
        this.layers.forEach((l, i) => {
            l.setZIndex(this.getZIndex(i));
        });
        this._overViewLayers.forEach((l, i) => {
            l.setZIndex(this.getZIndex(i));
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.layers, event.previousIndex, event.currentIndex);

        this.layers.forEach((l, i) => {
            l.setZIndex(this.getZIndex(i));
        });
    }

    private getZIndex(i) {
        const len = this.layers.length + 1;
        return len - i - 1;
    }

    public toggleAllLayers(event) {
        event.preventDefault();
        event.stopPropagation();

        const checked = event.target.checked;
        this._layers.map(x => x.setVisible(checked));
        this._systemLayers.map(x => x.setVisible(checked));
        this._overViewLayers.map(x => x.setVisible(checked));
        // for (const l of this._layers) {
        // l.setVisible(checked);
        // }
    }

    public checkIndeterminate() {
        const states = this._layers.map(x => x.getVisible());
        const unique = [...new Set(states)];


        if (unique.length === 2) {
            (this.inputCheckBox.nativeElement as HTMLInputElement).indeterminate = true;
        } else if (unique.length === 1) {
            (this.inputCheckBox.nativeElement as HTMLInputElement).indeterminate = false;
            (this.inputCheckBox.nativeElement as HTMLInputElement).checked = unique[0];
        }
    }

    public getOverlayLayerfromLayer(apiLayer){
        return this.ovLayers.find( (_layer) => apiLayer.id === (_layer.get('apiLayer')).id);
    }

}
