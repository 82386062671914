import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCheckOperationResult, ApiCheckOperationResultAction } from 'src/app/shared/interfaces/api-checkopertaion';
import { ApiStateValueStatus } from 'src/app/shared/interfaces/api-statevalue';




@Component({
    selector: 'app-check-operation',
    templateUrl: './check-operation.component.html',
    styleUrls: ['./check-operation.component.scss'],
})
export class CheckOperationComponent implements OnInit {

    @Input()
    set addData(data: { currentState; newState; topoActions; topoHints; scadaActions; scadaHints }) {
        this.currentState = data.currentState;
        this.newState = data.newState;
        this.topoActions = data.topoActions;
        this.topoHints = data.topoHints;
        this.scadaActions = data.scadaActions;
        this.scadaHints = data.scadaHints;
    }

    @Output()
    public sendAnswer = new EventEmitter<boolean>();

    topoActions: ApiCheckOperationResult[];
    topoHints: ApiCheckOperationResult[];
    scadaActions: ApiCheckOperationResult[];
    scadaHints: ApiCheckOperationResult[];

    hasForbidden = false;
    hasDischarge = false;
    hasUnlock = false;

    needsUnlock = false;
    needsDischarge = false;

    isUnlocked = false;
    isDischarged = false;

    allowedToExecute = false;

    currentState: ApiStateValueStatus;
    newState: ApiStateValueStatus;

    constructor() {
    }

    ngOnInit() {
        this.checkForForbidden();
    }

    public checkExecution() {


        if (this.hasForbidden) {

            this.allowedToExecute = false;

        } else {

            const unlock = !this.hasForbidden && this.hasUnlock;
            const discharge = !this.hasForbidden && this.hasDischarge;

            this.needsDischarge = discharge;
            this.needsUnlock = unlock;

            if( this.hasUnlock && this.hasDischarge) {

                this.allowedToExecute = this.isUnlocked && this.isDischarged;

            } else if( this.hasUnlock || this.hasDischarge) {

                this.allowedToExecute = this.isUnlocked || this.isDischarged;

            } else if( !this.hasUnlock && !this.hasDischarge) {

                this.allowedToExecute = true;
            }
        }
    }

    public cancel() {
        this.sendAnswer.emit(false);
    }

    public execute() {
        if (this.allowedToExecute) {
            this.sendAnswer.emit(true);
        }
    }

    private checkForForbidden() {

        const topoDischarge = this.topoActions.find(x => x.requiredAction === ApiCheckOperationResultAction.Discharge);
        const scadaDischarge = this.scadaActions.find(x => x.requiredAction === ApiCheckOperationResultAction.Discharge);

        const topoUnlock = this.topoActions.find(x => x.requiredAction === ApiCheckOperationResultAction.Unlock);
        const scadaUnlock = this.scadaActions.find(x => x.requiredAction === ApiCheckOperationResultAction.Unlock);

        const topoForbidden = this.topoActions.find(x => x.requiredAction === ApiCheckOperationResultAction.Forbidden);
        const scadaForbidden = this.scadaActions.find(x => x.requiredAction === ApiCheckOperationResultAction.Forbidden);

        this.hasForbidden = !!topoForbidden || !!scadaForbidden;
        this.hasDischarge = !!topoDischarge || !!scadaDischarge;
        this.hasUnlock = !!topoUnlock || !!scadaUnlock;

        this.checkExecution();
    }
}


@Component({
    selector: 'app-check-operation-dialog',
    template: `<app-check-operation [addData]="inputData" (sendAnswer)="dialogRef.close($event)"></app-check-operation>`
})
export class CheckOperationDialogComponent implements OnInit {

    public inputData;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: {
            currentState;
            newState;
            topoActions;
            topoHints;
            scadaActions;
            scadaHints;
        },
        public dialogRef: MatDialogRef<CheckOperationDialogComponent>
    ) {}

    ngOnInit() {
        this.inputData = this.data;
    }
}
