import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';

import {
    SchemaLoadingIndicatorState,
    SchemaStatusMessage,
    SchemaStatusMessageId
} from '../schema-menu/schema-menu.component';
import {MatDialog} from '@angular/material/dialog';
import {
    ObisConfig,
    ObisConfigData,
    ObisResponse,
    ObisResponseValues,
    ObiValueSelect
} from '../../shared/_ObisSchema/Models/Obis';
import {ObisHelpersService} from './Service/obis-helpers.service';


interface GrafanaUrlParams {
    epvi: string[];
    avi: string[];
    vvi: string[];
    kvi: string[];
};

@Component({
  selector: 'app-schema-obis-menu',
  templateUrl: './schema-obis-menu.component.html',
  styleUrls: ['./schema-obis-menu.component.scss'],
})
export class SchemaObisMenuComponent implements OnInit, OnDestroy {
    public loadingIndicatorStates = SchemaLoadingIndicatorState;
    public statusMessages: SchemaStatusMessage[] = [];

    private refreshIntervalId: number | undefined;
    public refreshWaitTimesObject = {
        '10s': 10 * 1000,
        '20s': 20 * 1000,
        '30s': 30 * 1000
    };
    get refreshWaitTimes(): string[]{
        return Object.keys(this.refreshWaitTimesObject);
    }

    public refreshSelected = '10s';

    public isLoading = true;
    public loaded = false;
    public hasErrors = false;
    public hasErrorHints = false;
    public enableGrafana = true;

    public selectedValueObject: { [meterPointId: string]: ObiValueSelect[] }= {};

    private _measuringSpotId: string[] = [];

    @Input() title: string | undefined;
    @Input() set measuringSpotId(value: string[]){
        value = (Array.isArray(value)) ? value : [value];
        this._measuringSpotId = value;

        this.refreshTimeChanged(this.refreshWaitTimesObject[this.refreshSelected]);
        this.load(this._measuringSpotId);

    }
    get measuringSpotId(): string[]{
        return this._measuringSpotId;
    };

    private _config: ObisConfig;
    @Input() set config(value: ObisConfig) {
        value.data = this.setTitleLanguage(value.data);
        this._config = value;
    }
    get config(): ObisConfig{
        return this._config;
    }

    private _apiUrl: string;
    @Input() set apiUrl(value: string){
        this._apiUrl = value;
    };
    get apiUrl(): string{
        return this._apiUrl;
    }

    get selectedNumericValues(): ObisResponseValues[]{
        const selectedKeys = Object.keys(this.selectedValueObject);
        if(selectedKeys.length === 0){
            return [];
        }
        const selectedValues = [];
        selectedKeys.forEach((meterPointId: string) => {
            this.selectedValueObject[meterPointId].forEach( (elem: ObiValueSelect) => {
                selectedValues.push(elem.element);
            });
        });
        return selectedValues;
    }

    @Output()
    public closeMenu = new EventEmitter();

    @Output() openGrafanaHistory = new EventEmitter<GrafanaUrlParams>();

    @Input() isGrafanaOpen = false;
    public measurementsData: ObisResponse[] = [];

    @ViewChild('errorTemplate') errorTemplate: TemplateRef<any>;

    constructor(
        private obisService: ObisHelpersService,
        private dialog: MatDialog,
    ) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void{
        clearInterval(this.refreshIntervalId);
    }

    load(measuringSpotId: string[]){
        this.isLoading = (!this.loaded);
        this.statusMessages = [];

        this.obisService.getMeasuringData(measuringSpotId, this.apiUrl)
            .subscribe({
                next: (data: ObisResponse[]) => {
                    if(!this.loaded){
                        data.forEach(x => {
                            x.values.forEach(y => { y.selected = false; });
                            this.selectedValueObject[x.meterPoint] = [];
                        });
                    } else {
                        data.forEach(obiResponse => {
                            if( this.selectedValueObject[obiResponse.meterPoint].length > 0){
                                obiResponse.values.forEach(obiResponseValue => {
                                    obiResponseValue.selected = this.selectedValueObject[obiResponse.meterPoint].some(obiSelectedValue => obiSelectedValue.element.element === obiResponseValue.element);
                                });
                            }
                        });
                    }

                    this.measurementsData = data;
                    this.isLoading = false;
                    this.loaded = true;
                },
                error: (error) => {
                    // console.log('error load measuring data', error);
                    this.pushMessage(SchemaStatusMessageId.LoadSchema,
                        $localize`:@@noData:No data`,
                        SchemaLoadingIndicatorState.Error);
                }
            });
    }

    setTitleLanguage(value: ObisConfigData[] ): ObisConfigData[] {
        const LOCALIZE_LANGUAGE = {
            voltage: $localize`:@@obisMeasurements.voltage:voltage`,
            electricity: $localize`:@@obisMeasurements.electricity:electricity`,
            capacity: $localize`:@@obisMeasurements.capacity:capacity`,
        };
        return value.map( (x: ObisConfigData) => {
            x.$title = LOCALIZE_LANGUAGE[x.title];
            return x;
        });
    }
    refreshTimeChanged(val: any){
        clearInterval(this.refreshIntervalId);
        this.refreshIntervalId = setInterval(() => {
            this.load(this._measuringSpotId);
        }, val);
    }

    updateSelectedValues(value: ObiValueSelect){
        if(value.state){
            this.selectedValueObject[value.meterPoint].push(value);
        }else{
            const indexToDelete = this.selectedValueObject[value.meterPoint].findIndex(x => x.element.vivavisId === value.element.vivavisId);
            this.selectedValueObject[value.meterPoint].splice(indexToDelete, 1);
        }
        if(this.isGrafanaOpen){
            this.clickOpenGrafanaHistory();
        }
    }

    pushMessage(id: SchemaStatusMessageId,
                message: string,
                state: SchemaLoadingIndicatorState = SchemaLoadingIndicatorState.Loading
    ){
        if (state === SchemaLoadingIndicatorState.Error) {
            this.hasErrors = true;
        }

        if (state === SchemaLoadingIndicatorState.ErrorHint) {
            this.hasErrorHints = true;
        }

        const sm = this.statusMessages.find(x => x.id === id);

        if (sm) {
            sm.message = message;
            sm.state = state;
        } else {
            this.statusMessages.push({
                id,
                message,
                state
            });
        }
    }

    getGrafanaUrlParams(): GrafanaUrlParams {
        const voltIds = this.selectedNumericValues.filter((x: ObisResponseValues) => x.unit.toLowerCase() === 'v').map(x => x.vivavisId);
        const ampereIds = this.selectedNumericValues.filter(x => x.unit.toLowerCase() === 'a').map(x => x.vivavisId);
        const kwIds = this.selectedNumericValues.filter(x => x.unit.toLowerCase() === 'kw').map(x => x.vivavisId);
        const kvarIds = this.selectedNumericValues.filter(x => x.unit.toLowerCase() === 'kvar').map(x => x.vivavisId);
        return { epvi: kwIds, avi: ampereIds, vvi: voltIds, kvi: kvarIds };
    }
    clickOpenGrafanaHistory(){
       this.openGrafanaHistory.emit(this.getGrafanaUrlParams());
    }

    public closeSchema() {
        this.closeMenu.emit();
    }

    closeLoadingHint(){
        this.hasErrorHints = false;
        this.hasErrors = false;
        this.isLoading = false;
    }

}
