import {DrawerEvent} from '../services/map.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BreakpointObserver} from '@angular/cdk/layout';
import {LocationStrategy} from '@angular/common';
import {
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatDrawer} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import * as Coordinates from 'coordinate-parser';
import {MD5} from 'object-hash';
import {Collection, Feature} from 'ol';
import {defaults as defaultControls} from 'ol/control';
import OverviewMap from 'ol/control/OverviewMap';
import BaseEvent from 'ol/events/Event';
import {getBottomRight, getCenter, getTopLeft} from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import {Geometry} from 'ol/geom';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import {defaults as defaultInteractions, DragAndDrop, Translate} from 'ol/interaction';
import Select from 'ol/interaction/Select';
import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import {default as olMap}  from 'ol/Map';
import Overlay from 'ol/Overlay';
import {fromLonLat, toLonLat, transform, transformExtent} from 'ol/proj';
import {XYZ} from 'ol/source';
import TileSource from 'ol/source/Tile';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import View from 'ol/View';
import {fromEvent, merge, Observable, of, skip, Subject} from 'rxjs';
import {concatMap, debounceTime, filter, map, takeUntil, tap} from 'rxjs/operators';
import Config, {AppConfigInterface} from 'src/app/config/Config';
import {ApiMapdownloadService} from 'src/app/shared/api-services/api-mapdownload.service';
import {ApiIdent} from 'src/app/shared/interfaces/api-ident';
import {ActionQueueService} from 'src/app/shared/services/action-queue.service';
import {ServerStatusService} from 'src/app/shared/services/server-status.service';
import {environment} from 'src/environments/environment';

import {ApiIdentService} from '../../shared/api-services/api-ident.service';
import {ApiLayer, ApiMapType} from '../../shared/interfaces/api-layer';
import {ApiConfigMarker} from '../../shared/interfaces/api-marker';
import {
    ConnectionStatus,
    ConnectionStatusService,
    ConnectionStatusState
} from '../../shared/services/connection-status.service';
import {LayersService} from '../../shared/services/layers.service';
import {ProjectionsService} from '../../shared/services/projections.service';
import {StyleService} from '../../shared/services/style.service';
import {TileRepositoryService} from '../../shared/services/tile-repository.service';
import {UpdateFeatureService} from '../../shared/services/updatefeature.service';
import {MapPopupComponent, MapPopupConfig} from '../map-popup/map-popup.component';
import {PopupState} from '../map-popup/popupstate.enum';
import {DrawerState, DrawerType, MapService} from '../services/map.service';
import {MarkerService} from '../services/marker.service';
import {MarkerType} from '../../shared/interfaces/api-marker';
import {AppMapLayer, BasicLayerState} from '../../shared/services/layers.service';
import {DownloadAreaConfig, DownloadAreaConfigState} from '../map-popup/download-area/download-area.component';
import {APP_CONFIG} from 'src/app/app.module';
import {ConsoleLoggingService} from '../../shared/services/console-logging.service';
import {Circle} from 'ol/style';
import {ObisConfig} from '../../shared/_ObisSchema/Models/Obis';
// import TouchEmulator from 'hammer-touchemulator';

import {applyStyle, applyBackground} from 'ol-mapbox-style';
import VectorTileLayer from 'ol/layer/VectorTile';


@Component({
    selector: 'app-main-map',
    templateUrl: './main-map.component.html',
    styleUrls: ['./main-map.component.scss'],
    providers: [LayersService, TileRepositoryService],
    animations: [
        trigger('transformDrawer', [
            state('open, open-instant', style({
                transform: 'none',
                visibility: 'visible',
            })),
            state('void', style({
                'box-shadow': 'none',
                visibility: 'hidden',
            })),
            transition('void => open-instant', animate('0ms')),
            transition('void <=> open, open-instant => void',
                animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
        ])
    ]
})
export class MainMapComponent implements OnInit, OnDestroy {




    @ViewChild('mapContainer') mapContainer;
    @ViewChild(MapPopupComponent) mapPopup;
    @ViewChild('drawer') drawer: MatDrawer;
    @ViewChild('templateBottomSheet') templateBottomSheet: TemplateRef<any>;
    @ViewChild('grafanaIFrame') grafanaIFrame!: ElementRef<any>;


    public map: olMap = null;
    public mapLayers: BaseLayer[] = [];

    public hoveredFeature: Feature<Geometry>;
    public drawerOpened = false;
    public activeMarker: ApiConfigMarker;
    public schemaCollapsed = false;
    public bottomSheetOpened = false;

    public connectionStatusEnum = ConnectionStatusState;

    public searchControl = new FormControl();
    public filteredFeatureProperties: Observable<Feature<Geometry>[]>;

    public topDrawerUrl: SafeResourceUrl;

    public enableGrafana = Config.frontend?.dashboardUrl || false;
    public enableInfoButton = Config.frontend?.infoUrl || false;
    public enableUploadGeoJson = false;
    public enableMarkerMenu = Config.frontend?.enableComponents?.marker ?? true;

    public showActionQueue = false;
    public showAirplaneMode = environment.mobile && (environment.develop || environment.staging);
    public isMobile = environment.mobile;
    public isDevelop = environment.develop;
    public isOffline = false;
    public hasBeenOffline = false;

    public identData = [];
    public identRequests: Observable<ApiIdent[]>[] = [];

    public cursorStyle = this.sanitizer.bypassSecurityTrustStyle('unset');

    // private backgroundLayers = [];
    public overviewMapControlCollapsed = true;
    public overviewMapControl: OverviewMap;
    public overviewMapLayers: BaseLayer[] = [];
    public overviewMapBackgroundLayer: LayerGroup = new LayerGroup({zIndex: 0});
    // public backgroundLayers2: TileLayer<XYZ | WMTS>[] = [];
    public backgroundLayers3: AppMapLayer[] = [];
    public selectedBackgroundLayer: TileLayer<TileSource> | VectorTileLayer;
    public backgroundTileLayer: TileLayer<TileSource> = new TileLayer({ zIndex: 0 });
    public backgroundVectorLayer: LayerGroup = new LayerGroup({ zIndex: 0 });

    public isBackgroundVector = false;
    public temporaryDataLayer: VectorLayer<VectorSource<Geometry>> = new VectorLayer();
    public schemaPicNo: number | undefined = undefined;
    public measuringSpot: string[] | undefined = undefined;
    public measuringSpotTitle: string | undefined = undefined;
    public schemaStyle: string | undefined = undefined;

    public mapPopupConfig: MapPopupConfig;

    private _showBottomDrawer = false;
    private _showTopDrawer = false;

    private allCoordinates = [];
    private _selectedFeature: Feature<Geometry>;

    private systemMarkerLayer: VectorLayer<VectorSource<Geometry>>;
    private markerLayer: VectorLayer<VectorSource<Geometry>>;
    public markerLayerGroup: LayerGroup = new LayerGroup();
    private markerFeatures: Feature<Geometry>[] = [];

    private layers: { [key: string]: Layer<any, any> } = {};

    // private hoverMarkerInteraction: Select;
    // private selectMarkerInteraction: Select;
    private addMarkerInteraction: Select;

    private drawLayer: VectorLayer<VectorSource<Geometry>>;
    private drawDownloadAreaInteraction: DragAndDrop;
    private selectDownloadArea: Select;
    private moveDownloadArea: Translate;
    private downloadAreaBbox: number[];

    private bboxLayer: VectorLayer<VectorSource<Geometry>>;

    private overlay: Overlay;

    private shouldUpdateUrl = true;
    private startLon = Config.frontend?.view.init.lon;
    private startLat = Config.frontend?.view.init.lat;
    private startZoom = Config.frontend?.view.init.zoom;
    private startSchema = null;
    private startObisSchema = null;
    private startBBox = this.appConfig.frontend.view.bbox || [];

    private lastZoom;
    private lastCenter;
    private hasQueryParams = false;

    private mapMoveEnd = new Subject<void>();
    private mapMoveEnd$: Observable<any>;

    private updateMarker = new Subject<void>();
    private updateMarker$: Observable<void>;

    private destroy$ = new Subject<void>();

    private handledBySelect = false;
    private expectedMarker: { vivavisId: string; markerType: MarkerType };

    private updateLayerIntervals: any = {};
    // private lastTileTimestamp = new Date().getTime();

    constructor(
        @Inject(APP_CONFIG) private appConfig: AppConfigInterface,
        private dialog: MatDialog,
        private layersService: LayersService,
        private projectionsService: ProjectionsService,
        private mapService: MapService,
        private actionQueueService: ActionQueueService,
        private snackBar: MatSnackBar,
        private styleService: StyleService,
        private markerService: MarkerService,
        private breakpointObserver: BreakpointObserver,
        private connectionStatusService: ConnectionStatusService,
        private apiIdent: ApiIdentService,
        private apiMapDownload: ApiMapdownloadService,
        private serverStatusService: ServerStatusService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private locationStrategy: LocationStrategy,
        private updateFeatureService: UpdateFeatureService,
        private logging: ConsoleLoggingService) {

        this.mapMoveEnd$ = this.mapMoveEnd.asObservable();
        this.updateMarker$ = this.updateMarker.asObservable().pipe(filter(() => this.enableMarkerMenu === true)).pipe(debounceTime(500));

        if (this.isDevelop) {
            this.showActionQueue = false;
        }

        this.backgroundTileLayer.set('name', 'bg');
        this.backgroundTileLayer.set('isBackground', true);

        this.route.queryParams
            .pipe(takeUntil(this.destroy$))
            .subscribe(p => {
                if (p.lat && p.lon && p.zoom) {
                    const goto = !isNaN(p.lat) && !isNaN(p.lon) && !isNaN(p.zoom);
                    if (goto) {
                        this.startLat = p.lat;
                        this.startLon = p.lon;
                        this.startZoom = p.zoom;
                        this.hasQueryParams = true;
                    }
                    if (p.schema && !isNaN(p.schema)) {
                        this.startSchema = p.schema;
                        setTimeout(() => {
                            this.openDrawer({
                                drawer: DrawerType.Bottom,
                                state: DrawerState.Open,
                                picNo: p.schema
                            });
                        }, 500);
                    }
                    if(p.obisSchema) {
                        this.startObisSchema = p.obisSchema;
                        setTimeout(() => {
                            this.openDrawer({
                                drawer: DrawerType.Bottom,
                                state: DrawerState.Open,
                                measuringSpot: p.obisSchema.split(',')
                            });
                        }, 500);
                    }
                } else {
                    this.hasQueryParams = false;
                }
            });

        this.subscribeToEvents();
    }


    @HostListener('window:keydown', ['$event'])
    keyboardInteractions(event: KeyboardEvent) {
        if (this.addMarkerMode) {
            switch (event.key) {
                case 'Esc':
                case 'Escape':
                    this.cancelAddMarkerMode();
                    break;
            }
        }
    }

    ngOnInit(): void {
        this.logging.logDebug('Map starting');

        setTimeout(() => {
            this.restoreMapState();
            this.setupMap();
        });
        // this.printLayers();
    }

    ngOnDestroy(): void {
        // this.subscriptions.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    public set addMarkerMode(value: boolean) {
        this.mapService.addMarkerMode = value;
    }

    public get addMarkerMode(): boolean {
        return this.mapService.addMarkerMode;
    }

    public set showTopDrawer(value: boolean) {
        this._showTopDrawer = value;
    }

    public get showTopDrawer(): boolean {
        return this._showTopDrawer;
    }

    public get availableMarkers(): ApiConfigMarker[] {
        return [];
    }

    public get selectedFeature(): Feature<Geometry> {
        return this._selectedFeature;
    }

    public set selectedFeature(v: Feature<Geometry>) {
        this._selectedFeature = v;
        if (v) {
            this.adjustCenter();
        }

    }

    public set showBottomDrawer(v: boolean) {
        this._showBottomDrawer = v;
    }

    public get showBottomDrawer() {
        return this._showBottomDrawer;
    }

    public verboseBrowserDetails() {

        // this.dialog.open(MapDebugDialogComponent);
    }

    public getGeoLocation() {
        if (navigator.geolocation) {

            navigator.geolocation.getCurrentPosition(
                (position) => {

                    const lat = position.coords.latitude;
                    const lon = position.coords.longitude;

                    this.logging.logDebug('navigator.geolocation.getCurrentPosition', {lat, lon, position});

                    const projection = this.map.getView().getProjection();
                    const coords = fromLonLat([lon, lat], projection);

                    this.map.getView().setCenter(coords);
                    this.map.getView().setZoom(18);

                },
                err => {
                    this.logging.log('Error: ', err);
                },
                {
                    enableHighAccuracy: true
                });


        } else {
            this.logging.log('navigator.geolocation missing');
        }
    }

    public uploadGeoJson(blobUrl: string) {

        this.temporaryDataLayer.setSource(new VectorSource({
            format: new GeoJSON(),
            url: blobUrl,
        }));

        this.temporaryDataLayer.setVisible(true);

        setTimeout(() => {
            const extent = this.temporaryDataLayer.getSource().getExtent();
            this.map.getView().fit(extent, { padding: [25, 25, 25, 25] });
        }, 200);
    }

    public removeUploadGeoJson(data: any) {
        this.temporaryDataLayer.setSource(new VectorSource());
    }

    public searchResult(f: Feature<Geometry>) {
        this.clearSelectedFeature();
        if (f) {
            const fromGeoCoder = f.get('geocoder');
            const bbox = f.get('bbox');
            let extent;

            if (bbox) {
                extent = bbox;
            } else {
                extent = f.getGeometry().getExtent();
            }

            // eslint-disable-next-line @typescript-eslint/dot-notation
            const data = f['values_']['searchResult'];
            this.map.getView().fit(extent);

            if (!fromGeoCoder) {

                const reqs = [of([data])];
                this.identRequests = reqs;
                this.mapPopupConfig = {
                    identRequests: reqs,
                    popupState: PopupState.Properties
                };

                const coordinates = (f.getGeometry() as Point).getCoordinates();

                setTimeout(() => {
                    this.showPopup(f);
                }, 500);
            }
        }
    }

    /**
     * @deprecated
     **/
    public openSettingsDialog() {
        const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 1024px)');
        let w = '60%';
        let h = '50%';

        if (isSmallScreen) {
            w = '75%';
            h = '60%';
        }
    }

    public closePopup() {
        this.bboxLayer.getSource().clear();
        this.clearSelectedFeature();
        this.overlay.setPosition(undefined);
    }

    openDrawer(event: DrawerEvent) {
        this.closeBottomDrawer();
        switch (event.drawer) {
            case DrawerType.Top:
                this.topDrawerUrl = event.url;
                this.showTopDrawer = event.state === DrawerState.Open;
                break;
            case DrawerType.Bottom:
                this.showBottomDrawer = event.state === DrawerState.Open;
                if(event.picNo){
                    this.schemaPicNo = event.picNo;
                    this.schemaStyle = event.schemaType;
                    this.startSchema = this.schemaPicNo;
                }else if(event.measuringSpot){
                    this.measuringSpot = event.measuringSpot;
                    this.measuringSpotTitle = event.measuringSpotTitle;
                    this.startObisSchema = this.measuringSpot;
                }
                break;

        }
        this.mapMoved();
    }

    public getObisSchemaConfig(): ObisConfig{
        return this.appConfig.frontend.schemaStyle.obis;
    }

    public getObisSchemaApiUrl(){
        const styleUrl: string | null = this.appConfig.frontend.schemaStyle.obisMeasurementsUrl;
        if(styleUrl !== null){
            Config.Api.complexMeasurementService = styleUrl;
        }
        return Config.Api.complexMeasurementService;
    }

    public closeBottomDrawer() {
        this.schemaPicNo = undefined;
        this.schemaStyle = undefined;
        this.startSchema = null;
        this.startObisSchema = null;
        this.measuringSpot = undefined;
        this.showBottomDrawer = false;
        this.mapMoved();
        // this.openDrawer({
        //     drawer: DrawerType.Bottom,
        //     state: DrawerState.Close,
        // });
    }

    /**
     * @deprecated
     */
    public openSchemaDrawer(picno) {
        this.logging.logDebug('openSchemaDrawer', picno);
        this.schemaPicNo = picno;
        this.showBottomDrawer = true;

        if (this.selectedFeature) {
            this.selectedFeature.set('schema', picno);
            this.startSchema = picno;
            this.mapMoved();
        }
    }

    public async markerAdded(expectedMarker) {
        this.expectedMarker = expectedMarker;
        this.mapService.addMarkerMode = false;
        this.bboxLayer.getSource().clear();
        this.cancelAddMarkerMode();

        setTimeout(() => this.overlay.setPosition(undefined), 1000);

        if (this.isMobile || this.updateFeatureService.isWebSocketConnected === false) {
            this.updateFeatureService.forceUpdate();
        }
    }

    public async markerRemoved() {
        setTimeout(() => {
            this.overlay.setPosition(undefined);
        }, 500);
    }

    public async toggleOnOfflineStatus() {
        // const serverStatus = await this.apiServerStatus.getStatus().toPromise();
        // this.isOffline = serverStatus.message === 'offline';
        // this.isOffline = this.connectionStatus === ConnectionStatus.Offline;

        if (this.isOffline) {
            this.serverStatusService.goOnline();
        } else {
            this.serverStatusService.goOffline();

        }
    }

    public toggleSchemaCollapse(collapsed) {
        this.schemaCollapsed = collapsed;
    }

    public cleanGrafanaUrlParameterIds(ids: string[]) {
        const str = ids.join(',');
        return `${str}`;
    }

    private infoUrl: SafeResourceUrl | undefined;
    public openInfoUrl(open: boolean) {
        let url = Config.frontend.infoUrl;

        if (url) {

            url = url.startsWith('http') || url.startsWith('//') ? url : environment.root + url;

            // if (environment.develop && !environment.mobile && !url.startsWith('http')) {
            //     url = environment.root + url;
            // }


            if(this.infoUrl === undefined){
                this.infoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            }
            if (open) {
                this.topDrawerUrl = this.infoUrl;
            }

            this.showTopDrawer = open;
        }
    }

    public openGrafanaDrawer(open: boolean, history: boolean = false, ids: any = null, historyUrl: string = Config.frontend.schema.grafanaHistoryUrl) {
        let url = Config.frontend.dashboardUrl;
        if (history && ids) {

            url = historyUrl;
            this.logging.logDebug('grafeHistoryURL', {url, open, history, ids});

            if (ids.epvi?.length > 0) {
                const epviString = this.cleanGrafanaUrlParameterIds(ids.epvi);
                url = url.replace('${epvi}', epviString);
            } else {
                const epviChunk = '&var-EPVI=${epvi}';
                const indexAvi = url.indexOf(epviChunk);
                if (indexAvi !== -1) {
                    url = url.replace(epviChunk, '');
                }
            }

            if (ids.avi?.length > 0) {
                const aviString = this.cleanGrafanaUrlParameterIds(ids.avi);
                url = url.replace('${avi}', aviString);
            } else {
                const aviChunk = '&var-AVI=${avi}';
                const indexAvi = url.indexOf(aviChunk);
                if (indexAvi !== -1) {
                    url = url.replace(aviChunk, '');
                }
            }

            if (ids.vvi?.length > 0) {
                const vviString = this.cleanGrafanaUrlParameterIds(ids.vvi);
                url = url.replace('${vvi}', vviString);
            } else {
                const vviChunk = '&var-VVI=${vvi}';
                const indexAvi = url.indexOf(vviChunk);
                if (indexAvi !== -1) {
                    url = url.replace(vviChunk, '');
                }
            }

            if (ids.kvi?.length > 0) {
                const kviString = this.cleanGrafanaUrlParameterIds(ids.kvi);
                url = url.replace('${kvi}', kviString);
            } else {
                const kviChunk = '&var-KVI=${kvi}';
                const indexAvi = url.indexOf(kviChunk);
                if (indexAvi !== -1) {
                    url = url.replace(kviChunk, '');
                }
            }
        }

        url = url.startsWith('http') || url.startsWith('//') ? url : environment.root + url;

        // if (environment.develop && !environment.mobile && !url.startsWith('http')) {
        //     url = environment.root + url;
        // }

        if (open) {
            this.topDrawerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }

        this.showTopDrawer = open;
    }

    public iFrameOnload(e){
        // console.log('iframeonload',e, this.grafanaIFrame);
        if(this.grafanaIFrame && Config.frontend.debug.dev){
            const contentWindow = this.grafanaIFrame.nativeElement.contentWindow;
            // console.log('test', contentWindow.getElementById('TimePickerContent'));
            // const varService = contentWindow.angular
            //     .element('grafana-app')
            //     .injector()
            //     .get('variableSrv');
            // console.log(varService);
            console.log('iframeonload',e, this.grafanaIFrame.nativeElement.contentWindow.location.href);
            fromEvent(this.grafanaIFrame.nativeElement, 'load')
                // Skip the initial load event and only capture subsequent events.
                .pipe(skip(1))
                .subscribe((event: Event) => console.log('fromEvent',event.target));
        }

    }

    public openGrafanaHistory(ids) {
        this.showTopDrawer = true;
        this.openGrafanaDrawer(true, true, ids);
    }

    public openGrafanaHistorybyBoardId(ids){
        const url = Config.frontend.schemaStyle.obisMeasurementsGrafanaUrl;
        this.showTopDrawer = true;
        this.openGrafanaDrawer(true, true, ids, url);

    }

    public setCursorStyle() {
        this.cursorStyle = this.mapService.getAddMarkerModeCursorStyle(this.activeMarker);
    }

    public addMarker(marker: ApiConfigMarker) {
        this.addMarkerMode = true;
        this.activeMarker = marker;
        // this.map.removeInteraction(this.selectMarkerInteraction);
        // this.map.removeInteraction(this.hoverMarkerInteraction);
        this.setCursorStyle();
    }

    public cancelAddMarkerMode() {
        this.addMarkerMode = false;
        this.activeMarker = null;
        // this.map.addInteraction(this.selectMarkerInteraction);
        // this.map.addInteraction(this.hoverMarkerInteraction);
        this.setCursorStyle();
    }

    public updateOverlayPosition() {
        const ex = this.selectedFeature.getGeometry().getExtent();
        const center = getCenter(ex);
        this.overlay.setPosition(center);
    }

    public changeBackgroundLayer3(selectedBgLayer: AppMapLayer, timedout = false) {
        const appMapLayer = selectedBgLayer;
        if (selectedBgLayer.state === BasicLayerState.Loading) {
            if (!timedout) {
                setTimeout(() => {
                    this.changeBackgroundLayer3(selectedBgLayer, true);
                }, 1000);
                return;
            }
        }

        if (appMapLayer.state === BasicLayerState.Loaded) {
            this.selectedBackgroundLayer = appMapLayer.layer;
        } else {
            this.selectedBackgroundLayer = this.backgroundLayers3.find(x => x.state === BasicLayerState.Loaded).layer;
        }

        if(this.selectedBackgroundLayer instanceof TileLayer
            ){
            this.backgroundTileLayer.set('name', this.selectedBackgroundLayer.get('name'));
            this.backgroundTileLayer.set('apiLayer', this.selectedBackgroundLayer.get('apiLayer'));
            this.backgroundTileLayer.set('layerId', this.selectedBackgroundLayer.get('layerId'));
            this.backgroundTileLayer.setSource(this.selectedBackgroundLayer.getSource() as TileSource as any);

            const overviewTileLayer = new TileLayer({ zIndex: 0 });
            overviewTileLayer.setSource(this.selectedBackgroundLayer.getSource());
            this.overviewMapBackgroundLayer.setLayers(new Collection([
                overviewTileLayer
            ]));

            if(this.overviewMapControl){
                this.overviewMapControl.getOverviewMap().setLayers(new Collection([this.overviewMapBackgroundLayer]));
            }

            this.backgroundTileLayer.getSource().on('tileloaderror', event => this.handleTileLoadError(event, this.selectedBackgroundLayer as TileLayer<TileSource>));
            this.backgroundTileLayer.set('visible', true);
            this.backgroundVectorLayer.set('visible', false);
            this.saveMapState();
        }else{

            const styleConfKeys = appMapLayer.apiLayer.shaping.split('://');
            if(styleConfKeys[0] === 'gpConfig'){

                const styleJSON = this.appConfig.frontend.Basemap.vectorStyles[styleConfKeys[1]];
                this.backgroundVectorLayer.setLayers(new Collection([]));
                console.log('style JOSON', styleJSON);
                this.backgroundVectorLayer.set('name', this.selectedBackgroundLayer.get('name'));
                this.backgroundVectorLayer.set('apiLayer', this.selectedBackgroundLayer.get('apiLayer'));
                this.backgroundVectorLayer.set('layerId', this.selectedBackgroundLayer.get('layerId'));


                const vecLayer = new VectorTileLayer({className: 'basemap'});
                this.setupVectorStyles(vecLayer, styleJSON).then(() => {
                    this.backgroundVectorLayer.setLayers(new Collection([
                        vecLayer
                    ]));

                    if(this.overviewMapControl){
                        const vecOvLayer = new VectorTileLayer({className: 'ov'});
                        this.setupVectorStyles(vecOvLayer, styleJSON).then( () => {
                            vecOvLayer.setSource(vecLayer.getSource());
                            this.overviewMapControl.getOverviewMap().setLayers(new Collection([
                                vecOvLayer
                            ]));
                        });

                    }

                    this.backgroundVectorLayer.set('visible', true);
                    this.backgroundTileLayer.set('visible', false);
                    this.saveMapState();
                });


            }


            // this.backgroundTileLayer = this.selectedBackgroundLayer;

        }

    }


    private async setupVectorStyles(layer: VectorTileLayer, styleGL: JSON){
        await applyBackground(layer, styleGL);
        await applyStyle(layer, styleGL);
    }

    // /**
    //  * @deprecated
    //  * @param selectedBgLayer
    //  */
    // public changeBackgroundLayer2(selectedBgLayer: TileLayer<TileSource>) {
    //     this.selectedBackgroundLayer = selectedBgLayer;
    //     this.backgroundTileLayer.set('name', selectedBgLayer.get('name'));
    //     this.backgroundTileLayer.set('apiLayer', selectedBgLayer.get('apiLayer'));
    //     this.backgroundTileLayer.set('layerId', selectedBgLayer.get('layerId'));
    //     this.backgroundTileLayer.setSource(selectedBgLayer.getSource());
    //     this.overviewMapBackgroundLayer.setSource(selectedBgLayer.getSource());
    //     this.backgroundTileLayer.getSource().on('tileloaderror', event => this.handleTileLoadError(event, selectedBgLayer));
    //     this.saveMapState();
    // }

    public toggleMarkerLayers() {
        const v = this.markerLayerGroup.getVisible();
        this.mapService.setMarkerLayerVisbility(!v);
    }

    public adjustCenter() {
        const extent = this._selectedFeature.getGeometry().getExtent();
        const centroid = getCenter(extent);
        const [lon, lat] = toLonLat(centroid);
        const earthR = 6378137;
        const dn = 100000;
        const dLat = lat * 0.6;
        const lat0 = lat + dLat * 180 / Math.PI;
        const newCentroid = fromLonLat([lon, lat0], this.map.getView().getProjection());

        this.map.getView().animate({
            center: centroid,
            duration: 500,
        });
    }

    public initDownloadAreaPopup() {
        const apiLayer = this.backgroundTileLayer.get('apiLayer') as ApiLayer;

        if (apiLayer.mapType === ApiMapType.Offline) {
            const center = this.map.getView().getCenter();
            const popupConfig: MapPopupConfig = {
                popupState: PopupState.DownloadArea,
                coordinates: {
                    x: center[0],
                    y: center[1]
                }
            };
            this.mapPopupConfig = popupConfig;
            this.showPopupWithPosition(null, center);
        } else {

            this.snackBar.open(
                $localize`:@@downloadArea.error.background:Not available for selected background map`,
                $localize`:@@dismiss:Dismiss`,
                { duration: 5000, verticalPosition: 'top' });
        }
    }

    public cancelDownloadArea() {
        this.downloadAreaBbox = null;
        this.map.removeInteraction(this.selectDownloadArea);
        this.map.removeInteraction(this.moveDownloadArea);
        this.selectDownloadArea = null;
        this.moveDownloadArea = null;
        this.drawLayer.getSource().clear();
        this.handledBySelect = false;
        this.mapPopupConfig = { popupState: PopupState.NoPopup };
        this.closePopup();
    }

    public downloadArea(config: DownloadAreaConfig) {
        // const {x, y, side, state} = config;
        if (config.state === DownloadAreaConfigState.Show) {
            // const transformed = transform([x,y], this.projectionsService.epsg25832Projection, this.map.getView().getProjection());
            const point = new Point([config.x, config.y]);
            const extent = point.getExtent();

            const offset = config.side * .5;
            const a = extent[0] - offset;
            const b = extent[1] - offset;
            const c = extent[2] + offset;
            const d = extent[3] + offset;

            const tl = [extent[0] - offset, extent[1] - offset];
            const tr = [extent[0] - offset, extent[1] + offset];

            const br = [extent[0] + offset, extent[1] + offset];
            const bl = [extent[0] + offset, extent[1] - offset];

            this.downloadAreaBbox = [a, b, c, d];

            const polyCoords = [tl, tr, br, bl];
            const pol = new Polygon([polyCoords]);
            const feature = new Feature(pol);

            this.drawLayer.getSource().clear();
            this.drawLayer.getSource().addFeature(feature);
            this.drawLayer.changed();

            this.map.getView().fit(feature.getGeometry(), { padding: [50, 0, 50, 0] });

            this.selectDownloadArea = new Select({
                layers: [this.drawLayer],
                style: () => this.styleService.downloadAreaStyle()
            });

            this.moveDownloadArea = new Translate({ features: this.selectDownloadArea.getFeatures() });
            this.map.addInteraction(this.selectDownloadArea);
            this.map.addInteraction(this.moveDownloadArea);

            this.moveDownloadArea.on('translateend', evt => {

                if (evt.features && evt.features.getLength() === 1) {
                    const f: Feature<Geometry> = evt.features.getArray()[0];
                    const p: Polygon = (f.getGeometry() as Polygon);
                    const topLeft = getTopLeft(p.getExtent());
                    const bottomRight = getBottomRight(p.getExtent());
                    const center = getCenter(p.getExtent());
                    const nbbox = [topLeft[0], topLeft[1], bottomRight[0], bottomRight[1]];
                    this.mapPopupConfig.coordinates.x = center[0];
                    this.mapPopupConfig.coordinates.y = center[1];
                    this.downloadAreaBbox = nbbox;
                    this.overlay.setPosition(center);
                }
            });

            this.handledBySelect = true;

        } else if (config.state === DownloadAreaConfigState.Download) {

            const mapId = (this.backgroundTileLayer.get('apiLayer') as ApiLayer).id;

            this.apiMapDownload.download(mapId, this.downloadAreaBbox)
                .subscribe({
                    next: data => {
                        this.logging.logDebug('Map Download data: ', data);
                        this.downloadAreaBbox = null;
                        this.drawLayer.getSource().clear();
                        this.handledBySelect = false;
                        this.closePopup();
                        this.cancelDownloadArea();
                    },
                    error: err => {
                        this.logging.log('Error: ', err);
                    }
                });
        }
    }

    private subscribeToEvents() {
        const giantOb = merge<any>(

            this.mapMoveEnd$
                .pipe(
                    debounceTime(250),
                    tap(() => this.mapMoved())
                ),

            this.updateFeatureService.updateColors$
                .pipe(tap(msg => {
                    Object.values(this.layers).forEach(l => {
                        if(l instanceof LayerGroup) {
                            l.getLayersArray().forEach( (_l: Layer<XYZ, any>) => {
                                this.refreshLayer(_l);
                            });
                        }else{
                            this.refreshLayer(l);
                        }
                    });
                })),

            this.updateFeatureService.updateMarkers$
                .pipe(map(() => null))
                .pipe(tap(this.updateMarker)),

            this.connectionStatusService.connectionStatus$
                .pipe(tap(status => this.updateConnectionStatus(status))),

            this.updateMarker$
                .pipe(tap(() => this.retrieveMarkers())),

            this.mapService.addMarkerMode$,

            this.mapService.markerLayerVisibility$
                .pipe(tap(visbility => this.markerLayerGroup.setVisible(visbility))),

            this.mapService.mapPopupSubject$
                .pipe(tap(config => { this.mapPopupConfig = config; })),

            this.mapService.drawer$
                .pipe(tap(this.openDrawer.bind(this))),

            this.layersService.loadLayers(),

            this.layersService.layers$
                .pipe(tap(this.addLayers.bind(this))),

            this.layersService.bgMapLayers$
                .pipe(tap(this.addBackgroundLayers.bind(this))),

            fromEvent(window, 'popstate')
                .pipe(concatMap(ev => this.route.queryParams))
                .pipe(tap((qp) => this.updatePopstate(qp)))
        );


        if (environment.mobile) {

            const actionQueueCount = this.actionQueueService.getCount()
                .pipe(
                    tap(count => {
                        if (count?.waiting > 0) {
                            this.showActionQueue = true;
                        }
                    }),
                    takeUntil(this.destroy$)
                );
            actionQueueCount.subscribe();
        }

        giantOb
            // .pipe(scan((all, n) => ({ ...all, ...n })))
            // .pipe(tap(console.log))
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    private updateConnectionStatus(status: ConnectionStatus) {
        this.isOffline = status.currentStatus === ConnectionStatusState.Offline ? true : false;

        if (status.currentStatus === ConnectionStatusState.Offline && this.hasBeenOffline === false) {
            this.hasBeenOffline = true;
        }

        this.showActionQueue = this.hasBeenOffline && this.isMobile;
    }

    private updatePopstate(qp: any) {
        const center = transform([qp.lon, qp.lat], this.projectionsService.epsg4326Projection, this.projectionsService.currentProjection);
        this.map.getView().setCenter(center);
        this.map.getView().setZoom(qp.zoom);
        this.shouldUpdateUrl = false;
    }

    private saveMapState() {
        if (this.map && this.map.getView()) {
            const currentProj = this.map.getView().getProjection();
            const center = this.map.getView().getCenter();
            const centerLonLat = transform(center, currentProj, this.projectionsService.epsg4326Projection);
            const zoom = this.map.getView().getZoom();
            const bgLayerId = (this.selectedBackgroundLayer instanceof TileLayer) ?
                this.backgroundTileLayer.get('layerId') : this.backgroundVectorLayer.get('layerId');
            const lastMapState = {
                bgLayerId,
                lon: centerLonLat[0],
                lat: centerLonLat[1],
                zoom
            };

            let mapState = this.mapService.getState();
            if (mapState) {
                mapState.map = lastMapState;
            } else {
                mapState = {
                    map: lastMapState
                };
            }

            this.mapService.saveState(mapState);
        }
    }

    private restoreBackgroundLayerState(): boolean {
        const mapState = this.mapService.getState();

        if (mapState?.map && mapState.map.bgLayerId) {
            const bgLayerId = mapState.map.bgLayerId;
            // const bgLayer = this.backgroundLayers2.find(x => x.get('layerId') === bgLayerId);
            const bgLayer = this.backgroundLayers3.find(x => x.apiLayer.id === bgLayerId);
            if (bgLayer) {
                bgLayer.selected = true;
                // this.changeBackgroundLayer2(bgLayer);
                this.changeBackgroundLayer3(bgLayer);
                return true;
            }
        }

        return false;
    }

    private restoreMapState() {
        if (!this.hasQueryParams) {
            const mapState = this.mapService.getState();

            if (mapState?.map) {
                const lat = mapState.map?.lat;
                const lon = mapState.map?.lon;
                const zoom = mapState.map?.zoom;
                if (lat && lon && zoom) {
                    const restoreCoords = !isNaN(lat) && !isNaN(lon) && !isNaN(zoom);
                    if (restoreCoords) {
                        const validCoords = new Coordinates([lat, lon].toString());
                        if (validCoords) {
                            this.startLat = validCoords.getLatitude();
                            this.startLon = validCoords.getLongitude();
                            this.startZoom = zoom;
                        }
                    }
                }
            }
        }
    }

    private triggerLayerUpdate(){
        const layerConfigs = this.appConfig.frontend.layerRefresh;
        const toUpdateLayers = [];

        this.map.getAllLayers().forEach( (mapLayer) => {
            layerConfigs.forEach( (layerConfig) => {
                if(`ol-layer api-layer layer-${layerConfig.layerMapId}` ===  mapLayer.getClassName()){
                    // const _bufferLayer = new TileLayer({
                    //     className: mapLayer.getClassName(),
                    //     source: new XYZ(  mapLayer.getSource().getProperties() )
                    // });

                    const _bufferLayer = this.layersService.getLayer(mapLayer.get('apiLayer'));
                    _bufferLayer.setVisible(false);
                    //not working like expect
                    // _bufferLayer.getSource().set('interpolate', false);

                    // _bufferLayer.setOpacity(0);
                    _bufferLayer.set('isBuffer', true);


                    // mapLayer.on('propertychange', (e) => postRenderFunc(e));
                    // _bufferLayer.getSource().on('tileloadend', (e) => postRenderFunc(e, mapLayer.getSource()));
                    // _bufferLayer.on('postrender', (e) => postRenderFunc(e, mapLayer.getSource()));

                    const bufferGroupLayers = new LayerGroup({
                        //@ts-ignore
                        className: mapLayer.getClassName(),
                        layers: [
                            mapLayer,
                            _bufferLayer
                        ]
                    });

                    bufferGroupLayers.set('apiLayer', mapLayer.get('apiLayer'));
                    bufferGroupLayers.set('name', mapLayer.get('apiLayer').name);
                    bufferGroupLayers.set('layerId', mapLayer.get('apiLayer').id);
                    bufferGroupLayers.set('isBackground', false);
                    bufferGroupLayers.set('overviewmap', true);
                    bufferGroupLayers.set('isVisible', true);
                    // this.map.addLayer(_bufferLayer);

                    //@ts-ignore
                    this.layers[mapLayer.get('apiLayer').id] = bufferGroupLayers;
                    this.map.removeLayer(mapLayer);
                    this.map.addLayer(bufferGroupLayers);
                    //
                    // this.mapLayers.push(bufferGroupLayers);

                    toUpdateLayers.push({layer:mapLayer, config: layerConfig, layerBuffer: _bufferLayer, group: bufferGroupLayers });
                }
            });
        });

        if(toUpdateLayers.length > 0){
            toUpdateLayers.forEach( (updateLayer) => {
                this.logging.logDebug(`init layer update for id ${updateLayer.config.layerMapId}, every ${updateLayer.config.updateInterval} seconds`, updateLayer);
                this.updateLayerIntervals[updateLayer.config.layerMapId] = setInterval( () => {

                    // this.refreshLayerInterval(updateLayer.layer, updateLayer.layerBuffer, updateLayer.config );
                    this.refreshLayerInterval(updateLayer.group, updateLayer.config );


                },updateLayer.config.updateInterval * 1000);
            });
        }else{
            this.logging.logDebug('no Layer will Update');
        }

    };


    // private refreshLayerInterval(layer: Layer<XYZ, any>, bufferLayer?: Layer<XYZ, any> | undefined, config?: any | undefined) {
    private refreshLayerInterval(group: LayerGroup, config?: any | undefined) {
        // let _sceneLayers = [];
        const ovmLayers =  this.map.getLayers().getArray() as Layer<XYZ, any>[];
        const ovmTileLayers = ovmLayers.filter(x => x.get('apiLayer'));

        const sceneLayer = {
            layer: null,
            buffer: null,
        };

        // this.logging.logDebug("ovmTileLayers ", ovmTileLayers)
        // if (ovmTileLayers?.length) {
        //     _sceneLayers = ovmTileLayers.filter(x => x.get('apiLayer').id === bufferLayer.get('apiLayer').id );
        // }


        group.getLayers().forEach( (_layer) => {
            if(_layer.get('isBuffer')){
                sceneLayer.buffer = _layer;
            }else{
                sceneLayer.layer = _layer;
            }
        });


        if(sceneLayer.buffer && config){
            setTimeout( () => {

                // sceneLayer.layer.setOpacity(0);
                // sceneLayer.layer.getSource().setProperties({interpolate: true}, true);
                sceneLayer.layer.setVisible(false);
                sceneLayer.layer.set('isBuffer', true);
                sceneLayer.buffer.set('isBuffer', false);

                // this.refreshOverviewMap(bufferLayer.get('apiLayer').id);

            }, ( config.swapDelay) * 1000  );

            // sceneLayer.buffer.getSource().refresh();
            this.refreshLayer(sceneLayer.buffer);

            // this.map.renderSync();
            sceneLayer.buffer.setVisible(true);
        }
    }

    private refreshLayer(layer: Layer<XYZ, any>) {
        // this.lastTileTimestamp = new Date().getTime();
        this.layersService.resetTimestamp();
        layer.getSource().refresh();
        // this.refreshOverviewMap(layer.get('apiLayer').id);
    }

    private refreshOverviewMap(layerId: string) {
        const sceneLayer = this.getLayerByApiLayerIdFromOverviewMap(layerId);
        if (sceneLayer) {
            sceneLayer.getSource().refresh();
        }
    }

    private getLayerByApiLayerIdFromOverviewMap(apiLayerId: string):  Layer<XYZ, any> | undefined{
        const ovmLayers = this.overviewMapControl.getOverviewMap().getLayers().getArray() as Layer<XYZ, any>[];
        const ovmTileLayers = ovmLayers.filter(x => x.get('apiLayer'));

        if (ovmTileLayers?.length) {
            return ovmTileLayers.find(x => x.get('apiLayer').id === apiLayerId) as Layer<XYZ, any>;
        }
    }

    private setupStaticLayers() {
        this.temporaryDataLayer.set('name', 'temp');

        this.markerLayer = new VectorLayer({
            className: 'layer-{user-marker-layer}',
            source: new VectorSource({ features: [] }),
            style: (f: Feature<Geometry>) => {
                const markerConfig = f.get('markerConfig') as ApiConfigMarker;
                const isOffline = f.get('isOffline');
                return this.markerService.markerStyle(markerConfig, false, isOffline);
            }
        });

        this.systemMarkerLayer = new VectorLayer({
            className: 'layer-{system-marker-layer}',
            source: new VectorSource({ features: [] }),
            style: (f: Feature<Geometry>) => {
                const markerConfig = f.get('markerConfig') as ApiConfigMarker;
                const isOffline = f.get('isOffline');
                return this.markerService.markerStyle(markerConfig, false, isOffline);
            }
        });

        this.markerLayer.set('ignore', true);
        this.markerLayer.set('name', 'userMarker');
        this.markerLayer.setMaxResolution(60);

        this.systemMarkerLayer.set('ignore', true);
        this.systemMarkerLayer.set('name', 'systemMarker');
        this.systemMarkerLayer.setMaxResolution(60);

        this.backgroundTileLayer.on('error', event => {
            this.logging.log('Error: ', event);
        });
    }

    private addBackgroundLayers(appMapLayers: AppMapLayer[]) {
        this.backgroundLayers3 = appMapLayers;

        if (this.backgroundLayers3.length > 0) {
            const restored = this.restoreBackgroundLayerState();
            if (!restored) {
                const bgLayer = this.backgroundLayers3[0];
                bgLayer.selected = true;
                this.changeBackgroundLayer3(bgLayer);
            }
        }
    }

    private addLayers(layers: Layer<any, any>[]) {
        layers.map(x => this.layers[x.get('apiLayer').id] = x);


        for (const layer of layers) {
            this.map.addLayer(layer);
        }

        this.systemMarkerLayer.setZIndex(this.map.getLayers().getLength() + 1);
        this.systemMarkerLayer.setMinZoom(16);
        // this.map.addLayer(this.systemMarkerLayer);

        this.markerLayer.setZIndex(this.map.getLayers().getLength() + 1);
        this.markerLayer.setMinZoom(16);
        // this.map.addLayer(this.markerLayer);

        this.markerLayerGroup = new LayerGroup({
            layers: [
                this.systemMarkerLayer,
                this.markerLayer
            ]
        });

        this.markerLayerGroup.setZIndex(this.map.getLayers().getLength() + 1);
        this.markerLayerGroup.set('name', 'Marker Group Layer');
        this.markerLayerGroup.set('apiLayer', { name: $localize`:@@marker:Marker`, id: '{marker-group-layer}' });
        this.markerLayerGroup.set('isSystem', true);

        this.map.addLayer(this.markerLayerGroup);

        this.temporaryDataLayer.setZIndex(this.map.getLayers().getLength() + 1);
        this.map.addLayer(this.temporaryDataLayer);

        this.bboxLayer.setZIndex(this.map.getLayers().getLength() + 1);
        this.drawLayer.setZIndex(this.map.getLayers().getLength() + 2);

        if(Config.frontend.layerRefreshActive){
            this.triggerLayerUpdate();
        }

        this.mapLayers = this.map.getLayers().getArray();

        this.addClickOnLayerEvent();
        this.addOverViewMap();
    }

    public toggleOverViewMap() {
        const isCollapsed = this.overviewMapControl.getCollapsed();
        if (isCollapsed) {
            this.overviewMapControl.setCollapsed(false);
        } else {
            this.overviewMapControl.setCollapsed(true);
        }
        this.overviewMapControlCollapsed = this.overviewMapControl.getCollapsed();
    }

    private addOverViewMap() {
        const overViewLayers: any[] = [this.overviewMapBackgroundLayer];

        const gisLayers = this.mapLayers
            .map(x => (x.get('overviewmap') === true) ? x : undefined)
            .filter(layer => !!layer) as TileLayer<any>[];

        let gl: any;
        if(Config.frontend.overview.renderLayers){
            for (gl of gisLayers) {
                // console.log('ovLayer ', gl.get('apiLayer'), gl);
                // const apiLayer = gl.get('apiLayer');
                let _layer: TileLayer<any> | undefined;
                if( gl instanceof TileLayer){
                    _layer = gl;
                }else if(gl instanceof LayerGroup){
                    _layer = (gl.getLayers().getArray()[0] as TileLayer<any> );
                }

                if(!!_layer){
                    // const newLayer = new TileLayer({
                    //     // source: new XYZ({
                    //     //     transition: 0,
                    //     //     tileUrlFunction: (tileCoord, p1, p2) => this.layersService.tileUrl(tileCoord, p1, p2, apiLayer, 'overviewmap')
                    //     // }),
                    //     source: _source,
                    //     zIndex: gl.getZIndex(),
                    //     preload: 100,
                    // });

                    const newLayer = new TileLayer({
                        ..._layer.getProperties(),
                        zIndex: _layer.getZIndex(),
                        preload: 100
                    });

                    const _maxZoom = (Config.frontend.overview.maxZoom !== undefined) ? Config.frontend.overview.maxZoom : _layer.getMaxZoom();
                    const _minZoom = (Config.frontend.overview.minZoom !== undefined) ? Config. frontend.overview.minZoom: _layer.getMinZoom();

                    newLayer.set('apiLayer', gl.get('apiLayer'));
                    newLayer.set('isBackground', false);
                    newLayer.set('minZoom', _minZoom);
                    newLayer.set('maxZoom', _maxZoom);

                    overViewLayers.push(newLayer);
                }
            }
        }

        this.overviewMapControl = new OverviewMap({
            className: 'ol-overviewmap ol-custom-overviewmap',
            layers: overViewLayers,
            collapsed: this.overviewMapControlCollapsed,
            view: new View({
                projection: this.map.getView().getProjection()
            })
        });

        this.map.addControl(this.overviewMapControl);
        this.overviewMapLayers = overViewLayers;

    }

    private printLayers(delay = 5000) {
        setTimeout(() => {
            this.map.getLayers().getArray().forEach(x => {
                this.logging.log('', {zIndex: x.getZIndex(), x: x.get('name')});
            });
        }, delay);
    }

    private setupOverlay() {
        const el = document.getElementById('map-popup');

        this.overlay = new Overlay({
            element: el,
            autoPan: {
                animation: {
                    duration: 250,
                },
                margin: 50
            }
        });
    }

    private setupDownloadAreaInteraction() {
        const drawSource = new VectorSource();

        this.drawLayer = new VectorLayer({
            source: drawSource,
            style: f => this.styleService.downloadAreaStyle()

        });

        this.drawLayer.set('name', 'Draw Box');

        this.drawDownloadAreaInteraction = new DragAndDrop({
            source: drawSource,
            projection: this.projectionsService.epsg3857Projection
        });
    }

    private setupMap(layers = []) {
        this.setupStaticLayers();
        this.setupOverlay();
        this.setupDownloadAreaInteraction();

        const startCenter = fromLonLat([this.startLon, this.startLat], this.projectionsService.epsg3857Projection);

        this.bboxLayer = new VectorLayer({
            zIndex: 10,
            source: new VectorSource({
                features: []
            }),
            // style: (f) => new Style({
            //     stroke: new Stroke({ width: 2, color: 'red' })
            // })
        });

        this.bboxLayer.set('name', 'bbox');


        const animateBbox = (event) => {
            if(event.target.getSource().getFeatures().length > 0){
                const _target =  event.target.getSource().getFeatures()[0];
                if(_target.getStyle().length === 2){
                    const _rot = _target.getStyle()[1].getImage().getRotation();
                    _target.getStyle()[1].getImage().setRotation(_rot + 0.01);
                    event.target.changed();
                    this.map.render();
                }
            }
        };
        this.bboxLayer.on('postrender', animateBbox);

        let transformedBbox;

        if (this.startBBox.length === 4) {
            transformedBbox = transformExtent(this.startBBox, this.projectionsService.epsg4326Projection, this.projectionsService.epsg3857Projection);
        }



        const stepZoomMode = !Config.frontend.view.stepZoom.enabled;
        const shiftDragZoom: boolean = Config.frontend.view.shiftDragZoom;
        this.map = new olMap({
            pixelRatio: window.devicePixelRatio,
            moveTolerance: 10,
            maxTilesLoading: 20,
            interactions: defaultInteractions({
                pinchRotate: false,
                shiftDragZoom,
                mouseWheelZoom: stepZoomMode,
                onFocusOnly: true,
                dragPan: true,
                altShiftDragRotate: false
            }),
            controls: defaultControls(),
            // controls: [],
            layers: [
                this.backgroundTileLayer,
                this.backgroundVectorLayer,
                ...layers,
                this.bboxLayer,
                this.drawLayer
            ],
            overlays: [this.overlay],
            target: document.getElementById('map'),
            view: new View({
                projection: this.projectionsService.epsg3857Projection,
                zoom: this.startZoom,
                minZoom: this.appConfig.frontend.view.minZoom,
                maxZoom: this.appConfig.frontend.view.maxZoom,
                center: startCenter,
                extent: transformedBbox
            })
        });


        if (environment.develop || environment.staging) {
            (window as any).mainMap = this.map;
        }

        this.map.on('moveend', () => {
            this.mapMoveEnd.next();
        });

        const getTouchDistance = (t1, t2) => Math.sqrt(Math.pow(t1.clientX - t2.clientX, 2) + Math.pow(t1.clientY - t2.clientY, 2));
        const center_mouse_Vector = ( mapCenter: any, mousePosition: any, zoom: number ) => {
            const dz = (zoom / (zoom * 2));

            const dx = ((mousePosition[0] - mapCenter[0]) * dz) + mapCenter[0];
            const dy =( (mousePosition[1] - mapCenter[1]) * dz) + mapCenter[1];

            return [dx, dy];
        };

        const addSteppZoomEvents = () => {
            let touchDistance = 0;

            // TouchEmulator();
            this.mapContainer.nativeElement.addEventListener('touchstart', (e) => {
                // e.preventDefault();
                // e.stopPropagation();
                if(e.targetTouches.length === 2){
                    const touch1 = e.targetTouches[0]; //touchMock;
                    const touch2 = e.targetTouches[1];
                    touchDistance = getTouchDistance(touch1, touch2);
                }
            }, false);

            this.mapContainer.nativeElement.addEventListener('touchmove', (e) => {
                // e.preventDefault();
                // e.stopPropagation();
                if(e.targetTouches.length === 2) {
                    const touch1 = e.targetTouches[0]; //touchMock;
                    const touch2 = e.targetTouches[1];
                    const newDistance = getTouchDistance(touch1, touch2);
                    zoomDistance( touchDistance - newDistance, this.map.getEventCoordinate(e.targetTouches[0]));
                }
            }, false);

            this.mapContainer.nativeElement.addEventListener('wheel', (e) => {
                // e.preventDefault();
                // e.stopPropagation();
                zoomDistance(e.deltaY, this.map.getEventCoordinate(e) as Array<number>);
            });
        };



        let maxZoomLevel = 0;
        let animationRunning = false;

        const zoomDistance = (distance: number, center: number[] ) => {
            const ANIMATION_TIME = (Config.frontend.view.stepZoom.options.animationTime !== undefined) ?
                Config.frontend.view.stepZoom.options.animationTime : 0;
            const INPUTDELAY_TIME  = (Config.frontend.view.stepZoom.options.inputDelay !== undefined) ?
                Config.frontend.view.stepZoom.options.inputDelay : 200;
            const INPUTDISTANCE_PIXEL  = (Config.frontend.view.stepZoom.options.inputTriggerDistance !== undefined) ?
                Config.frontend.view.stepZoom.options.inputTriggerDistance : 20;

            if(!animationRunning){

                maxZoomLevel = (distance > maxZoomLevel || distance < (-1 * maxZoomLevel)) ? distance : maxZoomLevel;
                const _view = this.map.getView();
                let zoom;
                // console.log('zoom', zoom, maxZoomLevel);
                if(maxZoomLevel >= INPUTDISTANCE_PIXEL ){ //zoom Out
                    // zoom = Math.round(_view.getZoom()) - 1;
                    zoom = this.lastZoom - 1;
                    // center = _view.getCenter();
                    center = center_mouse_Vector(_view.getCenter(), center, zoom);
                }
                if(maxZoomLevel <= (INPUTDISTANCE_PIXEL * -1)){ //zoom In
                    zoom = this.lastZoom + 1;
                    center = center_mouse_Vector(center, _view.getCenter(), zoom);
                    // center = _view.getCenter();
                }
                if(zoom){
                    animationRunning = true;
                    _view.animate({
                        zoom,
                        center,
                        duration: ANIMATION_TIME,
                    }, (e) => {
                        setTimeout(() => {
                            animationRunning = false;
                            this.mapMoved();
                        }, INPUTDELAY_TIME );
                    });

                }
                maxZoomLevel = 0;
            }

        };

        this.projectionsService.currentProjection = this.map.getView().getProjection();

        if(Config.frontend.view.stepZoom.enabled){
            addSteppZoomEvents();
        }

        this.addInteractions();
    }

    private locateExpectedMarker(position) {
        if (this.expectedMarker && position) {
            // this.map.getView().setCenter(position);
            this.map.getView().animate({
                center: position,
                duration: 500,
            });
            this.expectedMarker = undefined;
        }
    }

    private retrieveMarkers() {
        const viewBbox = this.map.getView().calculateExtent(this.map.getSize());

        const transformedViewBox = transform(viewBbox, this.map.getView().getProjection(), this.projectionsService.epsg25832Projection);
        let centerOnMarker = false;
        let centerForMarker;

        this.markerService
            .getMarkerForArea(transformedViewBox)
            .pipe(tap((markers) => {

                const newMarkers = [];
                const systemMarkers = [];

                for (const marker of markers) {
                    const markerType = marker.content;
                    const markerConfig = this.markerService.getMarkerConfigForMarkerType(markerType);
                    // const style = this.markerService.markerStyle(markerConfig);
                    const coords = [marker.x, marker.y];
                    const transformed = transform(coords, this.projectionsService.epsg25832Projection, this.map.getView().getProjection());
                    const feature = new Feature(new Point(transformed));
                    const vivavisId = marker.vivavisId;
                    const id = 'marker-' + marker.vivavisId + '-' + markerType + '-' + MD5(coords.toString());
                    feature.setId(id);
                    feature.set('vivavisId', marker.vivavisId);
                    feature.set('markerType', markerType);
                    feature.set('markerConfig', markerConfig);
                    feature.set('offlineRemoved', marker.isOfflineRemoved);
                    feature.set('offlineSet', marker.isOfflineSet);
                    feature.set('isOffline', { set: marker.isOfflineSet, removed: marker.isOfflineRemoved });
                    feature.set('selected', false);
                    // feature.setStyle(style);

                    if (this.expectedMarker && this.expectedMarker.markerType === markerType && this.expectedMarker.vivavisId === vivavisId) {
                        centerOnMarker = true;
                        centerForMarker = transformed;
                    }

                    if (markerType === MarkerType.NotNormal) {
                        systemMarkers.push(feature);
                    } else {
                        newMarkers.push(feature);
                    }
                }

                this.markerLayer.getSource().clear();
                this.markerLayer.getSource().addFeatures(newMarkers);

                this.systemMarkerLayer.getSource().clear();
                this.systemMarkerLayer.getSource().addFeatures(systemMarkers);

                if (centerOnMarker) {
                    this.locateExpectedMarker(centerForMarker);
                }

            }))
            // .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    private mapMoved() {

        this.updateMarker.next();

        if (!this.shouldUpdateUrl) {
            this.shouldUpdateUrl = true;
            return;
        }

        const centerRaw = this.map.getView().getCenter();
        const zoomLvl = this.map.getView().getZoom();
        const center = transform(centerRaw, this.projectionsService.currentProjection, this.projectionsService.epsg4326Projection);

        this.lastZoom = Math.round(zoomLvl);
        this.lastCenter = center;

        const queryParams = {
            lon: center[0],
            lat: center[1],
            zoom: zoomLvl,
            schema: this.startSchema,
            obisSchema: this.startObisSchema
        };

        // const state = {
        //     zoom: zoomLvl,
        //     center: centerRaw
        // };

        let query = ''
            + '?lat=' + center[1]
            + '&lon=' + center[0]
            + '&zoom=' + zoomLvl.toFixed(2);

        if (this.startSchema) {
            query += '&schema=' + this.startSchema;
        }

        if(this.startObisSchema){
            query += '&obisSchema=' + this.startObisSchema;
        }

        this.locationStrategy.pushState(queryParams, 'map', '/', query);
        this.saveMapState();
    }

    private clearHoveredFeature() {
        if (this.hoveredFeature) {
            if (!this.selectedFeature || this.hoveredFeature !== this.selectedFeature) {
                this.hoveredFeature.set('hovered', false);
                this.hoveredFeature.setStyle(null);
                this.hoveredFeature = null;
            }
        }
    }

    private clearSelectedFeature() {
        if (this.selectedFeature) {
            this.selectedFeature.set('selected', false);
            // this.selectedFeature.setStyle(null);
            this.selectedFeature = null;
            this.overlay.setPosition(undefined);
        }

        this.identData = [];
        this.bboxLayer.getSource().clear();
    }

    private addClickOnLayerEvent() {
        // const clickableLayers = Object.values(this.layers);

        this.map.on('singleclick', event => {
            event.preventDefault();
            event.stopPropagation();
            if (this.handledBySelect) {
                this.bboxLayer.getSource().clear();
                return;
            } else {
                this.clearSelectedFeature();
                const clickableLayers = this.map.getLayers().getArray()
                    .filter((_layer) => Object.values(this.layers)
                    .some( (__layer) => (_layer.getClassName() === __layer.getClassName()  &&  __layer.get('isVisible'))));


                const getFeatureRequest = (layer) => {
                    const apiLayer = layer.get('apiLayer') as ApiLayer;
                    this.logging.logDebug('click apiLayer', apiLayer);

                    if(apiLayer){

                        const req = this.apiIdent.getIdent(
                            apiLayer.foildId,
                            apiLayer.wsId,
                            this.buildIdentBbox(event.coordinate, this.getOffset()));
                        // requests.push(req);
                        return req;
                    }else{
                        return undefined;
                    }

                };

                const requests: Observable<ApiIdent[]>[] = clickableLayers.map( (layer) => getFeatureRequest(layer));


       /*
               this.map.forEachLayerAtPixel(event.pixel, (layer) => {
                    // const lonLat = toLonLat(event.coordinate, this.map.getView().getProjection());
                    const apiLayer = layer.get('apiLayer') as ApiLayer;

                    this.logging.log('Tile Click', { Coordinates: event.coordinate, InLonLat: lonLat, inLayer: apiLayer.name} );

                    // const source = layer.getSource();
                    // const grid = source.getTileGrid();
                    // const zoom = this.map.getView().getZoom();

                    // const tileCoord = grid.getTileCoordForCoordAndZ(event.coordinate, Math.floor(zoom));
                    // const tileMatrix = tileCoord[0];
                    // const tileCol = tileCoord[1];
                    // const tileRow = tileCoord[2];
                    // const origin = grid.getOrigin(tileMatrix);
                    // const res = grid.getResolution(tileMatrix);
                    // const tileSize = grid.getTileSize(tileMatrix);

                    // eslint-disable-next-line no-bitwise
                    // const i = Math.floor(((event.coordinate[0] - origin[0]) / res) % (tileSize[0] | tileSize as number));
                    // eslint-disable-next-line no-bitwise
                    // const j = Math.floor(((origin[1] - event.coordinate[1]) / res) % (tileSize[1] | tileSize as number));


                    const resolutionForZoom = this.map.getView().getResolutionForZoom(this.map.getView().getZoom());
                    const point = new Point(event.coordinate);
                    const extent = point.getExtent();

                    let offset = 7 * resolutionForZoom;
                    if (resolutionForZoom <= .15) {
                        // offset = .3;
                        offset = 1;
                    }
                    const a = extent[0] - offset;
                    const b = extent[1] - offset;
                    const c = extent[2] + offset;
                    const d = extent[3] + offset;

                    const tl = [extent[0] - offset, extent[1] - offset];
                    const tr = [extent[0] - offset, extent[1] + offset];

                    const br = [extent[0] + offset, extent[1] + offset];
                    const bl = [extent[0] + offset, extent[1] - offset];

                    const bbox = [a, b, c, d];

                    const polyCoords = [tl, tr, br, bl];
                    const pol = new Polygon([polyCoords]);
                    const feature = new Feature(pol);
                    feature.setId('bbox');
                    this.bboxLayer.getSource().addFeature(feature);

                    const req = this.apiIdent.getIdent(apiLayer.foildId, apiLayer.wsId, bbox);
                    requests.push(req);

                },
                    {
                        layerFilter: layer => clickableLayers.indexOf(layer) !== -1,
                        hitTolerance: 0
                    });
*/

                if (requests.length > 0) {
                    const feature = this.bboxLayer.getSource().getFeatureById('bbox');
                    const popupState = this.addMarkerMode ? PopupState.AddMarker : PopupState.Properties;
                    // console.log('-------- onClick Map ', requests,  this.activeMarker, popupState);
                    const _markersInIdent = [];
                    const _identBbox = this.buildIdentBboxFeature(event.coordinate, this.getOffset());

                    this.markerLayerGroup.getLayers().forEach( (_layer: VectorLayer<VectorSource>) => {
                        const _markerFeatures = _layer.getSource().getFeatures();
                        _markerFeatures.forEach( (_markerFeature) => {
                            _markersInIdent.push(_markerFeature.getProperties());
                        });
                    });

                    this.mapPopupConfig = {
                        identRequests: requests,
                        popupState,
                        activeMarker: this.activeMarker,
                        feature,
                        coordinates: {
                            x: event.coordinate[0],
                            y: event.coordinate[1]
                        },
                        markersInIdent: _markersInIdent
                    };
                    this.bboxLayer.getSource().addFeature(_identBbox);

                }
            }
        });
    }

    drawPopupFromIdent(event) {
        if(event){
            const feature = this.bboxLayer.getSource().getFeatureById('bbox');
            feature.unset('center');
            this.showPopupWithPosition(feature, [event.x, event.y]);
        }
    }

    private getOffset(){
        const _conf = Config.frontend.ident;
        let offset = 1;
        if(_conf.dependOnZoom){
            const resolutionForZoom = this.map.getView().getResolutionForZoom(this.map.getView().getZoom());
            offset = resolutionForZoom * _conf.offsetForBbox;
            if (resolutionForZoom <= .15) {
                // offset = .3;
                offset = 1;
            }
        }else{
            offset = _conf.offsetForBbox;
        }
        return offset;
    }

    private buildIdentBbox(coordinates, offset){
        const point = new Point(coordinates);
        const extent = point.getExtent();

        const a = extent[0] - offset;
        const b = extent[1] - offset;
        const c = extent[2] + offset;
        const d = extent[3] + offset;

        return [a, b, c, d];
    }

    private buildIdentBboxFeature(coordinates, offset){
        const point = new Point(coordinates);
        const extent = point.getExtent();


        const tl = [extent[0] - offset, extent[1] - offset];
        const tr = [extent[0] - offset, extent[1] + offset];

        const br = [extent[0] + offset, extent[1] + offset];
        const bl = [extent[0] + offset, extent[1] - offset];

        const polyCoords = [tl, tr, br, bl];

        const _style = [
            new Style({
                stroke: new Stroke({ width: 2, color: 'red' })
            }),

            new Style({
                geometry: 'center',
                image: new Circle({
                    radius: ((br[0] - tr[0]) * (1/this.map.getView().getResolution())) / 3,
                    rotation: 0,
                    stroke: new Stroke({
                        color: 'red',
                        width: 4,
                        lineDash: [1, 3],
                    }),
                })
            })

        ];
        const pol = new Polygon([polyCoords]);
        const feature = new Feature({
            geometry: pol,
            center: point
        });
        feature.setId('bbox');
        feature.setStyle(_style);
        return feature;
    }

    private addInteractions() {

        // this.selectMarkerInteraction = new Select({
        //     style: null,
        //     condition: click,
        //     hitTolerance: 5,
        //     layers: [this.markerLayer]
        // });
        //
        // this.hoverMarkerInteraction = new Select({
        //     style: (f: Feature<Geometry>) => {
        //         const markerType = f.get('markerType');
        //         const cfg = this.markerService.getMarkerConfigForMarkerType(markerType);
        //         const isOffline = f.get('isOffline');
        //         return this.markerService.markerStyle(cfg, true, isOffline);
        //     },
        //     condition: pointerMove,
        //     hitTolerance: 5,
        //     layers: [this.markerLayer]
        // });

        // this.map.addInteraction(this.selectMarkerInteraction);
        // this.map.addInteraction(this.hoverMarkerInteraction);

        // this.selectMarkerInteraction.on('select', event => {
        //     this.handledBySelect = true;
        //     event.stopPropagation();
        //     event.preventDefault();
        //     event.mapBrowserEvent.preventDefault();
        //     event.mapBrowserEvent.stopPropagation();
        //
        //     if (event.selected.length === 0) {
        //
        //         this.clearSelectedFeature();
        //
        //     } else if (event.selected.length === 1) {
        //
        //         const selectedFeature = event.selected[0];
        //         const offlineRemoved = selectedFeature.get('offlineRemoved');
        //         const offlineSet = selectedFeature.get('offlineSet');
        //
        //         if (!offlineRemoved || !offlineSet) {
        //
        //             this.selectedFeature = selectedFeature;
        //             const markerType = selectedFeature.get('markerType');
        //             selectedFeature.set('selected', true);
        //             const activeMarker = this.markerService.getMarkerConfigForMarkerType(markerType);
        //             console.log('onClick Marker', event, selectedFeature, markerType, activeMarker );
        //
        //             this.mapPopupConfig = {
        //                 popupState: PopupState.Delete,
        //                 vivavisId: selectedFeature.get('vivavisId'),
        //                 activeMarker
        //             };
        //
        //             this.showPopupWithPosition(selectedFeature, getCenter(selectedFeature.getGeometry().getExtent()), true);
        //         }
        //         this.selectMarkerInteraction.getFeatures().clear();
        //
        //         setTimeout(() => this.handledBySelect = false, 1000);
        //     }
        // });
        //
        // this.hoverMarkerInteraction.on('select', event => {
        //     event.stopPropagation();
        //     event.preventDefault();
        //     event.mapBrowserEvent.preventDefault();
        //     event.mapBrowserEvent.stopPropagation();
        //
        //     if (event.selected.length === 1) {
        //
        //         const selectedFeature = event.selected[0];
        //         const offlineRemoved = selectedFeature.get('offlineRemoved');
        //         const offlineSet = selectedFeature.get('offlineSet');
        //
        //         if (!offlineRemoved || !offlineSet) {
        //             this.map.getViewport().style.cursor = 'pointer';
        //         }
        //
        //     } else {
        //         this.map.getViewport().style.cursor = 'unset';
        //     }
        //
        // });


    }

    private showPopupWithPosition(f: Feature<Geometry>, position, isMarkerLayer = false) {
        this.logging.logDebug('showPopup2, pos', position);
        this.selectedFeature = f;

        if (isMarkerLayer) {
            this.overlay.setOffset([0, -15]);
        } else {
            this.overlay.setOffset([0, 0]);
        }
        this.overlay.setPosition(position);
    }

    private showPopup(f: Feature<Geometry>, isMarkerLayer = false,) {
        this.selectedFeature = f;

        if (this.selectedFeature) {
            const ex = this.selectedFeature.getGeometry().getExtent();
            const center = getCenter(ex);
            if (isMarkerLayer) {
                this.overlay.setOffset([0, -15]);
            } else {
                this.overlay.setOffset([0, 0]);
            }
            this.overlay.setPosition(center);
        }
    }

    private handleTileLoadError(event: BaseEvent, selectedBgLayer: TileLayer<TileSource>) {
        selectedBgLayer.set('hasError', true);
    }
}


// @Component({
//     selector: 'app-debug-dialog',
//     template: `<pre>{{printData}}</pre>`
// })
// export class MapDebugDialogComponent implements OnInit {

//     public data = {};
//     public printData = '';
//     constructor(
//         public dialogRef: MatDialogRef<MapDebugDialogComponent>
//     ) {
//         const keys = Object.keys(platform).filter(k => typeof(platform[k]) === 'string');
//         for (const k of keys) {
//             this.data[k] = platform[k];
//         }

//         this.printData = JSON.stringify(platform, null, 2);
//     }

//     ngOnInit() {
//     }
// }

