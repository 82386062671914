import { getCenter } from 'ol/extent';

import { CustomConfigService } from '../custom.config.service';
import { DewaIdentGrafanaComponent } from './dewa-ident-grafana/dewa-ident-grafana.component';

export class DewaCustomConfigService extends CustomConfigService {

    override searchResultTransformCoordinates(originalCoords: number[]): number[] {
        let coords: number[];
        if (originalCoords[0] === 0 && originalCoords[1] === 0) {
            coords = getCenter([6150169.271144253, 2899394.5010252534, 6150486.250185148, 2899711.480066148]);
        } else {
            coords = originalCoords;
        }
        return coords;
    }

    override popupPanelTitle(prop: any): string {
        const sapNumber = 'SAP number';
        const shortName = 'Element short name';
        const shotNameObj = prop.data[shortName];
        if (shotNameObj) {
            return prop.data[shortName];
        } else {
            return prop.data[sapNumber];
        }
    }

    popupPanelTitle_old(prop: any): string {
        const sapNumber = 'SAP number';
        const shortName = 'Element short name';
        const snd = prop.data[sapNumber];
        if (snd === '0' || snd === 0) {
            return prop.data[shortName];
        } else {
            return snd;
        }
    }

    override identDetailsComponent<T>(prop: T) {
        return { component: DewaIdentGrafanaComponent, data: prop };
    }


}
