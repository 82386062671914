import { Injectable } from '@angular/core';
import { Subject, Observable, firstValueFrom, BehaviorSubject } from 'rxjs';
import { scan, shareReplay, startWith, tap, map, filter, merge } from 'rxjs/operators';
import { ApiStateValueService } from 'src/app/shared/api-services/api-statevalue.service';
import { ActionHistoryItem, ActionHistoryTypes } from './action-history.interface';
import { ConsoleLoggingService } from '../../shared/services/console-logging.service';

@Injectable({
    providedIn: 'root'
})
export class ActionHistoryService {

    private storageKey = 'userHistory';
    // private history: ActionHistoryItem[] = [];
    private subject = new BehaviorSubject<ActionHistoryItem[]>(this.restore());
    // private addEntrySubject = new Subject<ActionHistoryItem>();

    public history$: Observable<ActionHistoryItem[]> = this.subject.asObservable().pipe(
        // startWith(this.restore()),
        scan((acc: ActionHistoryItem[], st: ActionHistoryItem[]) => [...acc, ...st]),
        map((x: ActionHistoryItem[]) => x.sort(this.sort.bind(this))),
        tap(console.log),
        tap(data => this.store(data)),
        shareReplay(1)
    );

    constructor( private logging: ConsoleLoggingService) {
        this.history$.subscribe();
    }

    public addHistoryEntry(partItem: Omit<ActionHistoryItem, 'id' | 'date'>) {
        let title = '';

        switch (partItem.type) {
            case ActionHistoryTypes.DeleteMarker:
                title = $localize`:@@actionQueue.element.marker.delete:Delete Marker`;
                break;
            case ActionHistoryTypes.SetMarker:
                title = $localize`:@@actionQueue.element.marker.add:Set Marker`;
                break;
            case ActionHistoryTypes.SwitchOff:
                title = $localize`:@@schema.state.OFF:OFF`;
                break;
            case ActionHistoryTypes.SwitchOn:
                title = $localize`:@@schema.state.ON:ON`;
                break;
        }

        const id = Math.random() * 11;

        // this.history.push({
        //     id,
        //     date: new Date(),
        //     ...partItem,
        //     title
        // });

        this.subject.next([{
            id,
            date: new Date(),
            ...partItem,
            title
        }]);

        // this.subject.next(this.history);

        return id;
    }

    // public async updateHistoryEntry(id: number, result: any) {
    //     // const entries = await firstValueFrom(this.history$);
    //     const entryIndex = this.history.findIndex(x => x.id === id);

    //     if (entryIndex !== -1) {
    //         this.history[entryIndex].result = result;
    //         // entry.result = result;
    //         this.subject.next(this.history);
    //     }
    // }

    private store(data) {
        localStorage.setItem(this.storageKey, JSON.stringify(data));
    }

    private restore(): ActionHistoryItem[] {
        let historyData = [];

        try {

            const json = localStorage.getItem(this.storageKey);

            if (json) {
                const data = JSON.parse(json);

                if (data && Array.isArray(data)) {
                    historyData = data.map(x => {
                        x.date = new Date(x.date);
                        return x;
                    });
                }
            }

        } catch (e) {
            this.logging.log('Error: ', e);
        }

        return historyData;
    }

    private sort(a: ActionHistoryItem, b: ActionHistoryItem) {
        return b.date.getTime() - a.date.getTime();
        // return a.date.getTime() - b.date.getTime();
    }

}
