import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Config from 'src/app/config/Config';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiMapdownloadService {
    constructor(private httpClient: HttpClient) { }

    /**
     * Mobile Only
     * Downloads Tiles into local File Cache
     *
     * @param mapId id of background map
     * @param bbox bbox to download
     * @returns HTTP Observable
     */
    download(mapId: string, bbox: number[]) {
        const httpParams = new HttpParams()
            .set('mapId', mapId)
            // .set('srs', Config.frontend?.srs || '3857')
            .set('srs', Config.frontend?.srs)
            .set('bbox', bbox.join(','));
        const url = `${environment.apiRoot}${Config.Api.mapdownload}`;
        return this.httpClient.get(url, { params: httpParams });
    }
}
