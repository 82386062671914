import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiConfigMarker} from '../../../shared/interfaces/api-marker';
import {MapService} from '../../services/map.service';
import {MarkerService} from '../../services/marker.service';
// import {MapSettingsStateService} from '../../map-settings/map-settings-state.service';
// import {MapSettingStateChange} from '../../map-settings/map-settings-state-change.enum';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-marker-menu-item',
    templateUrl: './marker-menu-item.component.html',
    styleUrls: ['./marker-menu-item.component.scss']
})
export class MarkerMenuItemComponent implements OnInit, OnDestroy {

    @ViewChild('canvas') canvasRef: ElementRef;

    public backgroundStyle;

    private _marker: ApiConfigMarker;


    @Input()
    public set marker(value: ApiConfigMarker) {
        if (value) {
            this._marker = value;
            this.setBackgroundStyle();
        }
    };

    public get marker(): ApiConfigMarker {
        return this._marker;
    }

    private subscriptions = new Subscription();

    constructor(private mapService: MapService) {}

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public setBackgroundStyle() {
        this.backgroundStyle = this.mapService.getMarkerBackgroundStyle(this._marker);
    }


}
