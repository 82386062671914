
<ng-container [ngTemplateOutlet]="loadingIndicator"></ng-container>
<div mat-chip-list>
    <app-numeric-value
        *ngFor="let nv of numericValues"
        (clicked)="selectNumericValue($event, nv)"
        [config]="nv"
        [id]="nv.id"
        [addMarkerMode]="addMarkerMode"
        [historyEnabled]="enableGrafana"
        class="schema-numeric-value"
    ></app-numeric-value>
</div>

<ng-container *ngFor="let angledText of angledTextLabels">
    <ng-container [ngTemplateOutlet]="angledTextTemplate" [ngTemplateOutletContext]="{data: angledText}" ></ng-container>
</ng-container>

<ng-container *ngFor="let markerOverlay of markerOverlays">
    <div [id]="markerOverlay.elementId" class="marker-wrapper" style="display: none">
        <ul>
            <ng-container *ngIf="enabledMarker" >
                <li *ngFor="let marker of markerOverlay.markers"
                (click)="openDeleteMarkerMenu($event, marker, markerOverlay);$event.preventDefault()"
                class="marker-item {{marker.content}}"
                [ngClass]="{
                    'selected': selectedMarkerOverlayType === markerOverlay.vivavisId + '-' + marker.content,
                    'selectable': notSelectableMarkerLayerType !== marker.content,
                    'hidden': markerOverlay.hidden
                }"
                [appMarkerIcon]="marker"></li>
            </ng-container>

            <ng-container *ngIf="!enabledMarker" >
                <li *ngFor="let marker of markerOverlay.markers"
                class="marker-item {{marker.content}}"
                [appMarkerIcon]="marker"></li>
            </ng-container>

        </ul>
    </div>
</ng-container>

<div class="schema-grid">

    <div id="schema-map" #schemaMap [style.cursor]="cursorStyle" [ngClass]="{'hidden-map': collapsed}"></div>
    <div class="schema-title">
        <h3 class="schema-sub-title" #titleEl (click)="fullTitle(titleEl)">{{stationName}}</h3>
        <h4 class="schema-sub-title" #titleSubEl></h4>
    </div>
    <div class="schema-top-buttons" *ngIf="showTopButtons">
        <!-- <div *ngIf="hasMarker">
            <input type="checkbox" [(ngModel)]="!areMarkerHidden" (change)="toggleMarkerVisibility()" [disabled]="markerOverlays.length === 0" />
            <span i18n="@@marker">Marker</span>
        </div> -->
    </div>

<!--    <button-->
<!--        mat-raised-button-->
<!--        color="accent"-->
<!--        id="grafanaHistoryButton"-->
<!--        *ngIf="enableGrafana && showTopButtons"-->
<!--        [disabled]="selectedNumericValues.length === 0"-->
<!--        (click)="clickOpenGrafanaHistory()"-->
<!--        i18n="@@schema.grafana.history.button">-->
<!--            <p>History</p>-->
<!--    </button>-->




    <button id="closeButton" mat-mini-fab (click)="closeSchema()">
        <mat-icon class="mat-18">close</mat-icon>
    </button>

    <section
        id="grafanaHistoryButton"
        [matTooltip]="(selectedNumericValues.length === 0) ? triggerGrafanaHistoryButtonWithHint.innerText : triggerGrafanaHistoryButton.innerText"
        [matTooltipClass]="'toolTipMultiLine'"
    >
        <button
            mat-mini-fab
            color="accent"
            *ngIf="enableGrafana && showTopButtons"
            [disabled]="selectedNumericValues.length === 0"
            (click)="clickOpenGrafanaHistory()">
            <mat-icon class="mat-18">history</mat-icon>
        </button>
        <template
            #triggerGrafanaHistoryButton>
            <section
                i18n='@@schema.grafana.history.button'>
                History
            </section>
        </template>
        <template
            #triggerGrafanaHistoryButtonWithHint>
            <section
                i18n='@@schema.grafana.history.button'>
                History
            </section>
            <section
                i18n='@@schema.grafana.history.hint.button'>
                History Hint
            </section>
        </template>
    </section>

    <section
        id="triggerMapControls"
        [matTooltip]="triggerMapControlsTooltip.innerText"
    >
        <button
            mat-mini-fab
            color="accent"
            (click)="triggerMapControls()"
            [ngClass]="mapControlEnable ? 'triggerMapControlsEnabled' : ''">
            <mat-icon class="mat-18">pan_tool</mat-icon>
        </button>
        <template
            #triggerMapControlsTooltip
            i18n='@@schema.control.trigger.button'
        >Control</template>
    </section>


</div>

<ng-template #loadingIndicator>
    <div *ngIf="isLoading" class="loading-indicator" [style.cursor]="cursorStyle">
        <div>
            <span *ngIf="hasErrors && !hasErrorHints" class="material-icons-outlined has-errors">error</span>
            <span *ngIf="!hasErrors && hasErrorHints" class="material-icons-outlined has-error-hints">warning</span>
            <mat-progress-spinner *ngIf="!hasErrors && !hasErrorHints" mode="indeterminate" color="primary"></mat-progress-spinner>
        </div>
        <div class="status-box">
            <ul>
                <li *ngFor="let sm of statusMessages" [ngClass]="{
                    'loaded': sm.state === loadingIndicatorStates.Loaded,
                    'loading':  sm.state === loadingIndicatorStates.Loading,
                    'hint':  sm.state === loadingIndicatorStates.Hint,
                    'error-hint':  sm.state === loadingIndicatorStates.ErrorHint,
                    'error':  sm.state === loadingIndicatorStates.Error
                    }">{{sm.message}}</li>
            </ul>
            <button *ngIf="hasErrors || hasErrorHints"
            id="continue-with-errors"
            (click)="closeLoadingHint()"
             mat-raised-button color="primary" i18n="@@continue">Continue</button>
        </div>
    </div>
</ng-template>

<ng-template #angledTextTemplate let-data="data">
    <div
        [style.display]="'none'"
        [style.max-height]="data.width"
        [id]="data.id"
        [ngClass]="{'default': !data.selected && !addMarkerMode, 'selected': data.selected}"
        (click)="!addMarkerMode && selectAngledText(data)"
        class="angled-text-labels">{{data.text}}</div>
</ng-template>


<ng-template #deleteMarkerMenuTemplate let-data>
    <div class="mat-menu-panel delete-marker-menu" [style.min-height]="'unset'">
        <button id="delete-marker-button" mat-menu-item color="warn" (click)="removeMarker(data)"><mat-icon>delete</mat-icon><span i18n="@@delete">Delete</span></button>
    </div>
</ng-template>

<ng-template #errorTemplate>
    <div>
        <h3>Error: Operation UnknownValue</h3>
        <button mat-button (click)="errorDialogRef.close()">Close</button>
    </div>
</ng-template>
