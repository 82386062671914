<div  [hidden]="!identData || identData.length === 0" class="ident-popup">
    <div class="map-popup-content">
        <div class="properties-table">
            <ng-container *ngIf="isMultiple">
                <mat-accordion>
                    <ng-container *ngFor="let prop of identData; let i = index">
                        <mat-expansion-panel (afterExpand)="expandedElement(i)" #panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="justify-content: space-between;">
                                        <div style="padding-right: 15px;">{{prop | popupPanelTitle}}</div>
                                        <div style="width: 24px; height: 25px;">
                                            <mat-icon *ngIf="prop.meta?.SchemabildNummer" style="transform: rotate(90deg)">schema</mat-icon>
                                        </div>
                                </mat-panel-title>
                                <section *ngIf="prop.markers">
                                    <mat-panel-description *ngIf="prop.markers.length > 0" class="panelHeaderIcons">
                                        <img class="mat-icon" *ngFor="let marker of prop.markers" [src]="marker.markerConfig.imgSrc">
                                    </mat-panel-description>
                                </section>
                            </mat-expansion-panel-header>
                            <app-ident-details
                                [identDataItem]="prop"
                                [measurements]="measurements"
                                (markerDeleteEvent)="processEventDeleteMarker($event)"
                            ></app-ident-details>

                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
            </ng-container>

            <ng-container *ngIf="!isMultiple && identData">

                <app-ident-details
                    [identDataItem]="identData[0]"
                    [measurements]="measurements"
                    (markerDeleteEvent)="processEventDeleteMarker($event)"
                ></app-ident-details>

            </ng-container>
        </div>

    </div>
</div>


